import { compose, withHandlers, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';

// actions
import { saveUserData, saveUserAccesses } from '../../store/actions/auth';

// services
import AuthService from '../../services/AuthService';

// views
import LoginView from './LoginView';

export default compose(
  connect(
    (state) => ({
      token: state.auth.token,
    }),
    (dispatch) => ({
      saveUserData: (data) => dispatch(saveUserData(data)),
      saveUserAccesses: (data) => dispatch(saveUserAccesses(data)),
    }),
  ),
  withState('login', 'setLogin', null),
  withState('password', 'setPassword', null),
  withState('error', 'setError', null),
  withState('isLoading', 'setIsLoading', false),
  withState('emptyFields', 'setEmptyFields', []),
  withState('showEmptyFields', 'setShowEmptyFields', false),
  withState('showErrorsFields', 'setShowErrorsFields', false),
  withHandlers({
    submitForm: (props) => async () => {
      const { login, password } = props;

      const emptyFields = [];
      if (!login) emptyFields.push('login');
      if (!password) emptyFields.push('password');
      props.setEmptyFields(emptyFields);
      props.setShowErrorsFields(false);
      if (emptyFields.length) {
        props.setShowEmptyFields(true);
        return;
      }

      props.setIsLoading(true);

      const auth = new AuthService();
      try {
        const result = await auth.login(login, password);
        props.setIsLoading(false);
        await props.saveUserData(result.userData);
        await props.saveUserAccesses(result.accesses);
        props.history.push('/dashboard');
      } catch (error) {
        console.log('Error: ', error);
        props.setError(error);
        setTimeout(() => {
          props.setError(null);
        }, 10000);
        props.setIsLoading(false);
        props.setShowErrorsFields(true);
      }
    },
  }),
  withHandlers({
    submitEvent: (props) => async (e) => {
      if (e.keyCode === 13) {
        props.submitForm();
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const auth = new AuthService();
      if (auth.loggedIn()) {
        this.props.history.push('/dashboard');
      }
    },
  }),
)(LoginView);
