import {
  compose,
  withState,
  lifecycle,
  withHandlers,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import validator from 'validator';

// actions
import { cleanUserData, saveUserAccesses } from '../../../store/actions/auth';
import {
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  setSecondaryBarItems,
  checkStatusPopup,
  setToggleLoad,
} from '../../../store/actions/dashboard';

import {
  AuthService,
  DirectoryService,
  UserService,
  directoryServiceMap,
  UserPayersService,
  UserCounterpartyService,
} from '../../../services';

// views
import PayerView from './DirectoriesView';

let infinitScrollRef = null;

export default compose(
  connect(
    (state) => ({
      user: state.auth.currentUser,
      secondaryBarItems: state.dashboard.secondaryBarItems,
      activeSecondaryItem: state.dashboard.activeSecondaryItem,
      checkStatus: state.dashboard.checkStatusPopup,
      access: state.auth.userAccesses,
      toggleLoad: state.dashboard.toggleLoad,
    }),
    (dispatch) => ({
      saveUserAccesses: (data) => dispatch(saveUserAccesses(data)),
      cleanUserData: () => dispatch(cleanUserData()),
      setSecondaryBarItems: (items) => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: (item) => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: (item) =>
        dispatch(setActiveSecondarySidebarItem(item)),
      checkStatusPopup: (item) => dispatch(checkStatusPopup(item)),
      setToggleLoad: (item) => dispatch(setToggleLoad(item)),
    }),
  ),
  // список сущностей на странице
  withState('payerList', 'setPayerList', []),
  // withState('payerCardDefault', 'setPayerCardDefault', []),
  // withState('valueSelection', 'setValueSelection', null),

  // отображает правый сайдбар
  withState('toggleOn', 'toggle', false),

  // активный таб в сайдбаре. Нужно когда у сущности есть привязки
  withState('activeID', 'setActiveID', ''),

  // управляет попапом для удаления
  withState('delPopup', 'setPopupVisible', false),

  // сохраняет ид сущности для удаления при нажатии на крестик
  // после использует этот ид когда будет положительный ответ попапа
  withState('toRemove', 'setToRemove', null),

  // data for sidebar
  // withState('entityName', 'setEntityName', ''),

  // содержит в себе данные для отображения в правом сайдбаре
  // инпуты, чекбоксы, линки на другие сущности
  withState('blockData', 'setBlockData', {}),

  // нужно для карточки с привяками
  // когда edit === true, то отображается таб бар
  // у таб бара с ид 0 табе мы можем сделать привязку с уже созданными сущностями
  // у таб бара с ид 1 видна форма для создания новой сущности и мновенной привязке
  withState('edit', 'setEdit', false),

  // состояни для привязки пользователей к сущности
  // отличается тем, что открывается форма со списком пользователей
  // без таб баров и соответсвенно создания пользователя сразу из карточки сущности
  withState('linkUser', 'setLinkUser', false),

  // устанавливае какой сервис будет текущим
  withState('service', 'setService', null),

  // опять, когда у сущности есть привязки,
  // то нам надо знать с какой сущностью мы связаны
  withState('linkedService', 'setLinkedService', null),

  // список существующих сущностей
  withState('itemsForLink', 'setItemsForLink', []),

  // настройки, в режиме редактирования показывает назвнаие
  // редактируемой сущности
  withState('forLinkTitle', 'setForLinkTitle', null),

  // структура формы сущности для привязки
  withState('linkedServiceForm', 'setLinkedServiceForm', {}),

  // структура формы основной сущности
  withState('serviceForm', 'setServiceForm', {}),

  // когда в карточке создаем новую сущность для привязке,
  // то здесь мы храним значения инпутов которые ввели
  withState('linkedServiceInputState', 'setLinkedServiceInputState', {}),

  // хуй знает)))
  withState('showCreateForm', 'setShowCreateForm', false),

  // следим за инпутами в новой карточке
  withState('newItemData', 'setNewItemData', {}),

  // активный таб
  withState('activeTab', 'setActiveTab', 0),

  // следим за инпутами в карточке с данными
  // бля, зачем выше тогда отдельный для новой?
  withState('inputs', 'setInputs', {}),
  withState('valueForLink', 'setValueForLink', ''),

  withProps({
    infinitScrollerRef: (ref) => {
      infinitScrollRef = ref;
    },
  }),

  // withState('isLoading', 'setIsLoading', false),
  withState('isLoadMore', 'setIsLoadMore', false),
  withState('recordsState', 'setRecordsState', 0),
  withState('hasMore', 'setHasMore', true),
  withState('searchValue', 'setSearchValue', ''),
  withState('nameRemovedElement', 'setNameRemovedElement', ''),
  withState('keyRemoveInputsDynamics', 'setKeyRemoveInputsDynamics', ''),
  withState('errorInputDynamic', 'setErrorInputDynamic', ''),
  withState('showLoaderLoading', 'setShowLoaderLoading', false),
  withHandlers({
    // выход
    logoutHandler: (props) => () => {
      const auth = new AuthService();
      auth.logout();
      props.history.push('/');
      props.cleanUserData();
    },
    functionCheckTime: (props) => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
    initData: (props) => (service, data, records) => {
      props.setService(service);
      props.setPayerList(data);
      props.setRecordsState(records);
    },
  }),

  withHandlers({
    skip: (props) => () => {
      props.setShowCreateForm(false);
      props.setInputs({});
      props.setActiveTab(0);
      props.setNewItemData({});
      props.toggle(false);
      props.setServiceForm({});
    },
  }),
  withHandlers({
    getDirectoryNameFromUrl: (props) => () =>
      props.location.pathname
        .split('/')
        .filter((el) => el !== '/' && el !== '')
        .pop(),
    loadDirectoryItems: (props) => async (directory) => {
      const elem = document.getElementById('directories-scroll-list');
      if (elem) {
        elem.scroll(0, 0);
      }
      props.secondaryBarItems.forEach(
        (item) =>
          item.url === directory && props.setActiveSecondarySidebarItem(item),
      );
      props.setShowCreateForm(false);
      props.toggle(false);
      props.setToggleLoad(true);
      props.setHasMore(true);
      props.setPopupVisible(false);
      props.setActiveID('');
      props.setToRemove(null);
      props.setNameRemovedElement('');
      props.setBlockData({});
      props.setEdit(false);
      props.setService(null);
      props.setLinkedService(null);
      props.setItemsForLink([]);
      props.setForLinkTitle(null);
      props.setLinkedServiceForm({});
      props.setLinkedServiceInputState({});
      props.setNewItemData({});
      props.setInputs({});

      try {
        if (directory in directoryServiceMap) {
          const result = await directoryServiceMap[directory].getItems();
          props.initData(
            directoryServiceMap[directory],
            result.data,
            result.records,
          );
        } else {
          const result = await directoryServiceMap.payers.getItems();
          props.initData(
            directoryServiceMap.payers,
            result.data,
            result.records,
          );
          props.secondaryBarItems.forEach(
            (item) =>
              item.url === 'payers' &&
              props.setActiveSecondarySidebarItem(item),
          );
        }
      } catch (error) {
        console.log('error', error);
      }
      props.setToggleLoad(false);
    },
  }),
  withHandlers({
    // для бесконечного скролла
    loadMore: (props) => async (p) => {
      try {
        const list = [...props.payerList];
        if (list.length === 0) {
          props.setHasMore(false);
          return;
        }

        if (props.recordsState === list.length) {
          props.setHasMore(false);
          return;
        }

        setTimeout(() => {
          props.setShowLoaderLoading(true);
        });
        props.setIsLoadMore(true);
        const result = await props.service.getItems(p);
        props.setRecordsState(result.records);
        props.setPayerList(list.concat(result.data));
        props.setIsLoadMore(false);
        setTimeout(() => {
          props.setShowLoaderLoading(false);
        });
      } catch (error) {
        console.log('error', error);
        setTimeout(() => {
          props.setShowLoaderLoading(false);
        });
      }
    },
    // для поиска элементов
    searchItem: (props) => async (value) => {
      if (!value.trim()) {
        props.setSearchValue('');
        const directory = props.getDirectoryNameFromUrl();
        props.loadDirectoryItems(directory);
        setTimeout(() => {
          if (infinitScrollRef) {
            infinitScrollRef.pageLoaded = 1;
          }
          props.setHasMore(true);
        }, 200);
        return;
      }
      props.setSearchValue(value);
      props.setToggleLoad(true);
      const body = {
        search: value,
      };
      try {
        const result = await props.service.searchItem(body);
        props.setPayerList(result);
        props.setRecordsState(result.length);
        if (value) {
          props.setHasMore(false);
        } else {
          props.setHasMore(true);
        }
        props.setToggleLoad(false);
      } catch (error) {
        props.setToggleLoad(false);
      }
    },
  }),

  withHandlers({
    // открывает и закрывает правй сайдбар
    toggleCurrent: (props) => async (item) => {
      try {
        const result = await props.service.getById(item.id);
        props.setBlockData(result);
        props.setServiceForm(props.service.getFields());
        const inputsStructure = {};
        if (result.inputs && result.inputs.length > 0) {
          result.inputs.forEach((input) => {
            inputsStructure[input.name] = input.value;
          });
        }
        if (result.inputsDynamics && result.inputsDynamics.length > 0) {
          result.inputsDynamics.forEach((input) => {
            inputsStructure[input.name] = [...input.items];
          });
        }
        if (result.checkboxes && result.checkboxes.length > 0) {
          result.checkboxes.forEach((checkbox) => {
            inputsStructure[checkbox.name] = checkbox.value;
          });
        }
        if (result.selection && result.selection.length > 0) {
          result.selection.forEach((select) => {
            inputsStructure[select.name] = select.value;
          });
        }
        props.setInputs(inputsStructure);
        props.toggle(true);
        props.setActiveID(item._id);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // если у сущность есть параметр canAddInput,
    // то добавит этот инпут при нажатии на кнопку
    addAdditionalLinkedInput: (props) => () => {
      const linkedServiceForm = { ...props.linkedServiceForm };
      const number = props.linkedServiceForm.inputs.length;
      const newInput = {
        ...props.linkedServiceForm.canAddInput,
        name: `${props.linkedServiceForm.canAddInput.name}-${number + 1}`,
      };
      linkedServiceForm.inputs.push(newInput);
      props.setLinkedServiceForm(linkedServiceForm);
    },
    addAdditionalInput: (props) => () => {
      const serviceForm = { ...props.serviceForm };
      const fields = props.service.getFields();
      const number = serviceForm.inputs.length;
      const newInput = {
        ...fields.canAddInput,
        name: `${fields.canAddInput.name}-${number + 1}`,
      };
      serviceForm.inputs.push(newInput);
      props.setServiceForm(serviceForm);
    },
  }),

  withHandlers({
    // в правом сайдбаре когда мы видим список привязанных элементов,
    // то любой из них мы можем удалить
    removeLinkedItem: (props) => async (id, item, itemId) => {
      try {
        const result = await props.service.removeLinkedItem(id, item, itemId);
        if (result.status === 200) {
          const updateBlockData = await props.service.getById(id);
          props.setBlockData(updateBlockData);
          const updateItem = await props.service.getItem(id);
          const itemList = props.payerList;
          itemList.forEach((value, index) => {
            if (value.id === updateItem.id) {
              itemList[index].counters = updateItem.counters;
              props.setPayerList(itemList);
            }
          });
          props.functionCheckTime('Success', 'Удалено');
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // привязывает элемент
    addLinkedItem: (props) => async (id, item, itemId) => {
      try {
        const result = await props.service.addLinkedItem(id, item, itemId);
        if (result.status === 200) {
          props.setEdit(false);
          const updateBlockData = await props.service.getById(id);
          props.setBlockData(updateBlockData);
          const updateItem = await props.service.getItem(id);
          const itemList = props.payerList;
          itemList.forEach((value, index) => {
            if (value.id === updateItem.id) {
              itemList[index].counters = updateItem.counters;
              props.setPayerList(itemList);
            }
          });
          props.functionCheckTime('Success', 'Успешно');
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    addUserToDirectory: (props) => async (entityId, userId) => {
      try {
        const { linkedUserService } = props.forLinkTitle;
        await linkedUserService.addUserToDirectory(userId, entityId);
        const updateBlockData = await props.service.getById(entityId);
        props.setBlockData(updateBlockData);
        props.setLinkUser(false);
        props.functionCheckTime('Success', 'Успешно');
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    removeUserFromDirectory: (props) => async (service, entityId, userId) => {
      try {
        await service.deleteLinkedUser(userId, entityId);
        const updateBlockData = await props.service.getById(entityId);
        props.setBlockData(updateBlockData);
        props.functionCheckTime('Success', 'Удалено');
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    initList: (props) => async () => {
      try {
        infinitScrollRef.pageLoaded = 1;
        const itemList = await props.service.getItems();
        props.setRecordsState(itemList.records);
        props.setPayerList(itemList.data);
        props.skip();
        setTimeout(() => {
          props.setHasMore(true);
        }, 200);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
  }),

  withHandlers({
    // открывает чиступю форму для создания
    openFormForCreate: (props) => () => {
      const fieldStructure = {};
      props.service.getFields().inputs.forEach((input) => {
        fieldStructure[input.name] = '';
      });

      if (props.service.getFields().inputsDynamics) {
        props.service.getFields().inputsDynamics.forEach((input) => {
          fieldStructure[input.name] = [];
        });
      }

      props.service.getFields().relations.forEach((relation) => {
        fieldStructure[relation.name] = [];
      });

      if (props.service.getFields().users) {
        props.service.getFields().users.forEach((user) => {
          fieldStructure[user.name] = [];
        });
      }

      props.service.getFields().checkboxes.forEach((checkbox) => {
        fieldStructure[checkbox.name] = false;
      });
      if (props.service.getFields().selection) {
        props.service.getFields().selection.forEach((select) => {
          fieldStructure[select.name] = 'Экодор';
        });
      }
      props.setServiceForm(props.service.getFields());

      props.setNewItemData(fieldStructure);
    },
    // грузит список сущностей для привязок
    // и создает структуру инпутов если откроем вкладку для создания нового
    showLinkedBar: (props) => async (linkedService, title, name) => {
      try {
        props.setEdit(true);
        props.setForLinkTitle({ title, name });
        props.setLinkedService(linkedService);
        const itemsForLink = await linkedService.getItems();
        const fields = await linkedService.getFields();
        props.setLinkedServiceForm(fields);
        const inputState = {};
        fields.inputs.forEach((field) => {
          inputState[field.name] = '';
        });
        fields.checkboxes.forEach((field) => {
          inputState[field.name] = false;
        });

        props.setLinkedServiceInputState(inputState);
        props.setItemsForLink(itemsForLink.data);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // в боковом меню отобразит список пользователей для привязки
    showUsersForLink: (props) => async (
      linkedUserService,
      title,
      roleName,
      userName,
    ) => {
      let itemsForLink;
      try {
        if (Array.isArray(roleName)) {
          const promeses = [];
          roleName.map((name) => {
            promeses.push(UserService.getUsersByRoleName(name));
          });
          itemsForLink = [].concat(...(await Promise.all(promeses)));
        } else {
          itemsForLink = await UserService.getUsersByRoleName(roleName);
        }
        if (userName) {
          props.setForLinkTitle({
            title,
            roleName,
            userName,
            linkedUserService,
          });
        } else {
          props.setForLinkTitle({ title, roleName, linkedUserService });
        }
        props.setItemsForLink(itemsForLink);
        props.setLinkUser(true);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // действие для добавления новой сущности
    // висит внизу на кнопке Добавить
    addedAction: (props) => async () => {
      const { id } = props.blockData;
      let result = null;
      if (
        Object.prototype.hasOwnProperty.call(
          props.linkedServiceInputState,
          'createaddress',
        )
      ) {
        try {
          result = await props.service.updateItem(
            id,
            props.linkedServiceInputState,
          );
          const updateItem = await props.service.getItem(id);
          const itemList = props.payerList;
          itemList.forEach((value, index) => {
            if (value.id === updateItem.id) {
              itemList[index].counters = updateItem.counters;
              props.setPayerList(itemList);
            }
          });
        } catch (error) {
          props.functionCheckTime('Error', error.message);
        }
      } else {
        try {
          result = await props.linkedService.createItem(
            props.linkedServiceInputState,
          );
          if (result.status === 200) {
            const item = props.linkedService.getApiName();
            const itemId = result.data.id;
            await props.addLinkedItem(id, item, itemId);
          }
        } catch (error) {
          props.functionCheckTime('Error', error.message);
        }
      }
      if (result && result.status === 200) {
        const updateBlockData = await props.service.getById(id);
        props.setBlockData(updateBlockData);
        props.setEdit(false);
      }
    },
    // удаляет сущность
    // висит внизу на кнопке Удалить
    removeAction: (props) => async (id) => {
      try {
        await props.service.deleteItem(id);
        props.setEdit(false);
        props.setPopupVisible(false);
        props.functionCheckTime('Success', 'Удалено');
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }

      const body = {
        search: props.searchValue,
      };
      try {
        const result = await props.service.searchItem(body);
        props.setPayerList(result);
        props.setRecordsState(result.length);
        if (props.searchValue) {
          props.setHasMore(false);
        } else {
          props.setHasMore(true);
        }
        props.setToggleLoad(false);
        props.setActiveID('');
        props.skip();
      } catch (error) {
        props.setToggleLoad(false);
        props.setActiveID('');
        props.skip();
      }
    },

    // создает новую сущность из чистой карточки
    createAction: (props) => async () => {
      try {
        const result = {
          name: props.newItemData.name,
        };
        Object.keys(props.newItemData).forEach((key) => {
          if (Array.isArray(props.newItemData[key])) {
            result[key] = props.newItemData[key].map((item) => {
              if (item.id) {
                return item.id;
              }
              if (key === 'emails') {
                return item.trim();
              }
              return item;
            });
          } else {
            result[key] = props.newItemData[key];
          }
        });
        if (
          result.emails &&
          !Array.isArray(result.emails) &&
          !/.+@.+\..+/i.test(result.emails)
        ) {
          props.functionCheckTime(
            'Error',
            'Невалидный адрес электронной почты',
          );
          return;
        }
        let error = false;
        if (result.emails && Array.isArray(result.emails)) {
          result.emails.forEach((item) => {
            if (!validator.isEmail(item.trim())) {
              props.functionCheckTime(
                'Error',
                'Невалидный адрес электронной почты',
              );
              props.setErrorInputDynamic(item.trim());
              error = true;
            }
          });
        }
        if (error) {
          return;
        }

        const response = await props.service.createItem(result);
        if (result.clients || result.manager || result.managerByRegion) {
          const usersId = [
            ...result.clients,
            ...result.manager,
            ...result.managerByRegion,
          ];
          const usersPromises = usersId.map((item) =>
            UserPayersService.addUserToDirectory(item, response.data._id),
          );
          await Promise.all(usersPromises);
        }
        if (result.providers) {
          const usersId = [...result.providers];
          const usersPromises = usersId.map((item) =>
            UserCounterpartyService.addUserToDirectory(item, response.data._id),
          );
          await Promise.all(usersPromises);
        }
        props.setShowCreateForm(false);
        const data = { ...response.data, id: response.data._id };
        props.toggleCurrent(data);

        const body = {
          search: props.searchValue,
        };
        try {
          const result = await props.service.searchItem(body);
          props.setPayerList(result);
          props.setRecordsState(result.length);
          props.setHasMore(false);
          props.setToggleLoad(false);
        } catch (error) {
          props.setToggleLoad(false);
        }
        props.functionCheckTime('Success', 'Сохранено');
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // действие создает новую сущность для привязки
    createLinkedItem: (props) => async () => {
      try {
        const result = await props.linkedService.createItem(
          props.linkedServiceInputState,
        );
        if (result.status === 200) {
          const newItemData = { ...props.newItemData };
          const entity = props.linkedService.getApiName();
          newItemData[entity] = [...newItemData[entity], result.data];
          props.setNewItemData(newItemData);
          props.setEdit(false);
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // обновляет данные
    updateAction: (props) => async () => {
      try {
        if (
          props.inputs.emails &&
          !Array.isArray(props.inputs.emails) &&
          !/.+@.+\..+/i.test(props.inputs.emails)
        ) {
          props.functionCheckTime(
            'Error',
            'Невалидный адрес электронной почты',
          );
          return;
        }
        let error = false;
        if (props.inputs.emails && Array.isArray(props.inputs.emails)) {
          props.inputs.emails.forEach((item) => {
            if (!validator.isEmail(item.trim())) {
              props.functionCheckTime(
                'Error',
                'Невалидный адрес электронной почты',
              );
              props.setErrorInputDynamic(item.trim());
              error = true;
            }
          });
        }
        if (error) {
          return;
        }

        const inputs = { ...props.inputs };
        if (inputs.emails && Array.isArray(inputs.emails)) {
          inputs.emails = inputs.emails.map((item) => item.trim());
        }

        await props.service.updateItem(props.blockData.id, inputs);
        props.functionCheckTime('Success', 'Данные обновлены');
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    // ищет элементы для связки
    searchLinkedItems: (props) => async (search) => {
      props.setValueForLink(search);
      try {
        const result = await props.linkedService.searchItem({ search });
        props.setItemsForLink(result);
      } catch (error) {
        console.log('error', error);
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setActiveSecondarySidebarItem(null);
      this.props.setToggleLoad(true);
      try {
        UserService.getRight()
          .then((item) => {
            this.props.saveUserAccesses({
              directory: item.result.directories,
              requests: item.result.requests,
              additionalPermissions: item.result.additionalPermissions,
            });
          })
          .catch((err) => console.log('err', err));
        this.props.setActiveSidebarItem('directories');
        const directories = await DirectoryService.getDirectories();
        const dirRoot = [];
        Object.keys(directories).forEach((item) => {
          if (this.props.access.directory[directories[item].url].findAll) {
            dirRoot[item] = directories[item];
          }
        });
        this.props.setSecondaryBarItems(dirRoot);
        this.props.setToggleLoad(false);
        let directory = this.props.getDirectoryNameFromUrl();
        this.props.loadDirectoryItems(directory);

        this.listener = this.props.history.listen((location) => {
          this.props.toggle(false);
          if (location.pathname.indexOf('/dashboard/directories') === -1)
            return;
          directory = location.pathname.split('/').pop();
          if (
            location.pathname !== '/' &&
            this.props.activeSecondaryItem &&
            directory !== this.props.activeSecondaryItem.url
          ) {
            this.props.setSearchValue('');
            this.props.loadDirectoryItems(directory);
          }
        });
      } catch (error) {
        console.log('error', error);
      }
    },
    componentWillUnmount() {
      this.props.setToggleLoad(false);
      if (this.listener) {
        this.listener();
      }
      this.props.setSecondaryBarItems([]);
      this.props.setActiveSecondarySidebarItem(null);
    },
  }),
)(PayerView);
