import { compose } from 'recompose';
import { connect } from 'react-redux';

import { StatusRequestView } from './StatusRequestView';

export default compose(
  connect((state) => ({
    access: state.auth.userAccesses,
    payers: state.request.payers,
  })),
)(StatusRequestView);
