import React from 'react';

import { compose, withState, withHandlers, lifecycle } from 'recompose';

import StampFilterView from './StampFilterView';
import StampFilterItem from './StampFilterItem/StampFilterItemContainer';

export default compose(
  withState('toggledOn', 'toggle', false),
  withState('currentState', 'setCurrentState', 'Тип отправки'),
  withHandlers({
    toggle: ({ toggle }) => () => toggle((current) => !current),
    convertToArray: (props) => () => React.Children.toArray(props.children),
  }),
  lifecycle({
    componentDidMount() {
      let { children } = this.props;

      React.Children.forEach(children, (child) => {
        if (child.type !== StampFilterItem) {
          throw new Error(
            'StampFilter children should be of type `StampFilterItem`.',
          );
        }
      });

      const isArray = Array.isArray(children);

      if (!isArray) {
        children = this.props.convertToArray();
      }
    },
  }),
)(StampFilterView);
