/**
 * @flow
 */
import React from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';

import {
  Form,
  TitleContainer,
  Title,
  TitleButton,
  MobileTitleButton,
  HistoryContainer,
  RequestWrapper,
  ContainerRequest,
  Loader,
  InfoBlockTotalWrapper,
} from './RequestStyles';
import { PopupMessage, ModaleShadow } from '../Directories/DirectoriesStyled';

import {
  Request,
  History,
  HistoryItem,
  Button,
  InfoBlockTotal,
  Popup,
} from '../../../components';

type RequestPropsType = {
  availableFields: Object,
  isLoading: Boolean,
  request: Object,
  historyRequest: Array<any>,
  payments: Array<Object>,
  addRequest: () => void,
  back: () => void,
  getExcel: () => void,
  changeDateHistory: () => void,
  editRequest: () => void,
  deleteRequest: () => void,
  scrollEvent: () => void,
  access: Object,
  hasMore: boolean,
  showRemoveRequest: boolean,
  loadMore: () => void,
  setShowRemoveRequest: () => void,
};

const WINDOW_HEIGHT = window.innerHeight;

export default function RequestFormView(props: RequestPropsType) {
  // формирование истории заявки
  const history = [];
  const historyPayments = [];
  const historyElements = [];
  if (props.access.directory.requestsHistory.findAll) {
    props.historyRequest.forEach((item) => {
      history.push({
        date: item.date,
        _id: item._id,
        userId: item.userID,
        field: item.field,
        newValue: item.newValue,
        oldValue: item.oldValue,
      });
    });
  }
  if (
    props.access.directory.paymentsHistory &&
    props.access.directory.paymentsHistory.findAll
  ) {
    props.historyPaymentsRequest.forEach((item) => {
      item.state.forEach((elem) => {
        historyPayments.push({
          date: item.date,
          _id: elem._id,
          userId: item.userId,
          field: elem.field,
          newValue:
            new RegExp('дата', 'gi').test(elem.field) &&
            elem.newValue &&
            elem.newValue.length
              ? elem.newValue.split('-').reverse().join('-')
              : elem.newValue,
          oldValue:
            new RegExp('дата', 'gi').test(elem.field) &&
            elem.oldValue &&
            elem.oldValue.length
              ? elem.oldValue.split('-').reverse().join('-')
              : elem.oldValue,
        });
      });
    });
  }
  const allHistory = [...history, ...historyPayments].sort(
    (a, b) => new Date(b.date) - new Date(a.date),
  );

  allHistory.forEach((element, index) => {
    const date = moment(element.date).format('DD-MM-YYYY');
    const time = moment(element.date).format('HH:mm:ss');
    const lastItem = index === props.historyRequest.length - 1;
    let previousValue = '';
    let nextValue = '';
    if (!element.isObject) {
      previousValue = element.oldValue;
      nextValue = element.newValue;
    } else {
      if (!element.oldValue || !element.newValue) return;
      const preObj = JSON.parse(element.oldValue);
      const nextObj = JSON.parse(element.newValue);
      Object.keys(preObj).forEach((field) => {
        if (field !== '_id') {
          if (preObj[field]) {
            if (!Array.isArray(preObj[field])) {
              previousValue += preObj[field];
            }
          }
        }
      });
      Object.keys(nextObj).forEach((field) => {
        if (field !== '_id') {
          if (nextObj[field]) {
            if (!Array.isArray(nextObj[field])) {
              nextValue += nextObj[field];
            }
          }
        }
      });
      if (previousValue === '') {
        previousValue = null;
      }
    }
    if (element.field === 'Дата загрузки') {
      if (previousValue) {
        previousValue = previousValue.slice(0, 10);
      }
      if (nextValue) {
        nextValue = nextValue.slice(0, 10);
      }
    }

    if (element.field === 'Рейтинг') {
      previousValue = (+previousValue).toFixed(2);
      nextValue = (+nextValue).toFixed(2);
    }

    historyElements.push(
      <HistoryItem
        key={element._id}
        date={date}
        time={time}
        userName={`${
          element.userId ? element.userId.firstName : 'Пользователь удален'
        } ${element.userId ? element.userId.lastName : ''}`}
        inputName={element.field}
        previousValue={previousValue}
        nextValue={nextValue}
        lastItem={lastItem}
      />,
    );
  });

  if (props.isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: '50%',
          left: '50%',
        }}>
        <Loader />
      </div>
    );
  }

  const formatNumber = (num) => {
    const formatNum = num.toLocaleString('ru').replace(',', '.');
    const arrNum = formatNum.split('.');

    if (!arrNum[1]) {
      arrNum[1] = '00';
    }
    if (arrNum[1] && arrNum[1].length < 2) {
      arrNum[1] += '0';
    }

    return `${arrNum[0]}.${arrNum[1]}`;
  };

  return (
    <Form onWheel={() => props.scrollEvent()} className="form-request">
      <TitleContainer>
        <Title>Заявки</Title>
        {props.access.directory.requests.insert ? (
          <TitleButton>
            <Button circle clicked={() => props.addRequest()} plus />
          </TitleButton>
        ) : null}
        <TitleButton white>
          <Button
            mode="white"
            clicked={() => {
              props.back();
            }}>
            Вернуться
          </Button>
        </TitleButton>
      </TitleContainer>
      <MobileTitleButton>
        <Button clicked={() => props.back()}>Вернуться</Button>
      </MobileTitleButton>
      <ContainerRequest>
        <RequestWrapper>
          {props.payments.payment && props.payments.payment.length > 0 && (
            <>
              <h3>Платежи</h3>
              <InfoBlockTotalWrapper>
                {props.payments.payment.map((payment) => (
                  <InfoBlockTotal
                    key={payment.id}
                    number={payment.paymentNumber}
                    date={
                      payment.date
                        ? payment.date.split('-').reverse().join('-')
                        : ''
                    }
                    amount={payment.amount ? formatNumber(+payment.amount) : ''}
                  />
                ))}
              </InfoBlockTotalWrapper>
            </>
          )}
          {props.payments.containerAgentPayment &&
            props.payments.containerAgentPayment.length > 0 && (
              <>
                <h3>Контейнерный агент</h3>
                <InfoBlockTotalWrapper>
                  {props.payments.containerAgentPayment.map((payment) => (
                    <InfoBlockTotal
                      number={payment.paymentNumber}
                      date={
                        payment.date
                          ? payment.date.split('-').reverse().join('-')
                          : ''
                      }
                      amount={
                        payment.amount ? formatNumber(+payment.amount) : ''
                      }
                    />
                  ))}
                </InfoBlockTotalWrapper>
              </>
            )}
          {props.request.buh_amountMore &&
            props.request.buh_amountMore.length > 0 && (
              <>
                <h3>Дополнительные суммы</h3>
                <InfoBlockTotalWrapper>
                  {props.request.buh_amountMore.map((item) => (
                    <InfoBlockTotal
                      number={item.accountNumber}
                      date={
                        item.date
                          ? item.date
                              .slice(0, 10)
                              .split('-')
                              .reverse()
                              .join('-')
                          : ''
                      }
                      amount={item.amount ? formatNumber(+item.amount) : ''}
                      comment={item.comment}
                    />
                  ))}
                </InfoBlockTotalWrapper>
              </>
            )}
          {props.request.buh_carrierMore &&
            props.request.buh_carrierMore.length > 0 && (
              <>
                <h3>Дополнительные суммы перевозчик</h3>
                <InfoBlockTotalWrapper>
                  {props.request.buh_carrierMore.map((item) => (
                    <InfoBlockTotal
                      number={item.accountNumber}
                      date=""
                      amount={item.amount ? formatNumber(+item.amount) : ''}
                    />
                  ))}
                </InfoBlockTotalWrapper>
              </>
            )}
          {props.request.buh_exportAgentMore &&
            props.request.buh_exportAgentMore.length > 0 && (
              <>
                <h3>Дополнительные суммы агент по вывозу</h3>
                <InfoBlockTotalWrapper>
                  {props.request.buh_exportAgentMore.map((item) => (
                    <InfoBlockTotal
                      number={item.accountNumber}
                      date=""
                      amount={item.amount ? formatNumber(+item.amount) : ''}
                    />
                  ))}
                </InfoBlockTotalWrapper>
              </>
            )}
          <Request
            columnTitles={props.availableFields}
            data={props.request}
            export={props.getExcel}
            edit={props.editRequest}
            setShowRemoveRequest={props.setShowRemoveRequest}
          />
        </RequestWrapper>
        {props.access.directory.requestsHistory.findAll ||
        props.access.directory.paymentsHistory.findAll ? (
          <HistoryContainer>
            <History
              requestNumber={props.request.number}
              changeDateHistory={props.changeDateHistory}>
              <div
                style={{
                  overflow: 'auto',
                  minHeight: `${WINDOW_HEIGHT - 282}px`,
                  height: `${WINDOW_HEIGHT - 282}px`,
                }}>
                <InfiniteScroll
                  pageStart={1}
                  loadMore={(p) => {
                    if (props.historyRequest.length !== 0) {
                      props.loadMore(p);
                    }
                  }}
                  hasMore={props.hasMore}
                  useWindow={false}
                  ref={props.infinitScrollerRef}>
                  {historyElements}
                </InfiniteScroll>
              </div>
            </History>
          </HistoryContainer>
        ) : null}
      </ContainerRequest>
      <PopupMessage show={props.showRemoveRequest}>
        <Popup status="Warning">
          <p>Вы точно хотите удалить заявку №{props.request.number}?</p>
          <div>
            <button
              type="button"
              onClick={() => {
                props.deleteRequest();
                props.setShowRemoveRequest(false);
              }}>
              Да
            </button>
            <button
              type="button"
              onClick={() => {
                props.setShowRemoveRequest(false);
              }}>
              Нет
            </button>
          </div>
        </Popup>
      </PopupMessage>
    </Form>
  );
}
