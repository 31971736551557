/* eslint-disable no-nested-ternary */
/**
 * @flow
 */
import React from 'react';

import { Button, Input, Popup, Checkbox } from '../../../components';
import {
  TitleCust,
  HeaderTitle,
} from '../Profile/CustColumns/CustColumnsStyled';
import { PopupMessage } from '../Directories/DirectoriesStyled';
import {
  Container,
  InputContainer,
  CheckboxesContainer,
  ContainerSpan,
  ContainerInputs,
  ContainerInput,
  ContainerInputCustom,
} from './SettingsEmailStyled';
import {
  Loaders,
  FiltersValueDelete,
} from '../RequestsTable/RequestTableStyles';

export default function RequestChangeRoleView(props) {
  const renderCheckboxes = (data) => {
    if (props.toggleLoad) {
      return (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <Loaders withoutMargin />
        </div>
      );
    }

    if (!data.length) {
      return (
        <p
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          Нет элементов, удовлетворяющих условиям поиска
        </p>
      );
    }

    return data.map((item) => (
      <InputContainer key={item._id}>
        <Checkbox
          message={
            item._id
              ? item.name
              : item.selected
              ? 'Снять все'
              : 'Установить все'
          }
          value={item.selected}
          onClickItem={() => {
            let newPayers = [];

            if (!item._id) {
              newPayers = props.payers.map((elem) => ({
                ...elem,
                selected: !item.selected,
              }));
            } else {
              newPayers = props.payers.map((elem) => {
                if (elem._id === item._id) {
                  return { ...elem, selected: !elem.selected };
                }
                return elem;
              });
            }

            props.setPayers(newPayers);
          }}
        />
      </InputContainer>
    ));
  };

  return (
    <Container>
      <HeaderTitle>
        <TitleCust
          style={{ display: 'flex', maxWidth: '100%', minWidth: '380px' }}>
          <span style={{ position: 'relative', left: '-2px' }}>Настройка</span>
          <span>email рассылки</span>
        </TitleCust>
        <Button clicked={props.onClickAddEmail} disabled={props.toggleLoad}>
          Добавить
        </Button>
      </HeaderTitle>
      <ContainerSpan>
        <span>
          Для добавления адреса электронной почты необходимо ввести email в поле
          ниже и выбрать чек-боксами Плательщиков, которым email будет добавлен.
        </span>
      </ContainerSpan>
      <ContainerInputs>
        <ContainerInputCustom>
          <Input
            type="text"
            placeholder="Введите email"
            value={props.email}
            changed={props.setEmail}
            disabled={props.toggleLoad}
          />
          {props.email && (
            <FiltersValueDelete
              onClick={() => props.setEmail('')}
              src={require('../../../assets/icons/close-default.svg')}
              alt="delete"
              style={{ right: '12px', top: '16px' }}
            />
          )}
        </ContainerInputCustom>
        <ContainerInput>
          <Input
            type="text"
            placeholder="Поиск"
            search
            value={props.search}
            changed={props.setSearch}
            disabled={props.toggleLoad}
          />
          {props.search && (
            <FiltersValueDelete
              onClick={() => props.setSearch('')}
              src={require('../../../assets/icons/close-default.svg')}
              alt="delete"
              style={{ right: '12px', top: '16px' }}
            />
          )}
        </ContainerInput>
      </ContainerInputs>
      <CheckboxesContainer>
        {renderCheckboxes(
          props.payers.filter((item) =>
            new RegExp(props.search, 'gi').test(item.name),
          ),
        )}
      </CheckboxesContainer>
      <PopupMessage show={props.showPopupAddingEmail}>
        <Popup status="Warning">
          <p>Вы точно хотите добавить этот email в плательщиков?</p>
          <div>
            <button
              type="button"
              onClick={() => {
                props.addEmailToPayers();
                props.setShowPopupAddingEmail(false);
              }}>
              Да
            </button>
            <button
              type="button"
              onClick={() => props.setShowPopupAddingEmail(false)}>
              Нет
            </button>
          </div>
        </Popup>
      </PopupMessage>
    </Container>
  );
}
