import axios from 'axios';
import AuthService from './AuthService';

import { BASE_URL } from '../constants';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originasRequest = config;
    if (status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      return axiosInstance
        .post('refreshToken', JSON.stringify({ refreshToken }))
        .then((response) => {
          originasRequest.headers.Authorization = response.data.token;
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          return axiosInstance(originasRequest);
        })
        .catch((err) => {
          if (err.response.data.error.message.includes('токен')) {
            err.response.data.error.message =
              'Требуется повторная авторизация пользователя';
            err.message = 'Требуется повторная авторизация пользователя';
          }
          const auth = new AuthService();
          auth.logout();
          if (window.location.pathname !== '/') {
            window.location.replace('/');
          }
          return Promise.reject(err);
        });
    }

    return Promise.reject(error);
  },
);

export function apiPost(path, body, params = {}) {
  // Object.keys(body).forEach((key) => {
  //   if (Array.isArray(body[key])) {
  //     body[key] = JSON.stringify(body[key]);
  //   }
  // });
  axiosInstance.interceptors.request.use((config) => {
    if (config.url === '/requests/excel') {
      config.responseType = 'blob';
    } else if (config.url === '/requests/excelRequestsStatus') {
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data',
      };
    } else if (config.responseType && config.url !== '/requests/excel') {
      delete config.responseType;
    }
    return config;
  });
  if (path === '/requests/excelRequestsStatus') {
    return axiosInstance.post(path, body, { params });
  }
  return axiosInstance.post(path, JSON.stringify(body), { params });
}

export function apiGet(path, query) {
  axiosInstance.interceptors.request.use((config) => {
    if (config.url === '/requests/excel') {
      config.responseType = 'blob';
    } else if (config.responseType && config.url !== '/requests/excel') {
      delete config.responseType;
    }
    return config;
  });
  return axiosInstance.get(path, query);
}

export function apiPut(path, body, config) {
  if (config && config.contentType) {
    return axiosInstance.put(path, body, {
      headers: { 'Content-Type': config.contentType },
    });
  }
  return axiosInstance.put(path, JSON.stringify(body));
}

export function apiDelete(path, data) {
  return axiosInstance.delete(path, { data });
}
