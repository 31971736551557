/**
 * @flow
 */

import React from 'react';

import { Item, Heading } from './SecondaryBarItemStyled';

type SecondaryBarItemPropsType = {
  name: string,
  active: boolean,
  action: (any) => void,
};

export default function SecondaryBarItemView(props: SecondaryBarItemPropsType) {
  return (
    <Item onClick={() => props.action()} active={props.active}>
      <Heading>{props.name}</Heading>
    </Item>
  );
}
