import { compose, withState, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import validator from 'validator';

import {
  setSecondaryBarItems,
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  checkStatusPopup,
  setToggleLoad,
} from '../../../store/actions/dashboard';
import { saveUserAccesses } from '../../../store/actions/auth';
import { UserService, RoleService, PayerService } from '../../../services';
import SettingsEmailView from './SettingsEmailView';

export default compose(
  connect(
    (state) => ({
      toggleLoad: state.dashboard.toggleLoad,
    }),
    (dispatch) => ({
      saveUserAccesses: (data) => dispatch(saveUserAccesses(data)),
      setSecondaryBarItems: (items) => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: (item) => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: (item) =>
        dispatch(setActiveSecondarySidebarItem(item)),
      checkStatusPopup: (item) => dispatch(checkStatusPopup(item)),
      setToggleLoad: (item) => dispatch(setToggleLoad(item)),
    }),
  ),
  withState('showPopupAddingEmail', 'setShowPopupAddingEmail', false),
  withState('email', 'setEmail', ''),
  withState('payers', 'setPayers', []),
  withState('search', 'setSearch', ''),
  withHandlers({
    functionCheckTime: (props) => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    onClickAddEmail: (props) => () => {
      if (!props.email.trim()) {
        props.functionCheckTime('Error', 'Введите email');
        return;
      }

      const validEmail = validator.isEmail(props.email.trim());
      if (!validEmail) {
        props.functionCheckTime('Error', 'Введите валидный email адрес');
        return;
      }

      const payersId = props.payers
        .filter((item) => item._id && item.selected)
        .map((item) => item._id);
      if (!payersId.length) {
        props.functionCheckTime('Error', 'Не выбраны плательщики');
        return;
      }

      if (validEmail && payersId.length) {
        props.setShowPopupAddingEmail(true);
      }
    },
    addEmailToPayers: (props) => async () => {
      try {
        props.setToggleLoad(true);

        const payersId = props.payers
          .filter((item) => item._id && item.selected)
          .map((item) => item._id);

        await PayerService.updateItems({
          payersId,
          emails: [props.email.trim()],
        });

        props.setEmail('');
        props.setToggleLoad(false);
        props.functionCheckTime(
          'Success',
          'Email адрес был добавлен в выбранных плательщиков',
        );
      } catch (error) {
        props.setToggleLoad(false);
        props.functionCheckTime('Error', error.message);
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setToggleLoad(true);
      try {
        const accesses = await UserService.getRight();
        const positions = await RoleService.getRoles();
        const payers = await PayerService.getItems(1, { records: 5000 });

        const payersData = payers.data;

        if (!payersData.find((item) => !item._id)) {
          payersData.unshift({ _id: null, name: null });
        }

        this.props.setPayers(
          payersData.map((item) => ({
            ...item,
            selected: false,
          })),
        );
        this.props.saveUserAccesses({
          directory: accesses.result.directories,
          requests: accesses.result.requests,
          additionalPermissions: accesses.result.additionalPermissions,
        });
        this.props.setActiveSidebarItem('users');
        this.props.setSecondaryBarItems([
          { id: '', name: 'Все', url: '/dashboard/users/' },
          ...positions,
          accesses.result.directories.roles.getAccessRights && {
            id: 'changeRole',
            name: 'Настройка ролей',
            url: '/dashboard/users/changeRole',
          },
          {
            id: 'settingsEmail',
            name: 'Настройка email рассылки',
            url: '/dashboard/users/settingsEmail',
          },
        ]);
        this.props.setActiveSecondarySidebarItem({
          id: 'settingsEmail',
          name: 'Настройка email рассылки',
          url: '/dashboard/users/settingsEmail',
        });
        this.props.setToggleLoad(false);
      } catch (error) {
        this.props.setToggleLoad(false);
        this.props.functionCheckTime('Error', error.message);
      }
    },
  }),
)(SettingsEmailView);
