import styled, { css } from 'styled-components';

import { base, colors } from '../../../styles';

const OpenInputContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const OpenInput = styled.input`
  cursor: pointer;
  background-color: #f9f9f9;
  border: 1px solid #d9dddf;
  font-size: 12px;
`;

const OpenFilterButton = styled.span`
  cursor: pointer;
  margin-left: 0.5em;

  :hover {
    opacity: 0.5;
  }
`;

const ContentContainer = styled.div`
  display: none;
  position: fixed;
  z-index: 999999999999;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0.125em;
  background: #fff;
  max-width: 32em;
  padding: 0.5em;

  ${(props) =>
    props.opened &&
    css`
      display: block;
    `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-top: 0.5em;
  padding-top: 0.5em;
  border-top: 1px solid #d9dddf;
`;

const Button = styled.button`
  padding: 0.25em 1em;
  border-radius: 0.125em;
  margin-left: 0.25em;
  cursor: pointer;
  border: 1px solid #d9dddf;
`;

const InputContainer = styled.div`
  align-items: center;
  margin: 0.125em 0;
`;

const Input = styled.input`
  width: 100% !important;
  box-sizing: border-box;
  font-size: 12px;
  color: ${colors.black};
  background: transparent;
  border: none;
  padding-right: 24px;
  &::placeholder {
    color: ${colors.light_gray};
  }
  &::-ms-clear {
    display: none;
  }
  ${(props) =>
    props.type === 'checkbox' &&
    css`
      width: 1.5em !important;
      cursor: pointer;
    `}
`;

const InputLabel = styled.label`
  cursor: pointer;
`;

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 16em;
  overflow: auto;
  overflow-x: hidden;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
  background-color: ${colors.white_smoke};
  border: none;
  border-radius: ${base / 2}px;
`;

const FilterValueDelete = styled.img`
  position: absolute;
  top: 10px;
  right: 12px;
  outline: none;
  cursor: pointer;
`;

const SearchIcon = styled.div`
  height: 24px;
  width: 24px;
  background-image: url(${require('../../../assets/icons/searchsvg.svg')});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
`;

export {
  OpenInputContainer,
  OpenInput,
  OpenFilterButton,
  ContentContainer,
  ButtonsContainer,
  Button,
  InputContainer,
  Input,
  InputLabel,
  CheckboxesContainer,
  SearchContainer,
  FilterValueDelete,
  SearchIcon,
};
