/**
 * @flow
 */
import React from 'react';

import { TimeZonesContainer } from './TimeZonesStyled';

import TimeZone from './TimeZone/TimeZoneContainer';

type TimeZonesPropsType = {
  zones: Array<any>,
};

export default function TimeZones(props: TimeZonesPropsType) {
  const timeZone = props.zones.map((zone) => (
    <TimeZone
      cityName={zone.city}
      time={zone.time}
      key={zone.zone + zone.city}
    />
  ));

  return <TimeZonesContainer>{timeZone}</TimeZonesContainer>;
}
