import { compose } from 'recompose';
import { connect } from 'react-redux';

import { StatusBarView } from './StatusBarView';

export default compose(
  connect((state) => ({
    quantityRequests: state.dashboard.quantityRequests,
  })),
)(StatusBarView);
