import { compose, withHandlers, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import axios from 'axios';

// actions
import { cleanUserData } from '../../store/actions/auth';

// services
import AuthService from '../../services/AuthService';

import { BASE_URL } from '../../constants';

import {
  checkStatusPopup,
  setToggleDrop,
  setShowWarningSaveRequest,
  setCallbackExitRequest,
} from '../../store/actions/dashboard';

// views
import DashboardMainView from './DashboardMainView';

let intervalId = null;

export default compose(
  connect(
    (state) => ({
      user: state.auth.currentUser,
      checkStatus: state.dashboard.checkStatusPopup,
      toggleDrop: state.dashboard.toggleDrop,
      showWarningSaveRequest: state.dashboard.showWarningSaveRequest,
      callbackExitRequest: state.dashboard.callbackExitRequest,
      dataRequest: state.dashboard.dataRequest,
      changedDataRequest: state.dashboard.changedDataRequest,
      dataPaymentRequest: state.dashboard.dataPaymentRequest,
      changedDataPaymentRequest: state.dashboard.changedDataPaymentRequest,
    }),
    (dispatch) => ({
      cleanUserData: () => dispatch(cleanUserData()),
      checkStatusPopup: (item) => dispatch(checkStatusPopup(item)),
      setToggleDrop: (item) => dispatch(setToggleDrop(item)),
      setShowWarningSaveRequest: (data) =>
        dispatch(setShowWarningSaveRequest(data)),
      setCallbackExitRequest: (data) => dispatch(setCallbackExitRequest(data)),
    }),
  ),
  withHandlers({
    logoutHandler: (props) => () => {
      const auth = new AuthService();
      auth.logout();
      props.history.push('/');
      props.cleanUserData();
    },
    openProfileHandler: (props) => () => {
      props.history.replace('/dashboard/profile/personalInfo');
    },
    openDashboardLogo: (props) => () => {
      props.history.replace('/dashboard');
    },
    openRequestFormHandler: (props) => () => {
      props.history.push('/dashboard/requests/create');
      document.location.reload();
    },
    goToPageCheck: (props) => (callback) => {
      if (props.location.pathname.includes('create')) {
        props.setShowWarningSaveRequest(true);
        props.setCallbackExitRequest(() => callback());
      } else if (props.location.pathname.includes('edit')) {
        const dataRequest = Object.keys(props.dataRequest).map((item) => {
          if (item.includes('buh_') || item.includes('balance')) {
            const a = { ...props.dataRequest[item] };
            a.name = +a.name;
            return { [item]: a };
          }
          return { [item]: props.dataRequest[item] };
        });
        const changedDataRequest = Object.keys(props.changedDataRequest).map(
          (item) => {
            if (item.includes('buh_') || item.includes('balance')) {
              const a = { ...props.changedDataRequest[item] };
              a.name = +a.name;
              return { [item]: a };
            }
            return { [item]: props.changedDataRequest[item] };
          },
        );
        if (
          JSON.stringify(dataRequest) !== JSON.stringify(changedDataRequest) ||
          JSON.stringify(props.dataPaymentRequest) !==
            JSON.stringify(props.changedDataPaymentRequest)
        ) {
          props.setShowWarningSaveRequest(true);
          props.setCallbackExitRequest(() => callback());
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      let token = localStorage.getItem('token');
      let refreshToken = localStorage.getItem('token');

      const auth = new AuthService();

      const axiosInstance = axios.create({
        /* / heroku server
          baseURL: 'https://amixgroup-backend.herokuapp.com/',
        // */
        //* / test server
        baseURL: BASE_URL,
        // */
        headers: { 'Content-Type': 'application/json' },
      });

      axiosInstance.interceptors.request.use((config) => {
        token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = token;
        }
        return config;
      });

      if (auth.isTokenExpired(token)) {
        if (auth.isTokenExpired(refreshToken)) {
          auth.logout();
          window.location.replace('/');
        } else {
          const response = await axiosInstance.post(
            'refreshToken',
            JSON.stringify({ refreshToken }),
          );
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('refreshToken', response.data.refreshToken);
        }
      }

      intervalId = setInterval(() => {
        refreshToken = localStorage.getItem('refreshToken');
        axiosInstance
          .post('refreshToken', JSON.stringify({ refreshToken }))
          .then((response) => {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('refreshToken', response.data.refreshToken);
          })
          .catch((err) => {
            if (err.response.status === 403) {
              auth.logout();
              window.location.replace('/');
            }
          });
      }, 6000000);
    },
    componentWillUnmount() {
      clearInterval(intervalId);
    },
  }),
)(DashboardMainView);
