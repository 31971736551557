/**
 * @flow
 */
import React from 'react';

import {
  Form,
  CalendarStyle,
  HistoryControl,
  ListItems,
  ControlCalendar,
  HistoryTitle,
  MobileHistoryControl,
  MobileHistoryTitle,
  MobileNumberRequest,
  MobileButton,
  Delete,
} from './HistoryStyled';

import { Calendar, Button } from '../index';

type HistoryPropsType = {
  children: any,
  requestNumber?: number,
  viewCalendar?: boolean,
  changeDateRangeForHistory: any,
  selectDate: (any) => void,
  setViewCalendar: (any) => void,
  date: Array,
  dateRange: string,
  setDate: (any) => void,
};

export default function HistoryView(props: HistoryPropsType) {
  return (
    <Form>
      {props.viewCalendar ? (
        <CalendarStyle>
          <Calendar
            eventOnOk={props.changeDateRangeForHistory}
            onSelect={props.selectDate}
            selectedValue={props.date}
            showClear={false}
            showToday={false}
            onChange={props.setDate}
          />
        </CalendarStyle>
      ) : null}
      <MobileButton>
        <Button
          circle
          clicked={() => props.setViewCalendar(!props.viewCalendar)}>
          <img
            style={{ width: '14px' }}
            src={require('../../assets/icons/calendar-history-white.svg')}
            alt="calendar-history"
          />
        </Button>
      </MobileButton>
      <HistoryControl>
        <HistoryTitle>Дата изменений в заявке</HistoryTitle>
        <ControlCalendar
          onClick={() => props.setViewCalendar(!props.viewCalendar)}>
          <img
            style={{ width: '14px', padding: '0px 10px 0px 0px' }}
            src={require('../../assets/icons/calendar-history.svg')}
            alt="calendar-history"
          />
          {props.dateRange ? props.dateRange : 'Выберите дату'}
          {props.dateRange && (
            <Delete
              onClick={(e) => {
                e.stopPropagation();
                props.changeDateRangeForHistory(null);
                props.selectDate(null);
              }}
            />
          )}
        </ControlCalendar>
      </HistoryControl>
      <MobileHistoryControl>
        <MobileHistoryTitle>
          <h1>История</h1>
          <h1 style={{ color: '#da350a', paddingLeft: '10px' }}>изменений</h1>
        </MobileHistoryTitle>
        <MobileNumberRequest>№{props.requestNumber}</MobileNumberRequest>
      </MobileHistoryControl>
      <ListItems>{props.children}</ListItems>
    </Form>
  );
}

HistoryView.defaultProps = {
  requestNumber: null,
  viewCalendar: false,
};
