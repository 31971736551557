import API_URL from './API_URL';
// Backend url
export const BASE_URL = API_URL;

// default image
export const DEFAUT_AVATAR = require('../assets/images/default_avatar.png');

// Request input - BUH_TOTAL
export const BUH_TOTAL_INPUT = 'buh_total';

// Requests fields
export const REQUEST_FIELDS = [
  {
    title: 'Отдел обслуживания клиентов',
    name: 'customerservice',
    items: [
      {
        title: 'Статус',
        name: 'statusRequest',
        value: false,
      },
      {
        title: 'Рейтинг',
        name: 'rating',
        value: false,
      },
      {
        title: 'Компания',
        name: 'company',
        value: false,
      },
      {
        title: 'Стоп-лист',
        name: 'stopSheet',
        value: false,
      },
      {
        title: 'Компания принадлежит "Амикс"',
        name: 'companyAmixgroup',
        value: false,
      },
      {
        title: 'Дата загрузки',
        name: 'dateLoad',
        value: false,
      },
      {
        title: 'Время загрузки',
        name: 'timeLoad',
        value: false,
      },
      {
        title: 'Тип отправки',
        name: 'typeSend',
        value: false,
      },
      {
        title: 'Вид контейнера',
        name: 'typeContainer',
        value: false,
      },
      {
        title: 'Вид доставки',
        name: 'typeElivery',
        value: false,
      },
      {
        title: 'Плательщики',
        name: 'payers',
        value: false,
      },
      {
        title: 'Заказчик',
        name: 'payer',
        value: false,
      },
      {
        title: 'Грузоотправитель',
        name: 'shipper',
        value: false,
      },
      {
        title: 'Грузополучатель',
        name: 'consignee',
        value: false,
      },
      {
        title: 'Адрес погрузки',
        name: 'addressLoading',
        value: false,
      },
      {
        title: 'Адрес разгрузки',
        name: 'addressUnloading',
        value: false,
      },
      {
        title: 'Город назначения',
        name: 'destinationCity',
        value: false,
      },
      {
        title: 'Номер заявки клиента',
        name: 'customerApplicationNumber',
        value: false,
      },
      {
        title: 'Груз фактический',
        name: 'loadActual',
        value: false,
      },
      {
        title: 'Вес',
        name: 'weight',
        value: false,
      },
      {
        title: 'Кол-во мест',
        name: 'numberSeats',
        value: false,
      },
      {
        title: 'Плановая дата выдачи',
        name: 'plannedIssueDate',
        value: false,
      },
      {
        title: 'Плановое время выдачи',
        name: 'scheduledIssueTime',
        value: false,
      },
      {
        title: 'OD',
        name: 'od',
        value: false,
      },
      {
        title: 'Shipment',
        name: 'shipment',
        value: false,
      },
      {
        title: 'Дата прибытия',
        name: 'dateArrival',
        value: false,
      },
      {
        title: 'Утепление',
        name: 'warming',
        value: false,
      },
      {
        title: 'Ветеринарное свидетельство',
        name: 'veterinaryCertificate',
        value: false,
      },
      {
        title: 'ЖДН Выгрузка',
        name: 'gdnUnloading',
        value: false,
      },
      {
        title: 'Крепление груза',
        name: 'loadFastening',
        value: false,
      },
      {
        title: 'Документы от агента',
        name: 'agentDocuments',
        value: false,
      },
      {
        title: 'Экспедитор погрузка',
        name: 'forwarderLoading',
        value: false,
      },
      {
        title: 'Экспедитор выгрузка',
        name: 'forwarderUnloading',
        value: false,
      },
      {
        title: 'Грузчики на погрузке',
        name: 'moversOnLoading',
        value: false,
      },
      {
        title: 'Грузчики на выгрузке',
        name: 'moversAtUnloading',
        value: false,
      },
      {
        title: 'Отслеживание',
        name: 'tracking',
        value: false,
      },
      {
        title: 'Расстояние до конечной станции',
        name: 'distance',
        value: false,
      },
      {
        title: 'Вагон',
        name: 'carriage',
        value: false,
      },
      {
        title: 'Дорожная дислокация',
        name: 'roadDislocation',
        value: false,
      },
      {
        title: 'Дополнительные услуги',
        name: 'additionalServices',
        value: false,
      },
      {
        title: 'Дополнительные услуги (контрагент)',
        name: 'additionalServicesCounterparty',
        value: false,
      },
      {
        title: 'Сумма',
        name: 'buh_amount',
        value: false,
      },
      {
        title: 'Дополнительные суммы',
        name: 'buh_amountMore',
        value: false,
      },
      {
        title: 'Менеджер',
        name: 'manager',
        value: false,
      },
      {
        title: 'Менеджер. Китай',
        name: 'managerByRegion',
        value: false,
      },
      {
        title: 'Примечание',
        name: 'note',
        value: false,
      },
    ],
  },
  {
    title: 'Отдел отправки',
    name: 'shipmentdepartment',
    items: [
      {
        title: 'Плановая дата отправки',
        name: 'plannedDispatchDate',
        value: false,
      },
      {
        title: 'Фактическая дата отправки',
        name: 'actualDateDispatch',
        value: false,
      },
      {
        title: 'Перевозчик по ЖД',
        name: 'railCarrier',
        value: false,
      },
      {
        title: 'Статус перевозчик по ЖД',
        name: 'railCarrierStatus',
        value: false,
      },
      {
        title: 'ПРР по прибытию',
        name: 'prrOnArrived',
        value: false,
      },
      {
        title: 'Собственник контейнера',
        name: 'ownerContainer',
        value: false,
      },
      {
        title: 'Терминал постановки',
        name: 'terminalStaging',
        value: false,
      },
      {
        title: 'Станция отправления',
        name: 'stationDeparture',
        value: false,
      },
      {
        title: 'Станция назначения',
        name: 'stationDestination',
        value: false,
      },
      {
        title: 'Груз документально',
        name: 'loadDocumented',
        value: false,
      },
      {
        title: 'Код груза',
        name: 'cargoCode',
        value: false,
      },
      {
        title: 'Инструкция по сдаче контейнера',
        name: 'instructionsDelivery',
        value: false,
      },
      {
        title: 'Релизы',
        name: 'releases',
        value: false,
      },
      {
        title: 'Охрана',
        name: 'security',
        value: false,
      },
      {
        title: 'Компания утепления',
        name: 'warmingCompany',
        value: false,
      },
      {
        title: 'Компания/имя экспедитор',
        name: 'companyNameForwarder',
        value: false,
      },
      {
        title: 'Компания грузчики на погрузке',
        name: 'companyMoversLoading',
        value: false,
      },
      {
        title: 'Принадлежность контейнера',
        name: 'containerAccessory',
        value: false,
      },
      {
        title: 'Номер доверенности',
        name: 'attorneyPowerNumber',
        value: false,
      },
      {
        title: 'Дата выхода судна',
        name: 'departureVesselDate',
        value: false,
      },
      {
        title: 'Дата прибытия судна',
        name: 'arrivalVesselDate',
        value: false,
      },
      {
        title: 'Дата уведомления',
        name: 'notificationDate',
        value: false,
      },
    ],
  },
  {
    title: 'Автоотдел',
    name: 'autodepartment',
    items: [
      {
        title: 'Номер контейнера',
        name: 'numberContainer',
        value: false,
      },
      {
        title: 'Номер пломбы',
        name: 'numberSeal',
        value: false,
      },
      {
        title: 'Агент авто на загрузке',
        name: 'agentAutoLoad',
        value: false,
      },
      {
        title: 'Водитель',
        name: 'driver',
        value: false,
      },
      {
        title: 'Статус выгрузки задания',
        name: 'unloadingDriverTaskStatus',
        value: false,
      },
      {
        title: 'Водитель (старый)',
        name: 'driverOld',
        value: false,
      },
      {
        title: 'Машина',
        name: 'car',
        value: false,
      },
      {
        title: 'Прицеп',
        name: 'trailer',
        value: false,
      },
    ],
  },
  {
    title: 'Отдел вывоз в регионы/документы',
    name: 'documentdepartment',
    items: [
      {
        title: 'Агент по вывозу',
        name: 'agentAutoCall',
        value: false,
      },
      {
        title: 'Статус агент по вывозу',
        name: 'agentAutoCallStatus',
        value: false,
      },
      {
        title: 'Агент по вывозу (Амикс)',
        name: 'exportAgentEurosib',
        value: false,
      },
      {
        title: 'Авто до выгрузки',
        name: 'autoBeforeUnloading',
        value: false,
      },
      {
        title: 'Водитель в регионе',
        name: 'driverInRegion',
        value: false,
      },
      {
        title: 'Машина в регионе',
        name: 'carInRegion',
        value: false,
      },
      {
        title: 'Компания грузчики на выгрузке',
        name: 'companyMoversUnloading',
        value: false,
      },
      {
        title: 'Дата выдачи',
        name: 'dateIssue',
        value: false,
      },
      {
        title: 'Время выдачи(фактическое)',
        name: 'timeIssue',
        value: false,
      },
      {
        title: 'Возврат документов с выгрузки',
        name: 'returnOfDocumentsFromUnloading',
        value: false,
      },
      {
        title: 'Акт',
        name: 'act',
        value: false,
      },
      {
        title: 'ЖД накладные',
        name: 'railwayInvoices',
        value: false,
      },
      {
        title: 'Погрузка',
        name: 'loading',
        value: false,
      },
      {
        title: 'ТТН погрузка',
        name: 'loadingTN',
        value: false,
      },
      {
        title: 'ТТН выгрузка',
        name: 'unloadingTN',
        value: false,
      },
      {
        title: 'Наименование документа',
        name: 'documentStatus',
        value: false,
      },
      {
        title: 'Тимофеево',
        name: 'timofeevo',
        value: false,
      },
      {
        title: 'Тимурово',
        name: 'timurovo',
        value: false,
      },
    ],
  },
  {
    title: 'Финансовый отдел',
    name: 'financialdepartment',
    items: [
      {
        title: 'Перевозчик',
        name: 'buh_carrier',
        value: false,
      },
      {
        title: 'Авто до погрузки',
        name: 'buh_autoBeforeLoading',
        value: false,
      },
      {
        title: 'За МКАД/ставка',
        name: 'buh_forMKAD_rate',
        value: false,
      },
      {
        title: 'За МКАД/км',
        name: 'buh_forMKAD_km',
        value: false,
      },
      {
        title: 'За МКАД/итого',
        name: 'buh_forMKAD',
        value: false,
      },
      {
        title: 'Дополнительный адрес погрузки',
        name: 'additionalLoadingAddress',
        value: false,
      },
      {
        title: 'Контейнерный агент',
        name: 'buh_containerAgent',
        value: false,
      },
      {
        title: 'СверхНорма/ставка',
        name: 'buh_aboveTheNorm_rate',
        value: false,
      },
      {
        title: 'СверхНорма/день',
        name: 'buh_aboveTheNorm_day',
        value: false,
      },
      {
        title: 'СверхНорма/итого',
        name: 'buh_aboveTheNorm',
        value: false,
      },
      {
        title: 'Агент по вывозу',
        name: 'buh_exportAgent',
        value: false,
      },
      {
        title: 'Перегруз/ставка',
        name: 'buh_overload_rate',
        value: false,
      },
      {
        title: 'Перегруз/тонна',
        name: 'buh_overload_ton',
        value: false,
      },
      {
        title: 'Перегруз/итого',
        name: 'buh_overload',
        value: false,
      },
      {
        title: 'Простой на выгрузке/ставка',
        name: 'buh_simpleToUnload_rate',
        value: false,
      },
      {
        title: 'Простой на выгрузке/час',
        name: 'buh_simpleToUnload_hour',
        value: false,
      },
      {
        title: 'Простой на выгрузке/итого',
        name: 'buh_simpleToUnload',
        value: false,
      },
      {
        title: 'Простой на погрузке/ставка',
        name: 'buh_simpleToLoad_rate',
        value: false,
      },
      {
        title: 'Простой на погрузке/час',
        name: 'buh_simpleToLoad_hour',
        value: false,
      },
      {
        title: 'Простой на погрузке/итого',
        name: 'buh_simpleToLoad',
        value: false,
      },
      {
        title: 'Простой/ставка',
        name: 'buh_plain_rate',
        value: false,
      },
      {
        title: 'Простой/час',
        name: 'buh_plain_hour',
        value: false,
      },
      {
        title: 'Простой/итого',
        name: 'buh_plain',
        value: false,
      },
      {
        title: 'Хранение на станции назначения/ставка',
        name: 'buh_storageAtTheDestinationStation_rate',
        value: false,
      },
      {
        title: 'Хранение на станции назначения/день',
        name: 'buh_storageAtTheDestinationStation_day',
        value: false,
      },
      {
        title: 'Хранение на станции назначения/итого',
        name: 'buh_storageAtTheDestinationStation',
        value: false,
      },
      {
        title: 'Грузчики на выгрузке',
        name: 'buh_moversAtUnloading',
        value: false,
      },
      {
        title: 'Экспедитор на погрузке',
        name: 'buh_forwarderOnLoading',
        value: false,
      },
      {
        title: 'Грузчики на погрузке',
        name: 'buh_moversOnLoading',
        value: false,
      },
      {
        title: 'Ветеринарное свидетельство',
        name: 'buh_veterinaryCertificate',
        value: false,
      },
      {
        title: 'Крепление груза',
        name: 'buh_loadFastening',
        value: false,
      },
      {
        title: 'Утепление',
        name: 'buh_warming',
        value: false,
      },
      {
        title: 'Прокладочный материал',
        name: 'buh_gasketMaterial',
        value: false,
      },
      {
        title: 'Дополнительные услуги',
        name: 'buh_additionalServices',
        value: false,
      },
      {
        title: 'Пломба',
        name: 'buh_seal',
        value: false,
      },
      {
        title: 'Платёжки',
        name: 'buh_bills',
        value: false,
      },
      {
        title: 'Агентские клиенту',
        name: 'buh_agentToClient',
        value: false,
      },
      {
        title: 'Менеджерские',
        name: 'buh_managerial',
        value: false,
      },
      {
        title: 'Агентские поставщиков',
        name: 'buh_agentSuppliers',
        value: false,
      },
      {
        title: 'ОБН',
        name: 'buh_OBN',
        value: false,
      },
      {
        title: 'Дополнительные суммы перевозчик',
        name: 'buh_carrierMore',
        value: false,
      },
      {
        title: 'Дополнительные суммы агент по вывозу',
        name: 'buh_exportAgentMore',
        value: false,
      },
    ],
  },
  {
    title: 'Номера счетов',
    name: 'billnumbers',
    items: [
      {
        title: 'Номер счёта клиента',
        name: 'customerAccountNumber',
        value: false,
      },
      {
        title: 'Дата счёта клиента',
        name: 'customerAccountDate',
        value: false,
      },
      {
        title: 'Счёт перевозчика',
        name: 'carrierAccount',
        value: false,
      },
      {
        title: 'Счёт автоуслуги',
        name: 'accountsAutoServices',
        value: false,
      },
      {
        title: 'Счёт контейнерного агента',
        name: 'containerAgentAccount',
        value: false,
      },
      {
        title: 'Счёт агента по вывозу',
        name: 'exportAgentAccount',
        value: false,
      },
      {
        title: 'Счёт грузчиков на погрузке',
        name: 'moversAccountOnLoading',
        value: false,
      },
      {
        title: 'Счёт грузчиков на выгрузке',
        name: 'moversAccountOnUnloading',
        value: false,
      },
      {
        title: 'Счёт крепление груза',
        name: 'accountSecuringCargo',
        value: false,
      },
      {
        title: 'Счёт утепление груза',
        name: 'billCargoWeatherization',
        value: false,
      },
      {
        title: 'Счёт хранение на станции назначения',
        name: 'accountStorageAtDestinationStation',
        value: false,
      },
      {
        title: 'Счёт простой на выгрузке',
        name: 'accountSimpleToUnload',
        value: false,
      },
      {
        title: 'Счёт простой на погрузке',
        name: 'accountSimpleToLoad',
        value: false,
      },
      {
        title: 'Счёт перегруз',
        name: 'accountOverload',
        value: false,
      },
      {
        title: 'Счёт сверхНорма',
        name: 'accountAboveTheNorm',
        value: false,
      },
      {
        title: 'Счет дополнительные услуги',
        name: 'accountAdditionalServices',
        value: false,
      },
      {
        title: 'Итого',
        name: 'buh_total',
        value: false,
      },
      {
        title: 'Остаток',
        name: 'balance',
        value: false,
      },
      {
        title: 'Оплачено',
        name: 'buh_pay',
        value: false,
      },
      {
        title: 'Оплата контейнерного агента',
        name: 'buh_containerAgentPayment',
        value: false,
      },
      {
        title: 'Выставлено простой на выгрузке',
        name: 'exhibitedSimpleToUnload',
        value: false,
      },
      {
        title: 'Выставлено хранение',
        name: 'exhibitedStorage',
        value: false,
      },
      {
        title: 'Выставлено сверхнорматив',
        name: 'exhibitedOverflow',
        value: false,
      },
      {
        title: 'Выставлено дополнительные услуги',
        name: 'exhibitedAdditionalServices',
        value: false,
      },
    ],
  },
];
// Roles right names
export const ROLES_NAME = {
  admin: 'admin',
  accountant: 'accountant',
  chiefAccountant: 'chiefAccountant',
  document: 'document',
  logist: 'logist',
  manager: 'manager',
  salesmanByRegion: 'salesmanByRegion',
  transaction: 'transaction',
  salesman: 'salesman',
  transactionSalesman: 'transactionSalesman',
  clients: 'client',
  provider: 'provider',
};

export const checkboxesList = [
  'act',
  'security',
  'forwarderLoading',
  'forwarderUnloading',
  'veterinaryCertificate',
  'gdnUnloading',
  'loadFastening',
  'agentDocuments',
  'railwayInvoices',
  'loading',
  'loadingTN',
  'unloadingTN',
  'stopSheet',
  'companyAmixgroup',
  'moversAtUnloading',
  'railCarrierStatus',
  'prrOnArrived',
  'agentAutoCallStatus',
  'moversOnLoading',
  'unloadingDriverTaskStatus',
];

export const numbersList = [
  'number',
  'buh_amount',
  'buh_carrier',
  'buh_autoBeforeLoading',
  'buh_forMKAD_rate',
  'buh_forMKAD_km',
  'buh_forMKAD',
  'buh_containerAgent',
  'buh_aboveTheNorm_rate',
  'buh_aboveTheNorm_day',
  'buh_aboveTheNorm',
  'buh_exportAgent',
  'buh_overload_rate',
  'buh_overload_ton',
  'buh_overload',
  'buh_simpleToUnload',
  'buh_simpleToUnload_rate',
  'buh_simpleToUnload_hour',
  'buh_simpleToLoad',
  'buh_simpleToLoad_rate',
  'buh_simpleToLoad_hour',
  'buh_plain',
  'buh_plain_rate',
  'buh_plain_hour',
  'buh_storageAtTheDestinationStation_rate',
  'buh_storageAtTheDestinationStation_day',
  'buh_storageAtTheDestinationStation',
  'buh_moversAtUnloading',
  'buh_forwarderOnLoading',
  'buh_moversOnLoading',
  'buh_veterinaryCertificate',
  'buh_loadFastening',
  'buh_warming',
  'buh_gasketMaterial',
  'buh_additionalServices',
  'buh_seal',
  'buh_bills',
  'buh_agentToClient',
  'buh_managerial',
  'buh_agentSuppliers',
  'buh_OBN',
  'buh_total',
  'balance',
  'buh_pay',
  'buh_containerAgentPayment',
  'buh_amountMoreAmount',
  'additionalLoadingAddress',
  'buh_carrierMoreAmount',
  'buh_exportAgentMoreAmount',
  'distance',
];

export const textsList = [
  'timeLoad',
  'customerApplicationNumber',
  'scheduledIssueTime',
  'od',
  'shipment',
  'warming',
  'tracking',
  'additionalServices',
  'cargoCode',
  'instructionsDelivery',
  'releases',
  'numberContainer',
  'timeIssue',
  'timofeevo',
  'timurovo',
  'customerAccountNumber',
  'carrierAccount',
  'accountsAutoServices',
  'containerAgentAccount',
  'exportAgentAccount',
  'moversAccountOnLoading',
  'moversAccountOnUnloading',
  'accountSecuringCargo',
  'billCargoWeatherization',
  'accountSimpleToUnload',
  'accountAboveTheNorm',
  'accountStorageAtDestinationStation',
  'weight',
  'numberSeats',
  'accountOverload',
  'accountSimpleToLoad',
  'numberSeal',
  'buh_amountMoreComment',
  'buh_amountMoreAccountNumber',
  'accountAdditionalServices',
  'buh_carrierMoreAccountNumber',
  'buh_exportAgentMoreAccountNumber',
  'attorneyPowerNumber',
  'note',
  'carriage',
  'roadDislocation',
  'exhibitedSimpleToUnload',
  'exhibitedStorage',
  'exhibitedOverflow',
  'exhibitedAdditionalServices',
];

export const datesList = [
  'dateLoad',
  'dateArrival',
  'plannedIssueDate',
  'plannedDispatchDate',
  'actualDateDispatch',
  'dateIssue',
  'customerAccountDate',
  'buh_amountMoreDate',
  'departureVesselDate',
  'arrivalVesselDate',
  'notificationDate',
];

export const selectionsList = [
  'typeSend',
  'typeContainer',
  'typeElivery',
  'containerAccessory',
  'returnOfDocumentsFromUnloading',
  'documentStatus',
];

export const selectors = [
  {
    field: 'typeSend',
    items: ['Не заполнено', 'Контейнерная', 'Авто', 'Вагонная', 'Сборка'],
  },
  {
    field: 'typeContainer',
    items: [
      'Не заполнено',
      '20FT',
      '20FT(HC)',
      '40FT',
      '40FT(HC)',
      '40FT(HC)PW',
      '40SHC',
    ],
  },
  {
    field: 'typeElivery',
    items: ['Не заполнено', 'До двери', 'До станции', 'Вывоз'],
  },
  {
    field: 'containerAccessory',
    items: ['Не заполнено', 'Клиент', 'Евросиб'],
  },
  {
    field: 'returnOfDocumentsFromUnloading',
    items: [
      'Не заполнено',
      'Вернуть в офис',
      'Вернуть в офис 2 адр.',
      'Вернуть в офис 3 адр.',
      'Вернуть в офис 4 адр.',
      'Сканы есть',
      'Неполный комплект',
      'Документы в офисе',
      'Оригиналы в офисе',
      'Документы передали',
      'Документы передали/оригиналы в офисе',
      'Документы передали/сканы есть',
      'Забрал сам клиент',
    ],
  },
  {
    field: 'documentStatus',
    items: [
      'Не заполнено',
      'ТвН',
      'ТТН',
      '2ТТН',
      'ТрН',
      'ТрН (особ.)',
      'ТТН, ТрН',
      'ТвН/ТОРГ-13, ТрН',
      'ТТН, ТрН, доверенность',
      'ТТН, ТрН, Экспедиторская расписка',
    ],
  },
];
