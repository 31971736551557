import { compose, withState, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import {
  setSecondaryBarItems,
  setActiveSecondarySidebarItem,
  checkStatusPopup,
  setActiveSidebarItem,
} from '../../../store/actions/dashboard';

import RequestChangeRoleView from './RequestChangeRoleView';
import { RoleService, UserService } from '../../../services';

import { saveUserAccesses } from '../../../store/actions/auth';

export default compose(
  connect(
    (state) => ({
      user: state.auth.currentUser,
      secondaryBarItems: state.dashboard.secondaryBarItems,
      activeSecondaryItem: state.dashboard.activeSecondaryItem,
      checkStatus: state.dashboard.checkStatusPopup,
      access: state.auth.userAccesses,
    }),
    (dispatch) => ({
      saveUserAccesses: (data) => dispatch(saveUserAccesses(data)),
      setActiveSecondarySidebarItem: (item) =>
        dispatch(setActiveSecondarySidebarItem(item)),
      setSecondaryBarItems: (items) => dispatch(setSecondaryBarItems(items)),
      checkStatusPopup: (item) => dispatch(checkStatusPopup(item)),
      setActiveSidebarItem: (item) => dispatch(setActiveSidebarItem(item)),
    }),
  ),
  // Роли
  withState('listRole', 'setListRole', []),

  // Список имеющихся ролей
  withState('roles', 'setRoles', []),

  // Записываем id роли чтобы получить ее права
  withState('accessRoles', 'setAccessRoles', []),

  // Показывает чекбоксы
  withState('openRoleEdit', 'setOpenRoleEdit', false),

  // Список заявок
  withState('listRequest', 'setListRequest', []),

  // Список Активных заявок для роли
  withState('listRequestAccess', 'setRequestAccess', []),

  // Список Активных Привелегий
  withState('listRoleAccess', 'setListRoleAccess', []),

  // запомнить ид роли
  withState('idState', 'setIDstate', ''),

  // Запоминаем имя роли
  withState('nameState', 'setNameState', ''),

  // Запоминаем защиту роли
  withState('protectionState', 'setProtectionState', false),

  // Запоминаем url роли
  withState('urlState', 'setUrlState', ''),

  withState('addRoleSection', 'setAddRoleSection', false),

  // Имя новой роли
  withState('inputRoleName', 'setInputRoleName', ''),

  // Адрес новой роли
  withState('inputRoleAddress', 'setInputRoleAddress', ''),

  withState('saveBtn', 'setSaveBtn', false),

  withState('createBtn', 'setCreateBtn', false),

  withState('skipDropDown', 'setSkipDropDown', false),

  withHandlers({
    functionCheckTime: (props) => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),

  withHandlers({
    roleIDAccessRightName: (props) => async (id, name, url, protection) => {
      try {
        props.setAddRoleSection(false);
        props.setCreateBtn(false);
        const role = await RoleService.getRoleAccessRights(id);
        const directories = {};
        const requests = {};
        Object.keys(role.directories).map((item) => {
          directories[item] = role.directories[item];
        });
        Object.keys(role.requests).map((item) => {
          requests[item] = role.requests[item];
        });

        if (url === 'client' || url === 'provider') {
          if (
            directories.requests &&
            directories.requests.insert &&
            directories.requests.update
          ) {
            directories.requests.insert = false;
            directories.requests.update = false;
          }
        }
        props.setListRoleAccess(directories);
        props.setAccessRoles(directories);
        props.setRequestAccess(requests);
        props.setSaveBtn(true);
        props.setOpenRoleEdit(true);
        props.setIDstate(id);
        props.setInputRoleName(name);
        props.setUrlState(url);
        props.setProtectionState(protection);
      } catch (error) {
        console.log('error', error);
      }
    },
    setCheckValueRole: (props) => (methods, itemCheckRole, checkbox) => {
      const newListRole = props.listRole;
      const { accessRoles } = props;
      Object.keys(newListRole).forEach((item) => {
        if (methods === item) {
          Object.keys(newListRole[methods].methods).forEach((name) => {
            if (checkbox === name) {
              Object.keys(accessRoles[methods]).forEach((result) => {
                if (checkbox === result) {
                  accessRoles[methods][result] = !accessRoles[methods][result];
                }
              });
            }
          });
        }
      });
      props.setListRoleAccess(accessRoles);
    },
    setCheckValueRequest: (props) => (itemCheckRequest) => {
      const newCustList = props.listRequest;
      const list = props.listRequestAccess;
      Object.keys(newCustList).forEach((item) => {
        if (itemCheckRequest === item) {
          list[item] = !list[item];
        }
      });
      props.setRequestAccess(list);
    },
    saveData: (props) => async (id, name, url) => {
      try {
        const role = props.roles.find((item) => item.id === id);
        const listRoleAccess = { ...props.listRoleAccess };
        if (!props.listRequestAccess.sendLoadingDataStatus) {
          listRoleAccess.requests.sendLoadingData = false;
        }
        const result = {
          accesses: JSON.stringify({
            directories: listRoleAccess,
            requests: props.listRequestAccess,
          }),
        };
        if (!role.protection) {
          result.rightname = url;
          result.name = name;
        }
        await RoleService.editRoles(id, result);
        UserService.getRight()
          .then((item) => {
            props.saveUserAccesses({
              directory: item.result.directories,
              requests: item.result.requests,
              additionalPermissions: item.result.additionalPermissions,
            });
          })
          .catch((err) => console.log('err', err));
        props.functionCheckTime('Success', 'Сохранено');
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    createData: (props) => async (name, url) => {
      try {
        const result = {
          name,
          rightname: url,
          accesses: JSON.stringify({
            directories: props.listRoleAccess,
            requests: props.listRequestAccess,
          }),
        };
        await RoleService.createRole(result);
        const positions = await RoleService.getRoles();
        props.setRoles(positions);
        props.setSaveBtn(false);
        props.setCreateBtn(false);
        props.setOpenRoleEdit(false);
        props.setSkipDropDown(false);
        props.setAddRoleSection(false);

        props.functionCheckTime('Success', 'Создано');
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    addRole: (props) => async () => {
      props.setInputRoleName('');
      props.setSaveBtn(false);
      props.setCreateBtn(true);
      const rolesList = await RoleService.getRolesAll();
      const requestRolesList = {};
      const requestsList = {};
      const requestRolesListValue = {};
      const requestsListValue = {};

      Object.keys(rolesList.directories).map((item) => {
        requestRolesList[item] = rolesList.directories[item];
      });

      Object.keys(rolesList.requests).map((item) => {
        requestsList[item] = rolesList.requests[item];
      });

      Object.keys(requestRolesList).map((item) => {
        requestRolesListValue[item] = {};
        Object.keys(requestRolesList[item].methods).map((itemValue) => {
          requestRolesListValue[item] = {
            ...requestRolesListValue[item],
            [itemValue]: false,
          };
        });
      });
      Object.keys(requestsList).map((item) => {
        requestsListValue[item] = false;
      });
      props.setAccessRoles(requestRolesListValue);
      props.setRequestAccess(requestsListValue);
      props.setOpenRoleEdit(true);
      props.setAddRoleSection(true);
    },
  }),

  withHandlers({
    deleteRole: (props) => async (id) => {
      try {
        const deleteResult = await RoleService.deleteRole(id);
        if (deleteResult === 'OK') {
          props.setSkipDropDown(true);
        }
        const positions = await RoleService.getRoles();

        const barItems = [
          { id: '', name: 'Все', url: '/dashboard/users/' },
          ...positions,
          {
            id: 'changeRole',
            name: 'Настройка ролей',
            url: '/dashboard/users/changeRole',
          },
        ];
        const settingsEmail =
          props.access &&
          props.access.directories &&
          props.access.directories.roles &&
          props.access.directories.roles.settingsEmail;
        if (settingsEmail) {
          barItems.push({
            id: 'settingsEmail',
            name: 'Настройка email рассылки',
            url: '/dashboard/users/settingsEmail',
          });
        }

        try {
          props.setSecondaryBarItems(barItems);
        } catch {
          console.log('error');
        }
        props.setRoles(positions);
        props.setSaveBtn(false);
        props.setCreateBtn(false);
        props.setOpenRoleEdit(false);
        props.setSkipDropDown(false);
        props.functionCheckTime('Success', 'Удалено');
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
  }),

  lifecycle({
    async componentDidMount() {
      try {
        let settingsEmail = false;
        UserService.getRight()
          .then((item) => {
            settingsEmail =
              item.result &&
              item.result.directories &&
              item.result.directories.roles &&
              item.result.directories.roles.settingsEmail;

            this.props.saveUserAccesses({
              directory: item.result.directories,
              requests: item.result.requests,
              additionalPermissions: item.result.additionalPermissions,
            });
          })
          .catch((err) => console.log('err', err));
        const roles = await RoleService.getRoles();
        this.props.setRoles(roles);

        const barItems = [
          { id: '', name: 'Все', url: '/dashboard/users/' },
          ...roles,
          {
            id: 'changeRole',
            name: 'Настройка ролей',
            url: '/dashboard/users/changeRole',
          },
        ];
        if (settingsEmail) {
          barItems.push({
            id: 'settingsEmail',
            name: 'Настройка email рассылки',
            url: '/dashboard/users/settingsEmail',
          });
        }

        if (
          this.props.secondaryBarItems &&
          !this.props.secondaryBarItems.lengh
        ) {
          this.props.setSecondaryBarItems(barItems);
        }
        this.props.setActiveSidebarItem('users');
        const rolesList = await RoleService.getRolesAll();
        const requestRolesList = {};
        const requestsList = {};
        Object.keys(rolesList.directories).forEach((item) => {
          requestRolesList[item] = rolesList.directories[item];
        });
        Object.keys(rolesList.requests).forEach((item) => {
          requestsList[item] = rolesList.requests[item];
        });
        this.props.setListRequest(requestsList);
        this.props.setListRole(requestRolesList);
        try {
          this.props.setActiveSecondarySidebarItem({
            id: 'changeRole',
            name: 'Настройка ролей',
            url: '/dashboard/users/changeRole',
          });
        } catch {
          console.log('error');
        }
      } catch (error) {
        console.log('error', error);
      }
    },
  }),
)(RequestChangeRoleView);
