/**
 * @flow
 */
import React from 'react';
import { ThemeProvider } from 'styled-components';

import {
  SideBar,
  NavBar,
  SecondaryBarMobile,
  SecondaryBarItem,
} from '../index';

import {
  Page,
  SideBarPage,
  MainSection,
  ContentArea,
  MobileViewSidebar,
} from './DashboardLayoutStyled';

type DashboardLayoutViewPropsType = {
  openMenu: boolean,
  setOpenMenu: (boolean) => void,
  children: any,
  user: any,
  logoutHandler: (any) => void,
  openProfileHandler: (any) => void,
  openDashboardLogo: (any) => void,
  activeItem?: string,
  activeSecondaryItem?: Object,
  secondaryBarItems: Array<Object>,
  getSecondaryBarItems: (string) => void,
  goToPage: (Object) => void,
  openRequestFormHandler: () => void,
};

export default function DashboardLayoutView(
  props: DashboardLayoutViewPropsType,
) {
  const theme = {
    openMenu: props.openMenu,
    secondaryBar: props.secondaryBarItems,
  };

  return (
    <ThemeProvider theme={theme}>
      <Page className="main-dashboard">
        <NavBar
          onClickMobile={() => props.setOpenMenu(!props.openMenu)}
          userName={`${props.user.firstName} ${props.user.lastName}`}
          avatar={props.user.avatar}
          logout={() => props.logoutHandler()}
          openProfile={() => props.openProfileHandler()}
          openDashboard={() =>
            props.goToPageCheck(() => props.openDashboardLogo())
          }
          openRequestForm={() => props.openRequestFormHandler()}
        />
        <MainSection className="main-section">
          <SideBarPage>
            <SideBar
              menuOpenHandler={() => props.setOpenMenu(!props.openMenu)}
              isOpen={props.openMenu}
              secondaryBarItems={props.secondaryBarItems}
              secondaryBarHandler={(entity) =>
                props.goToPageCheck(() => props.getSecondaryBarItems(entity))
              }
              activeItem={props.activeItem}
              activeSecondaryItem={props.activeSecondaryItem}
              activeSecondaryItemHandler={(item) => props.goToPage(item)}
            />
          </SideBarPage>
          <ContentArea className="content-area">
            <MobileViewSidebar>
              {props.secondaryBarItems.length > 0 && (
                <SecondaryBarMobile>
                  {props.secondaryBarItems.map((item) => (
                    <SecondaryBarItem
                      name={item.name}
                      action={() => props.goToPage(item)}
                      id={item.id}
                      key={item.id}
                      active={
                        props.activeSecondaryItem &&
                        props.activeSecondaryItem.id === item.id
                      }
                    />
                  ))}
                </SecondaryBarMobile>
              )}
            </MobileViewSidebar>
            {props.children}
          </ContentArea>
        </MainSection>
      </Page>
    </ThemeProvider>
  );
}

DashboardLayoutView.defaultProps = {
  activeItem: '',
  activeSecondaryItem: {},
};
