import React from 'react';

import * as S from './styled';
import { Loaders } from '../../../pages/Content/RequestsTable/RequestTableStyles';

const renderCheckboxes = (data, checkedItems, toggleValue, field) =>
  data.map((item) => (
    <S.InputContainer>
      <S.Input
        type="checkbox"
        id={`${field}-${item.name}-${item.id}`}
        checked={checkedItems.find((findItem) => findItem.id === item.id)}
        onChange={() => {
          toggleValue(item.name, item.id);
        }}
      />
      <S.InputLabel htmlFor={`${field}-${item.name}-${item.id}`}>
        {item.name}
      </S.InputLabel>
    </S.InputContainer>
  ));

const View = (props) => {
  const previewValue = () => {
    let val = '';
    val =
      props.filterModel[props.column.colId] &&
      props.filterModel[props.column.colId].values;
    if (val === undefined) {
      return '';
    }
    if (val === '') {
      return 'Не заполнено';
    }
    return val.join(', ');
  };
  const checkedItems = props.predestined ? [] : props.checkedItems;

  return (
    <S.OpenInputContainer>
      <div id={`custom-ag-filter-${props.column.colId}`}>
        <S.OpenInputContainer>
          <S.OpenInput
            className="custom-ag-filter-input"
            placeholder=""
            value={`  ${previewValue()}`}
            readOnly
            onClick={props.toggleOpened}
          />
          <S.OpenFilterButton
            className="ag-icon ag-icon-filter"
            unselectable="on"
            onClick={props.toggleOpened}
          />
        </S.OpenInputContainer>
        <S.ContentContainer
          opened={props.column.colId === props.openFilterField}>
          {!props.predestined &&
            !props.checkboxesList.includes(props.column.colId) && (
              <S.SearchContainer>
                <S.SearchIcon />
                <S.Input
                  placeholder="Начните вводить..."
                  value={props.searchValue}
                  onChange={props.handleChangeSearchValue}
                />
                {props.searchValue && (
                  <S.FilterValueDelete
                    onClick={() => {
                      props.handleChangeSearchValue({ target: { value: '' } });
                    }}
                    src={require('../../../assets/icons/delete-black.svg')}
                    alt="delete"
                  />
                )}
              </S.SearchContainer>
            )}
          <S.CheckboxesContainer>
            {props.isLoading ? (
              <div
                style={{
                  position: 'absolute',
                  left: '35%',
                  top: '45%',
                }}>
                <Loaders />
              </div>
            ) : !props.hasResult ? (
              <div style={{ textAlign: 'center' }}>Нет результатов</div>
            ) : props.searchResults.length > 0 ? (
              <React.Fragment>
                {renderCheckboxes(
                  [
                    ...props.searchResults,
                    ...props.checkedItems.filter(
                      (item) =>
                        !props.searchResults.find(
                          (findItem) => findItem.name === item.name,
                        ),
                    ),
                  ],
                  props.checkedItems,
                  props.toggleValue,
                  props.column.colId,
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {renderCheckboxes(
                  [
                    ...checkedItems
                      .filter(
                        (item) =>
                          !props.items.find(
                            (findItem) => findItem.name === item.name,
                          ),
                      )
                      .sort((a, b) => a.name.localeCompare(b.name)),
                    ...props.items,
                  ],
                  props.checkedItems,
                  props.toggleValue,
                  props.column.colId,
                )}
              </React.Fragment>
            )}
          </S.CheckboxesContainer>
          <S.ButtonsContainer>
            <S.Button onClick={props.onReset}>Сбросить</S.Button>
            <S.Button onClick={props.onApply}>Применить</S.Button>
          </S.ButtonsContainer>
        </S.ContentContainer>
      </div>
    </S.OpenInputContainer>
  );
};

export default View;
