import React from 'react';

import { Container, ContainerImg, Img } from './IconYesNoStyled';

const IconYesNoView = (props) => (
  <Container>
    <ContainerImg>
      {(props.value && (
        <Img src={require('../../assets/icons/yes.svg')} alt="yes" />
      )) || <Img src={require('../../assets/icons/no.svg')} alt="no" />}
    </ContainerImg>
  </Container>
);

export default IconYesNoView;
