import styled, { css } from 'styled-components';

import { base } from '../../styles';

export const StampTitle = styled.div`
  /* font-family: 'MuseoSansCyrl Bold', sans-serif; */
  font-weight: 300;
  font-style: normal;
  display: table-cell;
  width: ${base * 13}px;
  height: 24px;
  cursor: pointer;
  border: 1px solid #b8b8b8;
  border-radius: 2px;
  text-align: center;
  vertical-align: middle;
  color: #b8b8b8;
  ${(props) =>
    props.status &&
    css`
      background-color: #aeda9b;
      color: white;
    `}
`;
