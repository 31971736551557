/**
 * @flow
 */

import React from 'react';

import {
  DropdownWraper,
  UserName,
  UserAvatar,
  DropdownHeader,
  DropdownOptions,
  DropDownMask,
} from './DropdownStyled';

type DropdownPropsType = {
  avatar: string,
  name: string,
  children: any,
  toggledOn: (any) => void,
  toggle: string,
  convertToArray: (any) => Array<any>,
  toggleDrop: Boolean,
};

export default function Dropdown(props: DropdownPropsType) {
  let { children } = props;
  const isArray = Array.isArray(children);

  if (!isArray) {
    children = props.convertToArray();
  }

  return (
    <DropdownWraper>
      <DropdownHeader onClick={() => props.setToggleDrop(!props.toggleDrop)}>
        <UserName>{props.name}</UserName>
        <UserAvatar avatar={props.avatar} />
        <img
          style={{
            marginLeft: '10px',
          }}
          src={require('../../assets/icons/dropdown-arrow.svg')}
          alt="arrow"
        />
      </DropdownHeader>
      {props.toggleDrop && (
        <DropdownOptions onClick={() => props.setToggleDrop(!props.toggleDrop)}>
          {children}
        </DropdownOptions>
      )}
    </DropdownWraper>
  );
}
