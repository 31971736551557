/* eslint-disable react/prop-types */

import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';

// sytles
import './App.css';

// services
import AuthService from './services/AuthService';

// pages
import LoginPage from './pages/Login/LoginContainer';
import DashboardMain from './pages/DashboardMain/DashboardMainContainer';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const auth = new AuthService();
      if (auth.loggedIn()) {
        return <Component {...props} />;
      }
      return <Redirect to="/" />;
    }}
  />
);

function App() {
  return (
    <BrowserRouter>
      <div>
        <Route exact path="/" component={LoginPage} />
        <PrivateRoute path="/dashboard" component={DashboardMain} />
      </div>
    </BrowserRouter>
  );
}

export default compose(
  connect((state) => ({
    token: state.auth.token,
  })),
)(App);
