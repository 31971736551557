export const replaceSpecialCharacters = (str) => {
  let newStr = '';
  newStr = str.replace(/\*/g, '\\*');
  newStr = newStr.replace(/\+/g, '\\+');
  newStr = newStr.replace(/\?/g, '\\?');
  newStr = newStr.replace(/\\/g, '\\\\');
  newStr = newStr.replace(/\./g, '\\.');
  newStr = newStr.replace(/\^/g, '\\^');
  newStr = newStr.replace(/\[/g, '\\[');
  newStr = newStr.replace(/\]/g, '\\]');
  newStr = newStr.replace(/\(/g, '\\(');
  newStr = newStr.replace(/\)/g, '\\)');
  newStr = newStr.replace(/\$/g, '\\$');
  newStr = newStr.replace(/\&/g, '\\&');
  newStr = newStr.replace(/\|/g, '\\|');
  let retStr = '';
  for (const iterator of newStr) {
    if (iterator === '?' || iterator === '+' || iterator === '*') {
      retStr = retStr.slice(0, -1);
      retStr += iterator;
    } else {
      retStr += iterator;
    }
  }
  return retStr;
};
