/**
 * @flow
 */

import React from 'react';

import {
  InputComponent,
  SearchIcon,
  InputWrapper,
  DeleteValue,
} from './InputStyled';
import { numberWithCommas } from '../../helpers/RoundingNumbers';

type InputViewPropsType = {
  placeholder?: string,
  valid?: boolean,
  search?: boolean,
  value: any,
  inputType?: string,
  readOnlyState: boolean,
  changed: (any) => void,
  active: (any) => void,
  patternLat?: string,
  disabled?: Boolean,
  crop?: number,
  id?: string,
  location?: string,
  btnDelete?: boolean,
  autoFocus?: boolean,
  autoComplete?: string,
};

export default function InputView(props: InputViewPropsType) {
  return (
    <InputWrapper>
      {props.search && <SearchIcon search={props.search} />}
      <InputComponent
        disabled={props.disabled}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(event) => {
          if (props.crop) {
            props.changed(numberWithCommas(event.target.value, props.crop));
          } else {
            props.changed(event.target.value);
          }
        }}
        valid={props.valid}
        search={props.search}
        readOnly={props.readOnlyState}
        onFocus={() => {
          if (props.active) {
            props.active();
          }
        }}
        onBlur={() =>
          props.type === 'date' &&
          props.setInputType &&
          props.setInputType('text')
        }
        id={props.id}
        location={props.location}
        btnDelete={props.btnDelete}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
      />
      {props.btnDelete && props.value && (
        <DeleteValue
          src={require('../../../src/assets/icons/delete-black.svg')}
          onClick={() => props.changed('')}
          alt="delete"
        />
      )}
    </InputWrapper>
  );
}

InputView.defaultProps = {
  inputType: 'text',
  placeholder: '',
  valid: true,
  search: false,
  autoFocus: false,
  autoComplete: 'on',
};
