import styled, { css } from 'styled-components';
import { base, colors, screens } from '../../../styles';

export const Form = styled.div`
  width: 100%;
  height: 90%;
  position: relative;
  font-family: 'Source Sans Pro', sans-serif;
  @media (max-width: ${screens.screen_xs_max}) {
    margin-left: -35px;
  }
  @media (max-width: ${screens.screen_2xs_max}) {
    width: calc(100vw - 2 * ${base}px);
  }

  .ag-theme-balham .ag-header {
    font-family: 'Source Sans Pro', sans-serif;
  }
  /*Скрыть лицензию AG-GRID*/
  .ag-watermark {
    display: none;
  }
  /*Скрыть скроллинг колонки с номером*/
  .ag-horizontal-left-spacer {
    visibility: hidden;
  }

  .ag-theme-balham .ag-cell {
    border: 0px solid transparent;
  }
`;

export const ControlContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${screens.screen_xs_max}) {
    margin-top: -14px;
  }
  @media (max-width: ${screens.screen_2xs_max}) {
    flex-wrap: wrap;
  }
`;

export const Heading = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-right: 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 12px 0px;

  @media (max-width: ${screens.screen_xs_max}) {
    padding: 0 0 12px 0px;
  }
`;

export const TitleButton = styled.div`
  margin-right: 15px;
  position: relative;
  ${(props) =>
    props.filtersActivePoint &&
    css`
      &:before {
        content: '•';
        position: absolute;
        color: #da350a;
        top: -21px;
        left: 9px;
        font-size: 20px;
      }
    `}
`;

export const ExpandButtonWrapper = styled.div`
  padding-bottom: 12px;
  padding-left: 12px;
`;

export const CalendarContainer = styled.div`
  display: block;
  position: absolute;
  z-index: 15;
  top: 45px;
  ${(props) =>
    props.type === 'filter' &&
    css`
      top: 56px;
      left: -90%;
    `}

  @media (max-width: ${screens.screen_md_mid}) {
    top: 132px;
    left: 10px;
  }
`;

export const CalendarButton = styled.div`
  margin-left: auto;
  margin-right: 15px;
  padding-bottom: 12px;
  position: relative;

  ${(props) =>
    props.filterActive &&
    css`
      &:before {
        content: '•';
        position: absolute;
        color: #da350a;
        top: -21px;
        left: 9px;
        font-size: 20px;
      }
    `}

  @media (max-width: ${screens.screen_xs_max}) {
    margin-right: 3px;
  }
`;

export const InputDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: 12px;
  margin-right: 12px;

  input,
  div {
    height: 36px;
    width: ${base * 14}px;
  }
`;

export const InputTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: 12px;
  margin-right: 12px;

  input,
  div {
    height: 100%;
  }
`;

export const InputSearch = styled.div`
  position: relative;
  width: 220px;
  padding: 0 0 12px 0;
  input {
    padding-right: 24px;
    padding-left: 10px;
  }
  @media (max-width: ${screens.screen_md_min}) {
    display: none;
  }
`;

export const MobileInputSearch = styled.div`
  display: none;
  width: 220px;
  padding: 0 0 12px 0;

  @media (max-width: ${screens.screen_md_min}) {
    display: block;
  }

  @media (max-width: ${screens.screen_2xs_max}) {
    width: 100%;
  }
`;

export const FiltersContainer = styled.div`
  background: ${colors.white};
  margin: 0 -45px 0 -51px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0 0 82px;

  @media (max-width: ${screens.screen_xs_max}) {
    padding: 10px 0 0 10px;
    margin: 0 -100px 0 0;
  }
`;

export const TableContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 30px;
  font-size: 12px;

  @media (max-width: ${screens.screen_sm_mid}) {
    height: calc(100vh - 19 * ${base}px);
  }
  @media (max-width: ${screens.screen_4xs_max}) {
    height: calc(100vh - 22 * ${base}px);
  }
`;

export const FiltersValueContainer = styled.div`
  width: 150px;
  margin: 0 10px 10px 0;
`;

export const FiltersValueTitle = styled.div`
  margin: 0 0 2px 0;
  color: ${colors.light_gray};
  font-size: 10px;
`;

export const FiltersValue = styled.div`
  position: relative;

  input {
    height: 22px;
    font-size: 11px;
    padding: 0 17px 0 12px;
    text-overflow: ellipsis;
  }
`;

export const FiltersValueDelete = styled.img`
  width: 10px;
  position: absolute;
  top: 6px;
  right: 4px;
  outline: none;
  cursor: pointer;
`;

export const DateFiltersValueDelete = styled.img`
  width: 10px;
  height: 10px;
  position: relative;
  right: 22px;
  outline: none;
  cursor: pointer;

  @media (max-width: ${screens.screen_md_mid}) {
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 22px;
  font-size: 11px;
  outline: none;
  padding: 0 12px;
  border: none;
  border-radius: ${base / 2}px;
  background-color: ${colors.white_smoke};

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const ValueOptions = styled.div`
  position: absolute;
  z-index: 100;
  width: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 200px;
  margin-top: 5px;
  background-color: ${colors.white};
  border: 1px solid ${colors.history_gray};
  border-radius: ${base / 2}px;
`;

export const OptionValues = styled.div`
  padding: 3px 5px 3px 5px;
  cursor: pointer;

  &:hover {
    color: ${colors.primary_red};
  }

  &:active {
    color: ${colors.primary_red};
  }
`;

export const Loaders = styled.div`
  /* display: none; */
  border: 7px solid #dadada;
  border-top: 7px solid #656565;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
  margin-left: 20px;

  ${(props) =>
    props.withoutMargin &&
    css`
      margin-left: 0px;
    `}

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const IconButtonWrapper = styled.div`
  font-size: 18px;
  display: flex;
`;

export const ModalContentWrapper = styled.div`
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Textarea = styled.textarea`
  width: 90%;
  height: 75%;
  border: 1px solid #dadada;
  padding: 8px;
  margin: 16px 0;
  outline: none;
  resize: none;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  position: absolute;
  width: 250px;
  bottom: 20px;
  justify-content: space-between;

  button {
    width: 100%;
    padding-left: 90px;
  }
  ${(props) =>
    props.type === 'time' &&
    css`
      width: 100px;
      button {
        padding-left: 16px;
      }
    `}
`;
