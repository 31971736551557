import { compose, withState, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import {
  socket,
  connection as socketConnection,
} from '../../../services/socket';

// view
import RequestFromView from './RequestFormView';

// actions
import { saveUserAccesses } from '../../../store/actions/auth';
import {
  setSecondaryBarItems,
  setActiveSecondarySidebarItem,
  checkStatusPopup,
  setNewRequest,
  setDataRequest,
  setChangedDataRequest,
  setDataPaymentRequest,
  setChangedDataPaymentRequest,
} from '../../../store/actions/dashboard';

// services
import PayerService from '../../../services/PayerService';
import CounterpartyService from '../../../services/CounterpartyService';
import ShipperService from '../../../services/ShipperService';
import ConsigneeService from '../../../services/ConsigneeService';
import AutoagentService from '../../../services/AutoagentService';
import StationService from '../../../services/StationService';
import LoadService from '../../../services/LoadService';
import TerminalService from '../../../services/TerminalService';
import PaymentsService from '../../../services/PaymentsService';
import CitiesService from '../../../services/CitiesService';
import RailcarrierService from '../../../services/RailcarrierService';
import { RequestsService, UserPayersService } from '../../../services';
import { numberWithCommas } from '../../../helpers/RoundingNumbers';

const FIELDS = {
  number: {},
  state: {
    name: 'Предварительное',
  },
  stopSheet: {},
  companyAmixgroup: {},
  dateLoad: {},
  timeLoad: {},
  typeSend: {},
  typeContainer: {},
  typeElivery: {},
  numberContainer: {},
  numberSeal: {},
  payer: {},
  destinationCity: {},
  shipper: {},
  consignee: {},
  railCarrier: {},
  railCarrierStatus: {},
  prrOnArrived: {},
  agentAutoLoad: {},
  ownerContainer: {},
  agentAutoCall: {},
  agentAutoCallStatus: {},
  autoBeforeUnloading: {},
  companyMoversUnloading: {},
  companyMoversLoading: {},
  companyNameForwarder: {},
  addressLoading: {},
  addressUnloading: {},
  stationDeparture: {},
  stationDestination: {},
  loadActual: {},
  loadDocumented: {},
  weight: {},
  numberSeats: {},
  driver: {},
  unloadingDriverTaskStatus: {},
  driverOld: {},
  car: {},
  trailer: {},
  terminalStaging: {},
  instructionsDelivery: {},
  releases: {},
  company: {},
  manager: {},
  managerByRegion: {},
  security: {},
  warming: {},
  warmingCompany: {},
  forwarderLoading: {},
  forwarderUnloading: {},
  movers: {},
  companyMovers: {},
  veterinaryCertificate: {},
  gdnUnloading: {},
  loadFastening: {},
  agentDocuments: {},
  returnOfDocumentsFromUnloading: {},
  dateArrival: {},
  dateIssue: {},
  plannedIssueDate: {},
  tracking: {},
  distance: {},
  railwayInvoices: {},
  loading: {},
  loadingTN: {},
  unloadingTN: {},
  act: {},
  od: {},
  shipment: {},
  customerApplicationNumber: {},
  additionalServices: {},
  buh_amount: {},
  buh_carrier: {},
  buh_autoBeforeLoading: {},
  buh_forMKAD: {},
  buh_forMKAD_rate: {},
  buh_forMKAD_km: {},
  buh_containerAgent: {},
  buh_aboveTheNorm: {},
  buh_aboveTheNorm_rate: {},
  buh_aboveTheNorm_day: {},
  buh_forwarderOnLoading: {},
  buh_moversOnLoading: {},
  buh_moversAtUnloading: {},
  buh_veterinaryCertificate: {},
  buh_loadFastening: {},
  buh_warming: {},
  buh_additionalServices: {},
  buh_exportAgent: {},
  buh_overload_rate: {},
  buh_overload_ton: {},
  buh_overload: {},
  buh_simpleToUnload_rate: {},
  buh_simpleToUnload_hour: {},
  buh_simpleToUnload: {},
  buh_simpleToLoad_rate: {},
  buh_simpleToLoad_hour: {},
  buh_simpleToLoad: {},
  buh_plain_rate: {},
  buh_plain_hour: {},
  buh_plain: {},
  buh_storageAtTheDestinationStation_rate: {},
  buh_storageAtTheDestinationStation_day: {},
  buh_storageAtTheDestinationStation: {},
  buh_seal: {},
  buh_bills: {},
  buh_agentToClient: {},
  buh_managerial: {},
  buh_agentSuppliers: {},
  buh_OBN: {},
  buh_total: {},
  buh_gasketMaterial: {},
  customerAccountNumber: {},
  customerAccountAmount: {},
  customerAccountDate: {},
  carrierAccount: {},
  accountsAutoServices: {},
  containerAgentAccount: {},
  exportAgentAccount: {},
  moversAccountOnLoading: {},
  moversAccountOnUnloading: {},
  accountSecuringCargo: {},
  billCargoWeatherization: {},
  moversAtUnloading: {},
  moversOnLoading: {},
  scheduledIssueTime: {},
  documentStatus: {},
  timeIssue: {},
  timofeevo: {},
  actualDateDispatch: {},
  plannedDispatchDate: {},
  departureVesselDate: {},
  arrivalVesselDate: {},
  notificationDate: {},
  timurovo: {},
  balance: {},
  exportAgentEurosib: {},
  containerAccessory: {},
  additionalLoadingAddress: {},
  cargoCode: {},
  payers: [{ name: '', id: 1, data: '' }],
  customerAccountNumberStatus: {},
  carrierAccountStatus: {},
  accountsAutoServicesStatus: {},
  containerAgentAccountStatus: {},
  exportAgentAccountStatus: {},
  moversAccountOnLoadingStatus: {},
  moversAccountOnUnloadingStatus: {},
  accountSecuringCargoStatus: {},
  billCargoWeatherizationStatus: {},
  accountStorageAtDestinationStation: {},
  accountStorageAtDestinationStationStatus: {},
  accountSimpleToUnload: {},
  accountSimpleToUnloadStatus: {},
  accountSimpleToLoad: {},
  accountSimpleToLoadStatus: {},
  accountOverload: {},
  accountOverloadStatus: {},
  accountAboveTheNorm: {},
  accountAboveTheNormStatus: {},
  accountAdditionalServices: {},
  accountAdditionalServicesStatus: {},
  buh_amountMore: [],
  buh_carrierMore: [],
  buh_exportAgentMore: [],
  attorneyPowerNumber: {},
  additionalServicesCounterparty: {},
  note: {},
  exhibitedSimpleToUnload: {},
  exhibitedStorage: {},
  exhibitedOverflow: {},
  exhibitedAdditionalServices: {},
};
const PAYMENT_FIELDS = [
  {
    amount: '',
    paymentNumber: '',
    date: '',
    type: 'payment',
    id: 1,
  },
  {
    amount: '',
    paymentNumber: '',
    date: '',
    type: 'containerAgentPayment',
    id: 2,
  },
];

export default compose(
  connect(
    (state) => ({
      user: state.auth.currentUser,
      accesses: state.auth.userAccesses,
      checkStatus: state.dashboard.checkStatusPopup,
      checkStatus: state.dashboard.checkStatusPopup,
      dataRequest: state.dashboard.dataRequest,
    }),
    (dispatch) => ({
      saveUserAccesses: (data) => dispatch(saveUserAccesses(data)),
      setSecondaryBarItems: (items) => dispatch(setSecondaryBarItems(items)),
      setActiveSecondarySidebarItem: (item) =>
        dispatch(setActiveSecondarySidebarItem(item)),
      checkStatusPopup: (item) => dispatch(checkStatusPopup(item)),
      setNewRequest: (item) => dispatch(setNewRequest(item)),
      setDataRequest: (data) => dispatch(setDataRequest(data)),
      setChangedDataRequest: (data) => dispatch(setChangedDataRequest(data)),
      setDataPaymentRequest: (data) => dispatch(setDataPaymentRequest(data)),
      setChangedDataPaymentRequest: (data) =>
        dispatch(setChangedDataPaymentRequest(data)),
    }),
  ),
  withState('autocompleteValues', 'setAutocompleteValues', {}),
  withState('edit', 'setEdit', false),
  withState('total', 'setTotal', null),
  withState('activePayersField', 'setActivePayersField', null),
  withState('fieldsValue', 'setFieldsValue', { ...FIELDS }),
  withState('changedPaymentFields', 'setChangedPaymentFields', []),
  withState(
    'payments',
    'setPayments',
    PAYMENT_FIELDS.map((item) => ({
      ...item,
    })),
  ),
  withState('addedPaymentsField', 'setAddedPaymentField', []),
  withState(
    'containerAgentPaymentCounter',
    'setContainerAgentPaymentCounter',
    null,
  ),
  withState('paymentCounterAmount', 'setPaymentCounterAmount', null),
  withState('buhCounterAmountMore', 'setBuhCounterAmountMore', null),
  withState('fieldsWithErrors', 'setFieldsWithErrors', []),
  withState('showLoader', 'setShowLoader', true),
  withState('createUpdateInProcess', 'setCreateUpdateInProcess', false),
  withState('changedFields', 'setChangedFields', []),
  withState('listState', 'setListState', []),
  withHandlers({
    functionCheckTime: (props) => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    calculation: (props) => (field, oldVal = 0, newVal = 0) => {
      if (field === 'buh_containerAgent' && newVal === '-') {
        newVal = 0;
      }
      if (
        field === 'buh_simpleToUnload_rate' ||
        field === 'buh_simpleToUnload_hour' ||
        field === 'buh_simpleToLoad_rate' ||
        field === 'buh_simpleToLoad_hour' ||
        field === 'buh_plain_rate' ||
        field === 'buh_plain_hour' ||
        field === 'buh_storageAtTheDestinationStation_rate' ||
        field === 'buh_storageAtTheDestinationStation_day' ||
        field === 'buh_forMKAD_rate' ||
        field === 'buh_forMKAD_km' ||
        field === 'buh_aboveTheNorm_rate' ||
        field === 'buh_aboveTheNorm_day' ||
        field === 'buh_overload_rate' ||
        field === 'buh_overload_ton'
      ) {
        return;
      }
      const currentFieldsValue = { ...props.fieldsValue };
      if (currentFieldsValue.buh_total) {
        let total = 0;
        if (field === 'buh_amount') {
          total = Number(props.total) - Number(oldVal) + Number(newVal);
        } else {
          total =
            Number(props.total) -
            Number(newVal) +
            Number(oldVal === '-' ? '0' : oldVal);
        }
        props.setTotal(total.toFixed(2));
      }
    },
  }),
  withHandlers({
    updateInitOperation: (props) => (requestId, isInit = false) =>
      RequestsService.getRequestById(requestId)
        .then((result) => {
          if (isInit && result.doc.isEditing) {
            props.history.push('/dashboard/requests');
            return Promise.reject({
              message: 'Заявка открыта другим пользователем, попробуйте позже.',
            });
          }
          if (isInit && !result.doc.isEditing) {
            const pathname = props.location.pathname.split('/');
            socket.emit('open-request', {
              _id: pathname[4],
              user: `${props.user.firstName} ${props.user.lastName}`,
            });
          }
          Object.keys(result.doc).forEach((field) => {
            if (field === 'payers') {
              const listPayers = result.doc[field].map((item, index) => ({
                id: index + 1,
                name: item.name,
                data: item._id,
              }));
              result.doc[field] =
                listPayers.length > 0
                  ? listPayers
                  : [{ name: '', id: 1, data: '' }];
              return;
            }
            if (field === 'buh_amountMore') {
              const listBuhAmountMore = result.doc[field].map(
                (item, index) => ({
                  id: item._id,
                  idIndex: index + 1,
                  amount: item.amount,
                  comment: item.comment,
                  date: item.date,
                  accountNumber: item.accountNumber,
                  status: item.status,
                }),
              );
              result.doc[field] =
                listBuhAmountMore.length > 0 ? listBuhAmountMore : [];
              return;
            }
            if (
              field === 'buh_carrierMore' ||
              field === 'buh_exportAgentMore'
            ) {
              const listBuhMore = result.doc[field].map((item, index) => ({
                id: item._id,
                idIndex: index + 1,
                amount: item.amount,
                accountNumber: item.accountNumber,
                status: item.status,
              }));
              result.doc[field] = listBuhMore.length > 0 ? listBuhMore : [];
              return;
            }
            if (typeof result.doc[field] === 'object' && result.doc[field]) {
              result.doc[field] = {
                ...result.doc[field],
                name: result.doc[field].data,
              };
            } else {
              result.doc[field] = { name: result.doc[field] };
            }
          });
          props.setEdit(true);
          props.setDataRequest(JSON.parse(JSON.stringify(result.doc)));
          props.setChangedDataRequest(JSON.parse(JSON.stringify(result.doc)));
          props.setFieldsValue(result.doc, () => {
            if (result.doc.buh_total && result.doc.buh_total.name) {
              props.setTotal(result.doc.buh_total.name);
            } else {
              props.setTotal(0);
            }
          });
          if (props.accesses.directory.payments.findAll) {
            return PaymentsService.getById(result.doc._id.name);
          }
        })
        .then((paymentsData) => {
          if (paymentsData && paymentsData.length !== 0) {
            const copyPayments = [].concat(paymentsData);
            const paymentsList = paymentsData.filter(
              (payment) => payment.type === 'payment',
            );
            const containerList = paymentsData.filter(
              (payment) => payment.type === 'containerAgentPayment',
            );
            let counterPayment = 0;
            let counterContainer = 0;
            paymentsList.forEach(
              (item) =>
                (counterPayment = Number(counterPayment) + Number(item.amount)),
            );
            containerList.forEach(
              (item) =>
                (counterContainer =
                  Number(counterContainer) + Number(item.amount)),
            );
            if (counterContainer === 0) {
              copyPayments.push({
                amount: '',
                paymentNumber: '',
                date: '',
                type: 'containerAgentPayment',
                id: 2,
              });
            }
            if (counterPayment === 0) {
              copyPayments.push({
                amount: '',
                paymentNumber: '',
                date: '',
                type: 'payment',
                id: 1,
              });
            }
            props.setContainerAgentPaymentCounter(counterContainer);
            props.setPaymentCounterAmount(counterPayment);
            props.setPayments(copyPayments);
            props.setDataPaymentRequest(
              JSON.parse(JSON.stringify(copyPayments)),
            );
            props.setChangedDataPaymentRequest(
              JSON.parse(JSON.stringify(copyPayments)),
            );
            props.setChangedPaymentFields([]);
          } else {
            props.setPayments(
              PAYMENT_FIELDS.map((item) => ({
                ...item,
              })),
            );
            props.setChangedPaymentFields([]);
          }
        })
        .catch((error) => props.functionCheckTime('Error', error.message))
        .finally(() => props.setShowLoader(false)),
    skipDependencies: (props) => (entity) => {
      const currentFieldsValue = { ...props.fieldsValue };

      switch (entity) {
        case 'payer':
          if (currentFieldsValue.consignee) {
            currentFieldsValue.consignee.name = '';
          }
          if (currentFieldsValue.shipper) {
            currentFieldsValue.shipper.name = '';
          }
          if (currentFieldsValue.addressLoading) {
            currentFieldsValue.addressLoading.name = '';
          }
          if (currentFieldsValue.addressUnloading) {
            currentFieldsValue.addressUnloading.name = '';
          }
          if (currentFieldsValue.manager) {
            currentFieldsValue.manager.name = '';
          }
          if (currentFieldsValue.managerByRegion) {
            currentFieldsValue.managerByRegion.name = '';
          }
          props.setChangedFields([
            ...props.changedFields,
            'payer',
            'consignee',
            'shipper',
            'addressLoading',
            'addressUnloading',
            'manager',
            'managerByRegion',
          ]);
          break;
        case 'shipper':
          if (currentFieldsValue.addressLoading) {
            currentFieldsValue.addressLoading.name = '';
            props.setChangedFields([
              ...props.changedFields,
              'shipper',
              'addressLoading',
            ]);
          }
          break;
        case 'consignee':
          if (currentFieldsValue.addressUnloading) {
            currentFieldsValue.addressUnloading.name = '';
            props.setChangedFields([
              ...props.changedFields,
              'consignee',
              'addressUnloading',
            ]);
          }
          break;
        case 'agentAutoLoad':
          if (currentFieldsValue.car) {
            currentFieldsValue.car.name = '';
          }
          if (currentFieldsValue.driver) {
            currentFieldsValue.driver.name = '';
          }
          if (currentFieldsValue.trailer) {
            currentFieldsValue.trailer.name = '';
          }
          props.setChangedFields([
            ...props.changedFields,
            'agentAutoLoad',
            'car',
            'driver',
            'trailer',
          ]);
          break;
        default:
          return;
      }
      props.setFieldsValue(currentFieldsValue);
      props.setChangedDataRequest(
        JSON.parse(JSON.stringify(currentFieldsValue)),
      );
    },
  }),
  withHandlers({
    saveHandler: (props) => (body, autoSave = false) => {
      let id;
      const hasEmptyFields =
        props.changedPaymentFields &&
        props.changedPaymentFields
          .map((item) => {
            if (!item.amount || !item.date || !item.paymentNumber) {
              return 1;
            }
            return 0;
          })
          .reduce((accum, value) => accum + +value, 0) > 0;
      if (hasEmptyFields) {
        return Promise.reject({
          message: 'Не все заполнены поля в разделе Приход',
        });
      }

      return RequestsService.addRequest(body)
        .then((response) => {
          let number = 0;
          if (response.result._id) {
            id = response.result._id;
            number = response.result.number;
          } else {
            throw new Error('Не был получен id заявки');
          }
          Promise.all(
            props.changedPaymentFields.map((item) =>
              PaymentsService.addPayment({
                ...item,
                request: response.result._id,
              }),
            ),
          );
          return { id: response.result._id, number };
        })
        .then((data) => {
          if (autoSave) {
            props.history.replace(`/dashboard/requests/edit/${id}`);
            props.setChangedPaymentFields([]);
            props.updateInitOperation(id);
            return data;
          }
          if (props.accesses.directory.requests.findAll) {
            props.setSecondaryBarItems([]);
            props.history.push('/dashboard/requests/');
            return data;
          }
          props.setSecondaryBarItems([]);
          props.history.push('/dashboard');
          return data;
        })
        .catch((error) => {
          throw new Error(error.message);
        });
    },
    updateHandler: (props) => (body) => {
      const requestId = props.location.pathname.split('/').pop();
      const requestField = [];
      const addedFields = [];
      const hasEmptyFields =
        props.changedPaymentFields &&
        props.changedPaymentFields
          .map((item) => {
            if (!item.amount || !item.date || !item.paymentNumber) {
              return 1;
            }
            return 0;
          })
          .reduce((accum, value) => accum + +value, 0) > 0;
      if (hasEmptyFields) {
        return Promise.reject({
          message: 'Не все заполнены поля в разделе Приход',
        });
      }

      if (props.changedPaymentFields.length !== 0) {
        props.changedPaymentFields.forEach((item) => {
          if (typeof item.id !== 'string') {
            addedFields.push({ ...item, request: requestId });
          } else {
            requestField.push(item);
          }
        });
      }

      return new Promise((resolve) => resolve(1))
        .then(() => {
          if (addedFields.length !== 0) {
            return Promise.all(
              addedFields.map((item) => PaymentsService.addPayment(item)),
            )
              .then(() => PaymentsService.getById(requestId))
              .then((paymentsData) => {
                if (paymentsData.length !== 0) {
                  const copyPayments = [].concat(paymentsData);
                  const paymentsList = paymentsData.filter(
                    (payment) => payment.type === 'payment',
                  );
                  const containerList = paymentsData.filter(
                    (payment) => payment.type === 'containerAgentPayment',
                  );
                  let counterPayment = 0;
                  let counterContainer = 0;
                  paymentsList.forEach(
                    (item) =>
                      (counterPayment =
                        Number(counterPayment) + Number(item.amount)),
                  );
                  containerList.forEach(
                    (item) =>
                      (counterContainer =
                        Number(counterContainer) + Number(item.amount)),
                  );
                  if (counterContainer === 0) {
                    copyPayments.push({
                      amount: '',
                      paymentNumber: '',
                      date: '',
                      type: 'containerAgentPayment',
                      id: 2,
                    });
                  }
                  if (counterPayment === 0) {
                    copyPayments.push({
                      amount: '',
                      paymentNumber: '',
                      date: '',
                      type: 'payment',
                      id: 1,
                    });
                  }
                  props.setContainerAgentPaymentCounter(counterContainer);
                  props.setPaymentCounterAmount(counterPayment);
                  props.setPayments(copyPayments);
                }
              })
              .catch((error) =>
                props.functionCheckTime('Error', error.message),
              );
          }
        })
        .then(() => {
          if (requestField.length !== 0) {
            return Promise.all(
              requestField.map((item) =>
                PaymentsService.updatePayment(item.id, item),
              ),
            );
          }
        })
        .then(() => RequestsService.updateRequest(requestId, body))
        .then((data) => {
          Object.keys(data.result).forEach((field) => {
            if (field === 'payers') {
              const listPayers = data.result[field].map((item, index) => ({
                id: index + 1,
                name: item.name,
                data: item._id,
              }));
              data.result[field] =
                listPayers.length > 0
                  ? listPayers
                  : [{ name: '', id: 1, data: '' }];
              return;
            }
            if (field === 'buh_amountMore') {
              const listBuhAmountMore = data.result[field].map(
                (item, index) => ({
                  id: item._id,
                  idIndex: index + 1,
                  amount: item.amount,
                  comment: item.comment,
                  date: item.date,
                  accountNumber: item.accountNumber,
                  status: item.status,
                }),
              );
              data.result[field] =
                listBuhAmountMore.length > 0 ? listBuhAmountMore : [];
              return;
            }
            if (
              field === 'buh_carrierMore' ||
              field === 'buh_exportAgentMore'
            ) {
              const listBuhMore = data.result[field].map((item, index) => ({
                id: item._id,
                idIndex: index + 1,
                amount: item.amount,
                accountNumber: item.accountNumber,
                status: item.status,
              }));
              data.result[field] = listBuhMore.length > 0 ? listBuhMore : [];
              return;
            }
            if (typeof data.result[field] === 'object' && data.result[field]) {
              data.result[field] = {
                ...data.result[field],
                name: data.result[field].name,
              };
            } else {
              data.result[field] = { name: data.result[field] };
            }
          });

          props.setFieldsValue(data.result, () => {
            if (data.result.buh_total && data.result.buh_total.name) {
              props.setTotal(data.result.buh_total.name);
            } else {
              props.setTotal(0);
            }
            setTimeout(() => {
              props.setChangedPaymentFields([]);
              props.setDataRequest(
                JSON.parse(JSON.stringify(props.fieldsValue)),
              );
              props.setChangedDataRequest(
                JSON.parse(JSON.stringify(props.fieldsValue)),
              );
              props.setDataPaymentRequest(
                JSON.parse(JSON.stringify(props.payments)),
              );
              props.setChangedDataPaymentRequest(
                JSON.parse(JSON.stringify(props.payments)),
              );
            }, 100);
          });
        })
        .catch((error) => {
          throw new Error(error.message);
        });
    },
    selectedFieldValue: (props) => (field, value) => {
      props.setChangedFields([...props.changedFields, field]);
      const previousValue = props.fieldsValue;
      const currentFieldsValue = props.fieldsValue;
      currentFieldsValue[field] = value;
      if (field === 'payer' && value) {
        if (previousValue.payer.id !== currentFieldsValue.payer.id) {
          props.skipDependencies(field);
        }
        return UserPayersService.getLinkedUsers({ payer: value.id })
          .then((data) => {
            if (data.length) {
              const index = data.findIndex(
                (item) =>
                  item.roleID.rightName === 'salesman' ||
                  item.roleID.rightName === 'transactionSalesman',
              );
              if (index !== -1) {
                currentFieldsValue.manager = data[index];
              }
              const indexManagerByRegion = data.findIndex(
                (item) => item.roleID.rightName === 'salesmanByRegion',
              );
              if (indexManagerByRegion !== -1) {
                currentFieldsValue.managerByRegion = data[indexManagerByRegion];
              }
            }
          })
          .catch((error) => console.warn('[API GET USERS PAYERS]', error))
          .finally(() => {
            props.setChangedDataRequest(
              JSON.parse(JSON.stringify(currentFieldsValue)),
            );
            return props.setFieldsValue(currentFieldsValue, () => {
              props.setAutocompleteValues({});
            });
          });
      }
      props.setChangedDataRequest(
        JSON.parse(JSON.stringify(currentFieldsValue)),
      );
      return props.setFieldsValue(currentFieldsValue, () => {
        props.skipDependencies(field);
        props.setAutocompleteValues({});
      });
    },
    selectPayersField: (props) => (value, id) => {
      props.setChangedFields([...props.changedFields, 'payers']);
      const currentPayer = props.fieldsValue.payers.find(
        (item) => item.id === id,
      );
      const filterPayer = props.fieldsValue.payers.filter(
        (item) => item.id !== id,
      );
      const changedValue = [
        ...filterPayer,
        { ...currentPayer, name: value.name, data: value.id },
      ].sort((a, b) => a.id - b.id);
      props.setFieldsValue({ ...props.fieldsValue, payers: changedValue });
      props.setChangedDataRequest(
        JSON.parse(
          JSON.stringify({ ...props.fieldsValue, payers: changedValue }),
        ),
      );
    },
    changePayersField: (props) => async (value, id) => {
      props.setChangedFields([...props.changedFields, 'payers']);
      props.setActivePayersField(id);
      const currentPayer = props.fieldsValue.payers.find(
        (item) => item.id === id,
      );
      const filterPayer = props.fieldsValue.payers.filter(
        (item) => item.id !== id,
      );
      const changedValue = [
        ...filterPayer,
        { ...currentPayer, name: value },
      ].sort((a, b) => a.id - b.id);
      props.setFieldsValue({ ...props.fieldsValue, payers: changedValue });
      props.setChangedDataRequest(
        JSON.parse(
          JSON.stringify({ ...props.fieldsValue, payers: changedValue }),
        ),
      );
      const result = await PayerService.searchItem({ search: value, limit: 3 });
      props.setAutocompleteValues({ payers: result });

      const fieldsWithErrorsFiltered = props.fieldsWithErrors.filter(
        (item) => item !== `payers${id}`,
      );
      props.setFieldsWithErrors(fieldsWithErrorsFiltered);
      if (value && !result.length) {
        props.setFieldsWithErrors([...fieldsWithErrorsFiltered, `payers${id}`]);
      }
    },
    changeField: (props) => async (field, value, type) => {
      const currentFieldsValue = { ...props.fieldsValue };
      if (!currentFieldsValue[field]) return;
      if (field.includes('buh_') || field === 'additionalLoadingAddress') {
        props.calculation(field, currentFieldsValue[field].name, value);
      }
      currentFieldsValue[field].name = value;
      props.setFieldsValue(currentFieldsValue);
      props.setChangedDataRequest(
        JSON.parse(JSON.stringify(currentFieldsValue)),
      );

      if (field === 'state' && value !== 'Предварительное') {
        if (!props.listState.length) {
          props.setListState([...props.listState, value]);
        }
        if (
          props.listState.length &&
          props.listState[props.listState.length - 1] !== value
        ) {
          props.setListState([...props.listState, value]);
        }
      }

      if (type !== 'autocomplete') {
        props.setChangedFields([...props.changedFields, field]);
        return;
      }
      if (!value) {
        props.setChangedFields([...props.changedFields, field]);
      }
      let currentService = null;
      let dependencies = [];
      switch (field) {
        case 'payer':
          if (!value) {
            props.skipDependencies(field);
          }
          currentService = PayerService;
          break;
        case 'consignee':
          if (value === '') {
            props.skipDependencies(field);
          }
          if (props.fieldsValue.payer && props.fieldsValue.payer.id) {
            const obj = await PayerService.getById(props.fieldsValue.payer.id);
            if (obj.relations[0].items) {
              if (value) {
                value = value.toLowerCase();
                obj.relations[0].items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = obj.relations[0].items;
              }
            }
          }
          // currentService = ConsigneeService;
          break;
        case 'shipper':
          if (value === '') {
            props.skipDependencies(field);
          }
          if (props.fieldsValue.payer && props.fieldsValue.payer.id) {
            const obj = await PayerService.getById(props.fieldsValue.payer.id);
            if (obj.relations[1].items) {
              if (value) {
                value = value.toLowerCase();
                obj.relations[1].items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = obj.relations[1].items;
              }
            }
          }
          // currentService = ShipperService;
          break;
        case 'agentAutoLoad':
          if (value === '') {
            props.skipDependencies(field);
          }
          currentService = AutoagentService;
          break;
        case 'agentAutoCall':
        case 'exportAgentEurosib':
        case 'ownerContainer':
        case 'company':
        case 'companyMovers':
        case 'companyMoversUnloading':
        case 'companyMoversLoading':
        case 'companyNameForwarder':
        case 'warmingCompany':
        case 'forwarderLoading':
        case 'forwarderUnloading':
        case 'additionalServicesCounterparty':
          currentService = CounterpartyService;
          break;
        case 'railCarrier':
          currentService = RailcarrierService;
          break;
        case 'addressLoading':
          if (props.fieldsValue.shipper && props.fieldsValue.shipper.id) {
            const obj = await ShipperService.getById(
              props.fieldsValue.shipper.id,
            );
            if (obj.relations[0].items) {
              if (value) {
                value = value.toLowerCase();
                obj.relations[0].items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = obj.relations[0].items;
              }
            }
          }
          break;
        case 'addressUnloading':
          if (props.fieldsValue.consignee && props.fieldsValue.consignee.id) {
            const obj = await ConsigneeService.getById(
              props.fieldsValue.consignee.id,
            );
            if (obj.relations[0].items) {
              if (value) {
                value = value.toLowerCase();
                obj.relations[0].items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = obj.relations[0].items;
              }
            }
          }
          // currentService = AddressService;
          break;
        case 'stationDeparture':
        case 'stationDestination':
          currentService = StationService;
          break;
        case 'destinationCity':
          currentService = CitiesService;
          break;
        case 'driver':
          if (
            props.fieldsValue.agentAutoLoad &&
            props.fieldsValue.agentAutoLoad.id
          ) {
            const obj = await AutoagentService.getById(
              props.fieldsValue.agentAutoLoad.id,
            );
            const items = obj.relations[0].items.map((item) => ({
              id: item._id,
              ...item,
            }));
            if (items.length !== 0) {
              if (value) {
                value = value.toLowerCase();
                items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = items;
              }
            }
          }
          // currentService = DriverService;
          break;
        case 'car':
          if (
            props.fieldsValue.agentAutoLoad &&
            props.fieldsValue.agentAutoLoad.id
          ) {
            const obj = await AutoagentService.getById(
              props.fieldsValue.agentAutoLoad.id,
            );
            const items = obj.relations[1].items.map((item) => ({
              id: item._id,
              ...item,
            }));
            if (items.length !== 0) {
              if (value) {
                value = value.toLowerCase();
                items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = items;
              }
            }
          }
          // currentService = CarService;
          break;
        case 'trailer':
          if (
            props.fieldsValue.agentAutoLoad &&
            props.fieldsValue.agentAutoLoad.id
          ) {
            const obj = await AutoagentService.getById(
              props.fieldsValue.agentAutoLoad.id,
            );
            const items = obj.relations[2].items.map((item) => ({
              id: item._id,
              ...item,
            }));
            if (items.length !== 0) {
              if (value) {
                value = value.toLowerCase();
                items.forEach((item) => {
                  const text = item.name.toLowerCase();
                  if (text.includes(value)) {
                    dependencies.push(item);
                  }
                });
              } else {
                dependencies = items;
              }
            }
          }
          // currentService = TrailerService;
          break;
        case 'terminalStaging':
          currentService = TerminalService;
          break;
        case 'manager':
          if (props.fieldsValue.payer && props.fieldsValue.payer.id) {
            const result = await UserPayersService.getLinkedUsers({
              payer: props.fieldsValue.payer.id,
            });
            if (result.length > 0) {
              dependencies = result.map((item) => ({
                ...item,
                id: item._id,
                name: `${item.firstName} ${item.lastName}`,
              }));
            }
          }
          break;
        case 'loadActual':
        case 'loadDocumented':
          currentService = LoadService;
          break;
        default:
          console.log('default service');
          break;
      }

      let result = [];
      try {
        result = currentService
          ? await currentService.searchItem({ search: value || '', limit: 3 })
          : dependencies;
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }

      const autocompleteValues = {};
      autocompleteValues[field] = result;
      props.setAutocompleteValues(autocompleteValues);

      if (!value) {
        const currentFieldsValue = { ...props.fieldsValue };
        currentFieldsValue[field] = { name: '', data: '', id: '' };
        props.setFieldsValue(currentFieldsValue);
        props.setChangedDataRequest(
          JSON.parse(JSON.stringify(currentFieldsValue)),
        );
      }

      let dependenciesFields = [];
      if (!value) {
        switch (field) {
          case 'payer':
            dependenciesFields = [
              'consignee',
              'shipper',
              'addressLoading',
              'addressUnloading',
              'manager',
              'managerByRegion',
            ];
            break;
          case 'shipper':
            dependenciesFields = ['addressLoading'];
            break;
          case 'consignee':
            dependenciesFields = ['addressUnloading'];
            break;
          case 'agentAutoLoad':
            dependenciesFields = ['car', 'driver', 'trailer'];
            break;
        }
      }

      const fieldsWithErrorsFiltered = props.fieldsWithErrors.filter(
        (item) => item !== field && !dependenciesFields.includes(item),
      );
      props.setFieldsWithErrors(fieldsWithErrorsFiltered);
      if (
        value &&
        autocompleteValues[field] &&
        !autocompleteValues[field].length
      ) {
        props.setFieldsWithErrors([...fieldsWithErrorsFiltered, field]);
      }
    },
    changePaymentFields: (props) => (field, value, id, type) => {
      props.setChangedFields([...props.changedFields, 'payments']);
      const currentPayment = props.payments.find(
        (item) => item.id === id && item.type === type,
      );
      const filterPayments = props.changedPaymentFields.filter(
        (item) => item.id !== id,
      );
      props.setChangedPaymentFields([
        ...filterPayments,
        { ...currentPayment, [field]: value },
      ]);
      const modifiedFields = props.payments.map((item) => {
        if (item.id === id && item.type === type) {
          return { ...item, [field]: value };
        }
        return item;
      });
      props.setPayments(modifiedFields);
      props.setChangedDataPaymentRequest(
        JSON.parse(JSON.stringify(modifiedFields)),
      );
    },
    clearAutocompleteValues: (props) => () => {
      if (Object.keys(props.autocompleteValues).length) {
        props.setAutocompleteValues({});
      }
    },
    autoCreate: (props) => () => {
      props.setEdit(true);
    },
    changeBuhAmountMoreField: (props) => (field, value, idIndex) => {
      props.setChangedFields([...props.changedFields, 'buh_amountMore']);
      const currentBuhAmount = props.fieldsValue.buh_amountMore.find(
        (item) => item.idIndex === idIndex,
      );
      const filterBuhAmount = props.fieldsValue.buh_amountMore.filter(
        (item) => item.idIndex !== idIndex,
      );
      const changedValue = [
        ...filterBuhAmount,
        { ...currentBuhAmount, [field]: value },
      ].sort((a, b) => a.idIndex - b.idIndex);
      if (field === 'amount') {
        props.calculation(
          'buh_amount',
          currentBuhAmount.amount ? +currentBuhAmount.amount : 0,
          +value,
        );
      }
      props.setFieldsValue({
        ...props.fieldsValue,
        buh_amountMore: changedValue,
      });
      props.setChangedDataRequest(
        JSON.parse(
          JSON.stringify({
            ...props.fieldsValue,
            buh_amountMore: changedValue,
          }),
        ),
      );
    },
    changeBuhMore: (props) => (fieldsValue, field, value, idIndex) => {
      props.setChangedFields([...props.changedFields, fieldsValue]);
      const currentBuh = props.fieldsValue[fieldsValue].find(
        (item) => item.idIndex === idIndex,
      );
      const filterBuh = props.fieldsValue[fieldsValue].filter(
        (item) => item.idIndex !== idIndex,
      );
      const changedValue = [
        ...filterBuh,
        { ...currentBuh, [field]: value },
      ].sort((a, b) => a.idIndex - b.idIndex);
      if (field === 'amount') {
        props.calculation(
          fieldsValue,
          currentBuh.amount ? +currentBuh.amount : 0,
          +value,
        );
      }
      props.setFieldsValue({
        ...props.fieldsValue,
        [fieldsValue]: changedValue,
      });
      props.setChangedDataRequest(
        JSON.parse(
          JSON.stringify({ ...props.fieldsValue, [fieldsValue]: changedValue }),
        ),
      );
    },
    deletePayment: (props) => (id) => {
      props.setChangedFields([...props.changedFields, 'payments']);
      const payments = props.payments.filter((item) => item.id !== id);
      const changedPayments = props.changedPaymentFields.filter(
        (item) => item.id !== id,
      );

      if (`${id}`.length < 24) {
        props.setChangedPaymentFields(changedPayments);
        props.setPayments(payments);
        props.setChangedDataPaymentRequest(
          JSON.parse(JSON.stringify(payments)),
        );
        return Promise.resolve();
      }

      return PaymentsService.deletePayment(id)
        .then((response) => {
          props.setChangedPaymentFields(changedPayments);
          props.setPayments(payments);
          props.setChangedDataPaymentRequest(
            JSON.parse(JSON.stringify(payments)),
          );
        })
        .catch((error) => {
          throw new Error('Ошибка удаления прихода');
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      socketConnection();
      const pathname = this.props.location.pathname.split('/');
      RequestsService.getAvailableFields()
        .then((accessRoot) => {
          Object.keys(accessRoot).forEach((item) => {
            accessRoot[item] = true;
          });
          this.props.saveUserAccesses({
            directory: this.props.accesses.directory,
            requests: accessRoot,
            additionalPermissions: this.props.accesses.additionalPermissions,
          });
          if (pathname[3] === 'edit') {
            this.props.updateInitOperation(pathname[4], true);
          } else {
            Object.keys(FIELDS).forEach((key) => {
              if (key === 'state') {
                FIELDS[key] = { name: 'Предварительное' };
              } else if (key === 'payers') {
                FIELDS[key] = [{ name: '', id: 1, data: '' }];
              } else if (key === 'buh_amountMore') {
                FIELDS[key] = [];
              } else if (key === 'buh_carrierMore') {
                FIELDS[key] = [];
              } else if (key === 'buh_exportAgentMore') {
                FIELDS[key] = [];
              } else if (key === 'companyAmixgroup') {
                FIELDS[key] = { name: true };
              } else {
                FIELDS[key] = {};
              }
            });
            this.props.setPayments(
              PAYMENT_FIELDS.map((item) => ({
                ...item,
              })),
            );
            this.props.setFieldsValue({ ...FIELDS }, () => {
              this.props.calculation();
            });
            this.props.setShowLoader(false);
            this.props.setChangedFields([
              ...this.props.changedFields,
              'state',
              'companyAmixgroup',
            ]);
          }
        })
        .catch((error) => this.props.functionCheckTime('Error', error.message));
      socket.on('table', ({ event, _id }) => {
        if (event === 'delete' && pathname[4] === _id) {
          this.props.history.push('/dashboard/requests');
          this.props.functionCheckTime(
            'Success',
            'Заявка была удалена другим пользователем',
          );
        }
      });
    },
    componentWillUnmount() {
      socket.close();
      this.props.setSecondaryBarItems([]);
      this.props.setFieldsValue(FIELDS);
      this.props.setPayments(
        PAYMENT_FIELDS.map((item) => ({
          ...item,
        })),
      );
    },
  }),
)(RequestFromView);
