import styled, { css } from 'styled-components';

import { base, colors } from '../../../styles';

export const StyledTabNavItem = styled.div`
  cursor: pointer;
  height: 100%;
  margin-bottom: 28px;
  margin-right: 56px;
  font-size: 24px;

  ${(props) =>
    props.active &&
    css`
      color: ${colors.primary_red};
    `}
`;
