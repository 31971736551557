import styled from 'styled-components';
import { colors, screens } from '../../styles';

export const Form = styled.div`
  padding: 10px 10px 10px 20px;
  flex-grow: 0;

  position: relative;
  height: 100%;

  &:nth-child(2n + 1) {
    z-index: 1;
    background: ${colors.white};
  }
`;

export const Line = styled.div`
  position: absolute;
  height: calc(100% - 60px);
  z-index: 2;
  top: 51px;
  left: 39px;
  border-left: 1px solid #cccccc;

  @media (max-width: ${screens.screen_2xs_max}) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
`;

export const DateTime = styled.div`
  flex: 0 100px;
  color: ${colors.history_gray};

  @media (max-width: ${screens.screen_2xs_max}) {
    display: none;
  }
`;

export const Description = styled.div`
  flex: 1;
`;

export const UserName = styled.div`
  margin: 0 0 10px 0;
  font-weight: bold;
  font-size: 16px;

  @media (max-width: ${screens.screen_2xs_max}) {
    display: none;
  }
`;

export const Field = styled.div`
  margin: 0 0 5px 0;
  font-size: 14px;
`;

export const ArrowHorizontal = styled.img`
  width: 25px;

  @media (max-width: ${screens.screen_2xs_max}) {
    width: 25px;
  }
`;

export const ArrowVertical = styled.img`
  display: block;
  margin: 8px 0;
  height: 25px;

  @media (max-width: ${screens.screen_2xs_max}) {
    height: 25px;
  }
`;

export const TextGray = styled.div`
  color: ${colors.history_gray};
`;

export const MobileUserTime = styled.div`
  display: none;
  font-weight: bold;

  @media (max-width: ${screens.screen_2xs_max}) {
    margin: 0 0 10px 0;
    display: flex;
    flex-direction: row;
  }
`;

export const MobileDateTime = styled.div`
  font-weight: normal;
  margin: 0px 0px 0px 10px;
  color: ${colors.history_gray};
`;
