import AddressService from './AddressService';
import AuthService from './AuthService';
import AutoagentService from './AutoagentService';
import CarService from './CarService';
import ConsigneeService from './ConsigneeService';
import CounterpartyService from './CounterpartyService';
import DirectoryService from './DirectoryService';
import DriverService from './DriverService';
import LoadService from './LoadService';
import PayerService from './PayerService';
import RequestsHistoryService from './RequestsHistoryService';
import RequestsService from './RequestsService';
import RoleService from './RoleService';
import ShipperService from './ShipperService';
import StationService from './StationService';
import TerminalService from './TerminalService';
import TrailerService from './TrailerService';
import UserService from './UserService';
import UserPayersService from './UserPayersService';
import PaymentsService from './PaymentsService';
import UserCounterpartyService from './UserCounterpartyService';
import CitiesService from './CitiesService';
import RailcarrierService from './RailcarrierService';
import UserManagerService from './UserManagerService';
import UserTemplateService from './UserTemplateService';

const directoriesList = [
  { field: 'railCarrier', service: RailcarrierService },
  { field: 'agentAutoLoad', service: AutoagentService },
  { field: 'ownerContainer', service: CounterpartyService },
  { field: 'agentAutoCall', service: CounterpartyService },
  { field: 'stationDeparture', service: StationService },
  { field: 'stationDestination', service: StationService },
  { field: 'loadDocumented', service: LoadService },
  { field: 'driver', service: DriverService },
  { field: 'terminalStaging', service: TerminalService },
  { field: 'companyNameForwarder', service: CounterpartyService },
  { field: 'companyMoversLoading', service: CounterpartyService },
  { field: 'companyMoversUnloading', service: CounterpartyService },
  { field: 'warmingCompany', service: CounterpartyService },
  { field: 'car', service: CarService },
  { field: 'trailer', service: TrailerService },
  { field: 'exportAgentEurosib', service: CounterpartyService },
  { field: 'shipper', service: ShipperService },
  { field: 'consignee', service: ConsigneeService },
  { field: 'addressLoading', service: AddressService },
  { field: 'addressUnloading', service: AddressService },
  { field: 'destinationCity', service: CitiesService },
  { field: 'loadActual', service: LoadService },
  { field: 'payer', service: PayerService },
  { field: 'payers', service: PayerService },
  { field: 'manager', service: UserManagerService },
  { field: 'managerByRegion', service: UserManagerService },
  { field: 'additionalServicesCounterparty', service: CounterpartyService },
];

export {
  AddressService,
  AuthService,
  AutoagentService,
  CarService,
  ConsigneeService,
  CounterpartyService,
  DirectoryService,
  DriverService,
  LoadService,
  PayerService,
  RequestsHistoryService,
  RequestsService,
  RoleService,
  ShipperService,
  StationService,
  TerminalService,
  TrailerService,
  UserService,
  UserPayersService,
  PaymentsService,
  UserCounterpartyService,
  CitiesService,
  RailcarrierService,
  directoriesList,
  UserTemplateService,
};

export const directoryServiceMap = {
  payers: PayerService,
  counterparties: CounterpartyService,
  shippers: ShipperService,
  consignees: ConsigneeService,
  autoAgents: AutoagentService,
  drivers: DriverService,
  cars: CarService,
  trailers: TrailerService,
  stations: StationService,
  loads: LoadService,
  terminals: TerminalService,
  payments: PaymentsService,
  cities: CitiesService,
  railcarriers: RailcarrierService,
};
