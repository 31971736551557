/**
 * @flow
 */
import React from 'react';

import { StyledTabNav } from './TabNavStyled';

type TabNavPropsType = {
  children: any,
  onSetActiveTab: (number) => void,
  activeTabId: number,
};

const TabNavView = (props: TabNavPropsType) => {
  const tabsNavItems = props.children.map((item) => ({
    ...item,
    props: {
      ...item.props,
      onSetActiveTab: props.onSetActiveTab,
      activeTabId: props.activeTabId,
    },
  }));

  return <StyledTabNav>{tabsNavItems}</StyledTabNav>;
};

export default TabNavView;
