import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../styles';

export const Box = styled.form`
  height: calc(100vh - 144px);
  width: ${base * 34.25}px;
  /* font-family: 'MuseoSansCyrl Bold',sans-serif; */
  background-color: ${colors.white};
  padding: ${base * 8.75}px 0 38px;
  margin-top: -84px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  @media (max-width: ${screens.screen_xs_max}) {
    width: 100vw;
    padding-top: 48px;
  }
  ${(props) =>
    props.modaleWindow &&
    css`
      padding: 0;
      margin-top: 24px;
      align-items: center;
    `}
`;

export const Top = styled.div`
  display: flex;
  padding: 0 36px;
  border-left: 5px solid ${colors.primary_red};
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
`;

export const ListBlock = styled.div`
  height: ${base * 23}px;
  overflow: hidden;
  overflow-y: scroll;
`;

export const TextArea = styled.textarea`
  background-color: ${colors.white_smoke};
  border: none;
  border-radius: ${base / 2}px;
  font-size: 12px;
  color: ${colors.light_gray};
  padding: 12px 16px;
  resize: none;
  outline: none;
  width: calc(100% - 32px);
  height: 84px;
`;

export const InfoBlock = styled.div`
  margin-top: 18px;
  border: 1px solid ${colors.primary_red};
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 12px;
  }
`;

export const ListItem = styled.div`
  padding: 3px 40px;
  position: relative;
  flex-direction: row;
  display: flex;
  p {
    font-size: 18px;
    color: ${colors.black};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ${(props) =>
    props.withoutHidden &&
    css`
      display: block;
      p {
        overflow: visible;
        white-space: normal;
      }
    `}
  &:hover {
    background-color: ${colors.white_smoke};
  }
`;

export const Bottom = styled.div`
  padding: 0 40px;
  height: 20%;
  ${(props) =>
    props.needUpdateButton &&
    css`
      height: 27%;
    `}
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 24px;
  width: calc(100% - 78px);
  button {
    display: inline;
    margin-top: 12px;
  }
  @media (max-width: ${screens.screen_xs_max}) {
    bottom: 64px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FullInfo = styled.p`
  display: none;
  position: absolute;
`;

export const Tabs = styled.p`
  flex-direction: row;
  padding: 0 40px;
`;

export const Tab = styled.button`
  margin-right: 72px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: ${colors.light_gray};
  outline: none;
  &:hover {
    color: ${colors.primary_red};
  }
  &:active {
    color: ${colors.primary_red};
    outline: none;
    border: none;
  }
  ${(props) =>
    props.active &&
    css`
      color: ${colors.primary_red};
    `}
`;

export const FromList = styled.div`
  padding: 0 40px;
  margin: 18px 0 18px;
`;

export const Content = styled.div`
  height: calc(75% - 78px);
  overflow-y: auto;
  position: absolute;
  width: 100%;
  ${(props) =>
    props.theme.needPadding &&
    css`
      padding-left: ${base * 37}px;
    `}
  right: 0;
`;

export const Paragraph = styled.p`
  margin: 0;
  color: ${colors.black};
  font-size: 32px;
  ${(props) =>
    props.smallParagraph &&
    css`
      /* font-family: 'MuseoSansCyrl light',sans-serif; */
      font-size: 18px;
      color: ${colors.light_gray};
      padding: 0 40px 3px 40px;
    `}
`;
