import {
  compose,
  withProps,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';
import { connect } from 'react-redux';

import View from './view';

import { setAllFilterModel } from '../../../store/actions/request';
import { setOpenFilterField } from '../../../store/actions/dashboard';

import RequestsService from '../../../services/RequestsService';

import { getQueryFilters } from '../../../helpers/Filters';

import { checkboxesList, selectionsList } from '../../../constants';

export default (options) =>
  compose(
    connect(
      (state) => ({
        filterModel: state.request.allFilterModel || {},
        openFilterField: state.dashboard.openFilterField,
        expand: state.dashboard.expand,
        dateFiltersActive: state.request.dateFiltersActive,
        dateFilters: state.request.dateFilters,
        selectedStatus: state.request.selectedStatus,
        searchModel: state.request.searchModel,
      }),
      (dispatch) => ({
        setAllFilterModel: (data, login) =>
          dispatch(setAllFilterModel(data, login)),
        setOpenFilterField: (data) => dispatch(setOpenFilterField(data)),
      }),
    ),
    withProps((props) => ({
      ...props,
      options,
      checkboxesList,
    })),
    withState('items', 'setItems', []),
    withState('searchResults', 'setSearchResults', []),
    withState('isLoading', 'setIsLoading', false),
    withState('checkedItems', 'setCheckedItems', []),
    withState('searchValue', 'setSearchValue', ''),
    withState('hasResult', 'setHasResult', true),
    withHandlers({
      toggleOpened: (props) => () => {
        props.setOpenFilterField(
          props.column.colId === props.openFilterField
            ? ''
            : props.column.colId,
        );
        props.setSearchValue('');
        props.setSearchResults([]);
        props.setHasResult(true);
        props.setCheckedItems([]);

        if (!(props.column.colId === props.openFilterField)) {
          props.setIsLoading(true);
          const queryFilters = getQueryFilters(props.filterModel);
          if (props.dateFiltersActive) {
            queryFilters.daterange =
              props.dateFilters === undefined ? '' : props.dateFilters;
          }
          if (props.selectedStatus) {
            queryFilters.state = JSON.stringify([props.selectedStatus]);
          }
          if (props.searchModel) {
            queryFilters.search = props.searchModel;
          }
          RequestsService.getFilterList(props.column.colId, queryFilters)
            .then((response) => {
              const items = response.data.map((item) => {
                if (checkboxesList.includes(props.column.colId)) {
                  return {
                    name: item === true ? 'Да' : 'Нет',
                    id: item === true ? 'Да' : 'Нет',
                  };
                }
                if (selectionsList.includes(props.column.colId)) {
                  if (item) {
                    return { name: item, id: item };
                  }
                  return { name: 'Не заполнено', id: 'Не заполнено' };
                }
                if (
                  props.column.colId === 'addressLoading' ||
                  props.column.colId === 'addressUnloading'
                ) {
                  if (item._id) {
                    return { name: item.title, id: item._id };
                  }
                  return { name: 'Не заполнено', id: 'Не заполнено' };
                }
                if (item._id) {
                  return { name: item.name, id: item._id };
                }
                return { name: 'Не заполнено', id: 'Не заполнено' };
              });

              if (
                props.filterModel[props.column.colId] &&
                props.filterModel[props.column.colId].values
              ) {
                const byPreviousSearch = props.filterModel[
                  props.column.colId
                ].values
                  .filter(
                    (item) =>
                      !items.find(
                        (foundItem) =>
                          foundItem.name === item ||
                          foundItem.name === 'Не заполнено',
                      ),
                  )
                  .map((item) => ({ name: item }));

                const checkedItems = props.filterModel[
                  props.column.colId
                ].values.map((item) => ({
                  name: item,
                  id: props.filterModel[props.column.colId].ids[item],
                }));
                props.setCheckedItems(checkedItems);

                props.setItems([
                  ...checkedItems.sort((a, b) => a.name.localeCompare(b.name)),
                  ...[...byPreviousSearch, ...items].filter(
                    (item) =>
                      !checkedItems.find(
                        (findItem) => findItem.name === item.name,
                      ),
                  ),
                ]);
              } else {
                props.setItems([...items]);
              }
            })
            .finally(() => {
              props.setIsLoading(false);
              setTimeout(() => {
                const elemFilter = document.getElementById(
                  `custom-ag-filter-${props.column.colId}`,
                );
                if (elemFilter) {
                  const elem = elemFilter.children[1];
                  if (elem) {
                    const { clientWidth } = document.body;
                    const coords = elem.getBoundingClientRect();
                    if (coords.right > clientWidth - 51) {
                      if (props.expand) {
                        elem.style.left = `${
                          clientWidth - coords.width - 51 - 4
                        }px`;
                      } else {
                        elem.style.left = `${
                          clientWidth - coords.width - 51 - 20
                        }px`;
                      }
                    }
                  }
                }
              }, 100);
            });
        }

        if (document.getElementsByClassName('ag-header-container')[0]) {
          document.getElementsByClassName(
            'ag-header-container',
          )[0].style.transform = null;
        }
      },
      toggleValue: (props) => (item, id) => {
        if (props.checkedItems.map((item) => item.name).includes(item)) {
          props.setCheckedItems(
            props.checkedItems.filter((elem) => elem.id !== id),
          );
        } else {
          props.setCheckedItems([...props.checkedItems, { name: item, id }]);
        }
      },
      handleChangeSearchValue: (props) => (e) => {
        props.setSearchValue(e.target.value);
        if (e.target.value.length > 0) {
          const items = props.items.filter((item) =>
            new RegExp(e.target.value, 'gi').test(item.name),
          );
          if (items.length) {
            props.setHasResult(true);
          } else {
            props.setHasResult(false);
          }
          props.setSearchResults([...items]);
        } else {
          props.setSearchResults([]);
          props.setHasResult(true);
        }
      },
      onApply: (props) => () => {
        const ids = {};

        for (let i = 0; i < props.checkedItems.length; i += 1) {
          ids[props.checkedItems[i].name] = props.checkedItems[i].id;
        }

        props.setAllFilterModel(
          {
            ...props.filterModel,
            [props.column.colId]: {
              values: props.checkedItems.map((item) => item.name),
              ids,
            },
          },
          props.options.user.login,
        );

        setTimeout(() => {
          options.initData();
        }, 0);
        props.setSearchValue('');
        props.setSearchResults([]);
        props.setOpenFilterField('');
        props.setHasResult(true);
      },
      onReset: (props) => () => {
        props.setAllFilterModel(
          {
            ...props.filterModel,
            [props.column.colId]: {
              values: [],
            },
          },
          props.options.user.login,
        );

        setTimeout(() => {
          options.initData();
        }, 0);
        props.setOpenFilterField('');
        props.setCheckedItems([]);
        props.setSearchResults([]);
        props.setHasResult(true);
      },
      handleClickOutside: (props) => (e) => {
        const node = document.getElementById(
          `custom-ag-filter-${props.column.colId}`,
        );
        if (
          !e.path.includes(node) &&
          props.openFilterField === props.column.colId
        ) {
          props.setOpenFilterField('');
          props.setCheckedItems([]);
          props.setHasResult(true);
        }
      },
      handleScrollTable: (props) => (e) => {
        if (props.column.colId === props.openFilterField) {
          const elemFilter = document.getElementById(
            `custom-ag-filter-${props.column.colId}`,
          );
          if (elemFilter) {
            const elem = elemFilter.children[1];
            if (elem) {
              const coordsElem = elem.getBoundingClientRect();
              const coordsElemFilter = elemFilter.getBoundingClientRect();
              if (
                coordsElem.left > coordsElemFilter.left &&
                elem.style.left !== ''
              ) {
                elem.style.left = '';
              }
            }
          }
        }
      },
    }),
    lifecycle({
      componentDidMount() {
        document.addEventListener('click', this.props.handleClickOutside);
        const scrollableNode = document.getElementsByClassName(
          'ag-center-cols-viewport',
        )[0];
        if (scrollableNode) {
          scrollableNode.addEventListener(
            'scroll',
            this.props.handleScrollTable,
          );
        }
      },
      componentWillUnmount() {
        document.removeEventListener('click', this.props.handleClickOutside);
        if (this.props.column.colId === this.props.openFilterField) {
          this.props.setOpenFilterField('');
        }
        const scrollableNode = document.getElementsByClassName(
          'ag-center-cols-viewport',
        )[0];
        if (scrollableNode) {
          scrollableNode.removeEventListener(
            'scroll',
            this.props.handleScrollTable,
          );
        }
      },
    }),
  )(View);
