/**
 * @flow
 */

import React from 'react';

import {
  Container,
  Left,
  Right,
  UserAvatar,
  UserName,
  FirstName,
  LastName,
  UserPosition,
} from './UserCardStyled';

type UserCardPropsType = {
  avatar?: string,
  firstName: string,
  lastName: string,
  position: string,
  clickItem: (any) => void,
};

export default function UserCardView(props: UserCardPropsType) {
  return (
    <Container onClick={props.clickItem}>
      <Left>
        <UserAvatar avatar={props.avatar} />
      </Left>
      <Right>
        <UserName>
          <FirstName>{props.firstName}</FirstName>
          &nbsp;
          <LastName>{props.lastName}</LastName>
        </UserName>
        <UserPosition>{props.position}</UserPosition>
      </Right>
    </Container>
  );
}

UserCardView.defaultProps = {
  avatar: require('../../assets/images/default_avatar.png'),
};
