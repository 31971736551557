import React from 'react';

import { Container, ContainerSvg } from './StatusRequestStyled';

type StatusRequestProps = {
  request: object,
  access: object,
  payers: array,
};

export const StatusRequestView = (props: StatusRequestProps) => {
  let requestStatusColor = '';
  let loadingStatusColor = '';
  let trackingStatusColor = '';

  const payer = props.payers.find(
    (item) => item.id === props.request.payer._id,
  );

  switch (props.request.state) {
    case 'Предварительное':
      requestStatusColor = '#A9D08E';
      break;
    case 'В пути':
      requestStatusColor = '#F3E03B';
      break;
    case 'Прибыл':
      requestStatusColor = '#da9d0a';
      break;
    case 'Выдан клиенту':
      requestStatusColor = '#008F39';
      break;
    case 'Прогон':
      requestStatusColor = '#F4B084';
      break;
    case 'Закрыта':
      requestStatusColor = '#808080';
      break;
    case 'Вывоз':
      requestStatusColor = '#F58223';
      break;
    default:
      requestStatusColor = '#A9D08E';
      break;
  }

  switch (props.request.sendLoadingDataStatus) {
    case 'pending':
      loadingStatusColor = '#F3E03B';
      break;
    case 'success':
      loadingStatusColor = '#008F39';
      break;
    case 'error':
      loadingStatusColor = '#C1121C';
      break;
  }

  switch (props.request.sendTrackingDataStatus) {
    case 'pending':
      trackingStatusColor = '#F3E03B';
      break;
    case 'success':
      trackingStatusColor = '#008F39';
      break;
    case 'error':
      trackingStatusColor = '#C1121C';
      break;
  }

  if (!trackingStatusColor && payer && payer.sendTrackingData) {
    trackingStatusColor = '#F3E03B';
  }

  return (
    <Container>
      <ContainerSvg className="status-state">
        <svg viewBox="0 0 32 32" fill="none">
          <path
            d="M12 9V23M12 9L25.1414 10.8773C25.6341 10.9477 26 11.3696 26 11.8673V20.1327C26 20.6304 25.6341 21.0523 25.1414 21.1227L12 23M12 9L6.8356 9.86073C6.35341 9.9411 6 10.3583 6 10.8471V21.1529C6 21.6417 6.35341 22.0589 6.8356 22.1393L12 23"
            stroke={requestStatusColor}
            strokeWidth="1.5"
          />
          <path d="M9 9.5V22.5" stroke={requestStatusColor} strokeWidth="1.5" />
          <path
            d="M15 13V19"
            stroke={requestStatusColor}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M19 14V18"
            stroke={requestStatusColor}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M23 15V17"
            stroke={requestStatusColor}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </ContainerSvg>
      {props.access &&
        props.access.directory &&
        props.access.directory.requests &&
        props.access.directory.requests.sendLoadingData && (
          <ContainerSvg className="status-loading-data">
            <svg viewBox="0 0 32 32" fill="none">
              <path
                d="M21.625 23.5H10.3744C10.2086 23.5 10.0497 23.4342 9.93245 23.3169C9.81524 23.1997 9.74939 23.0408 9.74939 22.875V9.125C9.74939 8.95924 9.81524 8.80027 9.93245 8.68306C10.0497 8.56585 10.2086 8.5 10.3744 8.5H17.875L22.25 12.875V22.875C22.25 22.9571 22.2338 23.0383 22.2024 23.1142C22.171 23.19 22.125 23.2589 22.0669 23.3169C22.0089 23.375 21.94 23.421 21.8642 23.4524C21.7883 23.4838 21.7071 23.5 21.625 23.5Z"
                stroke={loadingStatusColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.875 8.5V12.875H22.2506"
                stroke={loadingStatusColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.5 16.625H18.5"
                stroke={loadingStatusColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.5 19.125H18.5"
                stroke={loadingStatusColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ContainerSvg>
        )}
      {props.access &&
        props.access.directory &&
        props.access.directory.requests &&
        props.access.directory.requests.sendTrackingData && (
          <ContainerSvg className="status-tracking-data">
            <svg viewBox="0 0 32 32" fill="none">
              <path
                d="M13.6625 10.2969C13.6625 8.4412 12.1525 6.93125 10.2969 6.93125C8.4412 6.93125 6.93125 8.4412 6.93125 10.2969C6.93125 10.9653 7.1267 11.6108 7.4964 12.1641C7.50552 12.1786 8.16634 13.2627 8.85474 14.3921L8.96349 14.5705L9.96832 16.219L10.2969 16.7581L13.085 12.1835C13.085 12.1834 13.0851 12.1834 13.0851 12.1833L13.0852 12.1831L13.2133 12.2612C13.2161 12.2565 13.2191 12.252 13.2221 12.2475L13.6625 10.2969ZM13.6625 10.2969C13.6625 10.9653 13.4671 11.6108 13.0973 12.1641L13.6625 10.2969ZM6.59087 12.7551L9.49838 17.5256C9.18095 17.7669 8.975 18.1486 8.975 18.5781C8.975 19.3071 9.56786 19.9 10.2969 19.9C10.8337 19.9 11.296 19.5785 11.5028 19.1187H14.4375C15.0008 19.1187 15.4594 19.5773 15.4594 20.1406C15.4594 20.704 15.0008 21.1625 14.4375 21.1625H9.75C8.5902 21.1625 7.64688 22.1058 7.64688 23.2656C7.64688 24.4254 8.5902 25.3687 9.75 25.3687H13.6562C13.9548 25.3687 14.1969 25.1267 14.1969 24.8281C14.1969 24.5295 13.9548 24.2875 13.6562 24.2875H9.75C9.18667 24.2875 8.72813 23.829 8.72813 23.2656C8.72813 22.7023 9.18667 22.2437 9.75 22.2437H14.4375C15.5973 22.2437 16.5406 21.3004 16.5406 20.1406C16.5406 18.9808 15.5973 18.0375 14.4375 18.0375H11.5028C11.4115 17.8344 11.2705 17.6587 11.0954 17.5256L14.0029 12.7551C14.4876 12.0255 14.7437 11.1755 14.7437 10.2969C14.7437 7.84474 12.749 5.85 10.2969 5.85C7.84474 5.85 5.85 7.84474 5.85 10.2969C5.85 11.1755 6.10618 12.0255 6.59087 12.7551ZM10.2969 18.8188C10.1643 18.8188 10.0563 18.7107 10.0563 18.5781C10.0563 18.4456 10.1643 18.3375 10.2969 18.3375C10.4294 18.3375 10.5375 18.4456 10.5375 18.5781C10.5375 18.7107 10.4294 18.8188 10.2969 18.8188Z"
                fill={trackingStatusColor}
                stroke={trackingStatusColor}
                strokeWidth="0.3"
              />
              <path
                d="M10.2969 12.4C11.4567 12.4 12.4 11.4567 12.4 10.2969C12.4 9.13708 11.4567 8.19375 10.2969 8.19375C9.13708 8.19375 8.19375 9.13708 8.19375 10.2969C8.19375 11.4567 9.13708 12.4 10.2969 12.4ZM10.2969 9.275C10.8602 9.275 11.3187 9.73355 11.3187 10.2969C11.3187 10.8602 10.8602 11.3187 10.2969 11.3187C9.73355 11.3187 9.275 10.8602 9.275 10.2969C9.275 9.73355 9.73355 9.275 10.2969 9.275Z"
                fill={trackingStatusColor}
                stroke={trackingStatusColor}
                strokeWidth="0.3"
              />
              <path
                d="M24.5036 18.6485L24.5036 18.6485L24.5026 18.6501L23.0387 20.9463L22.0335 22.523L21.7031 23.0412L21.3728 22.523L20.3676 20.9462L18.9031 18.6491L18.9027 18.6485C18.5329 18.0951 18.3375 17.4497 18.3375 16.7812C18.3375 14.9256 19.8475 13.4156 21.7031 13.4156C23.5588 13.4156 25.0688 14.9256 25.0688 16.7812C25.0688 17.4497 24.8733 18.0951 24.5036 18.6485ZM21.7031 12.3344C19.251 12.3344 17.2562 14.3291 17.2562 16.7812C17.2562 17.6618 17.5135 18.5135 18.0003 19.2442L20.8941 23.7834C20.7238 23.9156 20.5867 24.0885 20.4972 24.2875H17.1719C16.8733 24.2875 16.6312 24.5295 16.6312 24.8281C16.6312 25.1267 16.8733 25.3687 17.1719 25.3687H20.4972C20.704 25.8285 21.1662 26.15 21.7031 26.15C22.4321 26.15 23.025 25.5571 23.025 24.8281C23.025 24.4034 22.8237 24.0252 22.5122 23.7834L25.406 19.2442C25.8927 18.5135 26.15 17.6618 26.15 16.7812C26.15 14.3291 24.1553 12.3344 21.7031 12.3344ZM21.7031 25.0688C21.5706 25.0688 21.4625 24.9607 21.4625 24.8281C21.4625 24.6956 21.5706 24.5875 21.7031 24.5875C21.8357 24.5875 21.9437 24.6956 21.9437 24.8281C21.9437 24.9607 21.8357 25.0688 21.7031 25.0688Z"
                fill={trackingStatusColor}
                stroke={trackingStatusColor}
                strokeWidth="0.3"
              />
              <path
                d="M21.7031 14.6781C20.5433 14.6781 19.6 15.6215 19.6 16.7812C19.6 17.941 20.5433 18.8844 21.7031 18.8844C22.8629 18.8844 23.8063 17.941 23.8063 16.7812C23.8063 15.6215 22.8629 14.6781 21.7031 14.6781ZM21.7031 17.8031C21.1398 17.8031 20.6812 17.3446 20.6812 16.7812C20.6812 16.2179 21.1398 15.7594 21.7031 15.7594C22.2665 15.7594 22.725 16.2179 22.725 16.7812C22.725 17.3446 22.2665 17.8031 21.7031 17.8031Z"
                fill={trackingStatusColor}
                stroke={trackingStatusColor}
                strokeWidth="0.3"
              />
            </svg>
          </ContainerSvg>
        )}
    </Container>
  );
};
