import React from 'react';

import {
  FilterValue,
  FilterValueContainer,
  ButtonsContainer,
  FilterValueDelete,
  OpenInputContainer,
  OpenInput,
  OpenFilterButton,
  Button,
  SearchIcon,
} from './DateStyled';

import { CalendarContainer } from '../../../pages/Content/RequestsTable/RequestTableStyles';

import { Input, Calendar } from '../..';

export const DateView = (props) => {
  const index = 0;
  const previewValue = () => {
    let val1 = '';
    let val2 = '';
    val1 =
      props.filterModel[props.column.colId] &&
      props.filterModel[props.column.colId].values &&
      props.filterModel[props.column.colId].values[index] &&
      props.filterModel[props.column.colId].values[index].dateFrom;
    val2 =
      props.filterModel[props.column.colId] &&
      props.filterModel[props.column.colId].values &&
      props.filterModel[props.column.colId].values[index] &&
      props.filterModel[props.column.colId].values[index].dateTo;
    if (val1 === undefined && val2 === undefined) {
      return '';
    }
    if (val1 === '' && val2 === '') {
      return 'Не заполнено';
    }
    return `с ${props.formatDate(val1)} по ${props.formatDate(val2)}`;
  };

  return (
    <OpenInputContainer>
      <div id={`custom-ag-filter-${props.column.colId}`}>
        <OpenInputContainer>
          <OpenInput
            className="custom-ag-filter-input"
            placeholder=""
            value={`  ${previewValue()}`}
            readOnly
            onClick={props.toggleOpened}
          />
          <OpenFilterButton
            className="ag-icon ag-icon-filter"
            unselectable="on"
            onClick={props.toggleOpened}
          />
        </OpenInputContainer>
        {props.column.colId === props.openFilterField ? (
          <FilterValueContainer>
            <>
              {props.activeFilter === props.column.colId &&
              props.activeIndex === index ? (
                <CalendarContainer type="filter">
                  <FilterValueDelete
                    type="calendar"
                    onClick={() => {
                      props.setActiveFilter(null);
                      props.setActiveIndex(null);
                    }}
                    src={require('../../../assets/icons/delete-black.svg')}
                    alt="delete"
                  />
                  <Calendar
                    eventOnOk={(dates) =>
                      props.changeDateRange(dates, props.column.colId, index)
                    }
                    showToday={false}
                    showClear={false}
                    selectedValue={props.date[index] || []}
                    onSelect={(dates) =>
                      props.changeDateRange(dates, props.column.colId, index)
                    }
                    onChange={(dates) => {
                      const data = [...props.date];
                      data[index] = dates;
                      props.setDate(data);
                    }}
                  />
                </CalendarContainer>
              ) : null}
              <FilterValue>
                <SearchIcon />
                <Input
                  type="text"
                  value={
                    props.localItems[props.column.colId] &&
                    props.localItems[props.column.colId].values &&
                    props.localItems[props.column.colId].values[index]
                      ? `с ${props.formatDate(
                          props.localItems[props.column.colId].values[index]
                            .dateFrom,
                        )} по ${props.formatDate(
                          props.localItems[props.column.colId].values[index]
                            .dateTo,
                        )}`
                      : ''
                  }
                  placeholder="Выберите период..."
                  changed={() => {
                    props.setActiveFilter(props.column.colId);
                    props.setActiveIndex(index);
                  }}
                  active={() => {
                    props.setActiveFilter(props.column.colId);
                    props.setActiveIndex(index);
                  }}
                />
                {props.localItems[props.column.colId] &&
                  props.localItems[props.column.colId].values &&
                  props.localItems[props.column.colId].values[index] && (
                    <FilterValueDelete
                      onClick={() => {
                        const values = [];
                        props.changeFilter(props.column.colId, { values });
                      }}
                      src={require('../../../assets/icons/delete-black.svg')}
                      alt="delete"
                    />
                  )}
              </FilterValue>
            </>
            <ButtonsContainer>
              <Button
                onClick={() => props.clearFilter(props.column.colId, index)}>
                Не заполнено
              </Button>
              <Button
                onClick={() => props.resetFilter(props.column.colId, index)}>
                Сбросить
              </Button>
              <Button onClick={props.applyFilter}>Применить</Button>
            </ButtonsContainer>
          </FilterValueContainer>
        ) : null}
      </div>
    </OpenInputContainer>
  );
};
