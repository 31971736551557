/**
 * @flow
 */

import React from 'react';

import {
  ContainerRadioButton,
  RadioButton,
  LabelRadioButton,
  Circle,
} from './RadioButtonStyled';

type RadioButtonViewPropsType = {
  message: any,
  status?: boolean,
  setRadio: (any) => void,
};

export default function RadioButtonView(props: RadioButtonViewPropsType) {
  return (
    <ContainerRadioButton onClick={() => props.setRadio(!props.status)}>
      <RadioButton status={props.status}>
        <Circle status={props.status} />
      </RadioButton>
      <LabelRadioButton>{props.message}</LabelRadioButton>
    </ContainerRadioButton>
  );
}

RadioButtonView.defaultProps = {
  status: false,
};
