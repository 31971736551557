/**
 * @flow
 */
import React from 'react';

import { StyledTabPane } from './TabPaneStyled';

type TabPanePropsType = {
  children: any,
  activeTabId: number,
  tabId: number,
};

const TabPaneView = (props: TabPanePropsType) => (
  <StyledTabPane active={props.activeTabId === props.tabId}>
    {props.children}
  </StyledTabPane>
);

export default TabPaneView;
