import { replaceSpecialCharacters } from '../FunctionsForStrings';
import {
  numbersList,
  textsList,
  datesList,
  checkboxesList,
  selectionsList,
} from '../../constants';
import { directoriesList } from '../../services';

export const getQueryFilters = (filters) => {
  const query = {};
  if (filters && Object.keys(filters).length) {
    const buh_amountMore = {};
    const buh_amountMoreAccountNumber = {};
    const buh_amountMoreAmount = {};
    const buh_amountMoreDate = {};
    const buh_amountMoreComment = {};
    const buh_carrierMore = {};
    const buh_carrierMoreAccountNumber = {};
    const buh_carrierMoreAmount = {};
    const buh_exportAgentMore = {};
    const buh_exportAgentMoreAccountNumber = {};
    const buh_exportAgentMoreAmount = {};
    Object.keys(filters).forEach((key) => {
      const { values } = filters[key];
      switch (key) {
        case 'typeSend':
        case 'typeContainer':
        case 'typeElivery':
        case 'containerAccessory':
        case 'returnOfDocumentsFromUnloading':
        case 'documentStatus':
        case 'payer':
        case 'shipper':
        case 'consignee':
        case 'addressLoading':
        case 'addressUnloading':
        case 'destinationCity':
        case 'loadActual':
        case 'manager':
        case 'railCarrier':
        case 'ownerContainer':
        case 'terminalStaging':
        case 'stationDeparture':
        case 'stationDestination':
        case 'loadDocumented':
        case 'warmingCompany':
        case 'companyNameForwarder':
        case 'companyMoversLoading':
        case 'agentAutoLoad':
        case 'driver':
        case 'car':
        case 'trailer':
        case 'agentAutoCall':
        case 'exportAgentEurosib':
        case 'companyMoversUnloading':
        case 'accountSimpleToLoad':
        case 'accountSimpleToUnload':
        case 'accountAboveTheNorm':
        case 'accountStorageAtDestinationStation':
        case 'accountOverload':
        case 'payers':
        case 'managerByRegion':
        case 'additionalServicesCounterparty': {
          if (values.length) {
            const data = values.map((item) => {
              if (!item || item === 'Не заполнено') {
                return null;
              }
              if (key === 'trailer') {
                return item.replace(/Номер:/gi, '').trim();
              }
              if (key === 'car') {
                const value = item.split(',');
                const value1 = value[0]
                  ? value[0].replace(/Марка:|Гос. номер:|,|Номер:/gi, '').trim()
                  : '';
                const value2 = value[1]
                  ? value[1].replace(/Марка:|Гос. номер:|,|Номер:/gi, '').trim()
                  : '';
                return `${value1} ${value2}`;
              }
              if (key === 'driver') {
                if (item.includes('Ф.И.О')) {
                  const drivers = item.split(',');
                  if (drivers[0]) {
                    return drivers[0].slice(6).trim();
                  }
                }
              }
              return item;
            });
            const parameter = data.filter((item) => item !== null);
            if (data.includes(null)) {
              parameter.push(null);
            }
            query[key] = JSON.stringify(parameter);
          }
          break;
        }
        case 'dateArrival':
        case 'plannedIssueDate':
        case 'plannedDispatchDate':
        case 'actualDateDispatch':
        case 'dateIssue':
        case 'customerAccountDate':
        case 'departureVesselDate':
        case 'arrivalVesselDate':
        case 'notificationDate': {
          if (values.length) {
            const data = values.map((item) => {
              if (!item) {
                return [null];
              }
              return [item.dateFrom, item.dateTo];
            });
            const parameter = data.filter((item) => item[0] !== null);
            if (data.find((item) => item[0] === null)) {
              parameter.push([null]);
            }
            query[key] = JSON.stringify(parameter);
          }
          break;
        }
        case 'timeLoad':
        case 'scheduledIssueTime':
        case 'timeIssue':
        case 'customerApplicationNumber':
        case 'od':
        case 'shipment':
        case 'warming':
        case 'tracking':
        case 'additionalServices':
        case 'cargoCode':
        case 'instructionsDelivery':
        case 'releases':
        case 'numberContainer':
        case 'numberSeal':
        case 'timofeevo':
        case 'timurovo':
        case 'customerAccountNumber':
        case 'carrierAccount':
        case 'accountsAutoServices':
        case 'containerAgentAccount':
        case 'exportAgentAccount':
        case 'moversAccountOnLoading':
        case 'moversAccountOnUnloading':
        case 'weight':
        case 'numberSeats':
        case 'accountSecuringCargo':
        case 'accountAdditionalServices':
        case 'billCargoWeatherization':
        case 'note':
        case 'attorneyPowerNumber':
        case 'exhibitedSimpleToUnload':
        case 'exhibitedStorage':
        case 'exhibitedOverflow':
        case 'exhibitedAdditionalServices': {
          if (values.length) {
            const data = values.map((item) => {
              if (!item) {
                return null;
              }
              return replaceSpecialCharacters(item);
            });
            const parameter = data.filter((item) => item !== null);
            if (data.includes(null)) {
              parameter.push(null);
            }
            query[key] = JSON.stringify(parameter);
          }
          break;
        }
        case 'buh_amount':
        case 'buh_carrier':
        case 'buh_autoBeforeLoading':
        case 'buh_forMKAD_rate':
        case 'buh_forMKAD_km':
        case 'buh_forMKAD':
        case 'buh_containerAgent':
        case 'buh_aboveTheNorm_rate':
        case 'buh_aboveTheNorm_day':
        case 'buh_aboveTheNorm':
        case 'buh_exportAgent':
        case 'buh_overload_rate':
        case 'buh_overload_ton':
        case 'buh_overload':
        case 'buh_simpleToUnload':
        case 'buh_simpleToUnload_rate':
        case 'buh_simpleToUnload_hour':
        case 'buh_simpleToLoad':
        case 'buh_simpleToLoad_rate':
        case 'buh_simpleToLoad_hour':
        case 'buh_plain':
        case 'buh_plain_rate':
        case 'buh_plain_hour':
        case 'buh_storageAtTheDestinationStation_rate':
        case 'buh_storageAtTheDestinationStation_day':
        case 'buh_storageAtTheDestinationStation':
        case 'buh_moversAtUnloading':
        case 'buh_forwarderOnLoading':
        case 'buh_moversOnLoading':
        case 'buh_veterinaryCertificate':
        case 'buh_loadFastening':
        case 'buh_warming':
        case 'buh_gasketMaterial':
        case 'buh_additionalServices':
        case 'buh_seal':
        case 'buh_bills':
        case 'buh_agentToClient':
        case 'buh_managerial':
        case 'buh_agentSuppliers':
        case 'buh_OBN':
        case 'buh_total':
        case 'balance':
        case 'buh_pay':
        case 'additionalLoadingAddress':
        case 'buh_containerAgentPayment':
        case 'distance': {
          if (values.length) {
            const parameter = values.map((item) => {
              let val1 = +item[0];
              let val2 = +item[1];
              if (!val1) {
                val1 = 0;
              }
              if (!val2) {
                val2 = 0;
              }
              return [val1, val2];
            });
            query[key] = JSON.stringify(parameter);
          }
          break;
        }
        case 'act':
        case 'security':
        case 'forwarderLoading':
        case 'forwarderUnloading':
        case 'veterinaryCertificate':
        case 'gdnUnloading':
        case 'loadFastening':
        case 'agentDocuments':
        case 'railwayInvoices':
        case 'loading':
        case 'loadingTN':
        case 'unloadingTN':
        case 'stopSheet':
        case 'companyAmixgroup':
        case 'moversAtUnloading':
        case 'railCarrierStatus':
        case 'prrOnArrived':
        case 'agentAutoCallStatus':
        case 'unloadingDriverTaskStatus':
        case 'moversOnLoading': {
          if (values.length) {
            const parameter = values.map((item) => {
              if (item === 'Да') {
                return true;
              }
              return false;
            });
            query[key] = JSON.stringify(parameter);
          }
          break;
        }
      }
      if (key.replace(/[0-9]/g, '') === 'buh_amountMoreAmount') {
        if (values.length) {
          const parameter = values.map((item) => {
            let val1 = +item[0];
            let val2 = +item[1];
            if (!val1) {
              val1 = 0;
            }
            if (!val2) {
              val2 = 0;
            }
            return [val1, val2];
          });
          buh_amountMoreAmount[key.slice(20) - 1] = parameter;
        }
      }
      if (key.replace(/[0-9]/g, '') === 'buh_amountMoreAccountNumber') {
        if (values.length) {
          const data = values.map((item) => {
            if (!item) {
              return null;
            }
            return replaceSpecialCharacters(item);
          });
          const parameter = data.filter((item) => item !== null);
          if (data.includes(null)) {
            parameter.push(null);
          }
          buh_amountMoreAccountNumber[key.slice(27) - 1] = parameter;
        }
      }
      if (key.replace(/[0-9]/g, '') === 'buh_amountMoreComment') {
        if (values.length) {
          const data = values.map((item) => {
            if (!item) {
              return null;
            }
            return replaceSpecialCharacters(item);
          });
          const parameter = data.filter((item) => item !== null);
          if (data.includes(null)) {
            parameter.push(null);
          }
          buh_amountMoreComment[key.slice(21) - 1] = parameter;
        }
      }
      if (key.replace(/[0-9]/g, '') === 'buh_amountMoreDate') {
        if (values.length) {
          const data = values.map((item) => {
            if (!item) {
              return [null];
            }
            return [item.dateFrom, item.dateTo];
          });
          const parameter = data.filter((item) => item[0] !== null);
          if (data.find((item) => item[0] === null)) {
            parameter.push([null]);
          }
          buh_amountMoreDate[key.slice(18) - 1] = parameter;
        }
      }
      if (key.replace(/[0-9]/g, '') === 'buh_carrierMoreAccountNumber') {
        if (values.length) {
          const data = values.map((item) => {
            if (!item) {
              return null;
            }
            return replaceSpecialCharacters(item);
          });
          const parameter = data.filter((item) => item !== null);
          if (data.includes(null)) {
            parameter.push(null);
          }
          buh_carrierMoreAccountNumber[key.slice(28) - 1] = parameter;
        }
      }
      if (key.replace(/[0-9]/g, '') === 'buh_carrierMoreAmount') {
        if (values.length) {
          const parameter = values.map((item) => {
            let val1 = +item[0];
            let val2 = +item[1];
            if (!val1) {
              val1 = 0;
            }
            if (!val2) {
              val2 = 0;
            }
            return [val1, val2];
          });
          buh_carrierMoreAmount[key.slice(21) - 1] = parameter;
        }
      }
      if (key.replace(/[0-9]/g, '') === 'buh_exportAgentMoreAccountNumber') {
        if (values.length) {
          const data = values.map((item) => {
            if (!item) {
              return null;
            }
            return replaceSpecialCharacters(item);
          });
          const parameter = data.filter((item) => item !== null);
          if (data.includes(null)) {
            parameter.push(null);
          }
          buh_exportAgentMoreAccountNumber[key.slice(32) - 1] = parameter;
        }
      }
      if (key.replace(/[0-9]/g, '') === 'buh_exportAgentMoreAmount') {
        if (values.length) {
          const parameter = values.map((item) => {
            let val1 = +item[0];
            let val2 = +item[1];
            if (!val1) {
              val1 = 0;
            }
            if (!val2) {
              val2 = 0;
            }
            return [val1, val2];
          });
          buh_exportAgentMoreAmount[key.slice(25) - 1] = parameter;
        }
      }
    });
    if (Object.keys(buh_amountMoreAmount).length) {
      buh_amountMore.amount = buh_amountMoreAmount;
    }
    if (Object.keys(buh_amountMoreAccountNumber).length) {
      buh_amountMore.accountNumber = buh_amountMoreAccountNumber;
    }
    if (Object.keys(buh_amountMoreComment).length) {
      buh_amountMore.comment = buh_amountMoreComment;
    }
    if (Object.keys(buh_amountMoreDate).length) {
      buh_amountMore.date = buh_amountMoreDate;
    }
    if (Object.keys(buh_amountMore).length) {
      query.buh_amountMore = JSON.stringify(buh_amountMore);
    }
    if (Object.keys(buh_carrierMoreAccountNumber).length) {
      buh_carrierMore.accountNumber = buh_carrierMoreAccountNumber;
    }
    if (Object.keys(buh_carrierMoreAmount).length) {
      buh_carrierMore.amount = buh_carrierMoreAmount;
    }
    if (Object.keys(buh_carrierMore).length) {
      query.buh_carrierMore = JSON.stringify(buh_carrierMore);
    }
    if (Object.keys(buh_exportAgentMoreAccountNumber).length) {
      buh_exportAgentMore.accountNumber = buh_exportAgentMoreAccountNumber;
    }
    if (Object.keys(buh_exportAgentMoreAmount).length) {
      buh_exportAgentMore.amount = buh_exportAgentMoreAmount;
    }
    if (Object.keys(buh_exportAgentMore).length) {
      query.buh_exportAgentMore = JSON.stringify(buh_exportAgentMore);
    }
  }
  return query;
};

export const getQuerySort = (sorting) => {
  const query = {};
  if (sorting.length) {
    query.sort = '';
    sorting.forEach((item) => {
      if (item.colId.includes('buh_amountMore')) {
        const type = item.colId.replace(/[0-9]/g, '').slice(14);
        const typeField = type[0].toLowerCase() + type.slice(1);
        const indexField = +item.colId.replace(/\D/g, '') - 1;
        query.sort += `buh_amountMore ${typeField} ${indexField}:${
          item.sort === 'asc' ? 'up' : 'dn'
        };`;
      } else if (item.colId.includes('buh_carrierMore')) {
        const type = item.colId.replace(/[0-9]/g, '').slice(15);
        const typeField = type[0].toLowerCase() + type.slice(1);
        const indexField = +item.colId.replace(/\D/g, '') - 1;
        query.sort += `buh_carrierMore ${typeField} ${indexField}:${
          item.sort === 'asc' ? 'up' : 'dn'
        };`;
      } else if (item.colId.includes('buh_exportAgentMore')) {
        const type = item.colId.replace(/[0-9]/g, '').slice(19);
        const typeField = type[0].toLowerCase() + type.slice(1);
        const indexField = +item.colId.replace(/\D/g, '') - 1;
        query.sort += `buh_exportAgentMore ${typeField} ${indexField}:${
          item.sort === 'asc' ? 'up' : 'dn'
        };`;
      } else {
        query.sort += `${item.colId}:${item.sort === 'asc' ? 'up' : 'dn'};`;
      }
    });
  }
  return query;
};

export const formatDate = (dateString) =>
  `${
    new Date(dateString).getDate() < 10
      ? `0${new Date(dateString).getDate()}`
      : new Date(dateString).getDate()
  }-${
    new Date(dateString).getMonth() + 1 < 10
      ? `0${new Date(dateString).getMonth() + 1}`
      : new Date(dateString).getMonth() + 1
  }-${new Date(dateString).getFullYear()}`;

export const checkRequestInFilters = (
  filters,
  searchModel,
  selectedStatus,
  dateFiltersActive,
  dateFilters,
  request,
) => {
  const errorsList = [];

  if (searchModel) {
    errorsList.push(1);
  }
  if (selectedStatus) {
    if (request.state !== selectedStatus) {
      errorsList.push(1);
    }
  }
  if (dateFiltersActive) {
    const date1 = new Date(JSON.parse(dateFilters)[0]);
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setMilliseconds(0);
    const date2 = new Date(JSON.parse(dateFilters)[1]);
    date2.setHours(0);
    date2.setMinutes(0);
    date2.setSeconds(0);
    date2.setMilliseconds(0);
    const date = request.dateLoad
      ? `${request.dateLoad.split('-').reverse().join('-')}T00:00:00`
      : '1970-01-01';

    if (!(new Date(date) >= date1 && new Date(date) <= date2)) {
      errorsList.push(1);
    }
  }

  Object.keys(request).forEach((key) => {
    if (filters && filters[key] && filters[key].values.length) {
      if (
        directoriesList.map((elem) => elem.field).includes(key) ||
        selectionsList.includes(key)
      ) {
        if (request[key]) {
          if (!filters[key].values.includes(request[key].name)) {
            errorsList.push(1);
          }
        } else {
          errorsList.push(1);
        }
      }
      if (datesList.includes(key)) {
        if (request[key]) {
          const date = `${request[key]
            .split('-')
            .reverse()
            .join('-')}T00:00:00`;
          if (
            !(
              new Date(date) >= new Date(filters[key].values[0].dateFrom) &&
              new Date(date) <= new Date(filters[key].values[0].dateTo)
            )
          ) {
            errorsList.push(1);
          }
        } else {
          errorsList.push(1);
        }
      }
      if (textsList.includes(key)) {
        if (request[key]) {
          if (!filters[key].values[0].includes(request[key])) {
            errorsList.push(1);
          }
        } else {
          errorsList.push(1);
        }
      }
      if (numbersList.includes(key)) {
        if (
          !(
            +request[key] >= +filters[key].values[0][0] &&
            +request[key] <= +filters[key].values[0][1]
          )
        ) {
          errorsList.push(1);
        }
      }
      if (checkboxesList.includes(key)) {
        const val = filters[key].values[0] !== 'Нет';
        if (val !== request[key]) {
          errorsList.push(1);
        }
      }
    }
  });

  const buh_amountMoreAmount = Object.keys(filters).filter((item) =>
    new RegExp('buh_amountMoreAmount', 'gi').test(item),
  );
  buh_amountMoreAmount.forEach((item) => {
    if (request[item]) {
      if (
        !(
          +request[item] >= +filters[item].values[0][0] &&
          +request[item] <= +filters[item].values[0][1]
        )
      ) {
        errorsList.push(1);
      }
    } else {
      errorsList.push(1);
    }
  });
  const buh_amountMoreAccountNumber = Object.keys(filters).filter((item) =>
    new RegExp('buh_amountMoreAccountNumber', 'gi').test(item),
  );
  buh_amountMoreAccountNumber.forEach((item) => {
    if (request[item]) {
      if (!filters[item].values[0].includes(request[item])) {
        errorsList.push(1);
      }
    } else {
      errorsList.push(1);
    }
  });
  const buh_amountMoreComment = Object.keys(filters).filter((item) =>
    new RegExp('buh_amountMoreComment', 'gi').test(item),
  );
  buh_amountMoreComment.forEach((item) => {
    if (request[item]) {
      if (!filters[item].values[0].includes(request[item])) {
        errorsList.push(1);
      }
    } else {
      errorsList.push(1);
    }
  });
  const buh_amountMoreDate = Object.keys(filters).filter((item) =>
    new RegExp('buh_amountMoreDate', 'gi').test(item),
  );
  buh_amountMoreDate.forEach((item) => {
    if (request[item]) {
      const date = `${request[item].split('-').reverse().join('-')}T00:00:00`;
      if (
        !(
          new Date(date) >= new Date(filters[item].values[0].dateFrom) &&
          new Date(date) <= new Date(filters[item].values[0].dateTo)
        )
      ) {
        errorsList.push(1);
      }
    } else {
      errorsList.push(1);
    }
  });
  const buh_carrierMoreAccountNumber = Object.keys(filters).filter((item) =>
    new RegExp('buh_carrierMoreAccountNumber', 'gi').test(item),
  );
  buh_carrierMoreAccountNumber.forEach((item) => {
    if (request[item]) {
      if (!filters[item].values[0].includes(request[item])) {
        errorsList.push(1);
      }
    } else {
      errorsList.push(1);
    }
  });
  const buh_carrierMoreAmount = Object.keys(filters).filter((item) =>
    new RegExp('buh_carrierMoreAmount', 'gi').test(item),
  );
  buh_carrierMoreAmount.forEach((item) => {
    if (request[item]) {
      if (
        !(
          +request[item] >= +filters[item].values[0][0] &&
          +request[item] <= +filters[item].values[0][1]
        )
      ) {
        errorsList.push(1);
      }
    } else {
      errorsList.push(1);
    }
  });
  const buh_exportAgentMoreAccountNumber = Object.keys(filters).filter((item) =>
    new RegExp('buh_exportAgentMoreAccountNumber', 'gi').test(item),
  );
  buh_exportAgentMoreAccountNumber.forEach((item) => {
    if (request[item]) {
      if (!filters[item].values[0].includes(request[item])) {
        errorsList.push(1);
      }
    } else {
      errorsList.push(1);
    }
  });
  const buh_exportAgentMoreAmount = Object.keys(filters).filter((item) =>
    new RegExp('buh_exportAgentMoreAmount', 'gi').test(item),
  );
  buh_exportAgentMoreAmount.forEach((item) => {
    if (request[item]) {
      if (
        !(
          +request[item] >= +filters[item].values[0][0] &&
          +request[item] <= +filters[item].values[0][1]
        )
      ) {
        errorsList.push(1);
      }
    } else {
      errorsList.push(1);
    }
  });

  if (errorsList.length) return false;
  return true;
};

export const getPositionRequest = (api, request, sorting) => {
  const table = [
    request,
    ...api.getModel().rowsToDisplay.map((item) => item.data),
  ];

  table.sort((a, b) => {
    let val = 0;

    for (let i = 0; i < sorting.length; i++) {
      const item = sorting[i];
      if (numbersList.includes(item.colId)) {
        if (item.sort === 'asc') {
          val = a[item.colId] - b[item.colId];
        } else {
          val = b[item.colId] - a[item.colId];
        }
      }
      if (textsList.includes(item.colId)) {
        if (
          item.colId === 'scheduledIssueTime' ||
          item.colId === 'timeIssue' ||
          item.colId === 'timeLoad'
        ) {
          const date1 = new Date(1970, 0, 1);
          const date2 = new Date(1970, 0, 1);
          if (a[item.colId]) {
            const dateParts1 = a[item.colId].split(':');
            date1.setHours(+dateParts1[0], +dateParts1[1]);
          }
          if (b[item.colId]) {
            const dateParts2 = b[item.colId].split(':');
            date2.setHours(+dateParts2[0], +dateParts2[1]);
          }
          if (item.sort === 'asc') {
            if (date1 < date2) {
              val = -1;
            } else if (date1 > date2) {
              val = 1;
            } else {
              val = 0;
            }
          } else if (date2 < date1) {
            val = -1;
          } else if (date2 > date1) {
            val = 1;
          } else {
            val = 0;
          }
        } else {
          let string1 = '';
          let string2 = '';
          if (a[item.colId]) {
            string1 = a[item.colId];
          }
          if (b[item.colId]) {
            string2 = b[item.colId];
          }
          if (item.sort === 'asc') {
            val = string1.localeCompare(string2);
          } else {
            val = string2.localeCompare(string1);
          }
        }
      }
      if (datesList.includes(item.colId)) {
        let date1 = new Date(1970, 0, 1);
        let date2 = new Date(1970, 0, 1);
        if (a[item.colId]) {
          date1 = new Date(a[item.colId].split('-').reverse().join('-'));
        }
        if (b[item.colId]) {
          date2 = new Date(b[item.colId].split('-').reverse().join('-'));
        }
        if (item.sort === 'asc') {
          if (date1 < date2) {
            val = -1;
          } else if (date1 > date2) {
            val = 1;
          } else {
            val = 0;
          }
        } else if (date2 < date1) {
          val = -1;
        } else if (date2 > date1) {
          val = 1;
        } else {
          val = 0;
        }
      }
      if (checkboxesList.includes(item.colId)) {
        if (item.sort === 'asc') {
          val = a[item.colId] - b[item.colId];
        } else {
          val = b[item.colId] - a[item.colId];
        }
      }
      if (selectionsList.includes(item.colId)) {
        let string1 = '';
        let string2 = '';
        if (a[item.colId]) {
          string1 = a[item.colId];
        }
        if (b[item.colId]) {
          string2 = b[item.colId];
        }
        if (item.sort === 'asc') {
          val = string1.localeCompare(string2);
        } else {
          val = string2.localeCompare(string1);
        }
      }
      if (directoriesList.map((elem) => elem.field).includes(item.colId)) {
        if (item.colId === 'payers') {
          const string1 = a[item.colId]
            .map((elem) => elem.name)
            .sort((val1, val2) => val1.localeCompare(val2))
            .join(',');
          const string2 = b[item.colId]
            .map((elem) => elem.name)
            .sort((val1, val2) => val1.localeCompare(val2))
            .join(',');
          if (item.sort === 'asc') {
            val = string1.localeCompare(string2);
          } else {
            val = string2.localeCompare(string1);
          }
        } else if (
          item.colId === 'addressLoading' ||
          item.colId === 'addressUnloading'
        ) {
          let string1 = '';
          let string2 = '';
          if (a[item.colId] && a[item.colId].title) {
            string1 = a[item.colId].title;
          }
          if (b[item.colId] && b[item.colId].title) {
            string2 = b[item.colId].title;
          }
          if (item.sort === 'asc') {
            val = string1.localeCompare(string2);
          } else {
            val = string2.localeCompare(string1);
          }
        } else if (item.colId === 'car') {
          let string1 = '';
          let string2 = '';
          if (a[item.colId] && a[item.colId].number && a[item.colId].mark) {
            string1 = a[item.colId].mark + a[item.colId].number;
          }
          if (b[item.colId] && b[item.colId].number && b[item.colId].mark) {
            string2 = b[item.colId].mark + b[item.colId].number;
          }
          if (item.sort === 'asc') {
            val = string1.localeCompare(string2);
          } else {
            val = string2.localeCompare(string1);
          }
        } else if (item.colId === 'trailer') {
          let string1 = '';
          let string2 = '';
          if (a[item.colId] && a[item.colId].number) {
            string1 = a[item.colId].number;
          }
          if (b[item.colId] && b[item.colId].number) {
            string2 = b[item.colId].number;
          }
          if (item.sort === 'asc') {
            val = string1.localeCompare(string2);
          } else {
            val = string2.localeCompare(string1);
          }
        } else {
          let string1 = '';
          let string2 = '';
          if (a[item.colId] && a[item.colId].name) {
            string1 = a[item.colId].name;
          }
          if (b[item.colId] && b[item.colId].name) {
            string2 = b[item.colId].name;
          }
          if (item.sort === 'asc') {
            val = string1.localeCompare(string2);
          } else {
            val = string2.localeCompare(string1);
          }
        }
      }

      if (
        new RegExp('buh_amountMoreAmount', 'gi').test(item.colId) ||
        new RegExp('buh_carrierMoreAmount', 'gi').test(item.colId) ||
        new RegExp('buh_exportAgentMoreAmount', 'gi').test(item.colId)
      ) {
        let val1 = 0;
        let val2 = 0;
        if (a[item.colId]) {
          val1 = a[item.colId];
        }
        if (b[item.colId]) {
          val2 = b[item.colId];
        }
        if (item.sort === 'asc') {
          val = val1 - val2;
        } else {
          val = val2 - val1;
        }
      }
      if (
        new RegExp('buh_amountMoreAccountNumber', 'gi').test(item.colId) ||
        new RegExp('buh_amountMoreComment', 'gi').test(item.colId) ||
        new RegExp('buh_carrierMoreAccountNumber', 'gi').test(item.colId) ||
        new RegExp('buh_exportAgentMoreAccountNumber', 'gi').test(item.colId)
      ) {
        let string1 = '';
        let string2 = '';
        if (a[item.colId]) {
          string1 = a[item.colId];
        }
        if (b[item.colId]) {
          string2 = b[item.colId];
        }
        if (item.sort === 'asc') {
          val = string1.localeCompare(string2);
        } else {
          val = string2.localeCompare(string1);
        }
      }
      if (new RegExp('buh_amountMoreDate', 'gi').test(item.colId)) {
        let date1 = new Date(1970, 0, 1);
        let date2 = new Date(1970, 0, 1);
        if (a[item.colId]) {
          date1 = new Date(a[item.colId].split('-').reverse().join('-'));
        }
        if (b[item.colId]) {
          date2 = new Date(b[item.colId].split('-').reverse().join('-'));
        }
        if (item.sort === 'asc') {
          if (date1 < date2) {
            val = -1;
          } else if (date1 > date2) {
            val = 1;
          } else {
            val = 0;
          }
        } else if (date2 < date1) {
          val = -1;
        } else if (date2 > date1) {
          val = 1;
        } else {
          val = 0;
        }
      }

      if (val !== 0) break;
    }

    return val;
  });

  const index = table.findIndex((item) => item.id === request.id);
  const lastIndex = table.length - 1;
  return { index, lastIndex };
};
