/**
 * @flow
 */

import React from 'react';

import {
  ModaleWindow,
  ModaleWindowClose,
  ModaleWindowBackDrop,
  ModaleShadow,
  ModaleWindowForm,
} from './ModaleWindowStyled';

type ModaleWindowViewPropsType = {
  width?: any,
  height?: any,
  status?: boolean,
  closeAction: () => void,
  childred: any,
  exact?: boolean,
  top?: number,
};

export default function ModaleWindowView(props: ModaleWindowViewPropsType) {
  return (
    <ModaleShadow>
      <ModaleWindowForm>
        <ModaleWindow
          width={props.width}
          height={props.height}
          status={props.status}
          exact={props.exact}
          top={props.top}>
          <ModaleWindowClose onClick={() => props.closeAction()}>
            <img
              style={{ width: '14px' }}
              src={require('../../assets/icons/close-white.svg')}
              alt="close"
            />
          </ModaleWindowClose>
          {props.children}
        </ModaleWindow>
      </ModaleWindowForm>
      <ModaleWindowBackDrop status={props.status} />
    </ModaleShadow>
  );
}

ModaleWindowView.defaultProps = {
  width: 300,
  height: 300,
  status: false,
  top: 5,
};
