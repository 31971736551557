/**
 * @flow
 */

import React from 'react';

import { Item } from './DropdownItemStyled';

type DropdownItemPropsType = {
  link?: string,
  value?: string,
  title?: string,
  clicked: (any) => void,
};

export default function DropdownItem(props: DropdownItemPropsType) {
  return (
    <Item link={props.link} value={props.value} onClick={() => props.clicked()}>
      {props.title}
    </Item>
  );
}

DropdownItem.defaultProps = {
  link: null,
  value: null,
  title: null,
};
