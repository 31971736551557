import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import validator from 'validator';

// view
import UsersView from './UsersView';

// services
import UserService from '../../../services/UserService';
import RoleService from '../../../services/RoleService';

// actions
import {
  setSecondaryBarItems,
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  checkStatusPopup,
  setToggleLoad,
} from '../../../store/actions/dashboard';

import { saveUserAccesses } from '../../../store/actions/auth';

const USERS_LIST_DEFAULT = [
  {
    _id: '',
    firstName: '',
    lastName: '',
    position: '',
    login: '',
    phoneNumber: '',
    email: '',
    edit: '',
    avatar: '',
  },
];

export default compose(
  connect(
    (state) => ({
      activeSidebarItem: state.dashboard.activeItem,
      checkStatus: state.dashboard.checkStatusPopup,
      access: state.auth.userAccesses,
      toggleLoad: state.dashboard.toggleLoad,
      activeSecondaryItem: state.dashboard.activeSecondaryItem,
      currentUser: state.auth.currentUser,
    }),
    (dispatch) => ({
      saveUserAccesses: (data) => dispatch(saveUserAccesses(data)),
      setSecondaryBarItems: (items) => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: (item) => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: (item) =>
        dispatch(setActiveSecondarySidebarItem(item)),
      checkStatusPopup: (item) => dispatch(checkStatusPopup(item)),
      setToggleLoad: (item) => dispatch(setToggleLoad(item)),
    }),
  ),

  withState('role', 'setRole', null),
  withState('usersList', 'setUsersList', []),
  withState('toggleOn', 'toggle', false),
  withState('usersCardDefault', 'setUsersCardDefault', []),
  withState('roleName', 'setRoleName', ''),
  withState('positions', 'setPositions', {}),

  withState('isLoading', 'setIsLoading', false),

  // user info
  withState('userInfo', 'setUserInfo', {}),
  withState('editMode', 'setEditMode', false),

  // user inputs and info
  withState('userId', 'setUserId', ''),
  withState('position', 'setPosition', ''),
  withState('firstName', 'setFirstName', ''),
  withState('lastName', 'setLastName', ''),
  withState('login', 'setLogin', ''),
  withState('newPassword', 'setNewPassword', ''),
  withState('newPasswordRepeated', 'setNewPasswordRepeated', ''),
  withState('phone', 'setPhone', ''),
  withState('email', 'setEmail', ''),
  withState('avatar', 'setAvatar', ''),
  withState('currentRole', 'setCurrentRole', null),
  withState('roleID', 'setRoleId', ''),
  withState('oldPassword', 'setOldPassword', ''),

  withHandlers({
    skipInputs: (props) => () => {
      props.setUserId('');
      props.setPosition('');
      props.setFirstName('');
      props.setLastName('');
      props.setLogin('');
      props.setNewPassword('');
      props.setNewPasswordRepeated('');
      props.setPhone('');
      props.setEmail('');
      props.setAvatar('');
      props.setUserInfo({});
      props.setOldPassword('');
    },
  }),
  withHandlers({
    functionCheckTime: (props) => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),

  withHandlers({
    validateFields: (props) => (create = false) => {
      if (create && !props.firstName) {
        props.functionCheckTime('Error', 'Не заполнено поле "Имя"');
        return false;
      }

      if (create && !props.lastName) {
        props.functionCheckTime('Error', 'Не заполнено поле "Фамилия"');
        return false;
      }

      if (props.login < !/^[a-zA-Z0-9.]+$/.test(props.login)) {
        props.functionCheckTime(
          'Error',
          'Логин должен содержать только латинские символы',
        );
        return false;
      }

      if ((create || props.newPassword) && props.newPassword.length < 6) {
        props.functionCheckTime(
          'Error',
          'Пароль не может быть меньше 6 символов',
        );
        return false;
      }

      if (
        (create || props.newPassword) &&
        props.newPassword !== props.newPasswordRepeated
      ) {
        props.functionCheckTime('Error', 'Пароли не совпадают');
        return false;
      }

      if (create && !props.phone) {
        props.functionCheckTime('Error', 'Не заполнено поле "Телефон"');
        return false;
      }

      if (props.phone && !validator.isMobilePhone(props.phone)) {
        props.functionCheckTime('Error', 'Невалидный номер телефона');
        return false;
      }

      if (
        (create || props.email) &&
        (!validator.isEmail(props.email) ||
          !/^[a-zA-Z0-9.-_@]+$/.test(props.email))
      ) {
        props.functionCheckTime('Error', 'Невалидный адрес электронной почты');
        return false;
      }

      if (props.currentUser.id === props.userId && !props.oldPassword) {
        props.functionCheckTime('Error', 'Не заполнен старый пароль');
        return false;
      }

      if (!props.newPassword) {
        props.functionCheckTime('Error', 'Не заполнен новый пароль');
        return false;
      }

      return true;
    },
  }),

  withHandlers({
    getUsers: (props) => async () => {
      let users = [];
      if (props.role) {
        users = await UserService.getUsersByRole(props.role);
      } else {
        users = await UserService.getAllUsers();
      }
      users.forEach((user) => {
        user.position = props.positions[user.roleID];
      });
      props.setUsersList(users);
    },
  }),

  withHandlers({
    saveUserData: (props) => async () => {
      const isValid = props.validateFields();

      if (!isValid) {
        return;
      }

      try {
        const body = {};
        Object.keys(props.userInfo).forEach((key) => {
          if (props[key] !== props.userInfo[key]) {
            body[key] = props[key];
          }
        });
        if (props.newPassword) {
          body.password = props.newPassword;
        }
        if (props.oldPassword) {
          body.oldpassword = props.oldPassword;
        }
        const result = await UserService.updateUser(props.userId, body);
        if (result.status === 200) {
          props.toggle(false);
          props.skipInputs();
          props.functionCheckTime('Success', 'Успешно');
          props.getUsers();
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    deleteUser: (props) => async (id) => {
      try {
        const result = await UserService.deleteUser(id);
        if (result.status === 200) {
          props.toggle(false);
          props.skipInputs();
          props.functionCheckTime('Success', 'Успешно');
          props.getUsers();
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    addUser: (props) => async () => {
      const isValid = props.validateFields(true);

      if (!isValid) {
        return;
      }

      const body = {
        login: props.login,
        password: props.newPassword,
        firstName: props.firstName,
        lastName: props.lastName,
        phone: props.phone,
        email: props.email,
        roleID: props.role,
        avatar: props.avatar,
      };
      try {
        const result = await UserService.addUser(body);
        if (result.status === 200) {
          props.toggle(false);
          props.skipInputs();
          props.functionCheckTime('Success', 'Успешно');
          props.getUsers();
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
  }),

  withHandlers({
    toggleCurrent: (props) => (item) => {
      props.setEditMode(true);
      props.setUserInfo(item);
      props.setUserId(item.id);
      props.setPosition(item.position);
      props.setFirstName(item.firstName);
      props.setLastName(item.lastName);
      props.setLogin(item.login);
      props.setPhone(item.phone);
      props.setEmail(item.email);
      props.setAvatar(item.avatar);
      props.toggle(!props.toggleOn);
      props.setOldPassword('');
    },
    loadUsersByRole: (props) => async (role) => {
      props.setToggleLoad(true);
      let result = null;
      if (role) {
        result = await UserService.getUsersByRole(role);
      } else {
        result = await UserService.getAllUsers();
      }
      result.forEach((user) => {
        user.position = props.positions[user.roleID];
      });

      props.setToggleLoad(false);

      return result;
    },
    getRoleId: (props) => async (roleName) => {
      const roles = await RoleService.getRoles();
      let roleID = null;
      if (roleName === 'users') {
        props.setRoleName('Все');
        props.setActiveSecondarySidebarItem({ id: '', name: 'Все', url: '' });
      } else if (roleName) {
        roles.forEach((role) => {
          if (role.url === roleName) {
            roleID = role.id;
            props.setRole(role.id);
            props.setRoleName(role.name);
            props.setActiveSecondarySidebarItem(role);
          }
        });
      }

      return roleID;
    },
    getRoleNameFromUrl: (props) => () =>
      props.location.pathname
        .split('/')
        .filter((el) => el !== '/' && el !== '')
        .pop(),
  }),
  lifecycle({
    async componentDidMount() {
      this.props.setToggleLoad(true);
      try {
        const accesses = await UserService.getRight();
        this.props.saveUserAccesses({
          directory: accesses.result.directories,
          requests: accesses.result.requests,
          additionalPermissions: accesses.result.additionalPermissions,
        });
        let roleName = this.props.getRoleNameFromUrl();
        this.props.setActiveSidebarItem('users');
        const positions = await RoleService.getRoles();

        const barItems = [
          { id: '', name: 'Все', url: '/dashboard/users/' },
          ...positions,
          accesses.result.directories.roles.getAccessRights && {
            id: 'changeRole',
            name: 'Настройка ролей',
            url: '/dashboard/users/changeRole',
          },
        ];
        const settingsEmail =
          accesses.result &&
          accesses.result.directories &&
          accesses.result.directories.roles &&
          accesses.result.directories.roles.settingsEmail;
        if (settingsEmail) {
          barItems.push({
            id: 'settingsEmail',
            name: 'Настройка email рассылки',
            url: '/dashboard/users/settingsEmail',
          });
        }
        this.props.setSecondaryBarItems(barItems);

        this.props.setToggleLoad(false);
        const positionsById = {};
        positions.forEach((position) => {
          positionsById[position.id] = position.name;
        });
        this.props.setPositions(positionsById);
        let roleID = await this.props.getRoleId(roleName);

        let users = await this.props.loadUsersByRole(roleID);
        this.props.setUsersList(users);
        this.props.setUsersCardDefault(USERS_LIST_DEFAULT);

        this.listener = this.props.history.listen(async (location) => {
          this.props.toggle(false);
          if (location.pathname.indexOf('/dashboard/users') === -1) return;
          if (location.pathname !== '/') {
            roleID = null;
            if (
              location.state &&
              Object.hasOwnProperty.call(location.state, 'entityId')
            ) {
              roleID = location.state.entityId;
              this.props.setRole(roleID);
              this.props.setRoleName(location.state.entityName);
            } else {
              roleName = this.props.getRoleNameFromUrl();
              roleID = await this.props.getRoleId(roleName);
            }
            if (roleID === this.props.currentRole) return;
            this.props.setCurrentRole(roleID);
            if (roleID === 'changeRole' || roleID === 'settingsEmail') return;
            if (roleID) {
              users = await this.props.loadUsersByRole(roleID);
            } else {
              this.props.setRole(null, this.props.getUsers);
            }
            this.props.setUsersList(users);
          }
        });
      } catch (error) {
        console.log('error', error);
      }
    },
    componentWillUnmount() {
      if (this.listener) {
        this.listener();
      }
    },
  }),
)(UsersView);
