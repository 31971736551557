/**
 * @flow
 */
import React from 'react';

import { PageTitle, Button, UserCard, UserCardInfo } from '../../../components';

import {
  ContentPage,
  FormCardInfo,
  ContentGlobal,
  Title,
  MobileAddButton,
  Loader,
} from './UsersStyled';

type UserPageMainPropsType = {
  usersList: any,
  toggleOn: any,
  toggleCurrent: any,
  roleName: string,
  userId: string,
  firstName: string,
  lastName: string,
  position: string,
  login: string,
  phone: string,
  email: string,
  avatar: string,
  newPassword: string,
  newPasswordRepeated: string,
  editMode: boolean,
  role: string,
  setFirstName: (string) => void,
  setLastName: (string) => void,
  setLogin: (string) => void,
  setPhone: (string) => void,
  setEmail: (string) => void,
  setNewPassword: (string) => void,
  setNewPasswordRepeated: (string) => void,
  setEditMode: (boolean) => void,
  saveUserData: () => void,
  deleteUser: (string) => void,
  addUser: () => void,
  skipInputs: () => void,
  toggle: (boolean) => void,
  setRoleId: (String) => void,
  access: Object,
  toggleLoad: boolean,
};

const renderUsersList = (usersList, toggleCurrent, toggle) =>
  usersList.map((item) => (
    <UserCard
      key={item._id}
      id={item._id}
      firstName={item.firstName}
      lastName={item.lastName}
      position={item.position}
      avatar={item.avatar}
      clickItem={() => {
        toggle(false);
        setTimeout(() => toggleCurrent(item));
      }}
    />
  ));

export default function UserPage(props: UserPageMainPropsType) {
  return (
    <ContentGlobal>
      <ContentPage>
        <Title>
          <PageTitle>{props.roleName}</PageTitle>
          {props.role && props.access.directory.roles.insert ? (
            <Button
              circle
              clicked={() => {
                props.toggle(false);
                setTimeout(() => {
                  props.setEditMode(false);
                  props.toggle(true);
                });
                setTimeout(() => {
                  props.skipInputs();
                }, 500);
              }}
              plus
              title="Добавить"
            />
          ) : null}
        </Title>
        {props.toggleLoad ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: '50%',
              left: '50%',
            }}>
            <Loader />
          </div>
        ) : (
          renderUsersList(props.usersList, props.toggleCurrent, props.toggle)
        )}
      </ContentPage>
      {props.toggleOn && props.access.directory.users.findById ? (
        <FormCardInfo>
          <UserCardInfo
            id={props.userId}
            edit={props.editMode}
            firstName={props.firstName}
            changeFirstName={(value) => props.setFirstName(value)}
            lastName={props.lastName}
            changeLastName={(value) => props.setLastName(value)}
            position={props.position || props.roleName}
            login={props.login}
            changeLogin={(value) => props.setLogin(value)}
            phoneNumber={props.phone}
            changePhoneNumber={(value) => props.setPhone(value)}
            email={props.email}
            changeEmail={(value) => props.setEmail(value)}
            avatar={props.avatar}
            password={props.newPassword}
            repeatedPassword={props.newPasswordRepeated}
            changeNewPassword={(value) => props.setNewPassword(value)}
            setRoleId={(value) => props.setRoleId(value)}
            changeNewPasswordRepeated={(value) =>
              props.setNewPasswordRepeated(value)
            }
            saveAction={() => props.saveUserData()}
            deleteAction={() => props.deleteUser(props.userId)}
            addAction={() => props.addUser()}
            cancelAction={() => {
              props.skipInputs();
              props.toggle(false);
            }}
            changeOldPassword={(value) => props.setOldPassword(value)}
            oldPassword={props.oldPassword}
            currentUser={props.currentUser}
          />
        </FormCardInfo>
      ) : null}
      <MobileAddButton>
        {props.role && (
          <Button
            circle
            clicked={() => {
              props.skipInputs();
              props.setEditMode(false);
              props.toggle(!props.toggleOn);
            }}
            plus
          />
        )}
      </MobileAddButton>
    </ContentGlobal>
  );
}
