/**
 * @flow
 */
import Calendar from 'rc-calendar';
import InputMask from 'react-input-mask';
import React, { useEffect } from 'react';
import moment from 'moment';
import ruRu from 'rc-calendar/lib/locale/ru_RU';

import {
  Form,
  ContainerTitle,
  ContainerValues,
  ContainerValue,
  ValueTitle,
  ValueOptions,
  OptionValues,
  TitleText,
  TitleButton,
  CalcValue,
  StateContainer,
  StateButtons,
  StateButton,
  BasicInformationContainer,
  CargoInformationContainer,
  AdditionalServicesContainer,
  CountingContainer,
  AutocompleteItem,
  CalendarContainer,
  OuterFormCheckboxWrapper,
  CalendarMask,
  AddFieldButton,
  DeleteFieldButton,
  TitleContainerFixed,
  TitleContainerFlex,
  ContainerValueCalendar,
  DeleteFieldButtonContainer,
  ContainerValueButton,
  ContainerFlex,
} from './RequestFormStyles';
import {
  Input,
  Button,
  Checkbox,
  ModaleWindowPayerInfo,
  SelectDropList,
  Loader,
} from '../index';
import { InputComponent, InputWrapper } from '../Input/InputStyled';
import {
  roundingNumber,
  numberWithCommas,
} from '../../helpers/RoundingNumbers';

type RequestFormPropsType = {
  edit: boolean,
  state: String,
  accesses: Object,
  fieldsValue: Object,
  total: Number,
  payments: Array<Object>,
  autocompleteValues: Object,
  statusLoadCalendar: boolean,
  paymentDateCalendar: boolean,
  plannedDispatchDateCalendar: boolean,
  actualDispatchDateCalendar: boolean,
  statusCustomerAccountDateCalendar: boolean,
  statusIssueCalendar: boolean,
  statusArrivalCalendar: boolean,
  stateClaim: string,
  statusPlannedIssueDateCalendar: boolean,
  service: Object,
  statusWindow?: boolean,
  nameForm: string,
  returnToTheLastPage: () => void,
  save: () => void,
  update: () => void,
  selectedFieldValue: (any, any) => void,
  changeField: (string, string, any) => void,
  clearAutocompleteValues: () => void,
  setStatusLoadCalendar: (boolean) => void,
  setStatusArrivalCalendar: (boolean) => void,
  setStatusIssueCalendar: (boolean) => void,
  setStatusCustomerAccountDateCalendar: (boolean) => void,
  setPlannedDispatchDateCalendar: (boolean) => void,
  setActualDispatchDateCalendar: (boolean) => void,
  setStateClaim: (string) => void,
  changePaymentFields: (string, string, string) => void,
  setPaymentDateCalendar: (boolean) => void,
  setPayments: (Array<any>) => void,
  setStatusPlannedIssueDateCalendar: (boolean) => void,
  scrollEvent: () => void,
  createFormModal: (any, any) => void,
  convertCalendarDate: (string) => Object,
  calendaeSelectHandler: (string, string) => void,
  setStatusWindow: (Boolean) => void,
  access: Object,
  paymentCounterAmount: Number,
  setTotal: () => void,
  changePayersField: () => void,
  selectPayersField: () => void,
  setFieldsValue: () => void,
  activePayersField: Number,
  hasCustomerservice: boolean,
  hasShipmentdepartment: boolean,
  hasAutodepartment: boolean,
  hasDocumentdepartment: boolean,
  hasFinancialdepartment: boolean,
  hasBillnumbers: boolean,
  buhCounterAmountMore: Number,
  changeBuhAmountMoreField: () => void,
  fieldsWithErrors: Array<string>,
  setFieldsWithErrors: () => void,
  showLoader: boolean,
  departureVesselDateCalendar: boolean,
  setDepartureVesselDateCalendar: () => void,
  arrivalVesselDateCalendar: boolean,
  setArrivalVesselDateCalendar: () => void,
  notificationDateCalendar: boolean,
  setNotificationDateCalendar: () => void,
};

const stateList = [
  'Предварительное',
  'В пути',
  'Прибыл',
  'Выдан клиенту',
  'Прогон',
  'Закрыта',
  'Вывоз',
];

export default function RequestFormView(props: RequestFormPropsType) {
  const sumBuhAmountMore = props.fieldsValue.buh_amountMore
    ? props.fieldsValue.buh_amountMore.reduce(
        (accumulator, currentValue) => accumulator + +currentValue.amount,
        0,
      )
    : 0;

  useEffect(() => {
    if (
      props.accesses.requests.balance &&
      props.accesses.requests.buh_amount &&
      props.accesses.requests.buh_amountMore
    ) {
      if (props.paymentCounterAmount && props.fieldsValue.buh_amount.name) {
        let balance =
          props.fieldsValue.buh_amount.name -
          props.paymentCounterAmount +
          sumBuhAmountMore;
        if (balance < 0) balance = 0;
        props.changeField('balance', roundingNumber(balance), 'input-number');
      } else {
        props.changeField(
          'balance',
          roundingNumber(+props.fieldsValue.buh_amount.name + sumBuhAmountMore),
          'input-number',
        );
      }
    }
  }, [
    props.paymentCounterAmount,
    props.fieldsValue.buh_amount && props.fieldsValue.buh_amount.name,
    sumBuhAmountMore,
  ]);

  useEffect(() => {
    if (props.accesses.requests.buh_OBN) {
      const { fieldsValue, changeField } = props;
      const agentToClient =
        fieldsValue.buh_agentToClient && fieldsValue.buh_agentToClient.name
          ? fieldsValue.buh_agentToClient.name
          : '0';

      let managerial =
        fieldsValue.buh_managerial && fieldsValue.buh_managerial.name
          ? fieldsValue.buh_managerial.name
          : '0';

      const toDateString = moment()
        .tz('Europe/Moscow')
        .subtract(4, 'months')
        .format('YYYY-MM-DD');
      const toDate = new Date(toDateString).setUTCHours(0, 0, 0, 0);

      let dateLoadOk = false;
      if (fieldsValue.dateLoad && fieldsValue.dateLoad.name) {
        const dateLoad = new Date(
          fieldsValue.dateLoad.name.split('-').reverse().join('-'),
        ).setUTCHours(0, 0, 0, 0);
        if (dateLoad <= toDate) {
          dateLoadOk = true;
        }
      }
      const managersId = [
        '5d84c9b65f71eb76be6f4c91',
        '5d84ca875f71eb76be6f4c93',
      ];
      if (
        fieldsValue.manager &&
        fieldsValue.manager.id &&
        managersId.includes(fieldsValue.manager.id) &&
        dateLoadOk
      ) {
        managerial = '0';
      }
      const agentSuppliers =
        fieldsValue.buh_agentSuppliers && fieldsValue.buh_agentSuppliers.name
          ? fieldsValue.buh_agentSuppliers.name
          : '0';
      const forwarderOnLoading =
        fieldsValue.buh_forwarderOnLoading &&
        fieldsValue.buh_forwarderOnLoading.name
          ? fieldsValue.buh_forwarderOnLoading.name
          : '0';
      const veterinaryCertificate =
        fieldsValue.buh_veterinaryCertificate &&
        fieldsValue.buh_veterinaryCertificate.name
          ? fieldsValue.buh_veterinaryCertificate.name
          : '0';
      const sumObn =
        Number(agentToClient) +
        Number(managerial) +
        Number(agentSuppliers) +
        Number(forwarderOnLoading) +
        Number(veterinaryCertificate);
      changeField(
        'buh_OBN',
        (sumObn - (sumObn * 83.5) / 100).toFixed(2),
        'input-number',
      );
    }
  }, [
    props.fieldsValue.buh_agentToClient &&
      props.fieldsValue.buh_agentToClient.name,
    props.fieldsValue.buh_managerial && props.fieldsValue.buh_managerial.name,
    props.fieldsValue.buh_agentSuppliers &&
      props.fieldsValue.buh_agentSuppliers.name,
    props.fieldsValue.buh_forwarderOnLoading &&
      props.fieldsValue.buh_forwarderOnLoading.name,
    props.fieldsValue.buh_veterinaryCertificate &&
      props.fieldsValue.buh_veterinaryCertificate.name,
  ]);

  useEffect(() => {
    if (props.accesses.requests.buh_total) {
      if (props.fieldsValue.buh_total.name) {
        props.setTotal(props.fieldsValue.buh_total.name);
      }
    }
  }, [props.fieldsValue.buh_total && props.fieldsValue.buh_total.name]);

  const autocompleteValues = {};
  const formatStr = 'DD-MM-YYYY';
  const changeFormatDate = (strDate) => {
    const [year, month, day] = strDate.split('-');
    return [day, month, year].join('-');
  };
  const setCalendarValue = (value) => {
    if (!value || value.indexOf('_') > -1) return moment();
    if (value !== undefined || value.indexOf('_') === -1)
      return moment(changeFormatDate(value));
  };

  Object.keys(props.autocompleteValues).forEach((key) => {
    autocompleteValues[key] = [];
    if (props.autocompleteValues[key]) {
      props.autocompleteValues[key].forEach((value) => {
        autocompleteValues[key].push(
          <OptionValues
            key={value}
            onClick={() => {
              props.selectedFieldValue(key, value);
            }}>
            {value}
          </OptionValues>,
        );
      });
    }
  });

  if (props.showLoader) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: '50%',
          left: '50%',
        }}>
        <Loader />
      </div>
    );
  }

  return (
    <Form
      onWheel={() => props.scrollEvent()}
      onClick={(e) => {
        if (e.target.tagName !== 'INPUT') {
          props.clearAutocompleteValues();
        }
      }}
      onKeyUp={(e) => {
        if (e.key === 'Tab' && e.target.id) {
          props.scrollAndSetActiveSectionMouseAndKeyboard(
            e.target.id.slice(1),
            true,
          );
        }
      }}>
      {props.statusWindow ? (
        <ModaleWindowPayerInfo
          closeAction={() => props.setStatusWindow(false)}
          createActionModal={(value, name) => {
            if (name === 'payers') {
              props.changePayersField(value.id, props.activePayersField);
              props.selectPayersField(value, props.activePayersField);
            } else {
              props.changeField(name, value.id, null);
              props.selectedFieldValue(name, value);
            }
            props.setStatusWindow(false);
          }}
          nameForm={props.nameForm}
          service={props.service}
        />
      ) : null}
      <TitleContainerFixed>
        <TitleContainerFlex>
          <TitleText>
            {props.edit
              ? `Редактирование заявки №${props.fieldsValue.number.name}`
              : 'Создание заявки'}
          </TitleText>
          <TitleButton>
            <Button
              mode="white"
              disabled={props.createUpdateInProcess}
              clicked={() =>
                props.goToPageCheck(() => props.returnToTheLastPage())
              }>
              Вернуться
            </Button>
          </TitleButton>
          {props.edit ? (
            <TitleButton>
              <Button
                mode="red"
                disabled={props.createUpdateInProcess}
                clicked={() => props.update()}>
                Обновить
              </Button>
            </TitleButton>
          ) : (
            <TitleButton>
              <Button
                mode="red"
                disabled={props.createUpdateInProcess}
                clicked={() => props.save()}>
                Сохранить
              </Button>
            </TitleButton>
          )}
        </TitleContainerFlex>
      </TitleContainerFixed>
      <BasicInformationContainer
        id="customerservice"
        style={{ paddingTop: '50px' }}
      />
      {props.accesses.requests.state ? (
        <StateContainer>
          <ValueTitle>Состояние</ValueTitle>
          <StateButtons
            onClick={() =>
              props.scrollAndSetActiveSectionMouseAndKeyboard(
                'customerservice',
                true,
              )
            }>
            {stateList.map((item) => (
              <StateButton
                onClick={() => {
                  props.changeField('state', item);
                  props.setStateClaim(item);
                }}
                active={props.fieldsValue.state.name === item}
                status={item}>
                {item}
              </StateButton>
            ))}
          </StateButtons>
        </StateContainer>
      ) : null}
      {props.accesses.requests.stopSheet ? (
        <OuterFormCheckboxWrapper
          onClick={() =>
            props.scrollAndSetActiveSectionMouseAndKeyboard('customerservice')
          }>
          <Checkbox
            value={props.fieldsValue.stopSheet.name}
            message="Стоп-лист"
            onClickItem={(value) => {
              props.changeField('stopSheet', value, 'boolean');
            }}
          />
        </OuterFormCheckboxWrapper>
      ) : null}
      {props.accesses.requests.companyAmixgroup ? (
        <OuterFormCheckboxWrapper
          onClick={() =>
            props.scrollAndSetActiveSectionMouseAndKeyboard('customerservice')
          }>
          <Checkbox
            value={props.fieldsValue.companyAmixgroup.name}
            message="Компания принадлежит Амикс"
            onClickItem={(value) => {
              props.changeField('companyAmixgroup', value, 'boolean');
            }}
          />
        </OuterFormCheckboxWrapper>
      ) : null}
      <BasicInformationContainer>
        {props.hasCustomerservice ? (
          <ContainerTitle>Отдел обслуживания клиентов</ContainerTitle>
        ) : null}
        <ContainerValues>
          {props.accesses.requests.company ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Компания</ValueTitle>
              <Input
                type="text"
                placeholder="Выберите пункт..."
                value={props.fieldsValue.company.name}
                changed={(value) => {
                  props.changeField('company', value, 'autocomplete');
                }}
                active={() => {
                  props.changeField('company', '', 'autocomplete');
                }}
              />
              {props.autocompleteValues.company &&
              props.autocompleteValues.company.length ? (
                <ValueOptions>
                  {props.autocompleteValues.company.map((value) => (
                    <AutocompleteItem
                      key={value.id}
                      onClick={() =>
                        props.selectedFieldValue('company', value)
                      }>
                      {value.name}
                    </AutocompleteItem>
                  ))}
                </ValueOptions>
              ) : null}
              {props.autocompleteValues.company &&
                props.autocompleteValues.company.length === 0 && (
                  <ValueOptions>
                    <AutocompleteItem>
                      Ни одного справочника не найдено
                    </AutocompleteItem>
                  </ValueOptions>
                )}
            </ContainerValue>
          ) : null}
          {props.accesses.requests.dateLoad ? (
            <ContainerValueCalendar
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Дата загрузки</ValueTitle>
              <InputMask
                mask="12-39-9999"
                formatChars={{
                  1: '[0-3]',
                  2: '[0-9]',
                  3: '[0-1]',
                  9: '[0-9]',
                }}
                value={
                  props.fieldsValue.dateLoad.name
                    ? props.fieldsValue.dateLoad.name.indexOf('-') > 2
                      ? moment(props.fieldsValue.dateLoad.name).format(
                          'DD-MM-YYYY',
                        )
                      : props.fieldsValue.dateLoad.name
                    : null
                }
                onChange={(event) => {
                  props.changeField(
                    'dateLoad',
                    event.target.value,
                    'input-date',
                  );
                  if (
                    event.target.value.indexOf('_') === -1 &&
                    event.target.value.length > 0
                  ) {
                    props.setStatusLoadCalendar(false);
                  } else {
                    props.setStatusLoadCalendar(true);
                  }
                }}
                placeholder="Выберите дату..."
                onFocus={() => {
                  props.setStatusLoadCalendar(true);
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent {...inputProps} valid />
                  </InputWrapper>
                )}
              </InputMask>
              {props.statusLoadCalendar && (
                <CalendarContainer
                  className="calendar-container"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      props.setStatusLoadCalendar(false);
                    }
                  }}>
                  <Calendar
                    onSelect={(value) =>
                      props.calendaeSelectHandler('dateLoad', value)
                    }
                    onClear={() => props.setStatusLoadCalendar(false)}
                    format={formatStr}
                    defaultValue={props.convertCalendarDate(
                      props.fieldsValue.dateLoad.name,
                    )}
                    locale={ruRu}
                    tabindex="0"
                  />
                </CalendarContainer>
              )}
              {props.statusLoadCalendar && (
                <CalendarMask
                  onClick={() => props.setStatusLoadCalendar(false)}
                />
              )}
            </ContainerValueCalendar>
          ) : null}
          {props.accesses.requests.timeLoad ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Время загрузки</ValueTitle>
              <InputMask
                mask="12:34"
                formatChars={{
                  1: /[0-2]/,
                  2: /[0-9]/,
                  3: /[0-5]/,
                  4: /[0-9]/,
                }}
                placeholder="Введите время..."
                alwaysShowMask={false}
                value={props.fieldsValue.timeLoad.name}
                onChange={(event) => {
                  props.changeField(
                    'timeLoad',
                    event.target.value,
                    'input-text',
                  );
                }}
                onFocus={() => {
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent
                      {...inputProps}
                      valid
                      id="*customerservice"
                    />
                  </InputWrapper>
                )}
              </InputMask>
            </ContainerValue>
          ) : null}
          {props.accesses.requests.typeSend ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Тип отправки</ValueTitle>
              <SelectDropList
                list={[
                  { name: 'Контейнерная' },
                  { name: 'Авто' },
                  { name: 'Вагонная' },
                  { name: 'Сборка' },
                ]}
                itemValue={props.fieldsValue.typeSend}
                autocomplete={false}
                onInputValueChange={() => {}}
                onChange={(value) => {
                  (() => {
                    switch (value.name) {
                      case 'Контейнерная':
                        (async () => {
                          await props.changeField(
                            'buh_seal',
                            '150',
                            'input-number',
                          );
                          await props.changeField(
                            'buh_bills',
                            '60',
                            'input-number',
                          );
                          props.changeField('typeSend', value.name, 'select');
                        })();
                        break;
                      case 'Авто':
                      case 'Вагонная':
                      case 'Сборка':
                        (async () => {
                          await props.changeField(
                            'buh_seal',
                            '0',
                            'input-number',
                          );
                          await props.changeField(
                            'buh_bills',
                            '0',
                            'input-number',
                          );
                          props.changeField('typeSend', value.name, 'select');
                        })();
                        break;
                    }
                  })();
                }}
                placeholder="Выберите тип"
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.typeContainer ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Вид контейнера</ValueTitle>
              <SelectDropList
                list={[
                  { name: '20FT' },
                  { name: '20FT(HC)' },
                  { name: '40FT' },
                  { name: '40FT(HC)' },
                  { name: '40FT(HC)PW' },
                  { name: '40SHC' },
                ]}
                itemValue={props.fieldsValue.typeContainer}
                onInputValueChange={() => {}}
                autocomplete={false}
                onChange={(value) =>
                  props.changeField('typeContainer', value.name, 'select')
                }
                placeholder="Выберите вид"
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.typeElivery ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Вид доставки</ValueTitle>
              <SelectDropList
                list={[
                  { name: 'До двери' },
                  { name: 'До станции' },
                  { name: 'Вывоз' },
                ]}
                itemValue={props.fieldsValue.typeElivery}
                onInputValueChange={() => {}}
                autocomplete={false}
                onChange={(value) =>
                  props.changeField('typeElivery', value.name, 'select')
                }
                placeholder="Выберите вид"
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.payer ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Заказчик</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.payer &&
                  props.autocompleteValues.payer.length
                    ? props.accesses.directory.payers.findAll &&
                      props.accesses.directory.payers.insert
                      ? [
                          ...props.autocompleteValues.payer,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.payer]
                    : props.accesses.directory.payers.findAll &&
                      props.accesses.directory.payers.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.payer}
                autocomplete
                placeholder="Выберите пункт..."
                onInputValueChange={(value) =>
                  props.changeField('payer', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('payers', 'payer');
                  } else {
                    props.selectedFieldValue('payer', value);
                  }
                }}
                itemName="payer"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.payers ? (
            <>
              {props.fieldsValue.payers.map((item, index) => (
                <div style={{ marginBottom: '20px' }}>
                  <ContainerValue
                    key={item.id}
                    onClick={() =>
                      props.scrollAndSetActiveSectionMouseAndKeyboard(
                        'customerservice',
                      )
                    }>
                    <div style={{ display: 'flex' }}>
                      <ValueTitle>{`Плательщик ${index + 1}`}</ValueTitle>
                      {index === 0 ? (
                        <AddFieldButton
                          onClick={() => {
                            const generateId = (id) => {
                              if (
                                !props.fieldsValue.payers.find(
                                  (item) => item.id === id,
                                ) &&
                                id >
                                  props.fieldsValue.payers[
                                    props.fieldsValue.payers.length - 1
                                  ].id
                              ) {
                                return id;
                              }
                              return generateId(id + 1);
                            };
                            props.setFieldsValue({
                              ...props.fieldsValue,
                              payers: [
                                ...props.fieldsValue.payers,
                                {
                                  name: '',
                                  id: generateId(
                                    props.fieldsValue.payers.length + 1,
                                  ),
                                  data: '',
                                },
                              ],
                            });
                            props.setChangedDataRequest(
                              JSON.parse(
                                JSON.stringify({
                                  ...props.fieldsValue,
                                  payers: [
                                    ...props.fieldsValue.payers,
                                    {
                                      name: '',
                                      id: generateId(
                                        props.fieldsValue.payers.length + 1,
                                      ),
                                      data: '',
                                    },
                                  ],
                                }),
                              ),
                            );
                            props.setChangedFields([
                              ...props.changedFields,
                              'payers',
                            ]);
                          }}
                        />
                      ) : (
                        <DeleteFieldButton
                          onClick={() => {
                            const filteredPayers = props.fieldsValue.payers.filter(
                              (payer) => payer.id !== item.id,
                            );
                            props.setFieldsValue({
                              ...props.fieldsValue,
                              payers: filteredPayers,
                            });
                            props.setChangedDataRequest(
                              JSON.parse(
                                JSON.stringify({
                                  ...props.fieldsValue,
                                  payers: filteredPayers,
                                }),
                              ),
                            );
                            const fieldsWithErrorsFiltered = props.fieldsWithErrors.filter(
                              (elem) => elem !== `payers${item.id}`,
                            );
                            props.setFieldsWithErrors(fieldsWithErrorsFiltered);
                            props.setChangedFields([
                              ...props.changedFields,
                              'payers',
                            ]);
                          }}
                        />
                      )}
                    </div>
                    <SelectDropList
                      list={
                        props.autocompleteValues.payers &&
                        props.autocompleteValues.payers.length &&
                        props.activePayersField === item.id
                          ? props.accesses.directory.payers.findAll &&
                            props.accesses.directory.payers.insert
                            ? [
                                ...props.autocompleteValues.payers,
                                { name: '+ создать справочник' },
                              ]
                            : [...props.autocompleteValues.payers]
                          : props.accesses.directory.payers.findAll &&
                            props.accesses.directory.payers.insert
                          ? [
                              { name: 'Ни одного справочника не найдено' },
                              { name: '+ создать справочник' },
                            ]
                          : [{ name: 'Ни одного справочника не найдено' }]
                      }
                      itemValue={item}
                      placeholder="Выберите плательщика..."
                      autocomplete
                      onInputValueChange={(value) =>
                        props.changePayersField(value, item.id)
                      }
                      onChange={(value) => {
                        if (value.name === 'Ни одного справочника не найдено') {
                        } else if (value.name === '+ создать справочник') {
                          props.createFormModal('payers', 'payers');
                        } else {
                          props.selectPayersField(
                            value,
                            props.activePayersField,
                          );
                        }
                      }}
                      itemName={`payers${item.id}`}
                      fieldsWithErrors={props.fieldsWithErrors}
                      btnDelete
                    />
                  </ContainerValue>
                </div>
              ))}
            </>
          ) : null}
          {props.accesses.requests.shipper ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Грузоотправитель</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.shipper &&
                  props.autocompleteValues.shipper.length
                    ? props.autocompleteValues.shipper
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.shipper}
                autocomplete
                placeholder="Выберите пункт..."
                onInputValueChange={(value) =>
                  props.changeField('shipper', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else {
                    props.selectedFieldValue('shipper', value);
                  }
                }}
                itemName="shipper"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.consignee ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Грузополучатель</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.consignee &&
                  props.autocompleteValues.consignee.length
                    ? props.autocompleteValues.consignee
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.consignee}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('consignee', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else {
                    props.selectedFieldValue('consignee', value);
                  }
                }}
                itemName="consignee"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.addressLoading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Адрес погрузки</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.addressLoading &&
                  props.autocompleteValues.addressLoading.length
                    ? props.autocompleteValues.addressLoading
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.addressLoading}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('addressLoading', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else {
                    props.selectedFieldValue('addressLoading', value);
                  }
                }}
                itemName="addressLoading"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.addressUnloading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Адрес разгрузки</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.addressUnloading &&
                  props.autocompleteValues.addressUnloading.length
                    ? props.autocompleteValues.addressUnloading
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.addressUnloading}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('addressUnloading', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else {
                    props.selectedFieldValue('addressUnloading', value);
                  }
                }}
                itemName="addressUnloading"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.destinationCity ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Город назначения</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.destinationCity &&
                  props.autocompleteValues.destinationCity.length
                    ? props.accesses.directory.cities.findAll &&
                      props.accesses.directory.cities.insert
                      ? [
                          ...props.autocompleteValues.destinationCity,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.destinationCity]
                    : props.accesses.directory.cities.findAll &&
                      props.accesses.directory.cities.insert
                    ? [
                        { name: 'Ни одного города не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного города не найдено' }]
                }
                itemValue={props.fieldsValue.destinationCity}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('destinationCity', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного города не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('cities', 'destinationCity');
                  } else {
                    props.selectedFieldValue('destinationCity', value);
                  }
                }}
                itemName="destinationCity"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.customerApplicationNumber ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Номер заявки клиента</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.customerApplicationNumber.name}
                changed={(value) => {
                  props.changeField(
                    'customerApplicationNumber',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.loadActual ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Груз фактический</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.loadActual &&
                  props.autocompleteValues.loadActual.length
                    ? props.accesses.directory.loads.findAll &&
                      props.accesses.directory.loads.insert
                      ? [
                          ...props.autocompleteValues.loadActual,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.loadActual]
                    : props.accesses.directory.loads.findAll &&
                      props.accesses.directory.loads.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.loadActual}
                autocomplete
                placeholder="Выберите пункт..."
                onInputValueChange={(value) =>
                  props.changeField('loadActual', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('loads', 'loadActual');
                  } else {
                    props.selectedFieldValue('loadActual', value);
                  }
                }}
                itemName="loadActual"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.weight ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Вес</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.weight.name}
                changed={(value) => {
                  props.changeField('weight', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.numberSeats ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Количество мест</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.numberSeats.name}
                changed={(value) => {
                  props.changeField('numberSeats', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}

          {props.accesses.requests.plannedIssueDate ? (
            <ContainerValueCalendar
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Плановая дата выдачи</ValueTitle>
              <InputMask
                mask="12-39-9999"
                formatChars={{
                  1: '[0-3]',
                  2: '[0-9]',
                  3: '[0-1]',
                  9: '[0-9]',
                }}
                value={
                  props.fieldsValue.plannedIssueDate.name
                    ? props.fieldsValue.plannedIssueDate.name.indexOf('-') > 2
                      ? moment(props.fieldsValue.plannedIssueDate.name).format(
                          'DD-MM-YYYY',
                        )
                      : props.fieldsValue.plannedIssueDate.name
                    : null
                }
                onChange={(event) => {
                  props.changeField(
                    'plannedIssueDate',
                    event.target.value,
                    'input-date',
                  );
                  if (
                    event.target.value.indexOf('_') === -1 &&
                    event.target.value.length > 0
                  ) {
                    props.setStatusPlannedIssueDateCalendar(false);
                  } else {
                    props.setStatusPlannedIssueDateCalendar(true);
                  }
                }}
                placeholder="Выберите дату..."
                onFocus={() => {
                  props.setStatusPlannedIssueDateCalendar(true);
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent {...inputProps} valid />
                  </InputWrapper>
                )}
              </InputMask>

              {props.statusPlannedIssueDateCalendar && (
                <CalendarContainer
                  className="calendar-container"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      props.setStatusPlannedIssueDateCalendar(false);
                    }
                  }}>
                  <Calendar
                    onSelect={(value) =>
                      props.calendaeSelectHandler('plannedIssueDate', value)
                    }
                    onClear={() =>
                      props.setStatusPlannedIssueDateCalendar(false)
                    }
                    format={formatStr}
                    defaultValue={props.convertCalendarDate(
                      props.fieldsValue.plannedIssueDate.name,
                    )}
                    locale={ruRu}
                    tabindex="0"
                  />
                </CalendarContainer>
              )}
              {props.statusPlannedIssueDateCalendar && (
                <CalendarMask
                  onClick={() => props.setStatusPlannedIssueDateCalendar(false)}
                />
              )}
            </ContainerValueCalendar>
          ) : null}
          {props.accesses.requests.scheduledIssueTime ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Плановое время выдачи</ValueTitle>
              <InputMask
                mask="12:34"
                formatChars={{
                  1: /[0-2]/,
                  2: /[0-9]/,
                  3: /[0-5]/,
                  4: /[0-9]/,
                }}
                placeholder="Введите время..."
                alwaysShowMask={false}
                value={props.fieldsValue.scheduledIssueTime.name}
                onChange={(event) => {
                  props.changeField(
                    'scheduledIssueTime',
                    event.target.value,
                    'input-text',
                  );
                }}
                onFocus={() => {
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent
                      {...inputProps}
                      valid
                      id="*customerservice"
                    />
                  </InputWrapper>
                )}
              </InputMask>
            </ContainerValue>
          ) : null}
          {props.accesses.requests.od ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>OD</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.od.name}
                changed={(value) => {
                  props.changeField('od', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.shipment ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Shipment</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.shipment.name}
                changed={(value) => {
                  props.changeField('shipment', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.dateArrival ? (
            <ContainerValueCalendar
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Дата прибытия</ValueTitle>
              <InputMask
                mask="12-39-9999"
                formatChars={{
                  1: '[0-3]',
                  2: '[0-9]',
                  3: '[0-1]',
                  9: '[0-9]',
                }}
                value={
                  props.fieldsValue.dateArrival.name
                    ? props.fieldsValue.dateArrival.name.indexOf('-') > 2
                      ? moment(props.fieldsValue.dateArrival.name).format(
                          'DD-MM-YYYY',
                        )
                      : props.fieldsValue.dateArrival.name
                    : null
                }
                onChange={(event) => {
                  props.changeField(
                    'dateArrival',
                    event.target.value,
                    'input-date',
                  );
                  if (
                    event.target.value.indexOf('_') === -1 &&
                    event.target.value.length > 0
                  ) {
                    setTimeout(() => props.changeField('state', 'Прибыл'), 0);
                    if (
                      props.fieldsValue.dateIssue.name &&
                      props.fieldsValue.dateIssue.name.indexOf('_') === -1
                    ) {
                      const dateArr = props.fieldsValue.dateIssue.name.split(
                        '-',
                      );
                      const date = new Date(
                        dateArr[0].length === 4
                          ? dateArr.join('-')
                          : dateArr.reverse().join('-'),
                      );
                      date.setHours(0);
                      date.setMinutes(0);
                      date.setSeconds(0);
                      date.setMilliseconds(0);
                      const today = new Date();
                      today.setHours(0);
                      today.setMinutes(0);
                      today.setSeconds(0);
                      today.setMilliseconds(0);
                      if (date.getTime() <= today.getTime()) {
                        setTimeout(
                          () => props.changeField('state', 'Выдан клиенту'),
                          0,
                        );
                      }
                    }
                    props.setStatusArrivalCalendar(false);
                  } else {
                    props.setStatusArrivalCalendar(true);
                    if (event.target.value !== '') {
                      setTimeout(
                        () => props.changeField('state', props.stateClaim),
                        0,
                      );
                      if (
                        props.fieldsValue.numberContainer &&
                        props.fieldsValue.numberContainer.name &&
                        props.fieldsValue.numberContainer.name.indexOf('_') ===
                          -1
                      ) {
                        setTimeout(
                          () => props.changeField('state', 'В пути'),
                          0,
                        );
                      }
                      if (
                        props.fieldsValue.dateIssue.name &&
                        props.fieldsValue.dateIssue.name.indexOf('_') === -1
                      ) {
                        const dateArr = props.fieldsValue.dateIssue.name.split(
                          '-',
                        );
                        const date = new Date(
                          dateArr[0].length === 4
                            ? dateArr.join('-')
                            : dateArr.reverse().join('-'),
                        );
                        date.setHours(0);
                        date.setMinutes(0);
                        date.setSeconds(0);
                        date.setMilliseconds(0);
                        const today = new Date();
                        today.setHours(0);
                        today.setMinutes(0);
                        today.setSeconds(0);
                        today.setMilliseconds(0);
                        if (date.getTime() <= today.getTime()) {
                          setTimeout(
                            () => props.changeField('state', 'Выдан клиенту'),
                            0,
                          );
                        }
                      }
                    }
                  }
                }}
                placeholder="Выберите дату..."
                onFocus={() => {
                  props.setStatusArrivalCalendar(true);
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent {...inputProps} valid />
                  </InputWrapper>
                )}
              </InputMask>

              {props.statusArrivalCalendar && (
                <CalendarContainer
                  className="calendar-container"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      props.setStatusArrivalCalendar(false);
                    }
                  }}>
                  <Calendar
                    onSelect={(value) => {
                      if (!value) return;
                      props.calendaeSelectHandler('dateArrival', value);
                      if (props.stateClaim !== 'Выдан клиенту') {
                        setTimeout(
                          () => props.changeField('state', 'Прибыл'),
                          0,
                        );
                      }

                      if (
                        props.fieldsValue.dateIssue.name &&
                        props.fieldsValue.dateIssue.name.indexOf('_') === -1
                      ) {
                        const dateArr = props.fieldsValue.dateIssue.name.split(
                          '-',
                        );
                        const date = new Date(
                          dateArr[0].length === 4
                            ? dateArr.join('-')
                            : dateArr.reverse().join('-'),
                        );
                        date.setHours(0);
                        date.setMinutes(0);
                        date.setSeconds(0);
                        date.setMilliseconds(0);
                        const today = new Date();
                        today.setHours(0);
                        today.setMinutes(0);
                        today.setSeconds(0);
                        today.setMilliseconds(0);
                        if (date.getTime() <= today.getTime()) {
                          setTimeout(
                            () => props.changeField('state', 'Выдан клиенту'),
                            0,
                          );
                        }
                      }
                    }}
                    onClear={() => props.setStatusArrivalCalendar(false)}
                    format={formatStr}
                    defaultValue={props.convertCalendarDate(
                      props.fieldsValue.dateArrival.name,
                    )}
                    locale={ruRu}
                    tabindex="0"
                  />
                </CalendarContainer>
              )}
              {props.statusArrivalCalendar && (
                <CalendarMask
                  onClick={() => props.setStatusArrivalCalendar(false)}
                />
              )}
            </ContainerValueCalendar>
          ) : null}
          {props.accesses.requests.warming ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Утепление</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.warming.name}
                changed={(value) => {
                  props.changeField('warming', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.veterinaryCertificate ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Ветеринарное свидетельство</ValueTitle>
              <Checkbox
                value={props.fieldsValue.veterinaryCertificate.name}
                onClickItem={(value) => {
                  props.changeField('veterinaryCertificate', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.gdnUnloading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>ЖДН Выгрузка</ValueTitle>
              <Checkbox
                value={props.fieldsValue.gdnUnloading.name}
                onClickItem={(value) => {
                  props.changeField('gdnUnloading', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.loadFastening ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Крепление груза</ValueTitle>
              <Checkbox
                value={props.fieldsValue.loadFastening.name}
                onClickItem={(value) => {
                  props.changeField('loadFastening', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.agentDocuments ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Документы от агента</ValueTitle>
              <Checkbox
                value={props.fieldsValue.agentDocuments.name}
                onClickItem={(value) => {
                  props.changeField('agentDocuments', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}

          {props.accesses.requests.forwarderLoading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Экспедитор погрузка</ValueTitle>
              <Checkbox
                value={props.fieldsValue.forwarderLoading.name}
                onClickItem={(value) => {
                  props.changeField('forwarderLoading', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}

          {props.accesses.requests.forwarderUnloading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Экспедитор выгрузка</ValueTitle>
              <Checkbox
                value={props.fieldsValue.forwarderUnloading.name}
                onClickItem={(value) => {
                  props.changeField('forwarderUnloading', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.moversOnLoading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Грузчики на погрузке</ValueTitle>
              <Checkbox
                value={props.fieldsValue.moversOnLoading.name}
                onClickItem={(value) => {
                  props.changeField('moversOnLoading', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.moversAtUnloading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Грузчики на выгрузке</ValueTitle>
              <Checkbox
                value={props.fieldsValue.moversAtUnloading.name}
                onClickItem={(value) => {
                  props.changeField('moversAtUnloading', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.tracking ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Отслеживание</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.tracking.name}
                changed={(value) => {
                  props.changeField('tracking', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.distance ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Расстояние до конечной станции</ValueTitle>
              <Input
                type="text"
                value={
                  props.fieldsValue.distance
                    ? props.fieldsValue.distance.name
                    : ''
                }
                active={() => {
                  props.clearAutocompleteValues();
                }}
                disabled={true}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.carriage ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Вагон</ValueTitle>
              <Input
                type="text"
                value={
                  props.fieldsValue.carriage
                    ? props.fieldsValue.carriage.name
                    : ''
                }
                active={() => {
                  props.clearAutocompleteValues();
                }}
                disabled={true}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.roadDislocation ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Дорожная дислокация</ValueTitle>
              <Input
                type="text"
                value={
                  props.fieldsValue.roadDislocation
                    ? props.fieldsValue.roadDislocation.name
                    : ''
                }
                active={() => {
                  props.clearAutocompleteValues();
                }}
                disabled={true}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.additionalServices ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Дополнительные услуги</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.additionalServices.name}
                changed={(value) => {
                  props.changeField('additionalServices', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.additionalServicesCounterparty ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Дополнительные услуги (контрагент)</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.additionalServicesCounterparty &&
                  props.autocompleteValues.additionalServicesCounterparty.length
                    ? props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                      ? [
                          ...props.autocompleteValues
                            .additionalServicesCounterparty,
                          { name: '+ создать справочник' },
                        ]
                      : [
                          ...props.autocompleteValues
                            .additionalServicesCounterparty,
                        ]
                    : props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.additionalServicesCounterparty}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField(
                    'additionalServicesCounterparty',
                    value,
                    'autocomplete',
                  )
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal(
                      'counterparties',
                      'additionalServicesCounterparty',
                    );
                  } else {
                    props.selectedFieldValue(
                      'additionalServicesCounterparty',
                      value,
                    );
                  }
                }}
                itemName="additionalServicesCounterparty"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_amount ? (
            <div>
              <ContainerValue
                onClick={() =>
                  props.scrollAndSetActiveSectionMouseAndKeyboard(
                    'customerservice',
                  )
                }>
                <div style={{ display: 'flex' }}>
                  <ValueTitle>Сумма</ValueTitle>
                  {props.accesses.requests.buh_amountMore &&
                    props.fieldsValue.buh_amount.name > 0 && (
                      <AddFieldButton
                        onClick={() => {
                          const generateId = (idIndex) => {
                            if (
                              !props.fieldsValue.buh_amountMore.find(
                                (item) => item.idIndex === idIndex,
                              ) &&
                              idIndex >
                                props.fieldsValue.buh_amountMore[
                                  props.fieldsValue.buh_amountMore.length - 1
                                ].idIndex
                            ) {
                              return idIndex;
                            }
                            return generateId(idIndex + 1);
                          };
                          if (props.fieldsValue.buh_amountMore.length === 0) {
                            props.setFieldsValue({
                              ...props.fieldsValue,
                              buh_amountMore: [
                                ...props.fieldsValue.buh_amountMore,
                                { idIndex: 1 },
                              ],
                            });
                            props.setChangedDataRequest(
                              JSON.parse(
                                JSON.stringify({
                                  ...props.fieldsValue,
                                  buh_amountMore: [
                                    ...props.fieldsValue.buh_amountMore,
                                    { idIndex: 1 },
                                  ],
                                }),
                              ),
                            );
                          } else {
                            props.setFieldsValue({
                              ...props.fieldsValue,
                              buh_amountMore: [
                                ...props.fieldsValue.buh_amountMore,
                                {
                                  idIndex: generateId(
                                    props.fieldsValue.buh_amountMore.length + 1,
                                  ),
                                },
                              ],
                            });
                            props.setChangedDataRequest(
                              JSON.parse(
                                JSON.stringify({
                                  ...props.fieldsValue,
                                  buh_amountMore: [
                                    ...props.fieldsValue.buh_amountMore,
                                    {
                                      idIndex: generateId(
                                        props.fieldsValue.buh_amountMore
                                          .length + 1,
                                      ),
                                    },
                                  ],
                                }),
                              ),
                            );
                          }
                        }}
                        disabled={
                          (!props.accesses.directory.requests.updateAmount &&
                            (!props.accesses.requests.customerAccountNumber ||
                              (props.fieldsValue.customerAccountNumber &&
                                props.fieldsValue.customerAccountNumber
                                  .name))) ||
                          (!props.accesses.additionalPermissions
                            .allowEditingBuhAmount &&
                            props.fieldsValue.customerAccountStatus &&
                            props.fieldsValue.customerAccountStatus.name) ||
                          (!props.accesses.additionalPermissions
                            .allowEditingBuhAmount &&
                            props.fieldsValue.customerAccountDate &&
                            props.fieldsValue.customerAccountDate.name)
                        }
                      />
                    )}
                </div>
                <Input
                  placeholder="0.00"
                  value={
                    props.fieldsValue.buh_amount.name
                      ? numberWithCommas(
                          props.fieldsValue.buh_amount.name,
                          2,
                          props.activeEditNumberField === 'buh_amount',
                        )
                      : ''
                  }
                  changed={(value) => {
                    value = value.replace(',', '.');
                    value = value.split(' ').join('');
                    !isNaN(value) &&
                      props.changeField('buh_amount', value, 'input-number');
                  }}
                  active={() => {
                    props.clearAutocompleteValues();
                    props.setActiveEditNumberField('buh_amount');
                  }}
                  crop={2}
                  disabled={
                    (!props.accesses.directory.requests.updateAmount &&
                      (!props.accesses.requests.customerAccountNumber ||
                        (props.fieldsValue.customerAccountNumber &&
                          props.fieldsValue.customerAccountNumber.name))) ||
                    (!props.accesses.additionalPermissions
                      .allowEditingBuhAmount &&
                      props.fieldsValue.customerAccountStatus &&
                      props.fieldsValue.customerAccountStatus.name) ||
                    (!props.accesses.additionalPermissions
                      .allowEditingBuhAmount &&
                      props.fieldsValue.customerAccountDate &&
                      props.fieldsValue.customerAccountDate.name)
                  }
                />
              </ContainerValue>
            </div>
          ) : null}
          {props.accesses.requests.buh_amountMore &&
          props.fieldsValue.buh_amountMore &&
          props.fieldsValue.buh_amountMore.length > 0 ? (
            <>
              {props.fieldsValue.buh_amountMore.map((item, index) => (
                <>
                  <div>
                    <ContainerValue
                      onClick={() =>
                        props.scrollAndSetActiveSectionMouseAndKeyboard(
                          'customerservice',
                        )
                      }>
                      <div style={{ display: 'flex' }} key={item.idIndex}>
                        <ValueTitle>{`Сумма ${index + 1}`}</ValueTitle>
                        <DeleteFieldButton
                          onClick={() => {
                            props.changeBuhAmountMoreField(
                              'amount',
                              '',
                              item.idIndex,
                            );
                            const filteredBuhAmountMore = props.fieldsValue.buh_amountMore.filter(
                              (payer) => payer.idIndex !== item.idIndex,
                            );
                            setTimeout(() => {
                              props.setFieldsValue({
                                ...props.fieldsValue,
                                buh_amountMore: filteredBuhAmountMore,
                              });
                              props.setChangedDataRequest(
                                JSON.parse(
                                  JSON.stringify({
                                    ...props.fieldsValue,
                                    buh_amountMore: filteredBuhAmountMore,
                                  }),
                                ),
                              );
                            }, 0);
                          }}
                          disabled={
                            (!props.accesses.directory.requests.updateAmount &&
                              props.fieldsValue.buh_amountMore[index] &&
                              props.fieldsValue.buh_amountMore[index]
                                .accountNumber) ||
                            (!props.accesses.additionalPermissions
                              .allowEditingBuhAmount &&
                              props.fieldsValue.customerAccountStatus &&
                              props.fieldsValue.customerAccountStatus.name) ||
                            (!props.accesses.additionalPermissions
                              .allowEditingBuhAmount &&
                              props.fieldsValue.customerAccountDate &&
                              props.fieldsValue.customerAccountDate.name)
                          }
                        />
                      </div>
                      <Input
                        type="text"
                        placeholder="0.00"
                        value={
                          item.amount
                            ? numberWithCommas(
                                item.amount,
                                2,
                                props.activeEditNumberField ===
                                  `buh_amountMore${index + 1}`,
                              )
                            : ''
                        }
                        changed={(value) => {
                          value = value.replace(',', '.');
                          value = value.replace(/\s/g, '');
                          if (!isNaN(+value)) {
                            props.changeBuhAmountMoreField(
                              'amount',
                              value,
                              item.idIndex,
                            );
                          }
                        }}
                        active={() => {
                          props.clearAutocompleteValues();
                          props.setActiveEditNumberField(
                            `buh_amountMore${index + 1}`,
                          );
                        }}
                        crop={2}
                        disabled={
                          (!props.accesses.directory.requests.updateAmount &&
                            props.fieldsValue.buh_amountMore[index] &&
                            props.fieldsValue.buh_amountMore[index]
                              .accountNumber) ||
                          (!props.accesses.additionalPermissions
                            .allowEditingBuhAmount &&
                            props.fieldsValue.customerAccountStatus &&
                            props.fieldsValue.customerAccountStatus.name) ||
                          (!props.accesses.additionalPermissions
                            .allowEditingBuhAmount &&
                            props.fieldsValue.customerAccountDate &&
                            props.fieldsValue.customerAccountDate.name)
                        }
                      />
                    </ContainerValue>
                  </div>
                  <ContainerValue
                    onClick={() =>
                      props.scrollAndSetActiveSectionMouseAndKeyboard(
                        'customerservice',
                      )
                    }>
                    <ValueTitle>
                      {`Комментарий к сумме ${index + 1}`}
                    </ValueTitle>
                    <Input
                      type="text"
                      placeholder="Введите данные..."
                      value={item.comment}
                      changed={(value) =>
                        props.changeBuhAmountMoreField(
                          'comment',
                          value,
                          item.idIndex,
                        )
                      }
                      active={() => props.clearAutocompleteValues()}
                      btnDelete
                    />
                  </ContainerValue>
                </>
              ))}
            </>
          ) : null}
          {props.accesses.requests.manager ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Менеджер</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.manager &&
                  props.autocompleteValues.manager.length
                    ? props.autocompleteValues.manager
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.manager}
                autocomplete
                disabled
                placeholder="Выберите пункт..."
                onInputValueChange={(value) =>
                  props.changeField('manager', value, 'autocomplete')
                }
                onChange={(value) => props.selectedFieldValue('manager', value)}
                itemName="manager"
                fieldsWithErrors={props.fieldsWithErrors}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.managerByRegion ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Менеджер. Китай</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.managerByRegion &&
                  props.autocompleteValues.managerByRegion.length
                    ? props.autocompleteValues.managerByRegion
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.managerByRegion}
                autocomplete
                disabled
                placeholder="Выберите пункт..."
                onInputValueChange={(value) =>
                  props.changeField('managerByRegion', value, 'autocomplete')
                }
                onChange={(value) =>
                  props.selectedFieldValue('managerByRegion', value)
                }
                itemName="managerByRegion"
                fieldsWithErrors={props.fieldsWithErrors}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.note ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Примечание</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.note.name}
                changed={(value) => {
                  props.changeField('note', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
              />
            </ContainerValue>
          ) : null}
        </ContainerValues>
      </BasicInformationContainer>
      <CargoInformationContainer id="shipmentdepartment">
        {props.hasShipmentdepartment ? (
          <ContainerTitle>Отдел отправки</ContainerTitle>
        ) : null}
        <ContainerValues>
          {props.accesses.requests.plannedDispatchDate ? (
            <ContainerValueCalendar
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                  true,
                )
              }>
              <ValueTitle>Плановая дата отправки</ValueTitle>
              <InputMask
                mask="12-39-9999"
                formatChars={{
                  1: '[0-3]',
                  2: '[0-9]',
                  3: '[0-1]',
                  9: '[0-9]',
                }}
                value={
                  props.fieldsValue.plannedDispatchDate.name
                    ? props.fieldsValue.plannedDispatchDate.name.indexOf('-') >
                      2
                      ? moment(
                          props.fieldsValue.plannedDispatchDate.name,
                        ).format('DD-MM-YYYY')
                      : props.fieldsValue.plannedDispatchDate.name
                    : null
                }
                onChange={(event) => {
                  props.changeField(
                    'plannedDispatchDate',
                    event.target.value,
                    'input-date',
                  );
                  if (
                    event.target.value &&
                    event.target.value.indexOf('_') === -1 &&
                    event.target.value.length > 0
                  ) {
                    props.setPlannedDispatchDateCalendar(false);
                  } else {
                    props.setPlannedDispatchDateCalendar(true);
                  }
                }}
                placeholder="Выберите дату..."
                onFocus={() => {
                  props.setPlannedDispatchDateCalendar(true);
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent
                      {...inputProps}
                      valid
                      id="*shipmentdepartment"
                    />
                    <div tabIndex="0" />
                  </InputWrapper>
                )}
              </InputMask>
              {props.plannedDispatchDateCalendar && (
                <CalendarContainer
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      props.setPlannedDispatchDateCalendar(false);
                    }
                  }}>
                  <Calendar
                    onSelect={(value) =>
                      props.calendaeSelectHandler('plannedDispatchDate', value)
                    }
                    onClear={() => props.setPlannedDispatchDateCalendar(false)}
                    format={formatStr}
                    defaultValue={props.convertCalendarDate(
                      props.fieldsValue.plannedDispatchDate.name,
                    )}
                    locale={ruRu}
                    tabindex="0"
                  />
                </CalendarContainer>
              )}
              {props.plannedDispatchDateCalendar && (
                <CalendarMask
                  onClick={() => props.setPlannedDispatchDateCalendar(false)}
                />
              )}
            </ContainerValueCalendar>
          ) : null}
          {props.accesses.requests.actualDateDispatch ? (
            <ContainerValueCalendar
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Фактическая дата отправки</ValueTitle>
              <InputMask
                mask="12-39-9999"
                formatChars={{
                  1: '[0-3]',
                  2: '[0-9]',
                  3: '[0-1]',
                  9: '[0-9]',
                }}
                value={
                  props.fieldsValue.actualDateDispatch.name
                    ? props.fieldsValue.actualDateDispatch.name.indexOf('-') > 2
                      ? moment(
                          props.fieldsValue.actualDateDispatch.name,
                        ).format('DD-MM-YYYY')
                      : props.fieldsValue.actualDateDispatch.name
                    : null
                }
                onChange={(event) => {
                  props.changeField(
                    'actualDateDispatch',
                    event.target.value,
                    'input-date',
                  );
                  if (
                    event.target.value.indexOf('_') === -1 &&
                    event.target.value.length > 0
                  ) {
                    props.setActualDispatchDateCalendar(false);
                  } else {
                    props.setActualDispatchDateCalendar(true);
                  }
                }}
                placeholder="Выберите дату..."
                onFocus={() => {
                  props.setActualDispatchDateCalendar(true);
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent {...inputProps} valid />
                  </InputWrapper>
                )}
              </InputMask>
              {props.actualDispatchDateCalendar && (
                <CalendarContainer
                  className="calendar-container"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      props.setActualDispatchDateCalendar(false);
                    }
                  }}>
                  <Calendar
                    onSelect={(value) =>
                      props.calendaeSelectHandler('actualDateDispatch', value)
                    }
                    onClear={() => props.setActualDispatchDateCalendar(false)}
                    format={formatStr}
                    defaultValue={props.convertCalendarDate(
                      props.fieldsValue.actualDateDispatch.name,
                    )}
                    locale={ruRu}
                    tabindex="0"
                  />
                </CalendarContainer>
              )}
              {props.actualDispatchDateCalendar && (
                <CalendarMask
                  onClick={() => props.setActualDispatchDateCalendar(false)}
                />
              )}
            </ContainerValueCalendar>
          ) : null}
          {props.accesses.requests.railCarrier ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Перевозчик по ЖД</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.railCarrier &&
                  props.autocompleteValues.railCarrier.length
                    ? props.accesses.directory.railcarriers.findAll &&
                      props.accesses.directory.railcarriers.insert
                      ? [
                          ...props.autocompleteValues.railCarrier,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.railCarrier]
                    : props.accesses.directory.railcarriers.findAll &&
                      props.accesses.directory.railcarriers.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.railCarrier}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('railCarrier', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('railcarriers', 'railCarrier');
                  } else {
                    props.selectedFieldValue('railCarrier', value);
                  }
                }}
                itemName="railCarrier"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.railCarrierStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Статус перевозчик по ЖД</ValueTitle>
              <Checkbox
                value={props.fieldsValue.railCarrierStatus.name}
                onClickItem={(value) => {
                  props.changeField('railCarrierStatus', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.prrOnArrived ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>ПРР по прибытию</ValueTitle>
              <Checkbox
                value={props.fieldsValue.prrOnArrived.name}
                onClickItem={(value) => {
                  props.changeField('prrOnArrived', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.ownerContainer ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Собственник контейнера</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.ownerContainer &&
                  props.autocompleteValues.ownerContainer.length
                    ? props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                      ? [
                          ...props.autocompleteValues.ownerContainer,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.ownerContainer]
                    : props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.ownerContainer}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('ownerContainer', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('counterparties', 'ownerContainer');
                  } else {
                    props.selectedFieldValue('ownerContainer', value);
                  }
                }}
                itemName="ownerContainer"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.terminalStaging ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Терминал постановки</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.terminalStaging &&
                  props.autocompleteValues.terminalStaging.length
                    ? props.accesses.directory.terminals.findAll &&
                      props.accesses.directory.terminals.insert
                      ? [
                          ...props.autocompleteValues.terminalStaging,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.terminalStaging]
                    : props.accesses.directory.terminals.findAll &&
                      props.accesses.directory.terminals.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.terminalStaging}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('terminalStaging', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('terminals', 'terminalStaging');
                  } else {
                    props.selectedFieldValue('terminalStaging', value);
                  }
                }}
                itemName="terminalStaging"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.stationDeparture ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Станция отправления</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.stationDeparture &&
                  props.autocompleteValues.stationDeparture.length
                    ? props.accesses.directory.stations.findAll &&
                      props.accesses.directory.stations.insert
                      ? [
                          ...props.autocompleteValues.stationDeparture,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.stationDeparture]
                    : props.accesses.directory.stations.findAll &&
                      props.accesses.directory.stations.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.stationDeparture}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('stationDeparture', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('stations', 'stationDeparture');
                  } else {
                    props.selectedFieldValue('stationDeparture', value);
                  }
                }}
                itemName="stationDeparture"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.stationDestination ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Станция назначения</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.stationDestination &&
                  props.autocompleteValues.stationDestination.length
                    ? props.accesses.directory.stations.findAll &&
                      props.accesses.directory.stations.insert
                      ? [
                          ...props.autocompleteValues.stationDestination,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.stationDestination]
                    : props.accesses.directory.stations.findAll &&
                      props.accesses.directory.stations.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.stationDestination}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('stationDestination', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('stations', 'stationDestination');
                  } else {
                    props.selectedFieldValue('stationDestination', value);
                  }
                }}
                itemName="stationDestination"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.destinationCity ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Город назначения</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.destinationCity &&
                  props.autocompleteValues.destinationCity.length
                    ? props.accesses.directory.cities.findAll &&
                      props.accesses.directory.cities.insert
                      ? [
                          ...props.autocompleteValues.destinationCity,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.destinationCity]
                    : props.accesses.directory.cities.findAll &&
                      props.accesses.directory.cities.insert
                    ? [
                        { name: 'Ни одного города не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного города не найдено' }]
                }
                itemValue={props.fieldsValue.destinationCity}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('destinationCity', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('cities', 'destinationCity');
                  } else {
                    props.selectedFieldValue('destinationCity', value);
                  }
                }}
                itemName="destinationCity"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.loadDocumented ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Груз документально</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.loadDocumented &&
                  props.autocompleteValues.loadDocumented.length
                    ? props.accesses.directory.loads.findAll &&
                      props.accesses.directory.loads.insert
                      ? [
                          ...props.autocompleteValues.loadDocumented,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.loadDocumented]
                    : props.accesses.directory.loads.findAll &&
                      props.accesses.directory.loads.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.loadDocumented}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('loadDocumented', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('loads', 'loadDocumented');
                  } else {
                    props.selectedFieldValue('loadDocumented', value);
                  }
                }}
                itemName="loadDocumented"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.cargoCode ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Код груза</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.cargoCode.name}
                changed={(value) => {
                  props.changeField('cargoCode', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.instructionsDelivery ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Инструкция по сдаче контейнера</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.instructionsDelivery.name}
                changed={(value) => {
                  props.changeField(
                    'instructionsDelivery',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.releases ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Релизы</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.releases.name}
                changed={(value) => {
                  props.changeField('releases', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.security ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Охрана</ValueTitle>
              <Checkbox
                value={props.fieldsValue.security.name}
                onClickItem={(value) => {
                  props.changeField('security', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.warmingCompany ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Компания утепления</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.warmingCompany &&
                  props.autocompleteValues.warmingCompany.length
                    ? props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                      ? [
                          ...props.autocompleteValues.warmingCompany,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.warmingCompany]
                    : props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.warmingCompany}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('warmingCompany', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('counterparties', 'warmingCompany');
                  } else {
                    props.selectedFieldValue('warmingCompany', value);
                  }
                }}
                itemName="warmingCompany"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.companyNameForwarder ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Компания/имя экспедитор</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.companyNameForwarder &&
                  props.autocompleteValues.companyNameForwarder.length
                    ? props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                      ? [
                          ...props.autocompleteValues.companyNameForwarder,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.companyNameForwarder]
                    : props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.companyNameForwarder}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField(
                    'companyNameForwarder',
                    value,
                    'autocomplete',
                  )
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal(
                      'counterparties',
                      'companyNameForwarder',
                    );
                  } else {
                    props.selectedFieldValue('companyNameForwarder', value);
                  }
                }}
                itemName="companyNameForwarder"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.companyMoversLoading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Компания грузчики на погрузке</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.companyMoversLoading &&
                  props.autocompleteValues.companyMoversLoading.length
                    ? props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                      ? [
                          ...props.autocompleteValues.companyMoversLoading,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.companyMoversLoading]
                    : props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.companyMoversLoading}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField(
                    'companyMoversLoading',
                    value,
                    'autocomplete',
                  )
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal(
                      'counterparties',
                      'companyMoversLoading',
                    );
                  } else {
                    props.selectedFieldValue('companyMoversLoading', value);
                  }
                }}
                itemName="companyMoversLoading"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.containerAccessory ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Принадлежность контейнера</ValueTitle>
              <SelectDropList
                list={[{ name: 'Клиент' }, { name: 'Евросиб' }]}
                autocomplete={false}
                itemValue={props.fieldsValue.containerAccessory}
                onInputValueChange={() => {}}
                onChange={(value) =>
                  props.changeField('containerAccessory', value.name, 'select')
                }
                placeholder="Выберите тип"
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.attorneyPowerNumber ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Номер доверенности</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.attorneyPowerNumber.name}
                changed={(value) => {
                  props.changeField('attorneyPowerNumber', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.departureVesselDate ? (
            <ContainerValueCalendar
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Дата выхода судна</ValueTitle>
              <InputMask
                mask="12-39-9999"
                formatChars={{
                  1: '[0-3]',
                  2: '[0-9]',
                  3: '[0-1]',
                  9: '[0-9]',
                }}
                value={
                  props.fieldsValue.departureVesselDate.name
                    ? props.fieldsValue.departureVesselDate.name.indexOf('-') >
                      2
                      ? moment(
                          props.fieldsValue.departureVesselDate.name,
                        ).format('DD-MM-YYYY')
                      : props.fieldsValue.departureVesselDate.name
                    : null
                }
                onChange={(event) => {
                  props.changeField(
                    'departureVesselDate',
                    event.target.value,
                    'input-date',
                  );
                  if (
                    event.target.value.indexOf('_') === -1 &&
                    event.target.value.length > 0
                  ) {
                    props.setDepartureVesselDateCalendar(false);
                  } else {
                    props.setDepartureVesselDateCalendar(true);
                  }
                }}
                placeholder="Выберите дату..."
                onFocus={() => {
                  props.setDepartureVesselDateCalendar(true);
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent {...inputProps} valid />
                  </InputWrapper>
                )}
              </InputMask>
              {props.departureVesselDateCalendar && (
                <CalendarContainer
                  className="calendar-container"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      props.setDepartureVesselDateCalendar(false);
                    }
                  }}>
                  <Calendar
                    onSelect={(value) =>
                      props.calendaeSelectHandler('departureVesselDate', value)
                    }
                    onClear={() => props.setDepartureVesselDateCalendar(false)}
                    format={formatStr}
                    defaultValue={props.convertCalendarDate(
                      props.fieldsValue.departureVesselDate.name,
                    )}
                    locale={ruRu}
                    tabindex="0"
                  />
                </CalendarContainer>
              )}
              {props.departureVesselDateCalendar && (
                <CalendarMask
                  onClick={() => props.setDepartureVesselDateCalendar(false)}
                />
              )}
            </ContainerValueCalendar>
          ) : null}
          {props.accesses.requests.arrivalVesselDate ? (
            <ContainerValueCalendar
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Дата прибытия судна</ValueTitle>
              <InputMask
                mask="12-39-9999"
                formatChars={{
                  1: '[0-3]',
                  2: '[0-9]',
                  3: '[0-1]',
                  9: '[0-9]',
                }}
                value={
                  props.fieldsValue.arrivalVesselDate.name
                    ? props.fieldsValue.arrivalVesselDate.name.indexOf('-') > 2
                      ? moment(props.fieldsValue.arrivalVesselDate.name).format(
                          'DD-MM-YYYY',
                        )
                      : props.fieldsValue.arrivalVesselDate.name
                    : null
                }
                onChange={(event) => {
                  props.changeField(
                    'arrivalVesselDate',
                    event.target.value,
                    'input-date',
                  );
                  if (
                    event.target.value.indexOf('_') === -1 &&
                    event.target.value.length > 0
                  ) {
                    props.setArrivalVesselDateCalendar(false);
                  } else {
                    props.setArrivalVesselDateCalendar(true);
                  }
                }}
                placeholder="Выберите дату..."
                onFocus={() => {
                  props.setArrivalVesselDateCalendar(true);
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent {...inputProps} valid />
                  </InputWrapper>
                )}
              </InputMask>
              {props.arrivalVesselDateCalendar && (
                <CalendarContainer
                  className="calendar-container"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      props.setArrivalVesselDateCalendar(false);
                    }
                  }}>
                  <Calendar
                    onSelect={(value) =>
                      props.calendaeSelectHandler('arrivalVesselDate', value)
                    }
                    onClear={() => props.setArrivalVesselDateCalendar(false)}
                    format={formatStr}
                    defaultValue={props.convertCalendarDate(
                      props.fieldsValue.arrivalVesselDate.name,
                    )}
                    locale={ruRu}
                    tabindex="0"
                  />
                </CalendarContainer>
              )}
              {props.arrivalVesselDateCalendar && (
                <CalendarMask
                  onClick={() => props.setArrivalVesselDateCalendar(false)}
                />
              )}
            </ContainerValueCalendar>
          ) : null}
          {props.accesses.requests.notificationDate ? (
            <ContainerValueCalendar
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'shipmentdepartment',
                )
              }>
              <ValueTitle>Дата уведомления</ValueTitle>
              <InputMask
                mask="12-39-9999"
                formatChars={{
                  1: '[0-3]',
                  2: '[0-9]',
                  3: '[0-1]',
                  9: '[0-9]',
                }}
                value={
                  props.fieldsValue.notificationDate.name
                    ? props.fieldsValue.notificationDate.name.indexOf('-') > 2
                      ? moment(props.fieldsValue.notificationDate.name).format(
                          'DD-MM-YYYY',
                        )
                      : props.fieldsValue.notificationDate.name
                    : null
                }
                onChange={(event) => {
                  props.changeField(
                    'notificationDate',
                    event.target.value,
                    'input-date',
                  );
                  if (
                    event.target.value.indexOf('_') === -1 &&
                    event.target.value.length > 0
                  ) {
                    props.setNotificationDateCalendar(false);
                  } else {
                    props.setNotificationDateCalendar(true);
                  }
                }}
                placeholder="Выберите дату..."
                onFocus={() => {
                  props.setNotificationDateCalendar(true);
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent {...inputProps} valid />
                  </InputWrapper>
                )}
              </InputMask>
              {props.notificationDateCalendar && (
                <CalendarContainer
                  className="calendar-container"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      props.setNotificationDateCalendar(false);
                    }
                  }}>
                  <Calendar
                    onSelect={(value) =>
                      props.calendaeSelectHandler('notificationDate', value)
                    }
                    onClear={() => props.setNotificationDateCalendar(false)}
                    format={formatStr}
                    defaultValue={props.convertCalendarDate(
                      props.fieldsValue.notificationDate.name,
                    )}
                    locale={ruRu}
                    tabindex="0"
                  />
                </CalendarContainer>
              )}
              {props.notificationDateCalendar && (
                <CalendarMask
                  onClick={() => props.setNotificationDateCalendar(false)}
                />
              )}
            </ContainerValueCalendar>
          ) : null}
        </ContainerValues>
      </CargoInformationContainer>
      <AdditionalServicesContainer id="autodepartment">
        {props.hasAutodepartment ? (
          <ContainerTitle>Автоотдел</ContainerTitle>
        ) : null}
        <ContainerValues>
          {props.accesses.requests.numberContainer ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'autodepartment',
                  true,
                )
              }>
              <ValueTitle>№ контейнера</ValueTitle>
              <InputMask
                mask="aaaa 1111111"
                formatChars={{
                  a: '[a-zA-Z]',
                  1: '[0-9]',
                }}
                placeholder="Введите данные..."
                alwaysShowMask={false}
                value={props.fieldsValue.numberContainer.name}
                onChange={(event) => {
                  if (event.target.value === '') {
                    props.setChangedFields([
                      ...props.changedFields,
                      'numberContainer',
                    ]);
                    return;
                  }
                  props.changeField(
                    'numberContainer',
                    event.target.value.toUpperCase(),
                    'input-text',
                  );
                  if (event.target.value.indexOf('_') === -1) {
                    props.changeField('state', 'В пути');
                    props.changeField(
                      'numberContainer',
                      event.target.value.toUpperCase(),
                      'input-text',
                    );
                  } else {
                    props.changeField('state', 'Предварительное');
                  }
                  if (
                    props.fieldsValue.dateArrival.name &&
                    props.fieldsValue.dateArrival.name.indexOf('_') === -1
                  ) {
                    props.changeField('state', 'Прибыл');
                    props.changeField(
                      'numberContainer',
                      event.target.value.toUpperCase(),
                      'input-text',
                    );
                  }
                  if (
                    props.fieldsValue.dateIssue.name &&
                    props.fieldsValue.dateIssue.name.indexOf('_') === -1
                  ) {
                    const dateStr = props.fieldsValue.dateIssue.name
                      .split('-')
                      .reverse()
                      .join('-');
                    const date = new Date(dateStr);
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setMilliseconds(0);
                    const today = new Date();
                    today.setHours(0);
                    today.setMinutes(0);
                    today.setSeconds(0);
                    today.setMilliseconds(0);
                    if (date.getTime() <= today.getTime()) {
                      props.changeField('state', 'Выдан клиенту');
                      props.changeField(
                        'numberContainer',
                        event.target.value.toUpperCase(),
                        'input-text',
                      );
                    }
                  }
                }}
                onFocus={() => {
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent
                      {...inputProps}
                      valid
                      id="*autodepartment"
                    />
                  </InputWrapper>
                )}
              </InputMask>
            </ContainerValue>
          ) : null}
          {props.accesses.requests.numberSeal ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'autodepartment',
                )
              }>
              <ValueTitle>№ пломбы</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.numberSeal.name}
                changed={(value) => {
                  props.changeField('numberSeal', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.agentAutoLoad ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'autodepartment',
                )
              }>
              <ValueTitle>Агент авто на загрузке</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.agentAutoLoad &&
                  props.autocompleteValues.agentAutoLoad.length
                    ? props.accesses.directory.autoAgents.findAll &&
                      props.accesses.directory.autoAgents.insert
                      ? [
                          ...props.autocompleteValues.agentAutoLoad,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.agentAutoLoad]
                    : props.accesses.directory.autoAgents.findAll &&
                      props.accesses.directory.autoAgents.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.agentAutoLoad}
                autocomplete
                placeholder="Выберите пункт..."
                onInputValueChange={(value) =>
                  props.changeField('agentAutoLoad', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('autoAgents', 'agentAutoLoad');
                  } else {
                    props.selectedFieldValue('agentAutoLoad', value);
                  }
                }}
                itemName="agentAutoLoad"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.driver ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'autodepartment',
                )
              }>
              <ValueTitle>Водитель</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.driver &&
                  props.autocompleteValues.driver.length
                    ? props.accesses.directory.drivers.findAll &&
                      props.accesses.directory.drivers.insert
                      ? [
                          ...props.autocompleteValues.driver,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.driver]
                    : props.accesses.directory.drivers.findAll &&
                      props.accesses.directory.drivers.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.driver}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('driver', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('drivers', 'driver');
                  } else {
                    props.selectedFieldValue('driver', value);
                  }
                }}
                itemName="driver"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.unloadingDriverTaskStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'customerservice',
                )
              }>
              <ValueTitle>Статус выгрузки задания</ValueTitle>
              <Checkbox
                value={props.fieldsValue.unloadingDriverTaskStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'unloadingDriverTaskStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.driverOld ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'autodepartment',
                )
              }>
              <ValueTitle>Водитель (старый)</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.driverOld.name}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.car ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'autodepartment',
                )
              }>
              <ValueTitle>Машина</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.car &&
                  props.autocompleteValues.car.length
                    ? props.accesses.directory.cars.findAll &&
                      props.accesses.directory.cars.insert
                      ? [
                          ...props.autocompleteValues.car,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.car]
                    : props.accesses.directory.cars.findAll &&
                      props.accesses.directory.cars.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.car}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('car', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('cars', 'car');
                  } else {
                    props.selectedFieldValue('car', value);
                  }
                }}
                itemName="car"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.trailer ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'autodepartment',
                )
              }>
              <ValueTitle>Прицеп</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.trailer &&
                  props.autocompleteValues.trailer.length
                    ? props.accesses.directory.trailers.findAll &&
                      props.accesses.directory.trailers.insert
                      ? [
                          ...props.autocompleteValues.trailer,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.trailer]
                    : props.accesses.directory.trailers.findAll &&
                      props.accesses.directory.trailers.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.trailer}
                autocomplete
                placeholder="Выберите пункт..."
                onInputValueChange={(value) =>
                  props.changeField('trailer', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('trailers', 'trailer');
                  } else {
                    props.selectedFieldValue('trailer', value);
                  }
                }}
                itemName="trailer"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
        </ContainerValues>
      </AdditionalServicesContainer>
      <CountingContainer id="documentdepartment">
        {props.hasDocumentdepartment ? (
          <ContainerTitle>Отдел вывоз в регионы/документы</ContainerTitle>
        ) : null}
        <ContainerValues>
          {props.accesses.requests.agentAutoCall ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                  true,
                )
              }
              onFocus={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                  true,
                )
              }>
              <ValueTitle>Агент по вывозу</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.agentAutoCall &&
                  props.autocompleteValues.agentAutoCall.length
                    ? props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                      ? [
                          ...props.autocompleteValues.agentAutoCall,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.agentAutoCall]
                    : props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.agentAutoCall}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('agentAutoCall', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal('counterparties', 'agentAutoCall');
                  } else {
                    props.selectedFieldValue('agentAutoCall', value);
                  }
                }}
                id="*documentdepartment"
                itemName="agentAutoCall"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.agentAutoCallStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>Статус агент по вывозу</ValueTitle>
              <Checkbox
                value={props.fieldsValue.agentAutoCallStatus.name}
                onClickItem={(value) => {
                  props.changeField('agentAutoCallStatus', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.exportAgentEurosib ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>Агент по вывозу (Амикс)</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.exportAgentEurosib &&
                  props.autocompleteValues.exportAgentEurosib.length
                    ? props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                      ? [
                          ...props.autocompleteValues.exportAgentEurosib,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.exportAgentEurosib]
                    : props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.exportAgentEurosib}
                placeholder="Выберите пункт..."
                autocomplete
                onInputValueChange={(value) =>
                  props.changeField('exportAgentEurosib', value, 'autocomplete')
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal(
                      'counterparties',
                      'exportAgentEurosib',
                    );
                  } else {
                    props.selectedFieldValue('exportAgentEurosib', value);
                  }
                }}
                itemName="exportAgentEurosib"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.companyMoversUnloading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>Компания грузчики на выгрузке</ValueTitle>
              <SelectDropList
                list={
                  props.autocompleteValues.companyMoversUnloading &&
                  props.autocompleteValues.companyMoversUnloading.length
                    ? props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                      ? [
                          ...props.autocompleteValues.companyMoversUnloading,
                          { name: '+ создать справочник' },
                        ]
                      : [...props.autocompleteValues.companyMoversUnloading]
                    : props.accesses.directory.counterparties.findAll &&
                      props.accesses.directory.counterparties.insert
                    ? [
                        { name: 'Ни одного справочника не найдено' },
                        { name: '+ создать справочник' },
                      ]
                    : [{ name: 'Ни одного справочника не найдено' }]
                }
                itemValue={props.fieldsValue.companyMoversUnloading}
                autocomplete
                placeholder="Выберите пункт..."
                onInputValueChange={(value) =>
                  props.changeField(
                    'companyMoversUnloading',
                    value,
                    'autocomplete',
                  )
                }
                onChange={(value) => {
                  if (value.name === 'Ни одного справочника не найдено') {
                  } else if (value.name === '+ создать справочник') {
                    props.createFormModal(
                      'counterparties',
                      'companyMoversUnloading',
                    );
                  } else {
                    props.selectedFieldValue('companyMoversUnloading', value);
                  }
                }}
                itemName="companyMoversUnloading"
                fieldsWithErrors={props.fieldsWithErrors}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.dateIssue ? (
            <ContainerValueCalendar
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>Дата выдачи</ValueTitle>
              <InputMask
                mask="12-39-9999"
                formatChars={{
                  1: '[0-3]',
                  2: '[0-9]',
                  3: '[0-1]',
                  9: '[0-9]',
                }}
                value={
                  props.fieldsValue.dateIssue.name
                    ? props.fieldsValue.dateIssue.name.indexOf('-') > 2
                      ? moment(props.fieldsValue.dateIssue.name).format(
                          'DD-MM-YYYY',
                        )
                      : props.fieldsValue.dateIssue.name
                    : null
                }
                onChange={(event) => {
                  props.changeField(
                    'dateIssue',
                    event.target.value,
                    'input-date',
                  );
                  if (
                    event.target.value.indexOf('_') === -1 &&
                    event.target.value.length > 0
                  ) {
                    const dateStr = event.target.value
                      .split('-')
                      .reverse()
                      .join('-');
                    const date = new Date(dateStr);
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setMilliseconds(0);
                    const today = new Date();
                    today.setHours(0);
                    today.setMinutes(0);
                    today.setSeconds(0);
                    today.setMilliseconds(0);
                    if (date.getTime() <= today.getTime()) {
                      setTimeout(
                        () => props.changeField('state', 'Выдан клиенту'),
                        0,
                      );
                    }
                    if (date.getTime() > today.getTime()) {
                      setTimeout(
                        () => props.changeField('state', props.stateClaim),
                        0,
                      );
                      if (props.listState.length >= 1) {
                        setTimeout(
                          () =>
                            props.changeField(
                              'state',
                              props.listState[props.listState.length - 1],
                            ),
                          0,
                        );
                      }
                      if (
                        !props.listState.length &&
                        props.fieldsValue.numberContainer &&
                        props.fieldsValue.numberContainer.name
                      ) {
                        setTimeout(
                          () => props.changeField('state', 'В пути'),
                          0,
                        );
                      }
                      if (
                        !props.listState.length &&
                        props.fieldsValue.dateArrival &&
                        props.fieldsValue.dateArrival.name
                      ) {
                        setTimeout(
                          () => props.changeField('state', 'Прибыл'),
                          0,
                        );
                      }
                    }
                    props.setStatusIssueCalendar(false);
                  } else {
                    props.setStatusIssueCalendar(true);
                    if (event.target.value !== '') {
                      if (
                        props.listState.length >= 2 &&
                        props.listState[props.listState.length - 1] ===
                          'Выдан клиенту'
                      ) {
                        setTimeout(
                          () =>
                            props.changeField(
                              'state',
                              props.listState[props.listState.length - 2],
                            ),
                          0,
                        );
                      }
                      if (
                        !props.listState.length &&
                        props.fieldsValue.numberContainer &&
                        props.fieldsValue.numberContainer.name
                      ) {
                        setTimeout(
                          () => props.changeField('state', 'В пути'),
                          0,
                        );
                      }
                      if (
                        !props.listState.length &&
                        props.fieldsValue.dateArrival &&
                        props.fieldsValue.dateArrival.name
                      ) {
                        setTimeout(
                          () => props.changeField('state', 'Прибыл'),
                          0,
                        );
                      }
                    }
                  }
                }}
                placeholder="Выберите дату..."
                onFocus={() => {
                  props.setStatusIssueCalendar(true);
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent {...inputProps} valid />
                  </InputWrapper>
                )}
              </InputMask>

              {props.statusIssueCalendar && (
                <CalendarContainer
                  className="calendar-container"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      props.setStatusIssueCalendar(false);
                    }
                  }}>
                  <Calendar
                    onSelect={(value) => {
                      if (!value) return;
                      props.calendaeSelectHandler('dateIssue', value);
                      const date = new Date(value._d);
                      date.setHours(0);
                      date.setMinutes(0);
                      date.setSeconds(0);
                      date.setMilliseconds(0);
                      const today = new Date();
                      today.setHours(0);
                      today.setMinutes(0);
                      today.setSeconds(0);
                      today.setMilliseconds(0);
                      if (date.getTime() <= today.getTime()) {
                        setTimeout(
                          () => props.changeField('state', 'Выдан клиенту'),
                          0,
                        );
                      }
                      if (date.getTime() > today.getTime()) {
                        setTimeout(
                          () => props.changeField('state', props.stateClaim),
                          0,
                        );
                        if (props.listState.length >= 1) {
                          setTimeout(
                            () =>
                              props.changeField(
                                'state',
                                props.listState[props.listState.length - 1],
                              ),
                            0,
                          );
                        }
                      }
                    }}
                    onClear={() => props.setStatusIssueCalendar(false)}
                    format={formatStr}
                    defaultValue={props.convertCalendarDate(
                      props.fieldsValue.dateIssue.name,
                    )}
                    locale={ruRu}
                    tabindex="0"
                  />
                </CalendarContainer>
              )}
              {props.statusIssueCalendar && (
                <CalendarMask
                  onClick={() => props.setStatusIssueCalendar(false)}
                />
              )}
            </ContainerValueCalendar>
          ) : null}
          {props.accesses.requests.timeIssue ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>Время выдачи(фактическое)</ValueTitle>
              <InputMask
                mask="12:34"
                formatChars={{
                  1: /[0-2]/,
                  2: /[0-9]/,
                  3: /[0-5]/,
                  4: /[0-9]/,
                }}
                placeholder="Введите время..."
                alwaysShowMask={false}
                value={props.fieldsValue.timeIssue.name}
                onChange={(event) => {
                  props.changeField(
                    'timeIssue',
                    event.target.value,
                    'input-text',
                  );
                }}
                onFocus={() => {
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent
                      {...inputProps}
                      valid
                      id="*documentdepartment"
                    />
                  </InputWrapper>
                )}
              </InputMask>
            </ContainerValue>
          ) : null}
          {props.accesses.requests.returnOfDocumentsFromUnloading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>Возврат документов с выгрузки</ValueTitle>
              <SelectDropList
                list={[
                  { name: 'Вернуть в офис' },
                  { name: 'Вернуть в офис 2 адр.' },
                  { name: 'Вернуть в офис 3 адр.' },
                  { name: 'Вернуть в офис 4 адр.' },
                  { name: 'Сканы есть' },
                  { name: 'Неполный комплект' },
                  { name: 'Документы в офисе' },
                  { name: 'Оригиналы в офисе' },
                  { name: 'Документы передали' },
                  { name: 'Документы передали/оригиналы в офисе' },
                  { name: 'Документы передали/сканы есть' },
                  { name: 'Забрал сам клиент' },
                ]}
                itemValue={props.fieldsValue.returnOfDocumentsFromUnloading}
                autocomplete={false}
                onInputValueChange={() => {}}
                onChange={(value) =>
                  props.changeField(
                    'returnOfDocumentsFromUnloading',
                    value.name,
                    'select',
                  )
                }
                placeholder="Выберите вид"
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.act ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>Акт</ValueTitle>
              <Checkbox
                value={props.fieldsValue.act.name}
                onClickItem={(value) => {
                  props.changeField('act', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.railwayInvoices ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>ЖД накладные</ValueTitle>
              <Checkbox
                value={props.fieldsValue.railwayInvoices.name}
                onClickItem={(value) => {
                  props.changeField('railwayInvoices', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.loading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>Погрузка</ValueTitle>
              <Checkbox
                value={props.fieldsValue.loading.name}
                onClickItem={(value) => {
                  props.changeField('loading', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.loadingTN ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>ТТН погрузка</ValueTitle>
              <Checkbox
                value={props.fieldsValue.loadingTN.name}
                onClickItem={(value) => {
                  props.changeField('loadingTN', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.unloadingTN ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>ТТН выгрузка</ValueTitle>
              <Checkbox
                value={props.fieldsValue.unloadingTN.name}
                onClickItem={(value) => {
                  props.changeField('unloadingTN', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.documentStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>Наименование документа</ValueTitle>
              <SelectDropList
                list={[
                  { name: 'ТвН' },
                  { name: 'ТТН' },
                  { name: '2ТТН' },
                  { name: 'ТрН' },
                  { name: 'ТрН (особ.)' },
                  { name: 'ТТН, ТрН' },
                  { name: 'ТвН/ТОРГ-13, ТрН' },
                  { name: 'ТТН, ТрН, доверенность' },
                  { name: 'ТТН, ТрН, Экспедиторская расписка' },
                ]}
                itemValue={props.fieldsValue.documentStatus}
                autocomplete={false}
                onInputValueChange={() => {}}
                onChange={(value) =>
                  props.changeField('documentStatus', value.name, 'select')
                }
                placeholder="Выберите тип"
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.timofeevo ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>Тимофеево</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.timofeevo.name}
                changed={(value) => {
                  props.changeField('timofeevo', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.timurovo ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'documentdepartment',
                )
              }>
              <ValueTitle>Тимурово</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.timurovo.name}
                changed={(value) => {
                  props.changeField('timurovo', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
        </ContainerValues>
      </CountingContainer>
      <CountingContainer id="financialdepartment">
        {props.hasFinancialdepartment ? (
          <ContainerTitle>Финансовый отдел</ContainerTitle>
        ) : null}
        <ContainerValues>
          {props.accesses.requests.buh_carrier ? (
            <div>
              <ContainerValue
                onClick={() =>
                  props.scrollAndSetActiveSectionMouseAndKeyboard(
                    'financialdepartment',
                    true,
                  )
                }>
                <div style={{ display: 'flex' }}>
                  <ValueTitle>Перевозчик</ValueTitle>
                  {props.fieldsValue.buh_carrier.name > 0 && (
                    <AddFieldButton
                      onClick={() => {
                        const generateId = (idIndex) => {
                          if (
                            !props.fieldsValue.buh_carrierMore.find(
                              (item) => item.idIndex === idIndex,
                            ) &&
                            idIndex >
                              props.fieldsValue.buh_carrierMore[
                                props.fieldsValue.buh_carrierMore.length - 1
                              ].idIndex
                          ) {
                            return idIndex;
                          }
                          return generateId(idIndex + 1);
                        };
                        if (props.fieldsValue.buh_carrierMore.length === 0) {
                          props.setFieldsValue({
                            ...props.fieldsValue,
                            buh_carrierMore: [
                              ...props.fieldsValue.buh_carrierMore,
                              { idIndex: 1 },
                            ],
                          });
                          props.setChangedDataRequest(
                            JSON.parse(
                              JSON.stringify({
                                ...props.fieldsValue,
                                buh_carrierMore: [
                                  ...props.fieldsValue.buh_carrierMore,
                                  { idIndex: 1 },
                                ],
                              }),
                            ),
                          );
                        } else {
                          props.setFieldsValue({
                            ...props.fieldsValue,
                            buh_carrierMore: [
                              ...props.fieldsValue.buh_carrierMore,
                              {
                                idIndex: generateId(
                                  props.fieldsValue.buh_carrierMore.length + 1,
                                ),
                              },
                            ],
                          });
                          props.setChangedDataRequest(
                            JSON.parse(
                              JSON.stringify({
                                ...props.fieldsValue,
                                buh_carrierMore: [
                                  ...props.fieldsValue.buh_carrierMore,
                                  {
                                    idIndex: generateId(
                                      props.fieldsValue.buh_carrierMore.length +
                                        1,
                                    ),
                                  },
                                ],
                              }),
                            ),
                          );
                        }
                      }}
                    />
                  )}
                </div>
                <Input
                  placeholder="0.00"
                  value={
                    props.fieldsValue.buh_carrier.name
                      ? numberWithCommas(
                          props.fieldsValue.buh_carrier.name,
                          2,
                          props.activeEditNumberField === 'buh_carrier',
                        )
                      : ''
                  }
                  changed={(value) => {
                    value = value.replace(',', '.');
                    !isNaN(value.split(' ').join('')) &&
                      props.changeField(
                        'buh_carrier',
                        value.split(' ').join(''),
                        'input-text',
                      );
                  }}
                  active={() => {
                    props.clearAutocompleteValues();
                    props.setActiveEditNumberField('buh_carrier');
                  }}
                  crop={2}
                  id="*financialdepartment"
                />
              </ContainerValue>
            </div>
          ) : null}
          {props.accesses.requests.buh_carrierMore &&
          props.fieldsValue.buh_carrierMore &&
          props.fieldsValue.buh_carrierMore.length ? (
            <>
              {props.fieldsValue.buh_carrierMore.map((item, index) => (
                <>
                  <div>
                    <ContainerValue
                      onClick={() =>
                        props.scrollAndSetActiveSectionMouseAndKeyboard(
                          'financialdepartment',
                        )
                      }>
                      <div style={{ display: 'flex' }} key={item.idIndex}>
                        <ValueTitle>
                          {`Сумма перевозчик ${index + 1}`}
                        </ValueTitle>
                        <DeleteFieldButton
                          onClick={() => {
                            props.changeBuhMore(
                              'buh_carrierMore',
                              'amount',
                              '',
                              item.idIndex,
                            );
                            const filteredBuhMore = props.fieldsValue.buh_carrierMore.filter(
                              (elem) => elem.idIndex !== item.idIndex,
                            );
                            setTimeout(() => {
                              props.setFieldsValue({
                                ...props.fieldsValue,
                                buh_carrierMore: filteredBuhMore,
                              });
                              props.setChangedDataRequest(
                                JSON.parse(
                                  JSON.stringify({
                                    ...props.fieldsValue,
                                    buh_carrierMore: filteredBuhMore,
                                  }),
                                ),
                              );
                            }, 0);
                          }}
                        />
                      </div>
                      <Input
                        type="text"
                        placeholder="0.00"
                        value={
                          item.amount
                            ? numberWithCommas(
                                item.amount,
                                2,
                                props.activeEditNumberField ===
                                  `buh_carrierMore${index + 1}`,
                              )
                            : ''
                        }
                        changed={(value) => {
                          value = value.replace(',', '.');
                          value = value.replace(/\s/g, '');
                          if (!isNaN(+value)) {
                            props.changeBuhMore(
                              'buh_carrierMore',
                              'amount',
                              value,
                              item.idIndex,
                            );
                          }
                        }}
                        active={() => {
                          props.clearAutocompleteValues();
                          props.setActiveEditNumberField(
                            `buh_carrierMore${index + 1}`,
                          );
                        }}
                        crop={2}
                      />
                    </ContainerValue>
                  </div>
                </>
              ))}
            </>
          ) : null}
          {props.accesses.requests.buh_containerAgent ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Контейнерный агент</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_containerAgent.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_containerAgent.name,
                        2,
                        props.activeEditNumberField === 'buh_containerAgent',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  if (value.split('-').length === 2 && value.split('-')[0])
                    return;
                  (!isNaN(+value.split(' ').join('')) || value === '-') &&
                    props.changeField(
                      'buh_containerAgent',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_containerAgent');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_autoBeforeLoading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Авто до погрузки</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_autoBeforeLoading.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_autoBeforeLoading.name,
                        2,
                        props.activeEditNumberField === 'buh_autoBeforeLoading',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_autoBeforeLoading',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_autoBeforeLoading');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_forMKAD_rate ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>За МКАД/ставка</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_forMKAD_rate.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_forMKAD_rate.name,
                        2,
                        props.activeEditNumberField === 'buh_forMKAD_rate',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_forMKAD_rate',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (props.accesses.requests.buh_forMKAD) {
                    if (props.fieldsValue.buh_forMKAD_km.name) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_forMKAD',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue.buh_forMKAD_km.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_forMKAD_rate');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_forMKAD_km ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>За МКАД/км</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_forMKAD_km.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_forMKAD_km.name,
                        2,
                        props.activeEditNumberField === 'buh_forMKAD_km',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_forMKAD_km',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (props.accesses.requests.buh_forMKAD) {
                    if (props.fieldsValue.buh_forMKAD_rate.name) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_forMKAD',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue.buh_forMKAD_rate.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_forMKAD_km');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_forMKAD ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>За МКАД/итого</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_forMKAD.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_forMKAD.name,
                        2,
                        props.activeEditNumberField === 'buh_forMKAD',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_forMKAD',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (!isNaN(value.split(' ').join('')) && !value) {
                    setTimeout(() => {
                      props.changeField(
                        'buh_forMKAD_rate',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                    setTimeout(() => {
                      props.changeField(
                        'buh_forMKAD_km',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_forMKAD');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.additionalLoadingAddress ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Дополнительный адрес погрузки</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.additionalLoadingAddress.name
                    ? numberWithCommas(
                        props.fieldsValue.additionalLoadingAddress.name,
                        2,
                        props.activeEditNumberField ===
                          'additionalLoadingAddress',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'additionalLoadingAddress',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('additionalLoadingAddress');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_exportAgent ? (
            <div>
              <ContainerValue
                onClick={() =>
                  props.scrollAndSetActiveSectionMouseAndKeyboard(
                    'financialdepartment',
                  )
                }>
                <div style={{ display: 'flex' }}>
                  <ValueTitle>Агент по вывозу</ValueTitle>
                  {props.fieldsValue.buh_exportAgent.name > 0 && (
                    <AddFieldButton
                      onClick={() => {
                        const generateId = (idIndex) => {
                          if (
                            !props.fieldsValue.buh_exportAgentMore.find(
                              (item) => item.idIndex === idIndex,
                            ) &&
                            idIndex >
                              props.fieldsValue.buh_exportAgentMore[
                                props.fieldsValue.buh_exportAgentMore.length - 1
                              ].idIndex
                          ) {
                            return idIndex;
                          }
                          return generateId(idIndex + 1);
                        };
                        if (
                          props.fieldsValue.buh_exportAgentMore.length === 0
                        ) {
                          props.setFieldsValue({
                            ...props.fieldsValue,
                            buh_exportAgentMore: [
                              ...props.fieldsValue.buh_exportAgentMore,
                              { idIndex: 1 },
                            ],
                          });
                          props.setChangedDataRequest(
                            JSON.parse(
                              JSON.stringify({
                                ...props.fieldsValue,
                                buh_exportAgentMore: [
                                  ...props.fieldsValue.buh_exportAgentMore,
                                  { idIndex: 1 },
                                ],
                              }),
                            ),
                          );
                        } else {
                          props.setFieldsValue({
                            ...props.fieldsValue,
                            buh_exportAgentMore: [
                              ...props.fieldsValue.buh_exportAgentMore,
                              {
                                idIndex: generateId(
                                  props.fieldsValue.buh_exportAgentMore.length +
                                    1,
                                ),
                              },
                            ],
                          });
                          props.setChangedDataRequest(
                            JSON.parse(
                              JSON.stringify({
                                ...props.fieldsValue,
                                buh_exportAgentMore: [
                                  ...props.fieldsValue.buh_exportAgentMore,
                                  {
                                    idIndex: generateId(
                                      props.fieldsValue.buh_exportAgentMore
                                        .length + 1,
                                    ),
                                  },
                                ],
                              }),
                            ),
                          );
                        }
                      }}
                    />
                  )}
                </div>
                <Input
                  placeholder="0.00"
                  value={
                    props.fieldsValue.buh_exportAgent.name
                      ? numberWithCommas(
                          props.fieldsValue.buh_exportAgent.name,
                          2,
                          props.activeEditNumberField === 'buh_exportAgent',
                        )
                      : ''
                  }
                  changed={(value) => {
                    value = value.replace(',', '.');
                    !isNaN(value.split(' ').join('')) &&
                      props.changeField(
                        'buh_exportAgent',
                        value.split(' ').join(''),
                        'input-number',
                      );
                  }}
                  active={() => {
                    props.clearAutocompleteValues();
                    props.setActiveEditNumberField('buh_exportAgent');
                  }}
                  crop={2}
                />
              </ContainerValue>
            </div>
          ) : null}
          {props.accesses.requests.buh_exportAgentMore &&
          props.fieldsValue.buh_exportAgentMore &&
          props.fieldsValue.buh_exportAgentMore.length ? (
            <>
              {props.fieldsValue.buh_exportAgentMore.map((item, index) => (
                <>
                  <div>
                    <ContainerValue
                      onClick={() =>
                        props.scrollAndSetActiveSectionMouseAndKeyboard(
                          'financialdepartment',
                        )
                      }>
                      <div style={{ display: 'flex' }} key={item.idIndex}>
                        <ValueTitle>
                          {`Сумма агент по вывозу ${index + 1}`}
                        </ValueTitle>
                        <DeleteFieldButton
                          onClick={() => {
                            props.changeBuhMore(
                              'buh_exportAgentMore',
                              'amount',
                              '',
                              item.idIndex,
                            );
                            const filteredBuhMore = props.fieldsValue.buh_exportAgentMore.filter(
                              (elem) => elem.idIndex !== item.idIndex,
                            );
                            setTimeout(() => {
                              props.setFieldsValue({
                                ...props.fieldsValue,
                                buh_exportAgentMore: filteredBuhMore,
                              });
                              props.setChangedDataRequest(
                                JSON.parse(
                                  JSON.stringify({
                                    ...props.fieldsValue,
                                    buh_exportAgentMore: filteredBuhMore,
                                  }),
                                ),
                              );
                            }, 0);
                          }}
                        />
                      </div>
                      <Input
                        type="text"
                        placeholder="0.00"
                        value={
                          item.amount
                            ? numberWithCommas(
                                item.amount,
                                2,
                                props.activeEditNumberField ===
                                  `buh_exportAgentMore${index + 1}`,
                              )
                            : ''
                        }
                        changed={(value) => {
                          value = value.replace(',', '.');
                          value = value.replace(/\s/g, '');
                          if (!isNaN(+value)) {
                            props.changeBuhMore(
                              'buh_exportAgentMore',
                              'amount',
                              value,
                              item.idIndex,
                            );
                          }
                        }}
                        active={() => {
                          props.clearAutocompleteValues();
                          props.setActiveEditNumberField(
                            `buh_exportAgentMore${index + 1}`,
                          );
                        }}
                        crop={2}
                      />
                    </ContainerValue>
                  </div>
                </>
              ))}
            </>
          ) : null}
          {props.accesses.requests.buh_forwarderOnLoading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Экспедитор на погрузке</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_forwarderOnLoading.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_forwarderOnLoading.name,
                        2,
                        props.activeEditNumberField ===
                          'buh_forwarderOnLoading',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_forwarderOnLoading',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_forwarderOnLoading');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_warming ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Утепление</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_warming.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_warming.name,
                        2,
                        props.activeEditNumberField === 'buh_warming',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_warming',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_warming');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_gasketMaterial ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Прокладочный материал</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_gasketMaterial.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_gasketMaterial.name,
                        2,
                        props.activeEditNumberField === 'buh_gasketMaterial',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_gasketMaterial',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_gasketMaterial');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_moversOnLoading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Грузчики на погрузке</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_moversOnLoading.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_moversOnLoading.name,
                        2,
                        props.activeEditNumberField === 'buh_moversOnLoading',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_moversOnLoading',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_moversOnLoading');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_moversAtUnloading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Грузчики на выгрузке</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_moversAtUnloading.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_moversAtUnloading.name,
                        2,
                        props.activeEditNumberField === 'buh_moversAtUnloading',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_moversAtUnloading',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_moversAtUnloading');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_veterinaryCertificate ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Ветеринарное свидетельство</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_veterinaryCertificate.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_veterinaryCertificate.name,
                        2,
                        props.activeEditNumberField ===
                          'buh_veterinaryCertificate',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_veterinaryCertificate',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_veterinaryCertificate');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_plain_rate ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Простой/ставка</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_plain_rate.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_plain_rate.name,
                        2,
                        props.activeEditNumberField === 'buh_plain_rate',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_plain_rate',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (props.accesses.requests.buh_plain) {
                    if (props.fieldsValue.buh_plain_hour.name) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_plain',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue.buh_plain_hour.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_plain_rate');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_plain_hour ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Простой/час</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_plain_hour.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_plain_hour.name,
                        2,
                        props.activeEditNumberField === 'buh_plain_hour',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_plain_hour',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (props.accesses.requests.buh_plain) {
                    if (props.fieldsValue.buh_plain_rate.name) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_plain',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue.buh_plain_rate.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_plain_hour');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_plain ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Простой/итого</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_plain.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_plain.name,
                        2,
                        props.activeEditNumberField === 'buh_plain',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_plain',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (!isNaN(value.split(' ').join('')) && !value) {
                    setTimeout(() => {
                      props.changeField(
                        'buh_plain_rate',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                    setTimeout(() => {
                      props.changeField(
                        'buh_plain_hour',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_plain');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_overload_rate ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Перегруз/ставка</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_overload_rate.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_overload_rate.name,
                        2,
                        props.activeEditNumberField === 'buh_overload_rate',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_overload_rate',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (props.accesses.requests.buh_overload) {
                    if (props.fieldsValue.buh_overload_ton.name) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_overload',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue.buh_overload_ton.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_overload_rate');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_overload_ton ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Перегруз/тонна</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_overload_ton.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_overload_ton.name,
                        2,
                        props.activeEditNumberField === 'buh_overload_ton',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_overload_ton',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (props.accesses.requests.buh_overload) {
                    if (props.fieldsValue.buh_overload_rate.name) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_overload',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue.buh_overload_rate.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_overload_ton');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_overload ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Перегруз/итого</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_overload.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_overload.name,
                        2,
                        props.activeEditNumberField === 'buh_overload',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_overload',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (!isNaN(value.split(' ').join('')) && !value) {
                    setTimeout(() => {
                      props.changeField(
                        'buh_overload_rate',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                    setTimeout(() => {
                      props.changeField(
                        'buh_overload_ton',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_overload');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_simpleToUnload_rate ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Простой на выгрузке/ставка</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_simpleToUnload_rate.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_simpleToUnload_rate.name,
                        2,
                        props.activeEditNumberField ===
                          'buh_simpleToUnload_rate',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_simpleToUnload_rate',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (props.accesses.requests.buh_simpleToUnload) {
                    if (props.fieldsValue.buh_simpleToUnload_hour.name) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_simpleToUnload',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue.buh_simpleToUnload_hour.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_simpleToUnload_rate');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_simpleToUnload_hour ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Простой на выгрузке/час</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_simpleToUnload_hour.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_simpleToUnload_hour.name,
                        2,
                        props.activeEditNumberField ===
                          'buh_simpleToUnload_hour',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_simpleToUnload_hour',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (props.accesses.requests.buh_simpleToUnload) {
                    if (props.fieldsValue.buh_simpleToUnload_rate.name) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_simpleToUnload',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue.buh_simpleToUnload_rate.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_simpleToUnload_hour');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_simpleToUnload ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Простой на выгрузке/итого</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_simpleToUnload.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_simpleToUnload.name,
                        2,
                        props.activeEditNumberField === 'buh_simpleToUnload',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_simpleToUnload',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (!isNaN(value.split(' ').join('')) && !value) {
                    setTimeout(() => {
                      props.changeField(
                        'buh_simpleToUnload_rate',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                    setTimeout(() => {
                      props.changeField(
                        'buh_simpleToUnload_hour',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_simpleToUnload');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_simpleToLoad_rate ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Простой на погрузке/ставка</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_simpleToLoad_rate.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_simpleToLoad_rate.name,
                        2,
                        props.activeEditNumberField === 'buh_simpleToLoad_rate',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_simpleToLoad_rate',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (props.accesses.requests.buh_simpleToLoad) {
                    if (props.fieldsValue.buh_simpleToLoad_hour.name) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_simpleToLoad',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue.buh_simpleToLoad_hour.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_simpleToLoad_rate');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_simpleToLoad_hour ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Простой на погрузке/час</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_simpleToLoad_hour.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_simpleToLoad_hour.name,
                        2,
                        props.activeEditNumberField === 'buh_simpleToLoad_hour',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_simpleToLoad_hour',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (props.accesses.requests.buh_simpleToLoad) {
                    if (props.fieldsValue.buh_simpleToLoad_rate.name) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_simpleToLoad',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue.buh_simpleToLoad_rate.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_simpleToLoad_hour');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_simpleToLoad ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Простой на погрузке/итого</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_simpleToLoad.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_simpleToLoad.name,
                        2,
                        props.activeEditNumberField === 'buh_simpleToLoad',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_simpleToLoad',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (!isNaN(value.split(' ').join('')) && !value) {
                    setTimeout(() => {
                      props.changeField(
                        'buh_simpleToLoad_rate',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                    setTimeout(() => {
                      props.changeField(
                        'buh_simpleToLoad_hour',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_simpleToLoad');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_storageAtTheDestinationStation_rate ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Хранение на станции назначения/ставка</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_storageAtTheDestinationStation_rate.name
                    ? numberWithCommas(
                        props.fieldsValue
                          .buh_storageAtTheDestinationStation_rate.name,
                        2,
                        props.activeEditNumberField ===
                          'buh_storageAtTheDestinationStation_rate',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_storageAtTheDestinationStation_rate',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (
                    props.accesses.requests.buh_storageAtTheDestinationStation
                  ) {
                    if (
                      props.fieldsValue.buh_storageAtTheDestinationStation_day
                        .name
                    ) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_storageAtTheDestinationStation',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue
                                .buh_storageAtTheDestinationStation_day.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField(
                    'buh_storageAtTheDestinationStation_rate',
                  );
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_storageAtTheDestinationStation_day ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Хранение на станции назначения/день</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_storageAtTheDestinationStation_day.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_storageAtTheDestinationStation_day
                          .name,
                        2,
                        props.activeEditNumberField ===
                          'buh_storageAtTheDestinationStation_day',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_storageAtTheDestinationStation_day',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (
                    props.accesses.requests.buh_storageAtTheDestinationStation
                  ) {
                    if (
                      props.fieldsValue.buh_storageAtTheDestinationStation_rate
                        .name
                    ) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_storageAtTheDestinationStation',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue
                                .buh_storageAtTheDestinationStation_rate.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField(
                    'buh_storageAtTheDestinationStation_day',
                  );
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_storageAtTheDestinationStation ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Хранение на станции назначения/итого</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_storageAtTheDestinationStation.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_storageAtTheDestinationStation
                          .name,
                        2,
                        props.activeEditNumberField ===
                          'buh_storageAtTheDestinationStation',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_storageAtTheDestinationStation',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (!isNaN(value.split(' ').join('')) && !value) {
                    setTimeout(() => {
                      props.changeField(
                        'buh_storageAtTheDestinationStation_rate',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                    setTimeout(() => {
                      props.changeField(
                        'buh_storageAtTheDestinationStation_day',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField(
                    'buh_storageAtTheDestinationStation',
                  );
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_aboveTheNorm_rate ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>СверхНорма/cтавка</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_aboveTheNorm_rate.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_aboveTheNorm_rate.name,
                        2,
                        props.activeEditNumberField === 'buh_aboveTheNorm_rate',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_aboveTheNorm_rate',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (props.accesses.requests.buh_aboveTheNorm) {
                    if (props.fieldsValue.buh_aboveTheNorm_day.name) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_aboveTheNorm',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue.buh_aboveTheNorm_day.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_aboveTheNorm_rate');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_aboveTheNorm_day ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>СверхНорма/день</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_aboveTheNorm_day.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_aboveTheNorm_day.name,
                        2,
                        props.activeEditNumberField === 'buh_aboveTheNorm_day',
                      )
                    : ''
                }
                changed={async (value) => {
                  value = value.replace(',', '.');
                  (await !isNaN(value.split(' ').join(''))) &&
                    props.changeField(
                      'buh_aboveTheNorm_day',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (props.accesses.requests.buh_aboveTheNorm) {
                    if (props.fieldsValue.buh_aboveTheNorm_rate.name) {
                      !isNaN(value.split(' ').join('')) &&
                        props.changeField(
                          'buh_aboveTheNorm',
                          roundingNumber(
                            value.split(' ').join('') *
                              props.fieldsValue.buh_aboveTheNorm_rate.name,
                          ),
                        );
                    }
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_aboveTheNorm_day');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_aboveTheNorm ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>СверхНорма/итого</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_aboveTheNorm.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_aboveTheNorm.name,
                        2,
                        props.activeEditNumberField === 'buh_aboveTheNorm',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_aboveTheNorm',
                      value.split(' ').join(''),
                      'input-number',
                    );
                  if (!isNaN(value.split(' ').join('')) && !value) {
                    setTimeout(() => {
                      props.changeField(
                        'buh_aboveTheNorm_rate',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                    setTimeout(() => {
                      props.changeField(
                        'buh_aboveTheNorm_day',
                        value.split(' ').join(''),
                        'input-number',
                      );
                    }, 0);
                  }
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_aboveTheNorm');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_loadFastening ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Крепление груза</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_loadFastening.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_loadFastening.name,
                        2,
                        props.activeEditNumberField === 'buh_loadFastening',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_loadFastening',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_loadFastening');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_additionalServices ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Дополнительные услуги</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_additionalServices.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_additionalServices.name,
                        2,
                        props.activeEditNumberField ===
                          'buh_additionalServices',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_additionalServices',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_additionalServices');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_seal ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Пломба</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_seal.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_seal.name,
                        2,
                        props.activeEditNumberField === 'buh_seal',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_seal',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_seal');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_bills ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Платёжки</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_bills.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_bills.name,
                        2,
                        props.activeEditNumberField === 'buh_bills',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_bills',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_bills');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_managerial ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Менеджерские</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_managerial.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_managerial.name,
                        2,
                        props.activeEditNumberField === 'buh_managerial',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_managerial',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_managerial');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_agentToClient ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Агентские клиенту</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_agentToClient.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_agentToClient.name,
                        2,
                        props.activeEditNumberField === 'buh_agentToClient',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_agentToClient',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_agentToClient');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_agentSuppliers ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>Агентские поставщиков</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_agentSuppliers.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_agentSuppliers.name,
                        2,
                        props.activeEditNumberField === 'buh_agentSuppliers',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_agentSuppliers',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_agentSuppliers');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_OBN ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'financialdepartment',
                )
              }>
              <ValueTitle>ОБН</ValueTitle>
              <Input
                disabled
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_OBN.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_OBN.name,
                        2,
                        props.activeEditNumberField === 'buh_OBN',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  !isNaN(value.split(' ').join('')) &&
                    props.changeField(
                      'buh_OBN',
                      value.split(' ').join(''),
                      'input-number',
                    );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_OBN');
                }}
                crop={2}
              />
            </ContainerValue>
          ) : null}
        </ContainerValues>
      </CountingContainer>
      <CountingContainer id="billnumbers">
        {props.hasBillnumbers ? (
          <ContainerTitle>Номера счетов</ContainerTitle>
        ) : null}
        <ContainerValues>
          {props.accesses.requests.customerAccountNumber ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard(
                  'billnumbers',
                  true,
                )
              }>
              <ValueTitle>Номер счёта клиента</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.customerAccountNumber.name}
                changed={(value) => {
                  props.changeField(
                    'customerAccountNumber',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                id="*billnumbers"
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.customerAccountNumberStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Номер счёта клиента/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.customerAccountNumberStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'customerAccountNumberStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_amount ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Сумма счета клиента (сумма)</ValueTitle>
              <Input
                placeholder="0.00"
                value={
                  props.fieldsValue.buh_amount.name
                    ? numberWithCommas(
                        props.fieldsValue.buh_amount.name,
                        2,
                        props.activeEditNumberField === 'buh_amount',
                      )
                    : ''
                }
                changed={(value) => {
                  value = value.replace(',', '.');
                  value = value.split(' ').join('');
                  !isNaN(value) &&
                    props.changeField('buh_amount', value, 'input-number');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                  props.setActiveEditNumberField('buh_amount');
                }}
                crop={2}
                disabled={
                  (!props.accesses.directory.requests.updateAmount &&
                    (!props.accesses.requests.customerAccountNumber ||
                      (props.fieldsValue.customerAccountNumber &&
                        props.fieldsValue.customerAccountNumber.name))) ||
                  (!props.accesses.additionalPermissions
                    .allowEditingBuhAmount &&
                    props.fieldsValue.customerAccountStatus &&
                    props.fieldsValue.customerAccountStatus.name) ||
                  (!props.accesses.additionalPermissions
                    .allowEditingBuhAmount &&
                    props.fieldsValue.customerAccountDate &&
                    props.fieldsValue.customerAccountDate.name)
                }
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.customerAccountDate ? (
            <ContainerValueCalendar
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Дата счёта клиента</ValueTitle>
              <InputMask
                mask="12-39-9999"
                formatChars={{
                  1: '[0-3]',
                  2: '[0-9]',
                  3: '[0-1]',
                  9: '[0-9]',
                }}
                value={
                  props.fieldsValue.customerAccountDate.name
                    ? props.fieldsValue.customerAccountDate.name.indexOf('-') >
                      2
                      ? moment(
                          props.fieldsValue.customerAccountDate.name,
                        ).format('DD-MM-YYYY')
                      : props.fieldsValue.customerAccountDate.name
                    : null
                }
                onChange={(event) => {
                  props.changeField(
                    'customerAccountDate',
                    event.target.value,
                    'input-date',
                  );
                  if (
                    event.target.value.indexOf('_') === -1 &&
                    event.target.value.length > 0
                  ) {
                    props.setStatusCustomerAccountDateCalendar(false);
                  } else {
                    props.setStatusCustomerAccountDateCalendar(true);
                  }
                }}
                placeholder="Выберите дату..."
                onFocus={() => {
                  props.setStatusCustomerAccountDateCalendar(true);
                  props.clearAutocompleteValues();
                }}>
                {(inputProps) => (
                  <InputWrapper>
                    <InputComponent {...inputProps} valid />
                  </InputWrapper>
                )}
              </InputMask>
              {props.statusCustomerAccountDateCalendar && (
                <CalendarContainer
                  className="calendar-container"
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      props.setStatusCustomerAccountDateCalendar(false);
                    }
                  }}>
                  <Calendar
                    onSelect={(value) =>
                      props.calendaeSelectHandler('customerAccountDate', value)
                    }
                    onClear={() =>
                      props.setStatusCustomerAccountDateCalendar(false)
                    }
                    format={formatStr}
                    defaultValue={props.convertCalendarDate(
                      props.fieldsValue.customerAccountDate.name,
                    )}
                    locale={ruRu}
                    tabindex="0"
                  />
                </CalendarContainer>
              )}
              {props.statusCustomerAccountDateCalendar && (
                <CalendarMask
                  onClick={() =>
                    props.setStatusCustomerAccountDateCalendar(false)
                  }
                />
              )}
            </ContainerValueCalendar>
          ) : null}
          {props.accesses.requests.buh_amountMore &&
          props.fieldsValue.buh_amountMore &&
          props.fieldsValue.buh_amountMore.length > 0 ? (
            <>
              {props.fieldsValue.buh_amountMore.map((item, index) => (
                <>
                  <ContainerValue
                    onClick={() =>
                      props.scrollAndSetActiveSectionMouseAndKeyboard(
                        'billnumbers',
                      )
                    }>
                    <ValueTitle>
                      {`Номер счета для суммы ${index + 1}`}
                    </ValueTitle>
                    <Input
                      type="text"
                      placeholder="Введите данные..."
                      value={item.accountNumber}
                      changed={(value) => {
                        props.changeBuhAmountMoreField(
                          'accountNumber',
                          value,
                          item.idIndex,
                        );
                      }}
                      active={() => props.clearAutocompleteValues()}
                      btnDelete
                    />
                  </ContainerValue>
                  <ContainerValue
                    onClick={() =>
                      props.scrollAndSetActiveSectionMouseAndKeyboard(
                        'billnumbers',
                      )
                    }>
                    <ValueTitle>
                      {`Номер счета для суммы ${index + 1}/статус`}
                    </ValueTitle>
                    <Checkbox
                      value={item.status}
                      onClickItem={(value) =>
                        props.changeBuhAmountMoreField(
                          'status',
                          value,
                          item.idIndex,
                        )
                      }
                    />
                  </ContainerValue>
                  <ContainerValue
                    onClick={() =>
                      props.scrollAndSetActiveSectionMouseAndKeyboard(
                        'billnumbers',
                      )
                    }>
                    <ValueTitle>{`Сумма ${index + 1}`}</ValueTitle>
                    <Input
                      type="text"
                      placeholder="0.00"
                      value={
                        item.amount
                          ? numberWithCommas(
                              item.amount,
                              2,
                              props.activeEditNumberField ===
                                `buh_amountMore${index + 1}`,
                            )
                          : ''
                      }
                      changed={(value) => {
                        value = value.replace(',', '.');
                        value = value.replace(/\s/g, '');
                        if (!isNaN(+value)) {
                          props.changeBuhAmountMoreField(
                            'amount',
                            value,
                            item.idIndex,
                          );
                        }
                      }}
                      active={() => {
                        props.clearAutocompleteValues();
                        props.setActiveEditNumberField(
                          `buh_amountMore${index + 1}`,
                        );
                      }}
                      crop={2}
                      disabled={
                        !props.accesses.directory.requests.updateAmount &&
                        props.fieldsValue.buh_amountMore[index] &&
                        props.fieldsValue.buh_amountMore[index].accountNumber
                      }
                    />
                  </ContainerValue>
                  <ContainerValueCalendar
                    onClick={() =>
                      props.scrollAndSetActiveSectionMouseAndKeyboard(
                        'billnumbers',
                      )
                    }>
                    <ValueTitle>{`Дата счета суммы ${index + 1}`}</ValueTitle>
                    <InputMask
                      mask="12-39-9999"
                      formatChars={{
                        1: '[0-3]',
                        2: '[0-9]',
                        3: '[0-1]',
                        9: '[0-9]',
                      }}
                      value={
                        item.date
                          ? item.date.indexOf('-') > 2
                            ? moment(item.date).format('DD-MM-YYYY')
                            : item.date
                          : null
                      }
                      onChange={(event) => {
                        props.changeBuhAmountMoreField(
                          'date',
                          event.target.value,
                          item.idIndex,
                        );
                        if (
                          event.target.value.indexOf('_') === -1 &&
                          event.target.value.length > 0
                        ) {
                          props.setBuhAmountMoreDateCalendar(false);
                        } else {
                          props.setBuhAmountMoreDateCalendar(true);
                        }
                      }}
                      placeholder="Выберите дату..."
                      onFocus={() => {
                        props.setActiveDateBuhAmountMoreField(item.idIndex);
                        props.setBuhAmountMoreDateCalendar(true);
                        props.clearAutocompleteValues();
                      }}>
                      {(inputProps) => (
                        <InputWrapper>
                          <InputComponent
                            {...inputProps}
                            valid
                            id="*billnumbers"
                          />
                        </InputWrapper>
                      )}
                    </InputMask>
                    {props.buhAmountMoreDateCalendar &&
                      props.activeDateBuhAmountMoreField === item.idIndex && (
                        <CalendarContainer
                          className="calendar-container"
                          onKeyDown={(e) => {
                            if (e.key === 'Tab') {
                              props.setBuhAmountMoreDateCalendar(false);
                            }
                          }}>
                          <Calendar
                            onSelect={(value) => {
                              const dateString = value.toJSON().slice(0, 10);
                              props.changeBuhAmountMoreField(
                                'date',
                                dateString,
                                item.idIndex,
                              );
                              props.setBuhAmountMoreDateCalendar(false);
                            }}
                            onClear={() =>
                              props.setBuhAmountMoreDateCalendar(false)
                            }
                            format={formatStr}
                            defaultValue={item.date}
                            locale={ruRu}
                            tabindex="0"
                          />
                        </CalendarContainer>
                      )}
                    {props.buhAmountMoreDateCalendar && (
                      <CalendarMask
                        onClick={() =>
                          props.setBuhAmountMoreDateCalendar(false)
                        }
                      />
                    )}
                  </ContainerValueCalendar>
                </>
              ))}
            </>
          ) : null}
          {props.accesses.requests.carrierAccount ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт перевозчика</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.carrierAccount.name}
                changed={(value) => {
                  props.changeField('carrierAccount', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.carrierAccountStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт перевозчика/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.carrierAccountStatus.name}
                onClickItem={(value) => {
                  props.changeField('carrierAccountStatus', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_carrierMore &&
            props.fieldsValue.buh_carrierMore.map((item, index) => (
              <>
                <ContainerValue
                  onClick={() =>
                    props.scrollAndSetActiveSectionMouseAndKeyboard(
                      'billnumbers',
                    )
                  }>
                  <ValueTitle>
                    {`Номер счета для перевозчик ${index + 1}`}
                  </ValueTitle>
                  <Input
                    type="text"
                    placeholder="Введите данные..."
                    value={item.accountNumber}
                    changed={(value) =>
                      props.changeBuhMore(
                        'buh_carrierMore',
                        'accountNumber',
                        value,
                        item.idIndex,
                      )
                    }
                    active={() => props.clearAutocompleteValues()}
                    btnDelete
                  />
                </ContainerValue>
                <ContainerValue
                  onClick={() =>
                    props.scrollAndSetActiveSectionMouseAndKeyboard(
                      'billnumbers',
                    )
                  }>
                  <ValueTitle>
                    {`Номер счета для перевозчик ${index + 1}/статус`}
                  </ValueTitle>
                  <Checkbox
                    value={item.status}
                    onClickItem={(value) =>
                      props.changeBuhMore(
                        'buh_carrierMore',
                        'status',
                        value,
                        item.idIndex,
                      )
                    }
                  />
                </ContainerValue>
              </>
            ))}
          {props.accesses.requests.accountsAutoServices ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт автоуслуги</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.accountsAutoServices.name}
                changed={(value) => {
                  props.changeField(
                    'accountsAutoServices',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountsAutoServicesStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт автоуслуги/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.accountsAutoServicesStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'accountsAutoServicesStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.containerAgentAccount ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт контейнерного агента</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.containerAgentAccount.name}
                changed={(value) => {
                  props.changeField(
                    'containerAgentAccount',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.containerAgentAccountStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт контейнерного агента/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.containerAgentAccountStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'containerAgentAccountStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.exportAgentAccount ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт агента по вывозу</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.exportAgentAccount.name}
                changed={(value) => {
                  props.changeField('exportAgentAccount', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.exportAgentAccountStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт агента по вывозу/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.exportAgentAccountStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'exportAgentAccountStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.buh_exportAgentMore &&
            props.fieldsValue.buh_exportAgentMore.map((item, index) => (
              <>
                <ContainerValue
                  onClick={() =>
                    props.scrollAndSetActiveSectionMouseAndKeyboard(
                      'billnumbers',
                    )
                  }>
                  <ValueTitle>
                    {`Номер счета для агент по вывозу ${index + 1}`}
                  </ValueTitle>
                  <Input
                    type="text"
                    placeholder="Введите данные..."
                    value={item.accountNumber}
                    changed={(value) =>
                      props.changeBuhMore(
                        'buh_exportAgentMore',
                        'accountNumber',
                        value,
                        item.idIndex,
                      )
                    }
                    active={() => props.clearAutocompleteValues()}
                    btnDelete
                  />
                </ContainerValue>
                <ContainerValue
                  onClick={() =>
                    props.scrollAndSetActiveSectionMouseAndKeyboard(
                      'billnumbers',
                    )
                  }>
                  <ValueTitle>
                    {`Номер счета для агент по вывозу ${index + 1}/статус`}
                  </ValueTitle>
                  <Checkbox
                    value={item.status}
                    onClickItem={(value) =>
                      props.changeBuhMore(
                        'buh_exportAgentMore',
                        'status',
                        value,
                        item.idIndex,
                      )
                    }
                  />
                </ContainerValue>
              </>
            ))}
          {props.accesses.requests.moversAccountOnLoading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт грузчиков на погрузке</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.moversAccountOnLoading.name}
                changed={(value) => {
                  props.changeField(
                    'moversAccountOnLoading',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.moversAccountOnLoadingStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт грузчиков на погрузке/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.moversAccountOnLoadingStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'moversAccountOnLoadingStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.moversAccountOnUnloading ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт грузчиков на выгрузке</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.moversAccountOnUnloading.name}
                changed={(value) => {
                  props.changeField(
                    'moversAccountOnUnloading',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.moversAccountOnUnloadingStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт грузчиков на выгрузке/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.moversAccountOnUnloadingStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'moversAccountOnUnloadingStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountSecuringCargo ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт крепление груза</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.accountSecuringCargo.name}
                changed={(value) => {
                  props.changeField(
                    'accountSecuringCargo',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountSecuringCargoStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт крепление груза/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.accountSecuringCargoStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'accountSecuringCargoStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.billCargoWeatherization ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт утепление груза</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.billCargoWeatherization.name}
                changed={(value) => {
                  props.changeField(
                    'billCargoWeatherization',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.billCargoWeatherizationStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счёт утепление груза/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.billCargoWeatherizationStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'billCargoWeatherizationStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountStorageAtDestinationStation ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счет хранение на станции назначения</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={
                  props.fieldsValue.accountStorageAtDestinationStation.name
                }
                changed={(value) => {
                  props.changeField(
                    'accountStorageAtDestinationStation',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountStorageAtDestinationStationStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>
                Cчет хранение на станции назначения/статус
              </ValueTitle>
              <Checkbox
                value={
                  props.fieldsValue.accountStorageAtDestinationStationStatus
                    .name
                }
                onClickItem={(value) => {
                  props.changeField(
                    'accountStorageAtDestinationStationStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountSimpleToUnload ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счет простой на выгрузке</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.accountSimpleToUnload.name}
                changed={(value) => {
                  props.changeField(
                    'accountSimpleToUnload',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountSimpleToUnloadStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счет простой на выгрузке/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.accountSimpleToUnloadStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'accountSimpleToUnloadStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountSimpleToLoad ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счет простой на погрузке</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.accountSimpleToLoad.name}
                changed={(value) => {
                  props.changeField('accountSimpleToLoad', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountSimpleToLoadStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счет простой на погрузке/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.accountSimpleToLoadStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'accountSimpleToLoadStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountOverload ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счет перегруз</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.accountOverload.name}
                changed={(value) => {
                  props.changeField('accountOverload', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountOverloadStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счет перегруз/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.accountOverloadStatus.name}
                onClickItem={(value) => {
                  props.changeField('accountOverloadStatus', value, 'checbox');
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountAboveTheNorm ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счет сверхНорма</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.accountAboveTheNorm.name}
                changed={(value) => {
                  props.changeField('accountAboveTheNorm', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountAboveTheNormStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счет сверхНорма/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.accountAboveTheNormStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'accountAboveTheNormStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountAdditionalServices ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счет дополнительные услуги</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.accountAdditionalServices.name}
                changed={(value) => {
                  props.changeField(
                    'accountAdditionalServices',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.accountAdditionalServicesStatus ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Счет дополнительные услуги/статус</ValueTitle>
              <Checkbox
                value={props.fieldsValue.accountAdditionalServicesStatus.name}
                onClickItem={(value) => {
                  props.changeField(
                    'accountAdditionalServicesStatus',
                    value,
                    'checbox',
                  );
                }}
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.exhibitedSimpleToUnload ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Выставлено простой на выгрузке</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.exhibitedSimpleToUnload.name}
                changed={(value) => {
                  props.changeField(
                    'exhibitedSimpleToUnload',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.exhibitedStorage ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Выставлено хранение</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.exhibitedStorage.name}
                changed={(value) => {
                  props.changeField('exhibitedStorage', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.exhibitedOverflow ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Выставлено сверхнорматив</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.exhibitedOverflow.name}
                changed={(value) => {
                  props.changeField('exhibitedOverflow', value, 'input-text');
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
          {props.accesses.requests.exhibitedAdditionalServices ? (
            <ContainerValue
              onClick={() =>
                props.scrollAndSetActiveSectionMouseAndKeyboard('billnumbers')
              }>
              <ValueTitle>Выставлено дополнительные услуги</ValueTitle>
              <Input
                type="text"
                placeholder="Введите данные..."
                value={props.fieldsValue.exhibitedAdditionalServices.name}
                changed={(value) => {
                  props.changeField(
                    'exhibitedAdditionalServices',
                    value,
                    'input-text',
                  );
                }}
                active={() => {
                  props.clearAutocompleteValues();
                }}
                btnDelete
              />
            </ContainerValue>
          ) : null}
        </ContainerValues>
      </CountingContainer>
      <CountingContainer id="coming" style={{ padding: '20px 0' }}>
        {props.accesses.directory.payments.findAll ? (
          <ContainerTitle>Приход</ContainerTitle>
        ) : null}
        {props.accesses.directory.payments.findAll &&
        props.fieldsValue.buh_amount &&
        props.fieldsValue.buh_amount.name > 0 ? (
          <ContainerValues style={{ flexDirection: 'column' }}>
            {props.payments
              .filter((item) => item.type === 'payment')
              .map((item, index, self) => (
                <ContainerFlex key={item.id}>
                  <ContainerValueCalendar
                    onClick={() =>
                      props.scrollAndSetActiveSectionMouseAndKeyboard(
                        'coming',
                        true,
                      )
                    }>
                    <ValueTitle>{`Дата платежа ${index + 1}`}</ValueTitle>
                    <InputMask
                      mask="12-39-9999"
                      formatChars={{
                        1: '[0-3]',
                        2: '[0-9]',
                        3: '[0-1]',
                        9: '[0-9]',
                      }}
                      value={
                        item.date
                          ? item.date.indexOf('-') > 2
                            ? moment(item.date).format('DD-MM-YYYY')
                            : item.date
                          : ''
                      }
                      onChange={(event) => {
                        props.changePaymentFields(
                          'date',
                          event.target.value,
                          item.id,
                          'payment',
                        );
                        if (
                          event.target.value.indexOf('_') === -1 &&
                          event.target.value.length > 0
                        ) {
                          props.setPaymentDateCalendar(false);
                        } else {
                          props.setPaymentDateCalendar(true);
                        }
                      }}
                      placeholder="Выберите дату..."
                      onFocus={() => {
                        props.setActiveDatePaymentField(item.id);
                        props.setPaymentDateCalendar(true);
                        props.clearAutocompleteValues();
                      }}>
                      {(inputProps) => (
                        <InputWrapper>
                          <InputComponent {...inputProps} valid id="*coming" />
                        </InputWrapper>
                      )}
                    </InputMask>
                    {props.paymentDateCalendar &&
                      props.activeDatePaymentField === item.id && (
                        <CalendarContainer
                          onKeyDown={(e) => {
                            if (e.key === 'Tab') {
                              props.setPaymentDateCalendar(false);
                            }
                          }}
                          lastCalendar>
                          <Calendar
                            onSelect={(value) => {
                              props.calendaeSelectHandler(
                                'date',
                                value,
                                item.id,
                                'payment',
                              );
                              props.setPaymentDateCalendar(false);
                            }}
                            onClear={() => props.setPaymentDateCalendar(false)}
                            format={formatStr}
                            defaultValue={props.convertCalendarDate(item.date)}
                            locale={ruRu}
                            tabindex="0"
                          />
                        </CalendarContainer>
                      )}
                    {props.paymentDateCalendar && (
                      <CalendarMask
                        onClick={() => props.setPaymentDateCalendar(false)}
                      />
                    )}
                  </ContainerValueCalendar>
                  <ContainerValue
                    style={
                      !props.accesses.directory.payments.update &&
                      !props.accesses.directory.payments.delete
                        ? !props.accesses.directory.payments.insert
                          ? { pointerEvents: 'none' }
                          : null
                        : null
                    }
                    onClick={() =>
                      props.scrollAndSetActiveSectionMouseAndKeyboard('coming')
                    }>
                    <ValueTitle>{`Сумма оплаты ${index + 1}`}</ValueTitle>
                    <Input
                      type="text"
                      placeholder="0.00"
                      value={
                        item.amount
                          ? numberWithCommas(
                              item.amount,
                              2,
                              props.activeEditNumberField ===
                                `payments-payment${item.id}`,
                            )
                          : ''
                      }
                      changed={(value) => {
                        value = value.replace(',', '.');
                        value = value.replace(/\s/g, '');
                        if (!isNaN(+value)) {
                          const paymentsAmount = props.payments
                            .filter(
                              (payment) =>
                                payment.type === 'payment' &&
                                payment.id !== item.id,
                            )
                            .reduce((a, b) => a + +b.amount, 0);
                          props.changePaymentFields(
                            'amount',
                            value,
                            item.id,
                            'payment',
                          );
                          props.setPaymentCounterAmount(
                            paymentsAmount + +value,
                          );
                        }
                      }}
                      active={() => {
                        props.clearAutocompleteValues();
                        props.setActiveEditNumberField(
                          `payments-payment${item.id}`,
                        );
                      }}
                      crop={2}
                    />
                  </ContainerValue>
                  <ContainerValue
                    style={
                      !props.accesses.directory.payments.update &&
                      !props.accesses.directory.payments.delete
                        ? !props.accesses.directory.payments.insert
                          ? { pointerEvents: 'none' }
                          : null
                        : null
                    }
                    onClick={() =>
                      props.scrollAndSetActiveSectionMouseAndKeyboard('coming')
                    }>
                    <ValueTitle>{`№ Платежки ${index + 1}`}</ValueTitle>
                    <Input
                      type="text"
                      placeholder="Введите данные..."
                      value={item.paymentNumber}
                      changed={(value) =>
                        props.changePaymentFields(
                          'paymentNumber',
                          value,
                          item.id,
                          'payment',
                        )
                      }
                      active={() => props.clearAutocompleteValues()}
                      btnDelete
                    />
                  </ContainerValue>
                  <DeleteFieldButtonContainer>
                    <DeleteFieldButton
                      onClick={() => props.handlerDeletePayment(item.id)}
                    />
                  </DeleteFieldButtonContainer>
                  {index === self.length - 1 &&
                  props.accesses.directory.payments.insert &&
                  item.amount !== '' ? (
                    <ContainerValueButton>
                      <Button
                        mode="plus"
                        plus
                        clicked={() => {
                          const payments = props.payments.slice();
                          const propsPayments = {
                            date: '',
                            paymentNumber: '',
                            amount: '',
                            type: 'payment',
                            id: props.payments.length + 1,
                          };
                          payments.push(propsPayments);
                          props.setPayments(payments);
                          props.setChangedDataPaymentRequest(
                            JSON.parse(JSON.stringify(payments)),
                          );
                        }}
                      />
                    </ContainerValueButton>
                  ) : null}
                </ContainerFlex>
              ))}
          </ContainerValues>
        ) : null}
        {props.accesses.directory.payments.findAll &&
        props.fieldsValue.buh_containerAgent &&
        props.fieldsValue.buh_containerAgent.name < 0 ? (
          <div>
            <ContainerTitle>Контейнерный агент</ContainerTitle>
            <ContainerValues style={{ flexDirection: 'column' }}>
              {props.payments
                .filter((item) => item.type === 'containerAgentPayment')
                .map((item, index, self) => (
                  <ContainerFlex key={item.id}>
                    <ContainerValueCalendar
                      style={
                        !props.accesses.directory.payments.update &&
                        !props.accesses.directory.payments.delete
                          ? !props.accesses.directory.payments.insert
                            ? { pointerEvents: 'none' }
                            : null
                          : null
                      }
                      onClick={() =>
                        props.scrollAndSetActiveSectionMouseAndKeyboard(
                          'coming',
                        )
                      }>
                      <ValueTitle>Дата счета</ValueTitle>
                      <InputMask
                        mask="12-39-9999"
                        formatChars={{
                          1: '[0-3]',
                          2: '[0-9]',
                          3: '[0-1]',
                          9: '[0-9]',
                        }}
                        value={
                          item.date
                            ? item.date.indexOf('-') > 2
                              ? moment(item.date).format('DD-MM-YYYY')
                              : item.date
                            : null
                        }
                        onChange={(event) => {
                          props.changePaymentFields(
                            'date',
                            event.target.value,
                            item.id,
                            'containerAgentPayment',
                          );
                          if (
                            event.target.value.indexOf('_') === -1 &&
                            event.target.value.length > 0
                          ) {
                            props.setPaymentDateCalendar(false);
                          } else {
                            props.setPaymentDateCalendar(true);
                          }
                        }}
                        placeholder="Выберите дату..."
                        onFocus={() => {
                          props.setActiveDatePaymentField(item.id);
                          props.setPaymentDateCalendar(true);
                          props.clearAutocompleteValues();
                        }}>
                        {(inputProps) => (
                          <InputWrapper>
                            <InputComponent
                              {...inputProps}
                              valid
                              id="*coming"
                            />
                          </InputWrapper>
                        )}
                      </InputMask>
                      {props.paymentDateCalendar &&
                        props.activeDatePaymentField === item.id && (
                          <CalendarContainer
                            onKeyDown={(e) => {
                              if (e.key === 'Tab') {
                                props.setPaymentDateCalendar(false);
                              }
                            }}
                            lastCalendar>
                            <Calendar
                              onSelect={(value) => {
                                props.calendaeSelectHandler(
                                  'date',
                                  value,
                                  item.id,
                                  'containerAgentPayment',
                                );
                                props.setPaymentDateCalendar(false);
                              }}
                              onClear={() =>
                                props.setPaymentDateCalendar(false)
                              }
                              format={formatStr}
                              defaultValue={props.convertCalendarDate(
                                item.date,
                              )}
                              locale={ruRu}
                              tabindex="0"
                            />
                          </CalendarContainer>
                        )}
                      {props.paymentDateCalendar && (
                        <CalendarMask
                          onClick={() => props.setPaymentDateCalendar(false)}
                        />
                      )}
                    </ContainerValueCalendar>
                    <ContainerValue
                      style={
                        !props.accesses.directory.payments.update &&
                        !props.accesses.directory.payments.delete
                          ? !props.accesses.directory.payments.insert
                            ? { pointerEvents: 'none' }
                            : null
                          : null
                      }
                      onClick={() =>
                        props.scrollAndSetActiveSectionMouseAndKeyboard(
                          'coming',
                        )
                      }>
                      <ValueTitle>Сумма счета</ValueTitle>
                      <Input
                        type="text"
                        placeholder="0.00"
                        value={
                          item.amount
                            ? numberWithCommas(
                                item.amount,
                                2,
                                props.activeEditNumberField ===
                                  `payments-containerAgentPayment${item.id}`,
                              )
                            : ''
                        }
                        changed={(value) => {
                          value = value.replace(',', '.');
                          value = value.replace(/\s/g, '');
                          if (!isNaN(+value)) {
                            const paymentsAmount = props.payments
                              .filter(
                                (payment) =>
                                  payment.type === 'containerAgentPayment' &&
                                  payment.id !== item.id,
                              )
                              .reduce((a, b) => a + +b.amount, 0);
                            props.changePaymentFields(
                              'amount',
                              value,
                              item.id,
                              'containerAgentPayment',
                            );
                            props.setContainerAgentPaymentCounter(
                              paymentsAmount + +value,
                            );
                          }
                        }}
                        active={() => {
                          props.clearAutocompleteValues();
                          props.setActiveEditNumberField(
                            `payments-containerAgentPayment${item.id}`,
                          );
                        }}
                        crop={2}
                      />
                    </ContainerValue>
                    <ContainerValue
                      style={
                        !props.accesses.directory.payments.update &&
                        !props.accesses.directory.payments.delete
                          ? !props.accesses.directory.payments.insert
                            ? { pointerEvents: 'none' }
                            : null
                          : null
                      }
                      onClick={() =>
                        props.scrollAndSetActiveSectionMouseAndKeyboard(
                          'coming',
                        )
                      }>
                      <ValueTitle>№ счета</ValueTitle>
                      <Input
                        type="text"
                        placeholder="Введите данные..."
                        value={item.paymentNumber}
                        changed={(value) =>
                          props.changePaymentFields(
                            'paymentNumber',
                            value,
                            item.id,
                            'containerAgentPayment',
                          )
                        }
                        active={() => props.clearAutocompleteValues()}
                        btnDelete
                      />
                    </ContainerValue>
                    <DeleteFieldButtonContainer>
                      <DeleteFieldButton
                        onClick={() => props.handlerDeletePayment(item.id)}
                      />
                    </DeleteFieldButtonContainer>
                    {index === self.length - 1 &&
                    item.amount !== '' &&
                    props.accesses.directory.payments.insert ? (
                      <ContainerValueButton>
                        <Button
                          mode="plus"
                          plus
                          clicked={() => {
                            const payments = props.payments.slice();
                            const propsPayments = {
                              date: '',
                              paymentNumber: '',
                              amount: '',
                              type: 'containerAgentPayment',
                              id: props.payments.length + 1,
                            };
                            payments.push(propsPayments);
                            props.setPayments(payments);
                            props.setChangedDataPaymentRequest(
                              JSON.parse(JSON.stringify(payments)),
                            );
                          }}
                        />
                      </ContainerValueButton>
                    ) : null}
                  </ContainerFlex>
                ))}
            </ContainerValues>
          </div>
        ) : null}
        {props.fieldsValue.buh_amount &&
        !props.fieldsValue.buh_amount.name &&
        props.fieldsValue.buh_containerAgent &&
        !props.fieldsValue.buh_containerAgent.name ? (
          <ContainerTitle>Сумма заявки не указана</ContainerTitle>
        ) : null}
      </CountingContainer>
      {props.pathname ? (
        <CountingContainer id="total">
          <ContainerTitle />
          <ContainerValues>
            {props.total !== null ? (
              <ContainerValue>
                <ValueTitle>Итого</ValueTitle>
                <CalcValue value={props.total}>
                  {numberWithCommas(Number(props.total))}
                </CalcValue>
              </ContainerValue>
            ) : null}
            {props.accesses.requests.balance ? (
              <ContainerValue>
                <ValueTitle>Остаток</ValueTitle>
                <CalcValue value={props.fieldsValue.balance.name}>
                  {props.fieldsValue.balance.name
                    ? numberWithCommas(Number(props.fieldsValue.balance.name))
                    : 0}
                </CalcValue>
              </ContainerValue>
            ) : null}
          </ContainerValues>
        </CountingContainer>
      ) : null}
    </Form>
  );
}

RequestFormView.defaultProps = {
  statusWindow: false,
};
