import { compose, withState, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';

// view
import UpdateStatusRequestsView from './UpdateStatusRequestsView';

// services
import { RequestsService } from '../../../services';

import {
  checkStatusPopup,
  setExcelData,
  setExcelRowIndex,
} from '../../../store/actions/dashboard';

import { colors } from '../../../styles';

export default compose(
  connect(
    (state) => ({
      checkStatus: state.dashboard.checkStatusPopup,
      excelData: state.dashboard.excelData,
      excelRowIndex: state.dashboard.excelRowIndex,
    }),
    (dispatch) => ({
      checkStatusPopup: (item) => dispatch(checkStatusPopup(item)),
      setExcelData: (data) => dispatch(setExcelData(data)),
      setExcelRowIndex: (data) => dispatch(setExcelRowIndex(data)),
    }),
  ),
  withState('tableHeader', 'setTableHeader', []),
  withState('tableData', 'setTableData', []),
  withState('dataFile', 'setDataFile', null),
  withState('showDropzone', 'setShowDropzone', true),
  withState('showGrid', 'setShowGrid', false),
  withState('showLoader', 'setShowLoader', false),
  withState('currentSheet', 'setCurrentSheet', 0),
  withState('showModalWindow', 'setShowModalWindow', false),
  withState('tableParams', 'setTableParams', null),

  withHandlers({
    functionCheckTime: (props) => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    back: (props) => () => {
      props.history.push('/dashboard/requests');
    },
    updateStatusRequests: (props) => async () => {
      props.setShowDropzone(false);
      props.setShowGrid(true);
      props.setShowLoader(true);

      const formData = new FormData();
      formData.append(
        'excel-status',
        new Blob(
          [props.dataFile],
          { type: 'application/xls' },
          'excel-status.xls',
        ),
        'excel-status.xls',
      );
      let arrived = [];
      let containers = [];

      try {
        const data = await RequestsService.loadExcelStatus(formData);
        arrived = data.result.arrived.map((item) => ({
          number: item.requestNumber ? item.requestNumber : '',
          numberContainer: item.numberContainer ? item.numberContainer : '',
          date: item.loadDate ? item.loadDate : '',
          status: item.status,
          errors: item.errors.join(', '),
        }));
        containers = data.result.containers.map((item) => ({
          number: item.requestNumber ? item.requestNumber : '',
          numberContainer: item.numberContainer ? item.numberContainer : '',
          date: item.loadDate ? item.loadDate : '',
          status: item.status,
          errors: item.errors.join(', '),
        }));
        props.setDataFile(null);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }

      props.setShowLoader(false);
      props.setTableData(containers);
      props.setExcelData([containers, arrived]);
    },
    resetExcel: (props) => () => {
      props.setTableData([]);
      props.setExcelData([]);
      props.setCurrentSheet(0);
      props.setShowDropzone(true);
      props.setShowGrid(false);
      props.setShowModalWindow(false);
    },
    changeSheet: (props) => (sheet) => {
      const lastIndexRow = props.tableParams.api.getLastDisplayedRow();
      if (props.currentSheet === 0) {
        props.setExcelRowIndex([
          lastIndexRow || 0,
          props.excelRowIndex[1] ? props.excelRowIndex[1] : 0,
        ]);
      } else {
        props.setExcelRowIndex([
          props.excelRowIndex[0] ? props.excelRowIndex[0] : 0,
          lastIndexRow || 0,
        ]);
      }

      props.setCurrentSheet(sheet);
      props.setTableData(props.excelData[sheet], () => {
        if (props.excelRowIndex && props.excelRowIndex[sheet]) {
          if (
            props.tableParams.api.getDisplayedRowCount() -
              props.excelRowIndex[sheet] ===
            1
          ) {
            props.tableParams.api.ensureIndexVisible(
              props.tableParams.api.getDisplayedRowCount() - 1,
              'bottom',
            );
          } else {
            props.tableParams.api.ensureIndexVisible(
              props.excelRowIndex[sheet] - 11,
              'bottom',
            );
          }
        }
      });
    },
    onGridReady: (props) => (params) => {
      props.setTableParams(params);

      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((item) => {
        allColumnIds.push(item.colId);
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
      params.columnApi.setColumnWidth('status', 180);

      if (props.excelRowIndex && props.excelRowIndex[props.currentSheet]) {
        if (
          params.api.getDisplayedRowCount() -
            props.excelRowIndex[props.currentSheet] ===
          1
        ) {
          params.api.ensureIndexVisible(
            params.api.getDisplayedRowCount() - 1,
            'bottom',
          );
        } else {
          params.api.ensureIndexVisible(
            props.excelRowIndex[props.currentSheet] - 11,
            'bottom',
          );
        }
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.setTableHeader([
        {
          headerName: 'Номер заявки',
          field: 'number',
          cellStyle: (params) => {
            if (params.data) {
              const style = {};
              style.borderLeft = '5px solid transparent';
              if (params.data.status === 'Ошибка') {
                style.backgroundColor = `${colors.pink}`;
              }
              if (params.data.status === 'Обновлен') {
                style.borderLeft = `5px solid ${colors.blue}`;
              }
              return style;
            }
          },
        },
        {
          headerName: 'Номер контейнера',
          field: 'numberContainer',
          cellStyle: (params) => {
            if (params.data) {
              const style = {};
              if (params.data.status === 'Ошибка') {
                style.backgroundColor = `${colors.pink}`;
              }
              return style;
            }
          },
        },
        {
          headerName: 'Дата загрузки',
          field: 'date',
          cellStyle: (params) => {
            if (params.data) {
              const style = {};
              if (params.data.status === 'Ошибка') {
                style.backgroundColor = `${colors.pink}`;
              }
              return style;
            }
          },
        },
        {
          headerName: 'Статус',
          field: 'status',
          width: 180,
          cellStyle: (params) => {
            if (params.data) {
              const style = {};
              if (params.data.status === 'Ошибка') {
                style.backgroundColor = `${colors.pink}`;
                style.color = `${colors.primary_red}`;
              }
              if (params.data.status === 'Предупреждение') {
                style.color = `${colors.primary_red}`;
              }
              return style;
            }
          },
        },
        {
          headerName: 'Комментарий',
          field: 'errors',
          flex: 1,
          minWidth: 200,
          cellStyle: (params) => {
            if (params.data) {
              const style = {};
              if (params.data.status === 'Ошибка') {
                style.backgroundColor = `${colors.pink}`;
                style.color = `${colors.primary_red}`;
              }
              if (params.data.status === 'Предупреждение') {
                style.color = `${colors.primary_red}`;
              }
              return style;
            }
          },
        },
      ]);

      if (this.props.excelData.length) {
        this.props.setShowDropzone(false);
        this.props.setShowGrid(true);
        this.props.setDataFile(false);
        this.props.setTableData(this.props.excelData[this.props.currentSheet]);
      }
    },
    componentWillUnmount() {
      if (this.props.tableParams && this.props.tableParams.api) {
        const lastIndexRow = this.props.tableParams.api.getLastDisplayedRow();
        if (this.props.currentSheet === 0) {
          this.props.setExcelRowIndex([
            lastIndexRow || 0,
            this.props.excelRowIndex[1] ? this.props.excelRowIndex[1] : 0,
          ]);
        } else {
          this.props.setExcelRowIndex([
            this.props.excelRowIndex[0] ? this.props.excelRowIndex[0] : 0,
            lastIndexRow || 0,
          ]);
        }
      }
    },
  }),
)(UpdateStatusRequestsView);
