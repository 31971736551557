import { compose, lifecycle } from 'recompose';

import { connect } from 'react-redux';

import RequestView from './RequestView';

// services
import { UserService } from '../../services';

import { saveUserAccesses } from '../../store/actions/auth';

export default compose(
  connect(
    (state) => ({
      access: state.auth.userAccesses,
    }),
    (dispatch) => ({
      saveUserAccesses: (data) => dispatch(saveUserAccesses(data)),
    }),
  ),
  lifecycle({
    componentDidMount() {
      UserService.getRight()
        .then((item) => {
          this.props.saveUserAccesses({
            directory: item.result.directories,
            requests: item.result.requests,
            additionalPermissions: item.result.additionalPermissions,
          });
        })
        .catch((err) => console.warn('err', err));
    },
  }),
)(RequestView);
