import styled from 'styled-components';

import { base, colors, screens } from '../../styles';

export const Container = styled.div`
  span {
    width: 100% !important;
  }

  input {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: ${colors.white_smoke};
    border: none;
    border-radius: ${base / 2}px;
    box-sizing: border-box;
    height: ${base * 3.6}px;
    padding: 0 12px;
    font-size: 12px;
    color: ${colors.black};
    outline: none;

    &::placeholder {
      color: ${colors.light_gray};
    }
  }

  a {
    display: none;
  }

  @media (max-width: ${screens.screen_2xs_max}) {
    width: 350px;
  }
  @media (max-width: ${screens.screen_4xs_max}) {
    width: 100%;
  }
`;

export const DeleteValue = styled.img`
  position: absolute;
  top: 50%;
  right: 8px;
  outline: none;
  cursor: pointer;
`;
