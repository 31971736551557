import * as actionTypes from '../actions/actionTypes';

const initialState = {
  activeItem: null,
  activeSecondaryItem: null,
  secondaryBarItems: [],
  checkStatusPopup: {
    statusCheck: '',
    messageBox: '',
    statusTime: false,
  },
  needPadding: false,
  expand: false,
  showNewRequest: true,
  toggleDrop: false,
  toggleLoad: false,
  editRequestsList: [],
  openFilterField: '',
  quantityRequests: {
    rows: 0,
    totalRows: 0,
  },
  showWarningSaveRequest: false,
  callbackExitRequest: null,
  dataRequest: null,
  changedDataRequest: null,
  dataPaymentRequest: null,
  changedDataPaymentRequest: null,
  excelData: [],
  excelRowIndex: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        activeItem: action.payload,
      };
    case actionTypes.SET_ACTIVE_SECONDARY_SIDEBAR_ITEM:
      return {
        ...state,
        activeSecondaryItem: action.payload,
      };
    case actionTypes.SET_SECONDARY_BAR_ITEMS:
      return {
        ...state,
        secondaryBarItems: action.payload,
      };
    case actionTypes.CHECK_STATUS_POPUP:
      return {
        ...state,
        checkStatusPopup: action.payload,
      };
    case actionTypes.NEED_PADDING:
      return {
        ...state,
        needPadding: action.payload,
      };
    case actionTypes.SET_EXPAND:
      return {
        ...state,
        expand: action.payload,
      };
    case actionTypes.SHOW_NEW_REQUEST:
      return {
        ...state,
        showNewRequest: action.payload,
      };
    case actionTypes.TOGGLE_DROP:
      return {
        ...state,
        toggleDrop: action.payload,
      };
    case actionTypes.TOGGLE_LOAD:
      return {
        ...state,
        toggleLoad: action.payload,
      };
    case actionTypes.SET_OPEN_FILTER_FIELD:
      return {
        ...state,
        openFilterField: action.payload,
      };
    case actionTypes.SET_QUANTITY_REQUESTS:
      return {
        ...state,
        quantityRequests: action.payload,
      };
    case actionTypes.SET_SHOW_WARNING_SAVE_REQUEST:
      return {
        ...state,
        showWarningSaveRequest: action.payload,
      };
    case actionTypes.SET_CALLBACK_EXIT_REQUEST:
      return {
        ...state,
        callbackExitRequest: action.payload,
      };
    case actionTypes.SET_DATA_REQUEST:
      return {
        ...state,
        dataRequest: action.payload,
      };
    case actionTypes.SET_CHANGED_DATA_REQUEST:
      return {
        ...state,
        changedDataRequest: action.payload,
      };
    case actionTypes.SET_DATA_PAYMENT_REQUEST:
      return {
        ...state,
        dataPaymentRequest: action.payload,
      };
    case actionTypes.SET_CHANGED_DATA_PAYMENT_REQUEST:
      return {
        ...state,
        changedDataPaymentRequest: action.payload,
      };
    case actionTypes.SET_EXCEL_DATA:
      return {
        ...state,
        excelData: action.payload,
      };
    case actionTypes.SET_EXCEL_ROW_INDEX:
      return {
        ...state,
        excelRowIndex: action.payload,
      };
    default:
      return state;
  }
}
