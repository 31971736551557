import styled, { css } from 'styled-components';

import { base, screens, colors } from '../../../../styles';

export const MainPage = styled.div`
  max-width: ${base * 88}px;
`;

export const TitleCust = styled.div`
  max-width: ${base * 24.25}px;
  max-height: 38px;
  font-size: 32px;
  color: #000000;
  span {
    margin-right: ${base - 6}px;
    &:last-child {
      color: ${colors.primary_red};
    }
  }
  @media (max-width: ${screens.screen_xs_max}) {
    margin-bottom: 12px;
  }
`;

export const TitleCustConatiner = styled.div`
  font-size: 32px;
  color: #000000;
  span {
    margin-right: ${base - 6}px;
    &:last-child {
      color: ${colors.primary_red};
    }
  }
`;

export const CustColumnsMain = styled.div`
  background: #ffffff;
  box-shadow: 1px 1px 32px rgba(0, 0, 0, 0.05);
  border-radius: ${base - 6}px;
  padding: 38px 46px;
  p {
    line-height: 24px;
    font-size: 16px;
    color: #000000;
    margin-bottom: 20px;
  }
  @media (max-width: ${screens.screen_xs_max}) {
    padding: 24px;
  }
`;

export const NameColumns = styled.h1`
  font-size: 20px;
  color: #000000;
  margin-bottom: 28px;
  margin-right: 12px;
  width: 30%;

  @media (max-width: ${screens.screen_xs_max}) {
    width: 100%;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ColumnsSection = styled.div`
  margin-bottom: 12px;
`;

export const CheckBoxWrapper = styled.div`
  margin-right: 12px;
  width: 30%;
  margin-bottom: 20px;
  @media (max-width: ${screens.screen_md_max}) {
    width: 45%;
  }
  @media (max-width: ${screens.screen_md_min}) {
    width: 50%;
  }
  ${(props) =>
    props.changeRole &&
    css`
      width: 20%;
    `}
`;

export const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  @media (max-width: ${screens.screen_xs_max}) {
    flex-direction: column;
  }
`;

export const PopupCustTitle = styled.div`
  min-width: 324px;
  max-width: 594px;
  div {
    position: fixed;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
    bottom: -100px;
    transition: bottom 0.3s ease-in-out;
    bottom: 20px;
    border-radius: 8px;
    font-size: 20px;
  }
`;

export const SectionTitleWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

export const InputSearchBlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const InputSearchBlock = styled.div`
  position: relative;
  width: 250px;
  margin-bottom: ${base}px;

  input {
    padding-right: 24px;
    padding-left: 10px;
  }
`;

export const FiltersValueDelete = styled.img`
  width: 10px;
  position: absolute;
  top: 6px;
  right: 4px;
  outline: none;
  cursor: pointer;
`;

export const ContainerInput = styled.div`
  width: 250px;
  margin-bottom: ${base}px;
  margin-right: ${base}px;
`;

export const ContainerInputModal = styled.div`
  width: 250px;
  margin-bottom: ${base * 2}px;
`;

export const ContainerSelectDropList = styled.div`
  div {
    width: 97%;
  }

  input {
    cursor: pointer;
    caret-color: transparent;
  }
`;

export const ButtonGroups = styled.div`
  display: flex;

  button {
    margin-left: ${base}px;
  }

  button:first-child {
    margin-left: 0;
  }
`;
