/**
 * @flow
 */
import React from 'react';

import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import ruRu from 'rc-calendar/lib/locale/ru_RU';
import '../../styles/calendar/index.css';

import moment from 'moment';
import 'moment/locale/ru';

import {} from './CalendarStyled';

type CalendarViewPropsType = {
  eventOnOk: (any) => void,
  showToday: Boolean,
  showClear: Boolean,
  selectedValue: Array,
  onSelect: (any) => void,
  onChange: (any) => void,
};

moment.locale('ru');
const now = moment();
now.utcOffset(3);
const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');
const formatStr = 'DD-MM-YYYY';

export default function CalendarView(props: CalendarViewPropsType) {
  return (
    <RangeCalendar
      showToday={
        props.showToday !== undefined && props.showToday !== null
          ? props.showToday
          : true
      }
      dateInputPlaceholder={['Дата начала', 'Дата конца']}
      locale={ruRu}
      showOk
      showClear={
        props.showClear !== undefined && props.showClear !== null
          ? props.showClear
          : true
      }
      format={formatStr}
      onOk={(value) => props.eventOnOk(value)}
      selectedValue={props.selectedValue}
      onSelect={(value) => props.onSelect(value)}
      renderFooter={() => <span />}
      onChange={(value) => props.onChange(value)}
    />
  );
}
