/**
 * @flow
 */
import React from 'react';
import moment from 'moment';

import {
  Form,
  Title,
  TitleText,
  FieldContainer,
  FieldTitle,
  FieldValue,
  ExportButton,
  EditButton,
  DeleteButton,
  MobileButtons,
  MobileButton,
} from './RequestStyles';

import { Button, Checkbox } from '../index';

type RequestPropsType = {
  data: Object,
  columnTitles: Object,
  export: () => void,
  edit: () => void,
  delete: () => void,
  setShowRemoveRequest: () => void,
  access: Object,
};

const formatNumber = (num) => {
  const formatNum = num.toLocaleString('ru').replace(',', '.');
  const arrNum = formatNum.split('.');

  if (!arrNum[1]) {
    arrNum[1] = '00';
  }
  if (arrNum[1] && arrNum[1].length < 2) {
    arrNum[1] += '0';
  }

  return `${arrNum[0]}.${arrNum[1]}`;
};

export default function RequestView(props: RequestPropsType) {
  let color = '#A9D08E';
  const data = [];
  const section = [];
  // Инициализация цвета статуса
  switch (props.data.state) {
    case 'Предварительное':
      color = '#A9D08E';
      break;
    case 'В пути':
      color = '#FFFF00';
      break;
    case 'Прибыл':
      color = '#da9d0a';
      break;
    case 'Выдан клиенту':
      color = '#92D050';
      break;
    case 'Прогон':
      color = '#F4B084';
      break;
    case 'Закрыта':
      color = '#808080';
      break;
    case 'Вывоз':
      color = '#F58223';
      break;
    default:
      color = '#A9D08E';
      break;
  }
  Object.keys(props.columnTitles).forEach((key) => {
    if (
      key !== 'state' &&
      key !== 'number' &&
      key !== 'buh_amountMore' &&
      key !== 'buh_carrierMore' &&
      key !== 'buh_exportAgentMore'
    ) {
      let fieldValue;
      if (props.data[key] !== null && props.data[key] !== undefined) {
        switch (typeof props.data[key]) {
          case 'string':
            if (key === 'sendLoadingDataStatus') {
              let name = '';
              if (props.data[key] === 'success') {
                name = 'данные актуальны';
              } else if (props.data[key] === 'pending') {
                name = 'данные ждут отправки';
              } else if (props.data[key] === 'error') {
                name = 'ошибка отправки';
              }
              fieldValue = (
                <FieldValue>
                  {props.data[key] !== '' ? name : 'нет данных'}
                </FieldValue>
              );
            } else {
              fieldValue = (
                <FieldValue>
                  {props.data[key] !== '' ? props.data[key] : 'нет данных'}
                </FieldValue>
              );
            }
            break;
          case 'object':
            let value = '';
            if (key === 'payers') {
              if (props.data[key].length > 0) {
                props.data[key].forEach((item, index) => {
                  value += item.name;
                  value += index === props.data[key].length - 1 ? '' : ', ';
                });
              }
            }
            if (props.data[key].id) {
              value = props.data[key].data;
            }
            if (key === 'currentLocation') {
              value = props.data[key].data || '';
            }
            fieldValue = (
              <FieldValue>{value !== '' ? value : 'нет данных'}</FieldValue>
            );
            break;
          case 'boolean':
            fieldValue = (
              <FieldValue>
                <Checkbox value={props.data[key]} disabled />
              </FieldValue>
            );
            break;
          default:
            if (key === 'rating') {
              fieldValue = (
                <FieldValue>
                  {props.data[key]
                    ? formatNumber(props.data[key].toFixed(2))
                    : '0.00'}
                </FieldValue>
              );
            } else {
              fieldValue =
                key.includes('buh') ||
                key.includes('additionalLoadingAddress') ? (
                  <FieldValue>
                    {props.data[key] ? formatNumber(props.data[key]) : '0.00'}
                  </FieldValue>
                ) : (
                  <FieldValue>{props.data[key]}</FieldValue>
                );
            }
            break;
        }
      } else {
        fieldValue = <FieldValue>нет данных</FieldValue>;
      }
      switch (key) {
        case 'dateLoad':
        case 'timeLoad':
        case 'typeSend':
        case 'typeContainer':
        case 'typeElivery':
        case 'payer':
        case 'payers':
        case 'shipper':
        case 'consignee':
        case 'addressLoading':
        case 'addressUnloading':
        case 'customerApplicationNumber':
        case 'loadActual':
        case 'weight':
        case 'numberSeats':
        case 'plannedIssueDate':
        case 'scheduledIssueTime':
        case 'od':
        case 'shipment':
        case 'dateArrival':
        case 'warming':
        case 'veterinaryCertificate':
        case 'gdnUnloading':
        case 'loadFastening':
        case 'agentDocuments':
        case 'forwarderLoading':
        case 'forwarderUnloading':
        case 'moversOnLoading':
        case 'moversAtUnloading':
        case 'tracking':
        case 'additionalServices':
        case 'buh_amount':
        case 'manager':
        case 'managerByRegion':
        case 'attorneyPowerNumber':
        case 'additionalServicesCounterparty':
        case 'note':
          if (!section.includes('customerservice')) {
            section.push('customerservice');
            data.push(
              <FieldContainer
                style={{
                  borderTop: '85px solid transparent',
                  marginTop: '-85px',
                }}
                id="customerservice"
                key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
          } else {
            data.push(
              <FieldContainer key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
          }
          break;
        case 'plannedDispatchDate':
        case 'actualDateDispatch':
        case 'railCarrier':
        case 'railCarrierStatus':
        case 'prrOnArrived':
        case 'ownerContainer':
        case 'terminalStaging':
        case 'stationDeparture':
        case 'stationDestination':
        case 'destinationCity':
        case 'loadDocumented':
        case 'cargoCode':
        case 'instructionsDelivery':
        case 'releases':
        case 'security':
        case 'warmingCompany':
        case 'companyNameForwarder':
        case 'companyMoversLoading':
        case 'containerAccessory':
        case 'currentLocation':
        case 'departureVesselDate':
        case 'arrivalVesselDate':
        case 'notificationDate':
          if (!section.includes('shipmentdepartment')) {
            section.push('shipmentdepartment');
            data.push(
              <FieldContainer
                style={{
                  borderTop: '85px solid transparent',
                  marginTop: '-85px',
                }}
                id="shipmentdepartment"
                key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
          } else {
            data.push(
              <FieldContainer key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
          }
          break;
        case 'numberContainer':
        case 'numberSeal':
        case 'agentAutoLoad':
        case 'driver':
        case 'unloadingDriverTaskStatus':
        case 'car':
        case 'trailer':
          if (!section.includes('autodepartment')) {
            section.push('autodepartment');
            data.push(
              <FieldContainer
                style={{
                  borderTop: '85px solid transparent',
                  marginTop: '-85px',
                }}
                id="autodepartment"
                key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
          } else {
            data.push(
              <FieldContainer key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
          }
          break;
        case 'agentAutoCall':
        case 'exportAgentEurosib':
        case 'agentAutoCallStatus':
        case 'autoBeforeUnloading':
        case 'companyMoversUnloading':
        case 'dateIssue':
        case 'timeIssue':
        case 'returnOfDocumentsFromUnloading':
        case 'act':
        case 'railwayInvoices':
        case 'loading':
        case 'loadingTN':
        case 'unloadingTN':
        case 'documentStatus':
        case 'timofeevo':
        case 'timurovo':
          if (!section.includes('documentdepartment')) {
            section.push('documentdepartment');
            data.push(
              <FieldContainer
                style={{
                  borderTop: '85px solid transparent',
                  marginTop: '-85px',
                }}
                id="documentdepartment"
                key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
          } else {
            data.push(
              <FieldContainer key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
          }
          break;
        case 'buh_carrier':
        case 'buh_autoBeforeLoading':
        case 'buh_forMKAD_rate':
        case 'buh_forMKAD_km':
        case 'buh_forMKAD':
        case 'additionalLoadingAddress':
        case 'buh_plain_rate':
        case 'buh_plain_hour':
        case 'buh_plain':
        case 'buh_overload_rate':
        case 'buh_overload_ton':
        case 'buh_overload':
        case 'buh_containerAgent':
        case 'buh_aboveTheNorm_rate':
        case 'buh_aboveTheNorm_day':
        case 'buh_aboveTheNorm':
        case 'buh_exportAgent':
        case 'buh_simpleToUnload_rate':
        case 'buh_simpleToUnload_hour':
        case 'buh_simpleToUnload':
        case 'buh_simpleToLoad_rate':
        case 'buh_simpleToLoad_hour':
        case 'buh_simpleToLoad':
        case 'buh_storageAtTheDestinationStation_rate':
        case 'buh_storageAtTheDestinationStation_day':
        case 'buh_storageAtTheDestinationStation':
        case 'buh_moversAtUnloading':
        case 'buh_forwarderOnLoading':
        case 'buh_moversOnLoading':
        case 'buh_veterinaryCertificate':
        case 'buh_loadFastening':
        case 'buh_warming':
        case 'buh_gasketMaterial':
        case 'buh_additionalServices':
        case 'buh_seal':
        case 'buh_bills':
        case 'buh_agentToClient':
        case 'buh_managerial':
        case 'buh_agentSuppliers':
        case 'buh_OBN':
          if (!section.includes('financialdepartment')) {
            section.push('financialdepartment');
            data.push(
              <FieldContainer
                style={{
                  borderTop: '85px solid transparent',
                  marginTop: '-85px',
                }}
                id="financialdepartment"
                key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
          } else {
            data.push(
              <FieldContainer key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
          }
          break;
        case 'customerAccountNumber':
        case 'customerAccountNumberStatus':
        case 'customerAccountDate':
        case 'carrierAccount':
        case 'carrierAccountStatus':
        case 'accountsAutoServices':
        case 'accountsAutoServicesStatus':
        case 'containerAgentAccount':
        case 'containerAgentAccountStatus':
        case 'exportAgentAccount':
        case 'exportAgentAccountStatus':
        case 'moversAccountOnLoading':
        case 'moversAccountOnLoadingStatus':
        case 'moversAccountOnUnloading':
        case 'moversAccountOnUnloadingStatus':
        case 'accountSecuringCargo':
        case 'accountSecuringCargoStatus':
        case 'billCargoWeatherization':
        case 'billCargoWeatherizationStatus':
        case 'accountStorageAtDestinationStation':
        case 'accountStorageAtDestinationStationStatus':
        case 'accountSimpleToUnload':
        case 'accountSimpleToUnloadStatus':
        case 'accountSimpleToLoad':
        case 'accountSimpleToLoadStatus':
        case 'accountOverload':
        case 'accountOverloadStatus':
        case 'accountAboveTheNorm':
        case 'accountAboveTheNormStatus':
        case 'accountAdditionalServices':
        case 'accountAdditionalServicesStatus':
        case 'exhibitedSimpleToUnload':
        case 'exhibitedStorage':
        case 'exhibitedOverflow':
        case 'exhibitedAdditionalServices':
          if (!section.includes('billnumbers')) {
            section.push('billnumbers');
            data.push(
              <FieldContainer
                style={{
                  borderTop: '85px solid transparent',
                  marginTop: '-85px',
                }}
                id="billnumbers"
                key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
            if ('buh_amount' in props.columnTitles) {
              data.push(
                <FieldContainer key="buh_amount-2">
                  <FieldTitle>Сумма счета клиента (сумма)</FieldTitle>
                  <FieldValue>
                    {props.data.buh_amount
                      ? formatNumber(props.data.buh_amount)
                      : 'нет данных'}
                  </FieldValue>
                </FieldContainer>,
              );
            }
          } else {
            data.push(
              <FieldContainer key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
          }
          break;
        case 'dateCreate':
        case 'buh_total':
        case 'buh_pay':
        case 'buh_containerAgentPayment':
        case 'paymentState':
        case 'statusSendForm':
        case 'sendLoadingDataStatus':
          if (!section.includes('income')) {
            section.push('income');
            data.push(
              <FieldContainer
                style={{
                  borderTop: '85px solid transparent',
                  marginTop: '-85px',
                }}
                id="income"
                key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                <FieldValue>{fieldValue}</FieldValue>
              </FieldContainer>,
            );
          } else {
            data.push(
              <FieldContainer key={key}>
                <FieldTitle>{props.columnTitles[key]}</FieldTitle>
                {fieldValue}
              </FieldContainer>,
            );
          }
          break;
        case 'balance':
          data.push(
            <FieldContainer key={key}>
              <FieldTitle>{props.columnTitles[key]}</FieldTitle>
              <FieldValue>
                {props.data.balance ? formatNumber(props.data.balance) : '0.00'}
              </FieldValue>
            </FieldContainer>,
          );
          break;
        default:
          data.push(
            <FieldContainer key={key}>
              <FieldTitle>{props.columnTitles[key]}</FieldTitle>
              {fieldValue}
            </FieldContainer>,
          );
          break;
      }
    }
  });

  return (
    <Form>
      <MobileButtons>
        {props.access.directory.requests.export ? (
          <MobileButton>
            <Button circle clicked={() => props.export()}>
              <img
                style={{ width: '12px' }}
                src={require('../../assets/icons/export.svg')}
                alt="export"
              />
            </Button>
          </MobileButton>
        ) : null}
        {props.access.directory.requests.update ? (
          <MobileButton>
            <Button circle clicked={() => props.edit()}>
              <img
                style={{ width: '14px' }}
                src={require('../../assets/icons/edit.svg')}
                alt="edit"
              />
            </Button>
          </MobileButton>
        ) : null}
        {props.access.directory.requests.delete ? (
          <MobileButton>
            <Button circle clicked={() => props.setShowRemoveRequest(true)}>
              <img
                style={{ width: '26px' }}
                src={require('../../assets/icons/delete-white.svg')}
                alt="delete"
              />
            </Button>
          </MobileButton>
        ) : null}
      </MobileButtons>
      <Title>
        <TitleText style={{ borderLeft: `10px solid ${color}` }}>
          №{props.data.number ? props.data.number : ' -'}, {props.data.state}
        </TitleText>
        {props.access.directory.requests.export ? (
          <ExportButton>
            <Button circle clicked={() => props.export()}>
              <img
                style={{ width: '12px' }}
                src={require('../../assets/icons/export.svg')}
                alt="export"
              />
            </Button>
          </ExportButton>
        ) : (
          <div style={{ marginLeft: '30px' }} />
        )}
        {props.access.directory.requests.update ? (
          <EditButton>
            <Button circle clicked={() => props.edit()}>
              <img
                style={{ width: '14px' }}
                src={require('../../assets/icons/edit.svg')}
                alt="edit"
              />
            </Button>
          </EditButton>
        ) : null}
        {props.access.directory.requests.delete ? (
          <DeleteButton>
            <Button circle clicked={() => props.setShowRemoveRequest(true)}>
              <img
                style={{ width: '26px' }}
                src={require('../../assets/icons/delete-white.svg')}
                alt="delete"
              />
            </Button>
          </DeleteButton>
        ) : null}
      </Title>
      {data}
    </Form>
  );
}

RequestView.defaultProps = {};
