import {
  compose,
  withProps,
  withState,
  withHandlers,
  lifecycle,
} from 'recompose';

import { connect } from 'react-redux';

import { setAllFilterModel } from '../../../store/actions/request';

import { setOpenFilterField } from '../../../store/actions/dashboard';

import { TextView } from './TextView';

export default (options) =>
  compose(
    connect(
      (state) => ({
        filterModel: state.request.allFilterModel || {},
        openFilterField: state.dashboard.openFilterField,
        expand: state.dashboard.expand,
      }),
      (dispatch) => ({
        setFilterModel: (data, login) =>
          dispatch(setAllFilterModel(data, login)),
        setOpenFilterField: (data) => dispatch(setOpenFilterField(data)),
      }),
    ),
    withProps((props) => ({
      ...props,
      ...options,
    })),
    withState('localItems', 'setLocalItems', {}),
    withHandlers({
      toggleOpened: (props) => () => {
        props.setOpenFilterField(
          props.column.colId === props.openFilterField
            ? ''
            : props.column.colId,
        );
        props.setLocalItems({ ...props.filterModel });
        setTimeout(() => {
          const elemFilter = document.getElementById(
            `custom-ag-filter-${props.column.colId}`,
          );
          if (elemFilter) {
            const elem = elemFilter.children[1];
            if (elem) {
              const { clientWidth } = document.body;
              const coords = elem.getBoundingClientRect();
              if (coords.right > clientWidth - 51) {
                if (props.expand) {
                  elem.style.left = `${clientWidth - coords.width - 51 - 4}px`;
                } else {
                  elem.style.left = `${clientWidth - coords.width - 51 - 20}px`;
                }
              } else {
                elem.style.left = null;
              }
            }
          }
        }, 100);

        if (document.getElementsByClassName('ag-header-container')[0]) {
          document.getElementsByClassName(
            'ag-header-container',
          )[0].style.transform = null;
        }
      },
      changeFilter: (props) => (field, value) => {
        props.setLocalItems({ ...props.filterModel, [field]: value });
      },
      applyFilter: (props) => () => {
        props.setFilterModel({ ...props.localItems }, props.user.login);
        props.setOpenFilterField('');
        setTimeout(() => props.initData(), 0);
      },
      resetFilter: (props) => (field, index) => {
        props.setFilterModel(
          { ...props.filterModel, [field]: { values: [] } },
          props.user.login,
        );
        props.setOpenFilterField('');
        setTimeout(() => props.initData(), 0);
      },
      clearFilter: (props) => (field, index) => {
        const values = [];
        values[index] = '';
        props.setFilterModel(
          { ...props.filterModel, [field]: { values } },
          props.user.login,
        );
        props.setOpenFilterField('');
        setTimeout(() => props.initData(), 0);
      },
      handleClickOutside: (props) => (e) => {
        const node = document.getElementById(
          `custom-ag-filter-${props.column.colId}`,
        );
        if (
          !e.path.includes(node) &&
          props.openFilterField === props.column.colId
        ) {
          props.setOpenFilterField('');
        }
      },
      handleScrollTable: (props) => (e) => {
        if (props.column.colId === props.openFilterField) {
          const elemFilter = document.getElementById(
            `custom-ag-filter-${props.column.colId}`,
          );
          if (elemFilter) {
            const elem = elemFilter.children[1];
            if (elem) {
              const coordsElem = elem.getBoundingClientRect();
              const coordsElemFilter = elemFilter.getBoundingClientRect();
              if (
                coordsElem.left > coordsElemFilter.left &&
                elem.style.left !== ''
              ) {
                elem.style.left = '';
              }
            }
          }
        }
      },
    }),
    lifecycle({
      componentDidMount() {
        document.addEventListener('click', this.props.handleClickOutside);
        const scrollableNode = document.getElementsByClassName(
          'ag-center-cols-viewport',
        )[0];
        if (scrollableNode) {
          scrollableNode.addEventListener(
            'scroll',
            this.props.handleScrollTable,
          );
        }
      },
      componentWillUnmount() {
        document.removeEventListener('click', this.props.handleClickOutside);
        if (this.props.column.colId === this.props.openFilterField) {
          this.props.setOpenFilterField('');
        }
        const scrollableNode = document.getElementsByClassName(
          'ag-center-cols-viewport',
        )[0];
        if (scrollableNode) {
          scrollableNode.removeEventListener(
            'scroll',
            this.props.handleScrollTable,
          );
        }
      },
    }),
  )(TextView);
