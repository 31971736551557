import * as actionTypes from './actionTypes';

export function setActiveSidebarItem(data) {
  return {
    type: actionTypes.SET_ACTIVE_SIDEBAR_ITEM,
    payload: data,
  };
}

export function setActiveSecondarySidebarItem(data) {
  return {
    type: actionTypes.SET_ACTIVE_SECONDARY_SIDEBAR_ITEM,
    payload: data,
  };
}

export function setSecondaryBarItems(data) {
  return {
    type: actionTypes.SET_SECONDARY_BAR_ITEMS,
    payload: data,
  };
}

export function checkStatusPopup(data) {
  return {
    type: actionTypes.CHECK_STATUS_POPUP,
    payload: data,
  };
}

export function setNeedPadding(state) {
  return {
    type: actionTypes.NEED_PADDING,
    payload: state,
  };
}

export function setExpand(state) {
  return {
    type: actionTypes.SET_EXPAND,
    payload: state,
  };
}

export function setNewRequest(state) {
  return {
    type: actionTypes.SHOW_NEW_REQUEST,
    payload: state,
  };
}

export function setToggleDrop(state) {
  return {
    type: actionTypes.TOGGLE_DROP,
    payload: state,
  };
}

export function setToggleLoad(state) {
  return {
    type: actionTypes.TOGGLE_LOAD,
    payload: state,
  };
}

export function setOpenFilterField(data) {
  return {
    type: actionTypes.SET_OPEN_FILTER_FIELD,
    payload: data,
  };
}

export function setQuantityRequests(data) {
  return {
    type: actionTypes.SET_QUANTITY_REQUESTS,
    payload: data,
  };
}

export function setShowWarningSaveRequest(data) {
  return {
    type: actionTypes.SET_SHOW_WARNING_SAVE_REQUEST,
    payload: data,
  };
}

export function setCallbackExitRequest(data) {
  return {
    type: actionTypes.SET_CALLBACK_EXIT_REQUEST,
    payload: data,
  };
}

export function setDataRequest(data) {
  return {
    type: actionTypes.SET_DATA_REQUEST,
    payload: data,
  };
}

export function setChangedDataRequest(data) {
  return {
    type: actionTypes.SET_CHANGED_DATA_REQUEST,
    payload: data,
  };
}

export function setDataPaymentRequest(data) {
  return {
    type: actionTypes.SET_DATA_PAYMENT_REQUEST,
    payload: data,
  };
}

export function setChangedDataPaymentRequest(data) {
  return {
    type: actionTypes.SET_CHANGED_DATA_PAYMENT_REQUEST,
    payload: data,
  };
}

export function setExcelData(data) {
  return {
    type: actionTypes.SET_EXCEL_DATA,
    payload: data,
  };
}

export function setExcelRowIndex(data) {
  return {
    type: actionTypes.SET_EXCEL_ROW_INDEX,
    payload: data,
  };
}
