import * as actionTypes from '../actions/actionTypes';

const initialState = {
  showRatingModal: false,
  lastIndexRowRequest: null,
  allFilterModel: {
    values: [],
    ids: {},
  },
  selectedFilters: [],
  dateFilters: '',
  rangeDate: [],
  dateFiltersActive: false,
  selectedStatus: '',
  sortModel: [],
  searchModel: '',
  allFilterModelByLogin: {},
  otherFilters: {},
  sortModelByLogin: {},
  payers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW_RATING_MODAL:
      return {
        ...state,
        showRatingModal: action.payload,
      };
    case actionTypes.SET_LAST_INDEX_ROW_REQUEST:
      return {
        ...state,
        lastIndexRowRequest: action.payload,
      };
    case actionTypes.SET_ALL_FILTER_MODEL:
      return {
        ...state,
        allFilterModel: action.payload.data,
        allFilterModelByLogin: action.payload.login
          ? {
              ...state.allFilterModelByLogin,
              [action.payload.login]: action.payload.data,
            }
          : state.allFilterModelByLogin,
      };
    case actionTypes.SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: action.payload,
      };
    case actionTypes.SET_DATE_FILTERS:
      return {
        ...state,
        dateFilters: action.payload,
      };
    case actionTypes.SET_RANGE_DATE:
      return {
        ...state,
        rangeDate: action.payload,
      };
    case actionTypes.SET_DATE_FILTERS_ACTIVE:
      return {
        ...state,
        dateFiltersActive: action.payload,
      };
    case actionTypes.SET_SELECTED_STATUS:
      return {
        ...state,
        selectedStatus: action.payload,
      };
    case actionTypes.SET_SORT_MODEL:
      return {
        ...state,
        sortModel: action.payload,
      };
    case actionTypes.SET_SEARCH_MODEL:
      return {
        ...state,
        searchModel: action.payload,
      };
    case actionTypes.SET_OTHER_FILTERS:
      return {
        ...state,
        otherFilters: state.otherFilters
          ? {
              ...state.otherFilters,
              [action.payload.login]: {
                ...state.otherFilters[action.payload.login],
                ...action.payload.data,
              },
            }
          : { [action.payload.login]: { ...action.payload.data } },
      };
    case actionTypes.SET_SORT_MODEL_BY_LOGIN:
      return {
        ...state,
        sortModelByLogin: state.sortModelByLogin
          ? {
              ...state.sortModelByLogin,
              [action.payload.login]: [...action.payload.data],
            }
          : { [action.payload.login]: [...action.payload.data] },
      };
    case actionTypes.SET_PAYERS:
      return {
        ...state,
        payers: action.payload,
      };
    default:
      return state;
  }
}
