import styled from 'styled-components';

import { base, colors } from '../../styles';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: ${base * 36.5}px;
  width: 100%;
  box-sizing: border-box;

  font-weight: 900;
`;

export const InputMargin = styled.div`
  margin-bottom: 18px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 38px;
  border-radius: ${base / 2}px;
  border-left: 12px solid ${colors.primary_red};

  input {
    font-size: ${base * 1.333333}px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 0;

  button {
    transition: all 0.3s ease-in-out;
    &:hover {
      box-shadow: 0 0 18px rgba(218, 53, 10, 0.7);
    }
  }

  @media (max-width: 800px) {
    button {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const FormHeading = styled.h4`
  margin: 0;
  color: ${colors.black};
  font-size: 24px;
  margin-bottom: ${base * 1.8}px;
  margin-top: -3px;
`;
