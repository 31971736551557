/**
 * @flow
 */

import React from 'react';

import {
  StampFilterWraper,
  StampFilterHeader,
  StampFilterName,
  StampFilterOptions,
  StampClose,
} from './StampFilterStyled';

type StampFilterPropsType = {
  children: any,
  toggledOn: (any) => void,
  toggle: string,
  convertToArray: (any) => Array<any>,
  currentState: String,
  setCurrentState: (any) => void,
  clickClose: (any) => void,
};

export default function StampFilter(props: StampFilterPropsType) {
  let { children } = props;
  const isArray = Array.isArray(children);

  if (!isArray) {
    children = props.convertToArray();
  }

  const newChildItem = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      clickItemF: () => {
        props.setCurrentState(child.props.title);
      },
    }),
  );

  return (
    <StampFilterWraper>
      <StampFilterHeader
        currentStateItem={props.currentState !== 'Тип отправки'}>
        <StampFilterName onClick={props.toggle}>
          {props.currentState}
        </StampFilterName>
        <StampClose onClick={props.clickClose} />
      </StampFilterHeader>
      {props.toggledOn && (
        <StampFilterOptions onClick={props.toggle}>
          {newChildItem}
        </StampFilterOptions>
      )}
    </StampFilterWraper>
  );
}
