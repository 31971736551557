import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import CalendarSingle from 'rc-calendar';
import ruRu from 'rc-calendar/lib/locale/ru_RU';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import {
  Calendar,
  Button,
  Input,
  Popup,
  ModaleWindow,
  Rating,
  SelectStatuses,
  Tooltip,
  StatusBar,
  Loader,
  StatusRequest,
} from '../../../components';
import IconYesNoView from '../../../components/IconYesNo/IconYesNoView';
import { PopupMessage, ModaleShadow } from '../Directories/DirectoriesStyled';

import { formatDate } from '../../../helpers/Filters';

import {
  DirectoryFilter,
  NumberFloatingFilter,
  TextFloatingFilter,
  DateFloatingFilter,
} from '../../../components/CustomAgFilters';

import {
  Form,
  ControlContainer,
  FiltersContainer,
  TableContainer,
  CalendarContainer,
  TitleContainer,
  TitleButton,
  CalendarButton,
  InputDate,
  InputSearch,
  InputTable,
  FiltersValueContainer,
  FiltersValueTitle,
  FiltersValue,
  FiltersValueDelete,
  DateFiltersValueDelete,
  Select,
  ValueOptions,
  OptionValues,
  MobileInputSearch,
  Heading,
  Loaders,
  ExpandButtonWrapper,
  IconButtonWrapper,
  Textarea,
  ModalContentWrapper,
  ButtonsBlock,
} from './RequestTableStyles';

type RequestTablePropsType = {
  availableFields: Object,
  viewCalendar: boolean,
  showRatingModal: boolean,
  isReviewSending: boolean,
  ratingReview: String,
  clickCalendar: () => void,
  setViewCalendar: (any) => void,
  changeDateRange: (any) => void,
  dateFilters: string,
  dateFiltersActive: boolean,
  deleteDateFilter: () => void,
  filters: Object,
  applyFilters: () => void,
  changeFilters: (any, any) => void,
  selectedFiltersValue: (any, any) => void,
  deleteFilter: (any) => void,
  search: (any) => void,
  selectedRequests: Array<String>,
  typeFields: Object,
  showAutocompleteValues: Object,
  autocompleteValues: Object,
  getExcel: (any) => void,
  addRequest: () => void,
  deleteRequests: () => void,
  cancel: () => void,
  filtersActivePoint: boolean,
  isLoading: boolean,
  expand: boolean,
  setExpand: (boolean) => void,
  copyRequest: (string) => void,
  access: Object,
  setRating: (String, Number) => void,
  setRatingReview: (String) => void,
  readOnlyRating: () => void,
  sendReview: (any) => void,
  closeReviewModal: () => void,
  selectStatus: (any) => void,
  rangeDate: Array,
  setRangeDate: (any) => void,
  postRequest: (any) => void,
  setShowFilterBlock: (any) => void,
  isShowFilterBlock: boolean,
  removeFullScreen: (any) => void,
  addFullScreen: (any) => void,
  setDelPop: (any) => void,
  setDelSeveralRequests: (any) => void,
  delSeveralRequests: boolean,
  editRequest: (any) => void,
  closeDateModal: (any) => void,
  setModalDateValue: (any) => void,
  setModalDateValueMoment: (any) => void,
  idRequestEdit: boolean,
  isShowModalDate: boolean,
  modalDateValue: String,
  modalDateValueMoment: String,
  fieldRequestEdit: String,
  setShowWarning: (any) => void,
  showWarning: boolean,
  isShowModalTime: boolean,
  showWarningTime: boolean,
  closeTimeModal: (any) => void,
  setShowWarningTime: (any) => void,
  setModalTimeValue: (any) => void,
  modalTimeValue: String,
};

const statusBar = {
  statusPanels: [
    {
      statusPanel: 'statusBarComponent',
      align: 'left',
      statusPanelParams: {
        type: 'rows',
      },
    },
    {
      statusPanel: 'statusBarComponent',
      align: 'center',
      statusPanelParams: {
        type: 'totalRows',
      },
    },
    { statusPanel: 'agFilteredRowCountComponent' },
    { statusPanel: 'agSelectedRowCountComponent' },
    { statusPanel: 'agAggregationComponent' },
  ],
};

const localeText = {
  // for filter panel
  loadingOoo: 'Загрузка...',
  to: 'до',
  of: 'из',
  // for set filter
  selectAll: 'Выбрать все',
  searchOoo: 'Поиск...',
  // for number filter and text filter
  filterOoo: 'Фильтровать...',
  applyFilter: 'Применить фильтр',
  resetFilter: 'Сбросить фильтр',
  clearFilter: 'Очистить фильтр',
  equals: 'Равно',
  notEqual: 'Не равно',
  // for number filter
  lessThan: 'Меньше чем',
  greaterThan: 'Больше чем',
  lessThanOrEqual: 'Меньше чем или равно',
  greaterThanOrEqual: 'Больше чем или равно',
  inRange: 'В диапазоне',
  // for text filter
  contains: 'Содержит',
  notContains: 'Не содержит',
  startsWith: 'Начинается с',
  endsWith: 'Заканчивается чем',
  // filter conditions
  andCondition: 'И',
  orCondition: 'ИЛИ',
  // enterprise menu aggregation and status bar
  sum: 'Сумма',
  min: 'Минимум',
  max: 'Максимум',
  none: 'Нет',
  count: 'Количество',
  average: 'Среднее',
  filteredRows: 'Отфильтрованно строк',
  selectedRows: 'Выбрано строк',
  totalRows: 'Всего строк',
  totalAndFilteredRows: 'Строк',
  // other
  noRowsToShow: 'Нет данных...',
};

export default function RequestTableView(props: RequestTablePropsType) {
  const filters = [];
  const autocompleteValues = {};
  let dateFilters = '';
  const noteChange = `Вы точно хотите изменить поле "${
    props.availableFields[props.fieldRequestEdit]
  }"?`;
  const noteRemove = `Вы точно хотите удалить поле "${
    props.availableFields[props.fieldRequestEdit]
  }"?`;

  // генерация автокомплитов фильтров
  Object.keys(props.autocompleteValues).forEach((field) => {
    autocompleteValues[field] = [];
    props.autocompleteValues[field].forEach((value) => {
      let data = '';
      Object.keys(value).forEach((key) => {
        if (key !== '_id') {
          if (key !== 'id') {
            if (value[key]) {
              if (!Array.isArray(value[key])) {
                data += value[key];
                data += ' ';
              }
            }
          }
        }
      });
      autocompleteValues[field].push(
        <OptionValues
          key={value.id || value._id}
          onClick={() => props.selectedFiltersValue(field, value)}>
          {data}
        </OptionValues>,
      );
    });
  });
  // генерация строки фильтрации
  if (Object.keys(props.filters).length !== 0) {
    Object.keys(props.filters).forEach((field) => {
      switch (props.typeFields[field].type) {
        case 'input': {
          let inputTextValue = '';
          if (props.filters[field]) {
            if (props.typeFields[field].inputType === 'date') {
              const dateSplit = props.filters[field].split('-');
              if (dateSplit.length === 3) {
                inputTextValue = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
              }
            } else if (props.typeFields[field].inputType === 'number') {
              inputTextValue =
                `${props.filters[field]}`.replace(',', '.') || '';
            } else if (field === 'weight' || field === 'numberSeats') {
              inputTextValue = props.filters[field] || '';
            }
          }
          filters.push(
            <FiltersValueContainer key={field} style={{ width: 'auto' }}>
              <FiltersValueTitle>
                {props.availableFields[field]}
              </FiltersValueTitle>
              <FiltersValue>
                <Input
                  type="text"
                  value={inputTextValue}
                  changed={(value) => {
                    if (props.typeFields[field].inputType === 'date') {
                      const [year, month, day] = value.split('-');
                      value = `${day}-${month}-${year}`;
                    }
                    props.changeFilters(field, value);
                  }}
                  disabled={
                    props.typeFields[field].inputType === 'date' ||
                    props.typeFields[field].inputType === 'number'
                  }
                />
                <FiltersValueDelete
                  onClick={() => props.deleteFilter(field)}
                  src={require('../../../assets/icons/close-default.svg')}
                  alt="delete"
                />
              </FiltersValue>
            </FiltersValueContainer>,
          );
          break;
        }
        case 'select': {
          let data = props.filters[field];
          const options = [];
          if (!data) {
            data = '';
          }
          props.typeFields[field].values.forEach((value) => {
            options.push(<option key={value}>{value}</option>);
          });
          filters.push(
            <FiltersValueContainer key={field}>
              <FiltersValueTitle>
                {props.availableFields[field]}
              </FiltersValueTitle>
              <FiltersValue>
                <Select
                  defaultValue={data}
                  onChange={(event) =>
                    props.changeFilters(field, event.target.value)
                  }>
                  {options}
                </Select>
                <FiltersValueDelete
                  onClick={() => props.deleteFilter(field)}
                  src={require('../../../assets/icons/close-default.svg')}
                  alt="delete"
                />
              </FiltersValue>
            </FiltersValueContainer>,
          );
          break;
        }
        case 'autocomplete': {
          let data = '';
          if (props.filters[field]) {
            if (typeof props.filters[field] === 'object') {
              Object.keys(props.filters[field]).forEach((key) => {
                if (key !== '_id') {
                  if (key !== 'id') {
                    if (!Array.isArray(props.filters[field][key])) {
                      data += props.filters[field][key];
                      data += ' ';
                    }
                  }
                }
              });
            } else {
              data = props.filters[field];
            }
          }
          filters.push(
            <FiltersValueContainer key={field}>
              <FiltersValueTitle>
                {props.availableFields[field]}
              </FiltersValueTitle>
              <FiltersValue>
                <Input
                  type="text"
                  value={data}
                  changed={(value) => props.changeFilters(field, value)}
                />
                <FiltersValueDelete
                  onClick={() => props.deleteFilter(field)}
                  src={require('../../../assets/icons/close-default.svg')}
                  alt="delete"
                />
                {props.showAutocompleteValues[field] ? (
                  <ValueOptions>
                    {props.autocompleteValues[field]
                      ? autocompleteValues[field]
                      : null}
                  </ValueOptions>
                ) : null}
              </FiltersValue>
            </FiltersValueContainer>,
          );
          break;
        }
        case 'checkbox': {
          const data = props.filters[field];
          filters.push(
            <FiltersValueContainer key={field}>
              <FiltersValueTitle>
                {props.availableFields[field]}
              </FiltersValueTitle>
              <FiltersValue>
                <input
                  type="checkbox"
                  checked={data}
                  onChange={(event) =>
                    props.changeFilters(field, event.target.checked)
                  }
                />
                <FiltersValueDelete
                  onClick={() => props.deleteFilter(field)}
                  src={require('../../../assets/icons/close-default.svg')}
                  alt="delete"
                />
                {props.showAutocompleteValues[field] ? (
                  <ValueOptions>
                    {props.autocompleteValues[field]
                      ? autocompleteValues[field]
                      : null}
                  </ValueOptions>
                ) : null}
              </FiltersValue>
            </FiltersValueContainer>,
          );
          break;
        }
        default:
          break;
      }
    });
  }
  // генерация строки диапазона дат
  if (props.dateFilters === undefined ? '' : props.dateFilters !== '') {
    try {
      const date = JSON.parse(props.dateFilters);
      date[0] = date[0].split('-');
      date[1] = date[1].split('-');
      dateFilters = `${date[0][2].length === 1 ? 0 + date[0][2] : date[0][2]}.${
        date[0][1].length === 1 ? 0 + date[0][1] : date[0][1]
      }.${date[0][0]}`;
      dateFilters += ' - ';
      dateFilters += `${
        date[1][2].length === 1 ? 0 + date[1][2] : date[1][2]
      }.${date[1][1].length === 1 ? 0 + date[1][1] : date[1][1]}.${date[1][0]}`;
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <Form className="main-dashboard-form">
      <ControlContainer>
        <TitleContainer>
          <Heading>Заявки</Heading>
          {props.access.directory.requests.insert ? (
            <TitleButton>
              <Button
                circle
                clicked={() => {
                  props.addRequest();
                }}
                plus
                title="Добавить"
              />
            </TitleButton>
          ) : null}
          {props.allFilterModel &&
            Object.keys(props.allFilterModel)
              .map((item) => {
                if (
                  props.allFilterModel[item].values &&
                  props.allFilterModel[item].values.length
                ) {
                  return 1;
                }
                return 0;
              })
              .reduce((accum, value) => accum + +value, 0) > 0 && (
              <TitleButton>
                <Button
                  circle
                  clicked={() => {
                    props.setAllFilterModel(null, props.user.login);
                    setTimeout(() => props.initData(), 0);
                  }}
                  title="Сбросить фильтры">
                  <img
                    src={require('../../../assets/icons/clear.svg')}
                    alt="clear filters"
                  />
                </Button>
              </TitleButton>
            )}
          {props.access.directory.requests.delete &&
          props.selectedRequests.length ? (
            <TitleButton>
              <Button
                circle
                clicked={() => {
                  props.setDelPop(true);
                  props.setDelSeveralRequests(true);
                }}
                title="Удалить">
                <img
                  style={{ width: '22px' }}
                  src={require('../../../assets/icons/delete-white.svg')}
                  alt="delete"
                />
              </Button>
            </TitleButton>
          ) : null}
          {props.selectedRequests.length ||
          Object.keys(props.filters).length ? (
            <TitleButton>
              <Button
                circle
                clicked={() => {
                  props.cancel();
                  const elementTable = document.querySelector(
                    '.ag-theme-balham',
                  );
                  elementTable.style.height = '';
                }}
                title="Закрыть">
                <img
                  style={{ width: '12px' }}
                  src={require('../../../assets/icons/close-white.svg')}
                  alt="cancel"
                />
              </Button>
            </TitleButton>
          ) : null}
        </TitleContainer>
        <TitleContainer style={{ margin: '0 auto', paddingLeft: '0px' }}>
          {props.access.directory.requests.sendLoadingData ? (
            <TitleContainer style={{ paddingBottom: '0px' }}>
              <TitleButton>
                <Button
                  circle
                  clicked={() => props.setSendLoadingData(true)}
                  title="Отправка данных на погрузку"
                  disabled={props.processLoading}>
                  {props.processLoading ? (
                    <Loader width={10} height={10} marginLeft={0} />
                  ) : (
                    <img
                      style={{ width: '12px' }}
                      src={require('../../../assets/icons/sendLoadingData.svg')}
                      alt="export"
                    />
                  )}
                </Button>
              </TitleButton>
            </TitleContainer>
          ) : null}
          {props.access &&
          props.access.directory &&
          props.access.directory.requests &&
          props.access.directory.requests.export ? (
            <IconButtonWrapper>
              <TitleButton>
                <Button
                  circle
                  clicked={() => props.getExcel()}
                  title="Выгрузить">
                  <img
                    style={{ width: '12px' }}
                    src={require('../../../assets/icons/export.svg')}
                    alt="export"
                  />
                </Button>
              </TitleButton>
              <TitleButton>
                <Button
                  circle
                  clicked={() => props.getExcel(true)}
                  title="Выгрузить дебет">
                  <img
                    style={{ width: '14px' }}
                    src={require('../../../assets/icons/debit-down.png')}
                    alt="export"
                  />
                </Button>
              </TitleButton>
            </IconButtonWrapper>
          ) : (
            <div style={{ margin: '0 auto' }} />
          )}
          {props.access &&
          props.access.directory &&
          props.access.directory.requests &&
          props.access.directory.requests.uploadExcel ? (
            <TitleButton>
              <Button
                circle
                clicked={() => props.updateStatusRequests()}
                title="Обновить статусы">
                <img
                  style={{ width: '24px' }}
                  src={require('../../../assets/icons/load-excel.svg')}
                  alt="unloading"
                />
              </Button>
            </TitleButton>
          ) : null}
          {props.access &&
          props.access.directory &&
          props.access.directory.requests &&
          props.access.directory.requests.uploadAllRequests ? (
            <TitleButton>
              <Button
                circle
                clicked={() => props.unloadAllRequests()}
                title="Загрузить все заявки">
                <img
                  style={{ width: '12px' }}
                  src={require('../../../assets/icons/export.svg')}
                  alt="unload all requests"
                />
              </Button>
            </TitleButton>
          ) : null}
          {props.access &&
          props.access.directory &&
          props.access.directory.requests &&
          props.access.directory.requests.sendTrackingData ? (
            <TitleButton>
              <Button
                circle
                clicked={() => props.setIsSendTrackingData(true)}
                title="Слежение за контейнерами"
                disabled={props.processTracking}>
                {props.processTracking ? (
                  <Loader width={10} height={10} marginLeft={0} />
                ) : (
                  <img
                    style={{ width: '12px', transform: 'rotate(180deg)' }}
                    src={require('../../../assets/icons/export.svg')}
                    alt="send tracking data"
                  />
                )}
              </Button>
            </TitleButton>
          ) : null}
        </TitleContainer>
        <div style={{ position: 'relative' }}>
          <CalendarButton filterActive={props.dateFiltersActive}>
            <Button
              circle
              clicked={() => props.clickCalendar()}
              title="Календарь">
              <img
                style={{ width: '12px' }}
                src={require('../../../assets/icons/calendar-history-white.svg')}
                alt="calendar"
              />
            </Button>
          </CalendarButton>
          {props.viewCalendar ? (
            <>
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 102,
                }}
                onClick={() => props.setViewCalendar(false)}
              />
              <CalendarContainer style={{ zIndex: 103 }}>
                <FiltersValueDelete
                  src={require('../../../assets/icons/delete-black.svg')}
                  onClick={() => props.setViewCalendar(false)}
                  style={{
                    width: '16px',
                    top: '10px',
                    right: '8px',
                    zIndex: 1,
                  }}
                  alt="delete"
                />
                <Calendar
                  eventOnOk={props.changeDateRange}
                  showToday={false}
                  showClear={false}
                  selectedValue={
                    props.rangeDate && props.rangeDate.length === 0
                      ? [
                          moment(new Date().toJSON().slice(0, 10)),
                          moment(new Date().toJSON().slice(0, 10)),
                        ]
                      : [moment(props.rangeDate[0]), moment(props.rangeDate[1])]
                  }
                  onSelect={props.setRangeDate}
                  onChange={props.setRangeDate}
                />
              </CalendarContainer>
            </>
          ) : null}
        </div>
        {props.dateFilters === undefined ? (
          ''
        ) : props.dateFilters ? (
          <InputDate>
            <Input
              type="text"
              value={dateFilters}
              changed={() => props.setViewCalendar(true)}
              active={() => props.setViewCalendar(true)}
            />
            <DateFiltersValueDelete
              onClick={() => {
                props.deleteDateFilter();
                props.setRangeDate([]);
              }}
              src={require('../../../assets/icons/close-default.svg')}
              alt="delete"
            />
          </InputDate>
        ) : null}
        {props.access &&
          props.access.requests &&
          props.access.requests.state && (
            <SelectStatuses
              onChange={(value) => props.selectStatus(value)}
              selectedStatus={props.selectedStatus}
            />
          )}
        <InputSearch>
          <Input
            placeholder="Поиск"
            search="true"
            changed={(value) => props.search(value)}
            value={props.searchModel}
          />
          {props.searchModel && (
            <FiltersValueDelete
              onClick={() => props.search('')}
              src={require('../../../assets/icons/close-default.svg')}
              alt="delete"
              style={{ right: '12px', top: '16px' }}
            />
          )}
        </InputSearch>
        <ExpandButtonWrapper>
          <Button
            circle
            title={
              props.expand ? 'Выход из полноэкранного режима' : 'Во весь экран'
            }
            clicked={() => {
              props.setExpand(!props.expand);
              if (!props.expand) {
                props.addFullScreen();
              } else {
                props.removeFullScreen();
              }
            }}>
            {!props.expand ? (
              <img
                style={{ width: '12px' }}
                src={require('../../../assets/icons/expand.svg')}
                alt="calendar"
              />
            ) : (
              <img
                style={{ width: '12px' }}
                src={require('../../../assets/icons/despand.svg')}
                alt="calendar"
              />
            )}
          </Button>
        </ExpandButtonWrapper>
      </ControlContainer>
      <MobileInputSearch>
        <Input
          type="search"
          placeholder="Поиск"
          search="true"
          changed={(value) => props.search(value)}
        />
      </MobileInputSearch>
      {Object.keys(props.filters).length ? (
        <FiltersContainer className="filter-container">
          {filters}
        </FiltersContainer>
      ) : null}
      <TableContainer
        className="ag-theme-balham"
        onMouseOver={(event) => {
          if (
            event.target.className.includes &&
            event.target.className.includes('custom-ag-filter-input')
          ) {
            const value = event.target.value.trim();
            const coordsX = event.clientX;
            const coordsY = event.clientY;
            if (!value) return;
            setTimeout(() => {
              props.setShowTooltip(true);
              props.setValueTooltip(value);
              props.setCoordsTooltip({ x: coordsX, y: coordsY });
            }, 0);
          }
        }}
        onMouseOut={(event) => {
          if (
            event.target.className.includes &&
            event.target.className.includes('custom-ag-filter-input')
          ) {
            props.setShowTooltip(false);
            props.setValueTooltip(null);
            props.setCoordsTooltip(null);
          }
        }}>
        <Tooltip
          show={props.showTooltip}
          value={props.valueTooltip}
          coords={props.coordsTooltip}
        />
        {props.showLoaderLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              bottom: '10%',
              left: '50%',
              zIndex: 1,
            }}>
            <Loaders />
          </div>
        ) : null}
        {props.isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: '50%',
              left: '50%',
            }}>
            <Loaders />
          </div>
        ) : (
          <AgGridReact
            columnDefs={props.tableHeader}
            defaultColDef={{
              sortable: true,
              width: 150,
              comparator: (a, b) => 0,
              suppressMenu: true,
              sortingOrder: ['asc', 'desc'],
            }}
            tooltipShowDelay={0}
            floatingFilter
            localeText={localeText}
            rowData={props.tableData}
            suppressAutoSize
            rowSelection="multiple"
            multiSortKey="ctrl"
            suppressRowClickSelection
            enableRangeSelection
            enableCellTextSelection
            statusBar={statusBar}
            animateRows
            onGridReady={props.onGridReady}
            onCellClicked={props.onCellClicked}
            onCellDoubleClicked={props.onCellDoubleClicked}
            onCellValueChanged={props.onCellValueChanged}
            getRowNodeId={(data) => data.id}
            onBodyScroll={props.onBodyScroll}
            getContextMenuItems={props.getContextMenuItems}
            onDragStopped={props.onDragStopped}
            onSelectionChanged={props.onSelectionChanged}
            onSortChanged={props.onSortChanged}
            onCellMouseOver={props.onCellMouseOver}
            onCellMouseOut={props.onCellMouseOut}
            onCellContextMenu={props.onCellContextMenu}
            frameworkComponents={{
              directoryFilter: DirectoryFilter({
                initData: props.initData,
                user: props.user,
              }),
              numberFloatingFilter: NumberFloatingFilter({
                initData: props.initData,
                user: props.user,
              }),
              textFloatingFilter: TextFloatingFilter({
                initData: props.initData,
                user: props.user,
              }),
              dateFloatingFilter: DateFloatingFilter({
                initData: props.initData,
                user: props.user,
              }),
              statusBarComponent: StatusBar,
              inputDate: (params) => (
                <InputTable>
                  <Input
                    type="date"
                    value={params.value}
                    changed={(value) =>
                      props.editRequest(
                        params.node.data.id,
                        params.colDef.field,
                        value,
                      )
                    }
                  />
                </InputTable>
              ),
              inputTime: (params) => (
                <Input
                  type="time"
                  value={params.value}
                  changed={(value) =>
                    props.editRequest(
                      params.node.data.id,
                      params.colDef.field,
                      value,
                    )
                  }
                  location="grid"
                />
              ),
              rating: (params) => (
                <Rating
                  {...params}
                  setRating={props.setRating}
                  readOnlyRating={props.readOnlyRating}
                />
              ),
              iconYesNo: (params) => (
                <IconYesNoView value={params.value} params={params} />
              ),
              statusRequest: (params) => (
                <StatusRequest request={params.data} />
              ),
            }}
          />
        )}
      </TableContainer>
      {props.loadingExcel ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}>
          <Loaders />
        </div>
      ) : null}
      <PopupMessage show={props.delPopup}>
        <Popup status="Warning">
          {props.delSeveralRequests && props.selectedRequests.length ? (
            <p>Вы точно хотите удалить выбранные заявки?</p>
          ) : (
            <p>Вы точно хотите удалить заявку №{props.numberDelete}?</p>
          )}
          <div>
            <button
              type="button"
              onClick={() => {
                if (props.delSeveralRequests && props.selectedRequests.length) {
                  props.deleteRequests();
                } else {
                  props.deleteRequests(props.idDelete);
                }
                props.removeHandlerClear();
                props.setDelSeveralRequests(false);
              }}>
              Да
            </button>
            <button
              type="button"
              onClick={() => {
                props.removeHandlerClear();
                props.setDelSeveralRequests(false);
              }}>
              Нет
            </button>
          </div>
        </Popup>
      </PopupMessage>
      <PopupMessage show={props.copyH}>
        <Popup status="Warning">
          <p>Вы точно хотите создать копию заявки №{props.numberCopy}?</p>
          <div>
            <button
              type="button"
              onClick={() => {
                props.copyRequest(props.idCopy, props.positionCopy);
                props.copyHandlerClear();
              }}>
              Да
            </button>
            <button type="button" onClick={() => props.copyHandlerClear()}>
              Нет
            </button>
          </div>
        </Popup>
      </PopupMessage>
      <PopupMessage show={props.showWarning} zIndex={1001}>
        <Popup status="Warning">
          {props.modalDateValue ? <p>{noteChange}</p> : <p>{noteRemove}</p>}
          <div>
            <button
              type="button"
              onClick={() => {
                props.editRequest(
                  props.idRequestEdit,
                  props.fieldRequestEdit,
                  props.modalDateValue,
                );
                if (
                  props.fieldRequestEdit === 'dateArrival' &&
                  props.modalDateValue
                ) {
                  if (props.stateClaim !== 'Выдан клиенту') {
                    props.editRequest(props.idRequestEdit, 'state', 'Прибыл');
                  }
                  if (props.editableRequest.dateIssue) {
                    const dateArr = props.editableRequest.dateIssue.split('-');
                    const date = new Date(
                      dateArr[0].length === 4
                        ? dateArr.join('-')
                        : dateArr.reverse().join('-'),
                    );
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setMilliseconds(0);
                    const today = new Date();
                    today.setHours(0);
                    today.setMinutes(0);
                    today.setSeconds(0);
                    today.setMilliseconds(0);
                    if (date.getTime() <= today.getTime()) {
                      props.editRequest(
                        props.idRequestEdit,
                        'state',
                        'Выдан клиенту',
                      );
                    }
                  }
                } else if (
                  props.fieldRequestEdit === 'dateArrival' &&
                  !props.modalDateValue
                ) {
                  props.editRequest(
                    props.idRequestEdit,
                    'state',
                    props.stateClaim,
                  );
                  if (props.editableRequest.numberContainer) {
                    props.editRequest(props.idRequestEdit, 'state', 'В пути');
                  }
                  if (props.editableRequest.dateIssue) {
                    const dateArr = props.editableRequest.dateIssue.split('-');
                    const date = new Date(
                      dateArr[0].length === 4
                        ? dateArr.join('-')
                        : dateArr.reverse().join('-'),
                    );
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setMilliseconds(0);
                    const today = new Date();
                    today.setHours(0);
                    today.setMinutes(0);
                    today.setSeconds(0);
                    today.setMilliseconds(0);
                    if (date.getTime() <= today.getTime()) {
                      props.editRequest(
                        props.idRequestEdit,
                        'state',
                        'Выдан клиенту',
                      );
                    }
                  }
                }

                if (
                  props.fieldRequestEdit === 'dateIssue' &&
                  props.modalDateValue
                ) {
                  const dateArr = props.modalDateValue.split('-');
                  const date = new Date(
                    dateArr[0].length === 4
                      ? dateArr.join('-')
                      : dateArr.reverse().join('-'),
                  );
                  date.setHours(0);
                  date.setMinutes(0);
                  date.setSeconds(0);
                  date.setMilliseconds(0);
                  const today = new Date();
                  today.setHours(0);
                  today.setMinutes(0);
                  today.setSeconds(0);
                  today.setMilliseconds(0);
                  if (date.getTime() <= today.getTime()) {
                    props.editRequest(
                      props.idRequestEdit,
                      'state',
                      'Выдан клиенту',
                    );
                  }
                  if (date.getTime() > today.getTime()) {
                    props.editRequest(
                      props.idRequestEdit,
                      'state',
                      props.stateClaim,
                    );
                  }
                } else if (
                  props.fieldRequestEdit === 'dateIssue' &&
                  !props.modalDateValue
                ) {
                  if (props.stateClaim === 'Выдан клиенту') {
                    props.editRequest(
                      props.idRequestEdit,
                      'state',
                      'Предварительное',
                    );
                  } else {
                    props.editRequest(
                      props.idRequestEdit,
                      'state',
                      props.stateClaim,
                    );
                  }
                }
                props.closeDateModal();
              }}>
              Да
            </button>
            <button type="button" onClick={() => props.closeDateModal()}>
              Нет
            </button>
          </div>
        </Popup>
      </PopupMessage>
      <PopupMessage show={props.showWarningTime} zIndex={1001}>
        <Popup status="Warning">
          {props.modalTimeValue ? <p>{noteChange}</p> : <p>{noteRemove}</p>}
          <div>
            <button
              type="button"
              onClick={() => {
                props.editRequest(
                  props.idRequestEdit,
                  props.fieldRequestEdit,
                  props.modalTimeValue,
                );
                props.closeTimeModal();
              }}>
              Да
            </button>
            <button type="button" onClick={() => props.closeTimeModal()}>
              Нет
            </button>
          </div>
        </Popup>
      </PopupMessage>
      <PopupMessage show={props.sendLoadingData}>
        <Popup status="Warning">
          <p>Отправить данные?</p>
          <div>
            <button
              type="button"
              onClick={() => {
                props.postLoadingData();
                props.setSendLoadingData(false);
              }}>
              Да
            </button>
            <button
              type="button"
              onClick={() => {
                props.setSendLoadingData(false);
              }}>
              Нет
            </button>
          </div>
        </Popup>
      </PopupMessage>
      <PopupMessage show={props.isSendTrackingData}>
        <Popup status="Warning">
          <p>Отправить данные слежения?</p>
          <div>
            <button
              type="button"
              onClick={() => {
                props.sendTrackingData();
                props.setIsSendTrackingData(false);
              }}>
              Да
            </button>
            <button
              type="button"
              onClick={() => {
                props.setIsSendTrackingData(false);
              }}>
              Нет
            </button>
          </div>
        </Popup>
      </PopupMessage>
      {props.showRatingModal ? (
        <ModaleWindow closeAction={props.closeReviewModal} width={300}>
          <ModalContentWrapper>
            <Textarea
              placeholder="Расскажите, почему вы поставили именно такую оценку..."
              rows={10}
              value={props.ratingReview}
              onChange={({ target }) => {
                props.setRatingReview(target.value);
              }}
            />
            <div style={{ maxWidth: '90%' }}>
              <p style={{ marginBottom: '15px' }}>
                Информация этого сообщения конфиденциальная и ее получит
                исключительно руководство ООО "АмиксГрупп"
              </p>
            </div>
            {props.isReviewSending ? (
              <Loaders />
            ) : (
              <Button clicked={props.sendReview}>Отправить</Button>
            )}
          </ModalContentWrapper>
        </ModaleWindow>
      ) : null}
      {props.showEmailsModal ? (
        <ModaleWindow closeAction={props.closeEmailsModal} width={300}>
          <ModalContentWrapper>
            <Textarea
              placeholder="Укажите дополнительные email адреса через запятую, на которые придут копии писем (не обязательно для заполнения)"
              rows={10}
              value={props.stringEmails}
              onChange={({ target }) => props.setStringEmails(target.value)}
            />
            {props.isEmailsSending ? (
              <Loaders />
            ) : (
              <Button clicked={props.postRequest}>Отправить</Button>
            )}
          </ModalContentWrapper>
        </ModaleWindow>
      ) : null}
      {props.isShowModalDate ? (
        <ModaleWindow
          closeAction={props.closeDateModal}
          height={420}
          width={300}
          exact>
          <ModalContentWrapper>
            <CalendarContainer style={{ left: 'auto' }}>
              <CalendarSingle
                onSelect={(value) => {
                  let dateStr = '';
                  if (value) {
                    dateStr = formatDate(
                      `${value._d.getFullYear().toString()}-${(
                        value._d.getMonth() + 1
                      ).toString()}-${value._d.getDate().toString()}`,
                    )
                      .split('-')
                      .reverse()
                      .join('-');
                    props.setModalDateValueMoment(
                      `${value._d.getFullYear().toString()}-${(
                        value._d.getMonth() + 1
                      ).toString()}-${value._d.getDate().toString()}`,
                    );
                  }
                  props.setModalDateValue(dateStr);
                }}
                onClear={() => {
                  props.setModalDateValue('');
                  props.setModalDateValueMoment('');
                }}
                format="DD-MM-YYYY"
                defaultValue={moment(props.modalDateValueMoment)}
                selectedValue={
                  props.modalDateValueMoment
                    ? moment(props.modalDateValueMoment)
                    : props.modalDateValueMoment
                }
                locale={ruRu}
              />
            </CalendarContainer>
            <ButtonsBlock>
              <Button
                mode="red"
                clicked={() => {
                  props.setShowWarning(true);
                }}>
                Сохранить
              </Button>
            </ButtonsBlock>
          </ModalContentWrapper>
        </ModaleWindow>
      ) : null}
      {props.isShowModalTime ? (
        <ModaleWindow
          closeAction={props.closeTimeModal}
          height={140}
          width={200}
          exact>
          <ModalContentWrapper>
            <div style={{ width: '100px' }}>
              <Input
                type="time"
                value={props.modalTimeValue}
                changed={(value) => props.setModalTimeValue(value)}
              />
            </div>
            <ButtonsBlock type="time">
              <Button mode="red" clicked={() => props.setShowWarningTime(true)}>
                Сохранить
              </Button>
            </ButtonsBlock>
          </ModalContentWrapper>
        </ModaleWindow>
      ) : null}
    </Form>
  );
}
