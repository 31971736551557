import styled, { css } from 'styled-components';

import { base, colors } from '../../../styles';

export const FilterValue = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: ${colors.white_smoke};
  border: none;
  border-radius: ${base / 2}px;

  input {
    height: 24px;
    padding-right: 25px;
    padding-left: 0px;
  }
`;

export const FilterValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: #fff;
  padding: 0.5em;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0.125em;
  z-index: 999999999999;
`;

export const FilterCheckbox = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  ${(props) =>
    props.value &&
    css`
      background-image: url(${require('../../../assets/icons/check-box.svg')});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 60%;
    `}
`;

export const FilterValueDelete = styled.img`
  position: absolute;
  top: 4px;
  right: 7px;
  outline: none;
  cursor: pointer;
  ${(props) =>
    props.type === 'calendar' &&
    css`
      top: 8px;
      right: 4px;
      z-index: 1;
    `}
`;

export const Button = styled.button`
  padding: 0.25em 1em;
  border-radius: 0.125em;
  margin-left: 0.25em;
  cursor: pointer;
  border: 1px solid #d9dddf;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-top: 0.5em;
  padding-top: 0.5em;
  border-top: 1px solid #d9dddf;
`;

export const OpenInputContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const OpenInput = styled.input`
  cursor: pointer;
  background-color: #f9f9f9;
  border: 1px solid #d9dddf;
  font-size: 12px;
`;

export const OpenFilterButton = styled.span`
  cursor: pointer;
  margin-left: 0.5em;

  :hover {
    opacity: 0.5;
  }
`;

export const SearchIcon = styled.div`
  height: 24px;
  width: 24px;
  background-image: url(${require('../../../assets/icons/searchsvg.svg')});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
`;
