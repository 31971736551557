import styled, { css } from 'styled-components';
import { base, colors, screens } from '../../../styles';

export const Form = styled.div`
  width: 100%;
  font-size: ${base * 1.333333333}px;
`;

export const TitleContainer = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${screens.screen_xs_max}) {
    margin-bottom: 10px;
  }
`;

export const Title = styled.h1`
  font-size: 42px;
  font-weight: 600;
`;

export const TitleButton = styled.div`
  margin-right: ${base * 2}px;
  margin-left: 52px;

  ${(props) =>
    props.white &&
    css`
      margin-right: 0;
      margin-left: 0;

      @media (max-width: ${screens.screen_xs_max}) {
        display: none;
      }
    `}
`;

export const Loader = styled.div`
  border: 7px solid #dadada;
  border-top: 7px solid #656565;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
  margin-left: 20px;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const InfoBlockTotalWrapper = styled.div`
  max-width: 100%;
  padding: 12px 0;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${screens.screen_2xs_min}) {
    justify-content: space-between;
  }

  @media (max-width: ${screens.screen_3xs_min}) {
    flex-direction: row;
    justify-content: center;
  }

  .slick-slider {
    padding: 6px 0;
  }
  .slick-slide {
    padding: 6px 0;
  }
  .slick-slider {
    width: 750px;
  }
`;

export const MobileTitleButton = styled.div`
  display: none;
  margin-bottom: 32px;

  button {
    background-color: ${colors.white};
    color: ${colors.primary_red};
    border: 1px solid ${colors.primary_red};
  }

  @media (max-width: ${screens.screen_xs_max}) {
    display: block;
  }
`;

export const HistoryContainer = styled.div`
  background-color: ${colors.primary_gray};
  width: 40%;
  min-height: 100%;
  @media (max-width: ${screens.screen_md_max}) {
    width: 100%;
  }
`;

export const RequestWrapper = styled.div`
  width: 60%;
  margin-right: 48px;
  @media (max-width: ${screens.screen_md_max}) {
    width: 100%;
  }
`;

export const ContainerRequest = styled.div`
  display: flex;
  @media (max-width: ${screens.screen_md_max}) {
    flex-direction: column;
  }
`;
