/**
 * @flow
 */

import React from 'react';

import {} from './ModaleWindowPayerInfoStyled';

import {
  Middle,
  FormListInputWrapper,
  Paragraph,
  InputWrapper,
  CheckboxWrapper,
  AdditionalButtonWrapper,
  AdditionalButton,
  InfoBlock,
  FromList,
  ListBlock,
  ListItem,
  Tabs,
  Tab,
} from '../../pages/Content/Directories/DirectoriesStyled';

import { Bottom } from '../PayerInfo/PayerInfoStyled';

import {
  PageTitle,
  Button,
  Payer,
  PayerInfo,
  Input,
  Checkbox,
  Popup,
  PayerInfoBlock,
  LinkedItem,
  ModaleWindow,
  SelectDropDown,
  SelectDropDownItem,
} from '../index';

type ModaleWindowPayerInfoPropsType = {
  setShowCreateForm: (boolean) => void,
  createAction: () => void,
  createLinkedItem: () => void,
  activeTab: number,
  setActiveTab: (number) => void,
  serviceForm: Object,
  payerList: any,
  toggle: (any) => void,
  toggleOn: boolean,
  toggleCurrent: (any) => void,
  activeID?: string,
  delPopup: boolean,
  setPopupVisible: (any) => void,
  blockData: Object,
  edit: boolean,
  setEdit: (boolean) => void,
  linkUser: boolean,
  setLinkUser: (boolean) => void,
  activeTab: number,
  activeSecondaryItem: Object,
  openFormForCreate: () => void,
  setShowCreateForm: (boolean) => void,
  showCreateForm: boolean,
  isLoading: boolean,
  setToRemove: (any) => void,
  linkedServiceInputState: Object,
  createLinkedItem: () => void,
  itemsForLink: Object,
  forLinkTitle: Object,
  newItemData: Object,
  setNewItemData: (Object) => void,
  linkedServiceForm: Object,
  setLinkedServiceInputState: (Object) => void,
  service: Object,
  showLinkedBar: (Object, string, string) => void,
  addedAction: () => void,
  removeActoin: (string) => void,
  updateAction: () => void,
  addLinkedItem: (string, string, string) => void,
  removeLinkedItem: (string, string, string) => void,
  inputs: Object,
  setInputs: (Object) => void,
  toRemove: string,
  loadMore: () => void,
  isLoadMore: () => void,
  searchItem: (any) => void,
  hasMore: boolean,
  searchLinkedItems: (string) => void,
  setActiveID: (any) => void,
  addAdditionalLinkedInput: () => void,
  addAdditionalInput: () => void,
  serviceForm: Object,
  showUsersForLink: (Object, string, string) => void,
  removeUserFromDirectory: (Object, string, string) => void,
  addUserToDirectory: (string, string) => void,
};

export default function ModaleWindowPayerInfoView(
  props: ModaleWindowPayerInfoPropsType,
) {
  console.log('props.serviceForm', props.serviceForm);
  console.log(props.service);
  console.warn(props.nameForm);
  if (Object.keys(props.serviceForm).length === 0) return null;
  const renderUserList = () => (
    <InfoBlock>
      <React.Fragment>
        <FromList>
          <Input
            type="search"
            placeholder="Поиск"
            search="true"
            changed={(val) => console.log(val)}
          />
        </FromList>
        <Paragraph smallParagraph>{props.forLinkTitle.title}</Paragraph>
        <ListBlock isScroll>
          {props.itemsForLink.length > 0 &&
            props.itemsForLink.map((item) => (
              <ListItem
                withoutHidden
                key={item.id}
                onClick={() => {
                  const { id } = props.blockData;
                  props.addUserToDirectory(id, item.id);
                }}>
                <p>{`${item.firstName} ${item.lastName}`}</p>
              </ListItem>
            ))}
        </ListBlock>
      </React.Fragment>
    </InfoBlock>
  );

  const renderNormalCard = () => (
    <React.Fragment>
      {props.blockData.relations &&
        props.blockData.relations.length > 0 &&
        props.blockData.relations.map((block, i) => (
          <PayerInfoBlock
            title={block.title}
            key={`${i + 1}-${block.name}`}
            addItem={() =>
              props.showLinkedBar(block.linkedService, block.title, block.name)
            }
            items={
              block.items &&
              block.items.map((item) => (
                <LinkedItem
                  key={item._id || item.id}
                  address={item.name}
                  action={() => {
                    props.removeLinkedItem(
                      props.blockData.id,
                      block.name,
                      item.id || item._id,
                    );
                  }}
                />
              ))
            }
          />
        ))}
      {props.blockData.users &&
        props.blockData.users.length > 0 &&
        props.blockData.users.map((block, i) => (
          <PayerInfoBlock
            title={block.title}
            key={`${i + 1}-${block.name}`}
            addItem={() => {
              const { linkedUserService, title, name } = block;
              props.showUsersForLink(linkedUserService, title, name);
            }}
            items={
              block.items &&
              block.items.map((item) => (
                <LinkedItem
                  key={item.id}
                  address={`${item.firstName} ${item.lastName}`}
                  action={() => {
                    const { id } = props.blockData;
                    props.removeUserFromDirectory(
                      block.linkedUserService,
                      id,
                      item._id,
                    );
                  }}
                />
              ))
            }
          />
        ))}
      {props.blockData.inputs &&
        props.blockData.inputs.length > 0 &&
        props.blockData.inputs.map((input, i) => (
          <PayerInfoBlock
            title={input.title}
            key={`${i + 1}-${input.name}`}
            items={
              <InputWrapper>
                <Input
                  placeholder={input.title}
                  value={props.inputs[input.name]}
                  changed={(value) => {
                    const inputs = { ...props.inputs };
                    inputs[input.name] = value;
                    props.setInputs(inputs);
                  }}
                />
              </InputWrapper>
            }
          />
        ))}
      {props.blockData.checkboxes &&
        props.blockData.checkboxes.length > 0 &&
        props.blockData.checkboxes.map((checkbox) => (
          <InputWrapper>
            <PayerInfoBlock
              items={
                <CheckboxWrapper>
                  <Checkbox
                    message={checkbox.title}
                    value={props.inputs[checkbox.name]}
                    onClickItem={(value) => {
                      const inputs = { ...props.inputs };
                      inputs[checkbox.name] = value;
                      props.setInputs(inputs);
                    }}
                  />
                </CheckboxWrapper>
              }
            />
          </InputWrapper>
        ))}
    </React.Fragment>
  );

  return (
    <ModaleWindow closeAction={props.closeAction}>
      <PayerInfo
        modaleWindow
        create
        edit={props.edit}
        closeButton={() => {
          if (props.edit) {
            props.setEdit(false);
          } else {
            props.closeAction();
          }
        }}
        createAction={() => props.createAction()}
        createLinkedItem={() => {
          props.createLinkedItem();
        }}
        showAddButton={props.activeTab !== 0}>
        <Middle>
          {props.edit ? (
            <InfoBlock>
              <Tabs>
                <Tab
                  type="button"
                  onClick={() => props.setActiveTab(0)}
                  active={props.activeTab === 0}>
                  Из списка
                </Tab>
                <Tab
                  type="button"
                  onClick={() => props.setActiveTab(1)}
                  active={props.activeTab === 1}>
                  Создать
                </Tab>
              </Tabs>
              {props.activeTab === 0 ? (
                <React.Fragment>
                  <FromList>
                    <Input
                      type="search"
                      placeholder="Поиск"
                      search="true"
                      changed={(val) => props.searchLinkedItems(val)}
                    />
                  </FromList>
                  <Paragraph smallParagraph>
                    {props.forLinkTitle.title}
                  </Paragraph>
                  <ListBlock isScroll>
                    {props.itemsForLink.length > 0 &&
                      props.itemsForLink.map((item) => (
                        <ListItem
                          withoutHidden
                          key={item.id}
                          onClick={() => {
                            const result = { ...props.newItemData };
                            result[props.forLinkTitle.name] = [
                              ...result[props.forLinkTitle.name],
                              item,
                            ];
                            props.setNewItemData(result);
                            props.setEdit(false);
                          }}>
                          <p>{item.name}</p>
                        </ListItem>
                      ))}
                  </ListBlock>
                </React.Fragment>
              ) : (
                <FromList>
                  {props.linkedServiceForm &&
                    Array.isArray(props.linkedServiceForm.inputs) &&
                    props.linkedServiceForm.inputs.map((input, i) => (
                      <FormListInputWrapper key={`${i + 1}-${input.name}`}>
                        <Paragraph smallParagraph>{input.title}</Paragraph>
                        <Input
                          placeholder={input.title}
                          changed={(value) => {
                            const inputs = { ...props.linkedServiceInputState };
                            inputs[input.name] = value;
                            props.setLinkedServiceInputState(inputs);
                          }}
                        />
                      </FormListInputWrapper>
                    ))}
                  {props.linkedServiceForm &&
                    'canAddInput' in props.linkedServiceForm &&
                    props.linkedServiceForm.canAddInput && (
                      <AdditionalButtonWrapper>
                        <AdditionalButton
                          onClick={() => props.addAdditionalLinkedInput()}>
                          + Поле для ввода
                        </AdditionalButton>
                      </AdditionalButtonWrapper>
                    )}
                  {props.linkedServiceForm &&
                    Array.isArray(props.linkedServiceForm.checkboxes) &&
                    props.linkedServiceForm.checkboxes.map((checkbox, i) => (
                      <CheckboxWrapper key={`${i + 1}-checkbox`}>
                        <Checkbox
                          message={checkbox.title}
                          value={props.newItemData[checkbox.name]}
                          onClickItem={(value) => {
                            const inputs = { ...props.linkedServiceInputState };
                            inputs[checkbox.name] = value;
                            props.setLinkedServiceInputState(inputs);
                          }}
                        />
                      </CheckboxWrapper>
                    ))}
                </FromList>
              )}
            </InfoBlock>
          ) : (
            <React.Fragment>
              {props.serviceForm.relations.map((item, i) => (
                <PayerInfoBlock
                  title={item.title}
                  key={`${i + 1}-${item.name}`}
                  addItem={() => {
                    props.showLinkedBar(
                      item.linkedService,
                      item.title,
                      item.name,
                    );
                  }}
                  items={props.newItemData[item.name].map((linkedItem) => (
                    <LinkedItem
                      onMouseEnter={() => console.log('hover')}
                      key={linkedItem._id || linkedItem.id}
                      address={linkedItem.name}
                      action={() => {
                        const newItemData = { ...props.newItemData };
                        newItemData[item.name] = newItemData[item.name].filter(
                          (newItem) => newItem.id !== linkedItem.id,
                        );

                        props.setNewItemData(newItemData);
                      }}
                    />
                  ))}
                />
              ))}

              {props.serviceForm.inputs.map((input, i) => (
                <FormListInputWrapper key={`${i + 1}-${input.name}`}>
                  <Paragraph smallParagraph>{input.title}</Paragraph>
                  <InputWrapper>
                    <Input
                      placeholder={input.title}
                      value={props.newItemData[input.name]}
                      changed={(value) => {
                        const result = { ...props.newItemData };
                        result[input.name] = value;
                        props.setNewItemData(result);
                      }}
                    />
                  </InputWrapper>
                </FormListInputWrapper>
              ))}

              {'canAddInput' in props.serviceForm &&
                props.serviceForm.canAddInput && (
                  <AdditionalButtonWrapper>
                    <AdditionalButton
                      onClick={() => props.addAdditionalInput()}>
                      + Поле для ввода
                    </AdditionalButton>
                  </AdditionalButtonWrapper>
                )}
              {props.serviceForm.selection &&
                props.serviceForm.selection.map((select, i) => (
                  <FormListInputWrapper key={`${i + 1}-${select.name}`}>
                    <Paragraph smallParagraph>{select.title}</Paragraph>
                    <InputWrapper>
                      <SelectDropDown
                        placeholder={select.title}
                        selectedValue={props.newItemData[select.name]}>
                        {select.values.map((item) => (
                          <SelectDropDownItem
                            title={item}
                            selectOption={() => {
                              const newItemData = { ...props.newItemData };
                              newItemData[select.name] = item;
                              props.setNewItemData(newItemData);
                            }}
                          />
                        ))}
                      </SelectDropDown>
                    </InputWrapper>
                  </FormListInputWrapper>
                ))}

              {props.serviceForm.checkboxes.map((checkbox, i) => (
                <InputWrapper key={`${i + 1}-checkbox`}>
                  <CheckboxWrapper>
                    <Checkbox
                      message={checkbox.title}
                      value={props.newItemData[checkbox.name]}
                      onClickItem={(value) => {
                        const newItemData = { ...props.newItemData };
                        newItemData[checkbox.name] = value;
                        props.setNewItemData(newItemData);
                      }}
                    />
                  </CheckboxWrapper>
                </InputWrapper>
              ))}
            </React.Fragment>
          )}
        </Middle>
      </PayerInfo>
    </ModaleWindow>
  );
}

ModaleWindow.defaultProps = {
  width: 300,
  height: 300,
  status: false,
  activeID: '',
};
