import styled from 'styled-components';

export const StatusBarContainer = styled.div`
  display: flex;
  padding: 4px;
`;

export const StatusBarValue = styled.div`
  color: #000;
  margin-left: 4px;
`;

export const StatusBarText = styled.div``;
