import { apiGet } from '../ApiService';

import { RoleService, UserService } from '../index';

export default {
  getItems() {
    const roleName = ['salesman', 'transactionSalesman'];
    return this.getUsersManagers(roleName);
  },

  async getUsersManagers(roleName) {
    const promeses = [];
    roleName.map((name) => {
      promeses.push(UserService.getUsersByRoleName(name));
    });
    const response = [].concat(...(await Promise.all(promeses)));
    const data = response.map((item) => ({
      ...item,
      name: `${item.firstName} ${item.lastName}`,
    }));
    return { data };
  },

  searchItem(body) {
    const roleName = ['salesman', 'transactionSalesman'];
    return this.searchUsersManagers(roleName, body.search);
  },

  async searchUsersManagers(roleName, search) {
    const promeses = [];
    roleName.map((name) => {
      promeses.push(this.searchUsersByRoleName(name, search));
    });
    const response = [].concat(...(await Promise.all(promeses)));
    const data = response.map((item) => ({
      ...item,
      name: `${item.firstName} ${item.lastName}`,
    }));
    return data;
  },

  serachUsersByRole(roleID, search) {
    return apiGet('/users', { params: { roleID, search } })
      .then((response) =>
        response.data.docs.map((item) => ({
          id: item._id,
          ...item,
        })),
      )
      .catch((error) => console.log('Not Search', error));
  },

  searchUsersByRoleName(roleName, search) {
    return RoleService.getRoles()
      .then((response) => {
        let roleId = null;
        response.forEach((role) => {
          if (role.url === roleName) {
            roleId = role.id;
          }
        });
        return this.serachUsersByRole(roleId, search);
      })
      .then((response) => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
};
