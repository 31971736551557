import styled from 'styled-components';

import { base } from '../../../styles';

export const StyledTabNav = styled.div`
  display: flex;
  width: ${base * 41}px;
  hight: 48px;
  padding: 0 52px;
`;
