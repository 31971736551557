import { apiPost, apiGet, apiDelete } from '../ApiService';

export default {
  getApiName() {
    return 'usercounterparties';
  },

  addUserToDirectory(user, counterparty) {
    return apiPost('/usercounterparties', { user, counterparty })
      .then((response) => response)
      .catch((error) => console.log('error', error));
  },

  getLinkedUsers({ counterparty, rolename }) {
    return apiGet(
      `/usercounterparties?counterparty=${counterparty}&rolename=${rolename}`,
    )
      .then((response) =>
        response.data.docs.map((item) => ({
          ...item.userId,
          name: `${item.userId.firstName} ${item.userId.lastName}`,
          id: item.userId._id,
        })),
      )
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  deleteLinkedUser(user, counterparty) {
    return apiDelete('/usercounterparties', { user, counterparty })
      .then((response) => response)
      .catch((error) => console.log('error', error));
  },
};
