import styled, { css } from 'styled-components';

import { base, screens } from '../../../styles';

const clientHeight =
  document.documentElement.clientHeight || document.body.clientHeight;

export const Container = styled.div``;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.125em 0;
`;

export const CheckboxesContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${clientHeight - 320}px;
  margin-top: ${base / 3}px;
  margin-bottom: ${base}px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;

  @media (max-width: 1520px) {
    height: ${clientHeight - 340}px;
  }
  @media (max-width: ${screens.screen_sm_max}) {
    height: ${clientHeight - 360}px;
  }
  @media (max-width: ${screens.screen_sm_mid}) {
    height: ${clientHeight - 380}px;
  }
  @media (max-width: ${screens.screen_sm_min}) {
    height: ${clientHeight - 430}px;
  }
  @media (max-width: ${screens.screen_xs_min}) {
    height: ${clientHeight - 450}px;
  }
  @media (max-width: ${screens.screen_2xs_min}) {
    height: ${clientHeight - 470}px;
  }
`;

export const ContainerSpan = styled.div`
  margin-bottom: ${base}px;
`;

export const ContainerInputs = styled.div`
  width: 50%;
  margin-bottom: ${base}px;

  & > div {
    &:first-child {
      margin-bottom: ${base}px;
    }
  }
`;

export const ContainerInput = styled.div`
  position: relative;
`;

export const ContainerInputCustom = styled.div`
  position: relative;

  input {
    padding-left: 16px;
  }
`;
