import styled, { css } from 'styled-components';
import { base, colors } from '../../styles';

export const Container = styled.div`
  height: 60px;
  width: 100%;
  background: ${colors.primary_gray};
  display: flex;
  cursor: pointer;
  padding: ${base + 4}px 0;
  &:nth-child(2n) {
    background: ${colors.white};
  }
`;

const Wrapper = styled.div`
  display: flex;
`;

export const Left = styled(Wrapper)`
  padding: 0 32px;
  width: 64px;
  align-items: center;
`;

export const Right = styled(Wrapper)`
  width: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const UserAvatar = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #999999;
  ${(props) =>
    props.avatar &&
    css`
      background: url(${props.avatar});
      background-size: cover;
      background-position: center;
      background-color: #f9f9f9;
    `}
`;

export const UserName = styled.div`
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  padding: 0 0 ${base + 4}px 0;
`;

export const FirstName = styled.p`
  font-size: ${base + 4}px;
  color: ${colors.black};
`;

export const LastName = styled.p`
  font-size: ${base + 4}px;
  color: ${colors.primary_red};
`;

export const UserPosition = styled.p`
  font-size: ${base + 4}px;
  color: ${colors.light_gray};
`;
