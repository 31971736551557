import styled from 'styled-components';

import { base, colors } from '../../styles';

export const LinkedItemHover = styled.div`
  position: absolute;
  bottom: 36px;
  max-width: 240px;
  margin-right: ${base * 1.3}px;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  z-index: 1;
  & > span {
    display: none;
  }
`;

export const LinkedItemDefolt = styled.div`
  display: flex;
  align-items: center;
  padding-right: 38px;
  cursor: pointer;
  & > span {
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  & > button {
    width: 40px;
    height: 32px;
    background: url(${require('../../assets/icons/close-hover.svg')});
    opacity: 0;
    margin: 0;
  }
  &:hover > button {
    opacity: 1;
    background: url(${require('../../assets/icons/close-hover.svg')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30%;
  }
`;

export const LinkedContainer = styled.div`
  position: relative;
  &:hover {
    ${(props) => props.theme.value}
    background-color: ${colors.white_smoke};
    ${LinkedItemHover} {
      background-color: #f9f9f9;
      border: 1px solid #000000;
      & > span {
        display: block;
      }
    }

    ${() => {
      setTimeout(() => {
        const tooltips = document.querySelectorAll('.linked-item-hover');
        tooltips.forEach((item) => {
          const coords = item.getBoundingClientRect();
          if (coords.width > 16 && coords.top < 200) {
            item.style.bottom = `${-200 + coords.top}px`;
          }
        });
      });
    }}
  }
`;
