import { compose, withHandlers, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { checkStatusPopup } from '../../store/actions/dashboard';
import { saveUserData } from '../../store/actions/auth';
// services
import { UserService, UserTemplateService } from '../../services';
import NavBarView from './NavBarView';

export default compose(
  withRouter,
  connect(
    (state) => ({
      user: state.auth.currentUser,
      token: state.auth.token,
      showNewRequest: state.dashboard.showNewRequest,
      access: state.auth.userAccesses,
    }),
    (dispatch) => ({
      saveUserData: (data) => dispatch(saveUserData(data)),
      checkStatusPopup: (data) => dispatch(checkStatusPopup(data)),
    }),
  ),
  withState('template', 'setTemplate', null),
  withState('templates', 'setTemplates', []),
  withHandlers({
    functionCheckTime: (props) => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    getTemplates: (props) => async () => {
      try {
        const params = { userId: props.user._id };
        const result = await UserTemplateService.getTemplates(params);
        props.setTemplates(result.result);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    chooseTemplate: (props) => async (data) => {
      const template = props.templates.find((item) => item._id === data.value);

      if (template) {
        try {
          await UserService.updateUser(props.user._id, {
            templateId: template._id,
            fieldsRequests: template.fieldsRequests || [],
            pinnedFields: template.pinnedFields || [],
          });
          const resultUser = await UserService.getUserById(props.user._id);

          props.saveUserData(resultUser);
          props.setTemplate(template);
        } catch (error) {
          props.functionCheckTime('Error', error.message);
        }

        document.querySelector('.nav-bar') &&
          document.querySelector('.nav-bar').querySelectorAll('input') &&
          document.querySelector('.nav-bar').querySelectorAll('input')[0] &&
          document
            .querySelector('.nav-bar')
            .querySelectorAll('input')[0]
            .blur();
      }

      if (!template) {
        props.history.push('/dashboard/profile/custColumns');
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      try {
        const userById = await UserService.getUserById(this.props.user._id);
        if (userById.templateId) {
          const template = await UserTemplateService.getTemplateById(
            userById.templateId,
          );

          this.props.setTemplate(template.result);
        }
      } catch (error) {
        this.props.functionCheckTime('Error', error.message);
      }
    },
    async componentDidUpdate(prevProps) {
      if (
        (prevProps.user.templateId || this.props.user.templateId) &&
        prevProps.user.templateId !== this.props.user.templateId
      ) {
        if (this.props.user.templateId) {
          const template = await UserTemplateService.getTemplateById(
            this.props.user.templateId,
          );
          this.props.setTemplate(template.result);
        } else {
          this.props.setTemplate(null);
        }
      }
    },
  }),
)(NavBarView);
