import React from 'react';

import { Input } from '../..';

import {
  FilterValue,
  FilterValueContainer,
  ButtonsContainer,
  OpenInputContainer,
  OpenInput,
  OpenFilterButton,
  Button,
  FilterValueDelete,
  SearchIcon,
} from './TextStyled';

export const TextView = (props) => {
  const index = 0;
  const previewValue = () => {
    let val = '';
    val =
      props.filterModel[props.column.colId] &&
      props.filterModel[props.column.colId].values &&
      props.filterModel[props.column.colId].values[index];
    if (val === undefined) {
      return '';
    }
    if (val === '') {
      return 'Не заполнено';
    }
    return val;
  };

  return (
    <OpenInputContainer>
      <div id={`custom-ag-filter-${props.column.colId}`}>
        <OpenInputContainer>
          <OpenInput
            className="custom-ag-filter-input"
            placeholder=""
            value={`  ${previewValue()}`}
            readOnly
            onClick={props.toggleOpened}
          />
          <OpenFilterButton
            className="ag-icon ag-icon-filter"
            unselectable="on"
            onClick={props.toggleOpened}
          />
        </OpenInputContainer>
        {props.column.colId === props.openFilterField ? (
          <FilterValueContainer>
            <FilterValue>
              <SearchIcon />
              <Input
                type={
                  props.column.colId === 'timeLoad' ||
                  props.column.colId === 'scheduledIssueTime' ||
                  props.column.colId === 'timeIssue'
                    ? 'time'
                    : 'text'
                }
                value={
                  props.localItems[props.column.colId] &&
                  props.localItems[props.column.colId].values &&
                  props.localItems[props.column.colId].values[index]
                }
                placeholder="Введите значение..."
                changed={(value) => {
                  let values = [];
                  if (props.localItems[props.column.colId]) {
                    values = [...props.localItems[props.column.colId].values];
                    if (values[index] !== undefined) {
                      values[index] = value;
                    } else {
                      values = [...values, value];
                    }
                  } else {
                    values[index] = value;
                  }
                  props.changeFilter(props.column.colId, { values });
                }}
                location="filter"
              />
              {props.localItems[props.column.colId] &&
                props.localItems[props.column.colId].values &&
                props.localItems[props.column.colId].values[index] && (
                  <FilterValueDelete
                    onClick={() => {
                      const values = [];
                      values[index] = '';
                      props.changeFilter(props.column.colId, { values });
                    }}
                    src={require('../../../assets/icons/delete-black.svg')}
                    alt="delete"
                    field={props.column.colId}
                  />
                )}
            </FilterValue>
            <ButtonsContainer>
              <Button
                onClick={() => props.clearFilter(props.column.colId, index)}>
                Не заполнено
              </Button>
              <Button
                onClick={() => props.resetFilter(props.column.colId, index)}>
                Сбросить
              </Button>
              <Button onClick={props.applyFilter}>Применить</Button>
            </ButtonsContainer>
          </FilterValueContainer>
        ) : null}
      </div>
    </OpenInputContainer>
  );
};
