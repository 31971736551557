/**
 * @flow
 */

import React from 'react';

import { StampTitle } from './StampStyled';

type StampViewPropsType = {
  statusStamp: boolean,
  message: any,
  setStamp: (any) => void,
};

export default function StampView(props: StampViewPropsType) {
  return (
    <StampTitle
      onClick={() => props.setStamp(!props.statusStamp)}
      status={props.statusStamp}>
      {props.message}
    </StampTitle>
  );
}
