/**
 * @flow
 */

import React from 'react';

import { LogoWrapper } from './LogoStyled';

type LogoPropsType = {
  clicked: (any) => void,
};

export default function Logo(props: LogoPropsType) {
  return (
    <LogoWrapper onClick={() => props.clicked()}>
      <img
        style={{ height: '36px' }}
        src={require('../../assets/icons/logo.svg')}
        alt="logo"
      />
    </LogoWrapper>
  );
}
