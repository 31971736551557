import { compose } from 'recompose';
import { connect } from 'react-redux';

import SideBarView from './SideBarView';

export default compose(
  connect((state) => ({
    user: state.auth.currentUser,
    access: state.auth.userAccesses,
    toggleLoad: state.dashboard.toggleLoad,
  })),
)(SideBarView);
