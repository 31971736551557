import moment from 'moment';
import { apiPost, apiGet, apiPut, apiDelete } from '../ApiService';

export default {
  getApiName() {
    return 'requests';
  },
  getAllRequests(page = 1, records = 100, params = {}) {
    const query = `?page=${page}&records=${records}`;
    return apiGet(`/requests${query}`, { params })
      .then((response) => {
        const output = {
          pages: {
            all: response.data.pages.all,
            current: response.data.pages.current,
          },
          records: {
            all: response.data.pages.allRecords,
            output: response.data.pages.outputRecords,
            total: response.data.pages.total,
          },
          data: [],
        };
        response.data.docs.forEach((element) => {
          const request = {};
          Object.keys(element).forEach((field) => {
            if (field === '_id') {
              request.id = element[field];
            } else if (field === 'manager') {
              let name = '';
              if (element[field].firstName && element[field].lastName) {
                name = `${element[field].firstName} ${element[field].lastName}`;
              }
              request[field] = { ...element[field], name };
            } else if (field === 'dateCreate') {
              request[field] = moment(element[field]).format(
                'DD-MM-YYYY HH:mm:ss',
              );
            } else if (
              (field === 'dateLoad' ||
                field === 'plannedIssueDate' ||
                field === 'dateArrival' ||
                field === 'dateIssue' ||
                field === 'customerAccountDate' ||
                field === 'plannedDispatchDate' ||
                field === 'actualDateDispatch' ||
                field === 'departureVesselDate' ||
                field === 'arrivalVesselDate' ||
                field === 'notificationDate') &&
              element[field]
            ) {
              request[field] = moment(element[field]).format('DD-MM-YYYY');
            } else {
              request[field] = element[field];
            }
          });
          output.data.push(request);
        });
        return output;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  getRequestByIdForTable(id) {
    return apiGet(`/request/${id}`)
      .then((response) => {
        const output = {};
        if (response.data.doc) {
          Object.keys(response.data.doc).forEach((field) => {
            if (field === '_id') {
              output.id = response.data.doc[field];
            } else if (field === 'dateCreate') {
              output[field] = moment(response.data.doc[field]).format(
                'DD-MM-YYYY HH:mm:ss',
              );
            } else if (
              (field === 'dateLoad' ||
                field === 'plannedIssueDate' ||
                field === 'dateArrival' ||
                field === 'dateIssue' ||
                field === 'customerAccountDate' ||
                field === 'plannedDispatchDate' ||
                field === 'actualDateDispatch' ||
                field === 'departureVesselDate' ||
                field === 'arrivalVesselDate' ||
                field === 'notificationDate') &&
              response.data.doc[field]
            ) {
              output[field] = moment(response.data.doc[field]).format(
                'DD-MM-YYYY',
              );
            } else {
              output[field] = response.data.doc[field];
            }
          });
        }
        return output;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  getRequestById(id) {
    return apiGet(`/request/${id}`)
      .then((response) => {
        const res = {
          doc: {
            id: response.data.doc._id,
          },
          headers: response.data.headers,
        };
        Object.keys(response.data.doc).forEach((field) => {
          if (field === 'dateCreate') {
            res.doc[field] = moment(response.data.doc[field]).format(
              'DD-MM-YYYY HH:mm:ss',
            );
          } else if (
            (field === 'dateLoad' ||
              field === 'plannedIssueDate' ||
              field === 'dateArrival' ||
              field === 'dateIssue' ||
              field === 'customerAccountDate' ||
              field === 'plannedDispatchDate' ||
              field === 'actualDateDispatch' ||
              field === 'departureVesselDate' ||
              field === 'arrivalVesselDate' ||
              field === 'notificationDate') &&
            response.data.doc[field]
          ) {
            res.doc[field] = moment(response.data.doc[field]).format(
              'DD-MM-YYYY',
            );
          } else if (response.data.doc[field] === null) {
            res.doc[field] = null;
          } else if (
            typeof response.data.doc[field] === 'object' &&
            !Array.isArray(response.data.doc[field])
          ) {
            let data = '';
            if (field === 'manager') {
              const { firstName, lastName } = response.data.doc[field];
              if (firstName) data += `${firstName} `;
              if (lastName) data += lastName;
            } else if (
              field === 'addressLoading' ||
              field === 'addressUnloading'
            ) {
              data = response.data.doc[field].title;
            } else if (field === 'driver') {
              Object.keys(response.data.doc[field]).forEach((key) => {
                if (key !== '_id') {
                  if (response.data.doc[field][key]) {
                    data += response.data.doc[field][key];
                    data += ' ';
                  }
                }
              });
            } else if (field === 'trailer') {
              if (
                response.data.doc[field]._id &&
                !response.data.doc[field].name
              ) {
                data = response.data.doc[field].number;
              } else {
                data = response.data.doc[field].name;
              }
            } else if (field === 'car') {
              if (
                response.data.doc[field]._id &&
                !response.data.doc[field].name
              ) {
                data = `${response.data.doc[field].mark} ${response.data.doc[field].number}`;
              } else {
                data = response.data.doc[field].name;
              }
            } else {
              data = response.data.doc[field].name;
            }
            res.doc[field] = { id: response.data.doc[field]._id, data };
          } else {
            res.doc[field] = response.data.doc[field];
          }
        });
        return res;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  getRequestsExcel(params) {
    return apiPost('/requests/excel', {}, params)
      .then((response) => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  getAvailableFields() {
    return apiGet('/requests/availablefields')
      .then((response) => {
        if (response && response.data) {
          return response.data;
        }
        throw new Error('Требуется повторная авторизация пользователя');
      })
      .catch((error) => {
        throw new Error(
          error.response ? error.response.data.error.message : error.message,
        );
      });
  },
  addRequest(data) {
    return apiPost('/request', data)
      .then((response) => {
        if (response && response.data) {
          return { result: response.data, status: response.status };
        }
        throw new Error('Требуется повторная авторизация пользователя');
      })
      .catch((error) => {
        throw new Error(
          error.response ? error.response.data.error.message : error.message,
        );
      });
  },
  updateRequest(id, fields) {
    return apiPut(`/request/${id}`, fields)
      .then((response) => {
        if (response && response.data) {
          return { result: response.data, status: response.status };
        }
        throw new Error('Требуется повторная авторизация пользователя');
      })
      .catch((error) => {
        throw new Error(
          error.response ? error.response.data.error.message : error.message,
        );
      });
  },
  deleteRequest(id) {
    return apiDelete(`/request/${id}`)
      .then((response) => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  copyRequest(id) {
    return apiPost('/requests/copy', { request: id })
      .then((response) => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  postRequest(requests, emails) {
    return apiPost('/requests/forms', { requests, emails })
      .then((response) => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(
          error.response.data.error
            ? error.response.data.error.message
            : { message: 'Выгрузка по ЖД не удалась' },
        );
      });
  },
  getMaxCountColumns() {
    return apiGet('/requests/maxlength')
      .then((response) => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(
          error.response.data.error
            ? error.response.data.error.message
            : { message: 'Ошибка получения количества колонок' },
        );
      });
  },
  getDriverTask(listId) {
    return apiGet(`/requests/driverTask/?listId=${listId}`)
      .then((response) => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  getFilterList(openedFilter, paramsFilters) {
    const query = `?fieldDistinct=${openedFilter}`;
    return apiGet(`/requests/filter/${query}`, { params: paramsFilters })
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  postLoadingData(body, query) {
    let queryStr = '';
    if (!body.ids.length) {
      Object.keys(query).forEach(
        (item) => (queryStr += `&${item}=${query[item]}`),
      );
      if (queryStr.length) {
        queryStr = `?${queryStr.slice(1)}`;
      }
    }
    return apiPost(`/requests/loadingData${queryStr}`, body)
      .then((response) => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(
          error.response.data.error
            ? error.response.data.error.message
            : { message: 'Отправить данные на погрузку не удалось' },
        );
      });
  },
  loadExcelStatus(body) {
    return apiPost('/requests/excelRequestsStatus', body)
      .then((response) => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  getTrackingDataExcel(params) {
    return apiPost('/requests/trackingDataExcel', {}, params)
      .then((response) => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
};
