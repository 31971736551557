import styled from 'styled-components';

import { base, colors, screens } from '../../styles';

export const NavBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  height: 43px;
  width: calc(100% - 48px);
  box-shadow: 10px 0px 12px 0px rgba(0, 0, 0, 0.08);
  padding: 24px;
  position: fixed;
  z-index: 102;
`;

export const LogoWrapper = styled.div`
  width: ${base * 17}px;
  margin-right: 12px;
  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  & > button {
    margin-right: 12px;
  }
`;

export const MobileNavbar = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: ${screens.screen_xs_max}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const AddButtonWrapper = styled.div`
  margin-right: 12px;

  button {
    height: 100%;
  }
`;

// only mobile
export const HideComponent = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
`;

export const ContainerSelectDropList = styled.div`
  div {
    width: 97%;
    margin-bottom: 0;
  }

  input {
    cursor: pointer;
    caret-color: transparent;
  }
`;
