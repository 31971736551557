import { compose, withState, lifecycle, withHandlers } from 'recompose';

import { connect } from 'react-redux';

import CustColumnsView from './CustColumnsView';

// services
import { UserService, UserTemplateService } from '../../../../services';

// actions
import { saveUserData, saveUserAccesses } from '../../../../store/actions/auth';
import {
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  setSecondaryBarItems,
  checkStatusPopup,
} from '../../../../store/actions/dashboard';
import { REQUEST_FIELDS as CustListFull } from '../../../../constants';

export default compose(
  withState('custList', 'setCustList', []),
  withState('saveName', 'setSaveName', []),
  withState('custSaved', 'setCustSaved', false),
  withState('isLoading', 'setIsLoading', false),
  withState('searchValue', 'setSearchValue', ''),
  withState('filteredCustList', 'setFilteredCustList', []),
  withState('isChanged', 'setIsChanged', false),
  withState('templateName', 'setTemplateName', ''),
  withState('template', 'setTemplate', null),
  withState('templates', 'setTemplates', []),
  withState('userFields', 'setUserFields', []),
  withState('showTemplateModal', 'setShowTemplateModal', false),
  withState('showPopupUpdate', 'setShowPopupUpdate', false),
  withState('showPopupRemove', 'setShowPopupRemove', false),
  connect(
    (state) => ({
      user: state.auth.currentUser,
      userAccesses: state.auth.userAccesses,
    }),
    (dispatch) => ({
      saveUserData: (data) => dispatch(saveUserData(data)),
      setSecondaryBarItems: (items) => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: (item) => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: (item) =>
        dispatch(setActiveSecondarySidebarItem(item)),
      saveUserAccesses: (data) => dispatch(saveUserAccesses(data)),
      checkStatusPopup: (data) => dispatch(checkStatusPopup(data)),
    }),
  ),
  withHandlers({
    functionCheckTime: (props) => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
  }),
  withHandlers({
    selectAllInSection: (props) => (sectionName, value) => {
      const newCustList = [...props.custList];
      const newSaveName = [];
      newCustList.forEach((item) => {
        item.items.forEach((itemCheckBox) => {
          if (item.name !== sectionName && itemCheckBox.value) {
            newSaveName.push(itemCheckBox.name);
            return;
          }
          if (item.name !== sectionName) return;
          itemCheckBox.value = value;
          if (itemCheckBox.value) {
            newSaveName.push(itemCheckBox.name);
          } else if (newSaveName.indexOf(itemCheckBox.name) !== -1) {
            newSaveName.splice(newSaveName.indexOf(itemCheckBox.name), 1);
          }
        });
      });
      props.setCustList(newCustList);
      props.setSaveName(newSaveName);
      props.setIsChanged(true);
    },
    openProfileHandler: (props) => () => {
      props.history.replace('/dashboard/profile/personalInfo');
    },
    setCheckValue: (props) => (itemCheck) => {
      const newCustList = props.custList;
      const newSaveName = [];
      newCustList.forEach((item) => {
        item.items.forEach((itemCheckBox) => {
          if (itemCheck === itemCheckBox.name) {
            itemCheckBox.value = !itemCheckBox.value;
          }
          if (itemCheckBox.value) {
            newSaveName.push(itemCheckBox.name);
          } else if (newSaveName.indexOf(itemCheckBox.name) !== -1) {
            newSaveName.splice(newSaveName.indexOf(itemCheckBox.name), 1);
          }
        });
      });
      props.setCustList(newCustList);
      props.setSaveName(newSaveName);
      props.setIsChanged(true);
    },
    saveCustColumnsData: (props) => async () => {
      const resultUser = await UserService.getUserById(props.user._id);

      // для сохранения настройки порядка полей
      let oldFields = resultUser.fieldsRequests.filter((item) =>
        props.saveName.includes(item),
      );
      const newFields = props.saveName.filter(
        (item) => !oldFields.includes(item),
      );
      if (!props.isChanged) {
        oldFields = resultUser.fieldsRequests;
      }
      const body = {
        fieldsRequests: [...oldFields, ...newFields],
      };
      try {
        const result = await UserService.updateUser(props.user._id, body);

        // user templates
        if (props.template && props.templateName) {
          body.name = props.templateName;

          await UserTemplateService.updateTemplate(props.template._id, body);

          props.functionCheckTime('Success', 'Шаблон успешно обновлен');
        }
        if (!props.template && props.templateName) {
          body.userId = props.user._id;
          body.name = props.templateName;
          const newTemplate = await UserTemplateService.addTemplate(body);

          const resultUserUpdated = await UserService.getUserById(
            props.user._id,
          );
          props.saveUserData(resultUserUpdated);
          props.setTemplate(newTemplate.result);
          props.setTemplateName(newTemplate.result.name);
          props.functionCheckTime('Success', 'Шаблон успешно создан');
        }

        if (result.status === 200) {
          props.setCustSaved(true);
          setTimeout(() => {
            props.setCustSaved(false);
          }, 3000);
        }
      } catch (err) {
        setTimeout(() => {
          props.setCustSaved(false);
        }, 3000);
      }
    },
    searchItems: (props) => (value) => {
      const filteredItems = props.custList
        .map((item) => ({
          ...item,
          items: item.items.filter((item) =>
            new RegExp(value, 'gi').test(item.title),
          ),
        }))
        .filter((item) => item.items.length > 0);
      props.setFilteredCustList(filteredItems);
    },
    changeTemplateName: (props) => (value) => {
      props.setTemplateName(value);
    },
    getTemplates: (props) => async () => {
      try {
        const params = { userId: props.user._id };
        const result = await UserTemplateService.getTemplates(params);
        props.setTemplates(result.result);
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    chooseTemplate: (props) => async (data) => {
      const template = props.templates.find((item) => item._id === data.value);

      if (template) {
        try {
          await UserService.updateUser(props.user._id, {
            templateId: template._id,
            fieldsRequests: template.fieldsRequests || [],
            pinnedFields: template.pinnedFields || [],
          });
          const resultUser = await UserService.getUserById(props.user._id);

          props.saveUserData(resultUser);

          CustListFull.forEach((itemCustList) => {
            itemCustList.items.forEach((itemNameCust) => {
              if (template.fieldsRequests.indexOf(itemNameCust.name) !== -1) {
                itemNameCust.value = true;
              } else {
                itemNameCust.value = false;
              }
            });
          });

          const fields = CustListFull.map((item) => ({
            title: item.title,
            name: item.name,
            items: item.items.filter(
              (field) => props.userFields.indexOf(field.name) !== -1,
            ),
          }));

          props.setTemplateName(template.name);
          props.setTemplate(template);
          props.setCustList(fields);
          props.setFilteredCustList(fields);
          props.functionCheckTime('Success', 'Шаблон успешно применен');
        } catch (error) {
          props.functionCheckTime('Error', error.message);
        }
      }

      if (!template) {
        props.setTemplate(null);
        props.setTemplateName('');
        props.setShowTemplateModal(true);
      }

      document.querySelector('.cust-columns-main') &&
        document
          .querySelector('.cust-columns-main')
          .querySelectorAll('input') &&
        document
          .querySelector('.cust-columns-main')
          .querySelectorAll('input')[0] &&
        document
          .querySelector('.cust-columns-main')
          .querySelectorAll('input')[0]
          .blur();
    },
    removeTemplate: (props) => async () => {
      if (props.template && props.template._id) {
        try {
          await UserTemplateService.deleteTemplate(props.template._id);
          await UserService.updateUser(props.user._id, { templateId: null });
          const resultUser = await UserService.getUserById(props.user._id);

          props.saveUserData(resultUser);
          props.setTemplate(null);
          props.setTemplateName('');
          props.functionCheckTime('Success', 'Шаблон успешно удален');
        } catch (error) {
          props.functionCheckTime('Error', error.message);
        }
      }
    },
    cancelTemplate: (props) => async () => {
      if (props.template) {
        try {
          await UserService.updateUser(props.user._id, { templateId: null });
          const resultUser = await UserService.getUserById(props.user._id);

          props.saveUserData(resultUser);
          props.setTemplate(null);
          props.setTemplateName('');
          props.functionCheckTime('Success', 'Шаблон успешно отменен');
        } catch (error) {
          props.functionCheckTime('Error', error.message);
        }
      }
    },
    closeTemplateModal: (props) => () => {
      props.setShowTemplateModal(false);
    },
  }),
  lifecycle({
    async componentDidMount() {
      try {
        this.props.setIsLoading(true);
        const result = await UserService.getRight();

        const userById = await UserService.getUserById(this.props.user._id);

        let { fieldsRequests } = userById;
        if (userById.templateId) {
          const template = await UserTemplateService.getTemplateById(
            userById.templateId,
          );

          // eslint-disable-next-line prefer-destructuring
          fieldsRequests = template.result.fieldsRequests;
          this.props.setTemplateName(template.result.name);
          this.props.setTemplate(template.result);
        }

        let { requests = {} } = result.result;

        if (Object.keys(requests).length === 0) {
          requests = this.props.userAccesses;
        }

        const userFields = Object.keys(requests).filter(
          (item) => requests[item],
        );

        this.props.setUserFields(userFields);

        CustListFull.forEach((itemCustList) => {
          itemCustList.items.forEach((itemNameCust) => {
            if (fieldsRequests.indexOf(itemNameCust.name) !== -1) {
              itemNameCust.value = true;
            } else {
              itemNameCust.value = false;
            }
          });
        });

        const fields = CustListFull.map((item) => ({
          title: item.title,
          name: item.name,
          items: item.items.filter(
            (field) => userFields.indexOf(field.name) !== -1,
          ),
        }));
        this.props.setCustList(fields);
        this.props.setFilteredCustList(fields);
        this.props.setIsLoading(false);
      } catch (error) {
        this.props.setIsLoading(false);
        this.props.functionCheckTime('Error', error.message);
      }

      this.props.setSecondaryBarItems([
        {
          id: '123',
          name: 'Личная информация',
          url: '/dashboard/profile/personalInfo',
        },
        {
          id: '1234',
          name: 'Смена пароля',
          url: '/dashboard/profile/changePassword',
        },
        {
          id: '12345',
          name: 'Настройка колонок',
          url: '/dashboard/profile/custColumns',
        },
      ]);
      this.props.setActiveSidebarItem('');
    },
  }),
)(CustColumnsView);
