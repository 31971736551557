/**
 * @flow
 */
import React from 'react';

import { PageTitle, TimeZones } from '../../../components';
import {
  Content,
  TimeZonesWrapper,
  UserName,
  TimeZonesTitle,
  Greeting,
} from './MainStyled';

type MainPropsType = {
  user: Object,
};

export default function Main(props: MainPropsType) {
  return (
    <Content>
      <Greeting>
        <PageTitle>
          С возвращением,&nbsp;<UserName> {props.user.firstName}</UserName>
        </PageTitle>
      </Greeting>
      <TimeZonesWrapper>
        <TimeZonesTitle>Время в городах:</TimeZonesTitle>
        <TimeZones />
      </TimeZonesWrapper>
    </Content>
  );
}
