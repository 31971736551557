import styled from 'styled-components';

import { base, colors, screens } from '../../styles';

export const InfoBlockTotalWrapper = styled.div`
  width: ${base * 13}px;
  min-height: ${base * 10}px;
  border-radius: 6px;
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.05) 0px ${base * 0.2}px ${base * 0.4}px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  margin: ${base * 0.3}px ${base * 0.3}px;
  @media (max-width: ${screens.screen_sm_mid}) {
    width: ${base * 10}px;
  }
  @media (max-width: ${screens.screen_2xs_min}) {
    width: 40%;
  }
  @media (max-width: ${screens.screen_3xs_min}) {
    width: 90%;
  }
`;

export const InfoBlockTotalNumber = styled.h3``;

export const InfoBlockTotalDate = styled.p``;

export const InfoBlockTotalAmount = styled.p``;

export const InfoBlockTotalComment = styled.p`
  word-break: break-word;
`;
