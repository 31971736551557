import styled, { css } from 'styled-components';

import { base, screens } from '../../styles';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 38px;
  box-sizing: border-box;
  min-height: 100vh;
  ${(props) =>
    props.background &&
    css`
      background-image: url(${props.background});
      background-repeat: no-repeat;
      background-size: cover;
    `}
  transition: all .3s ease-in-out;
  @media (max-width: 800px) {
    background-position: center;
  }
`;

export const LogoWrapper = styled.div`
  width: ${base * 22}px;
  margin: 40px 0 40px 0;
`;

export const MainPart = styled.div`
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const MainPartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: ${base * 36.5}px;
  height: 100%;
  @media (max-width: 800px) {
    align-items: center;
    margin: 0 auto;
  }
`;

export const SecondaryPart = styled.div`
  display: flex;
  width: 50%;
  align-items: flex-end;
  justify-content: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const TimeZonesWrapper = styled.div`
  height: ${base * 13}px;
  width: 100%;
  font-size: ${base + 6}px;
  @media (max-width: ${screens.screen_lg_min}) {
    height: ${base * 25}px;
    padding: 0 24px;
    div {
      div {
        width: 100%;
        padding: 0 24px;
      }
    }
  }
`;

export const AdditionalInformation = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 16px;
  color: black;
  opacity: 0.3;

  img {
    width: 100px;
  }

  p {
    margin: 0;

    a {
      text-decoration: none;
      color: black;

      &:visited {
        color: black;
      }
    }
  }
`;

export const PopupMessage = styled.div`
  position: fixed;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
  transition: bottom 0.3s ease-in-out;
  ${(props) =>
    props.show &&
    css`
      bottom: 20px;
    `}
`;
