import styled from 'styled-components';

import { base, colors } from '../../../styles';

export const StampItem = styled.div`
  color: #adadad;
  padding: 6px 20px;
  font-size: 18px;
  &:hover {
    color: ${colors.primary_red};
  }
`;
