import React from 'react';
import Rating from 'react-rating';

export default function RequestFormView(props) {
  return (
    <Rating
      emptySymbol={
        <img
          style={{ width: '12px' }}
          src={require('../../assets/icons/star-grey.svg')}
          alt="img"
        />
      }
      fullSymbol={
        <img
          style={{ width: '12px' }}
          src={require('../../assets/icons/star.svg')}
          alt="img"
        />
      }
      onClick={(value) => props.setRating(props.data.id, value)}
      initialRating={props.value}
      readonly={props.readOnlyRating()}
    />
  );
}
