import { compose, withState, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  socket,
  connection as socketConnection,
} from '../../../services/socket';

import { saveUserAccesses } from '../../../store/actions/auth';
import {
  changeShowRatingModal,
  setLastIndexRowRequest,
  setAllFilterModel,
  setDateFilters,
  setRangeDate,
  setDateFiltersActive,
  setSelectedStatus,
  setSortModel,
  setSearchModel,
  setOtherFilters,
  setSortModelByLogin,
  setPayers,
} from '../../../store/actions/request';
import {
  BUH_TOTAL_INPUT,
  ROLES_NAME,
  REQUEST_FIELDS,
  numbersList,
  textsList,
  datesList,
  checkboxesList,
  selectors,
} from '../../../constants';
import { colors } from '../../../styles';

import {
  AddressService,
  AutoagentService,
  CarService,
  ConsigneeService,
  CounterpartyService,
  DriverService,
  LoadService,
  PayerService,
  RequestsService,
  ShipperService,
  StationService,
  TerminalService,
  TrailerService,
  UserService,
  CitiesService,
  directoriesList,
  UserTemplateService,
} from '../../../services';

import {
  setSecondaryBarItems,
  setActiveSidebarItem,
  setActiveSecondarySidebarItem,
  setExpand,
  setNewRequest,
  checkStatusPopup,
  setQuantityRequests,
} from '../../../store/actions/dashboard';

import RequestTableView from './RequestsTableView';

import { numberWithCommas } from '../../../helpers/RoundingNumbers';
import {
  getQueryFilters,
  getQuerySort,
  checkRequestInFilters,
  getPositionRequest,
} from '../../../helpers/Filters';

const sortModelDefault = [
  {
    colId: 'dateLoad',
    sort: 'desc',
  },
  {
    colId: 'payer',
    sort: 'asc',
  },
];

let headerNode = null;
let scrollableNode = null;
let timerOverId = null;
let timerOutId = null;
let timerScrollId = null;

export default compose(
  connect(
    (state) => ({
      activeSidebarItem: state.dashboard.activeItem,
      user: state.auth.currentUser,
      expand: state.dashboard.expand,
      access: state.auth.userAccesses,
      showRatingModal: state.request.showRatingModal,
      lastIndexRowRequest: state.request.lastIndexRowRequest,
      allFilterModel: state.request.allFilterModel,
      selectedFilters: state.request.selectedFilters,
      dateFilters: state.request.dateFilters,
      rangeDate: state.request.rangeDate,
      dateFiltersActive: state.request.dateFiltersActive,
      selectedStatus: state.request.selectedStatus,
      editRequestsList: state.dashboard.editRequestsList,
      sortModel: state.request.sortModel,
      searchModel: state.request.searchModel,
      allFilterModelByLogin: state.request.allFilterModelByLogin,
      otherFilters: state.request.otherFilters,
      sortModelByLogin: state.request.sortModelByLogin,
      quantityRequests: state.dashboard.quantityRequests,
    }),
    (dispatch) => ({
      saveUserAccesses: (data) => dispatch(saveUserAccesses(data)),
      setSecondaryBarItems: (items) => dispatch(setSecondaryBarItems(items)),
      setActiveSidebarItem: (item) => dispatch(setActiveSidebarItem(item)),
      setActiveSecondarySidebarItem: (item) =>
        dispatch(setActiveSecondarySidebarItem(item)),
      setExpand: (state) => dispatch(setExpand(state)),
      setNewRequest: (item) => dispatch(setNewRequest(item)),
      changeShowRatingModal: (data) => dispatch(changeShowRatingModal(data)),
      checkStatusPopup: (data) => dispatch(checkStatusPopup(data)),
      setLastIndexRowRequest: (data) => dispatch(setLastIndexRowRequest(data)),
      setAllFilterModel: (data, login) =>
        dispatch(setAllFilterModel(data, login)),
      setDateFilters: (data) => dispatch(setDateFilters(data)),
      setRangeDate: (data) => dispatch(setRangeDate(data)),
      setDateFiltersActive: (data) => dispatch(setDateFiltersActive(data)),
      setSelectedStatus: (data) => dispatch(setSelectedStatus(data)),
      setSortModel: (data) => dispatch(setSortModel(data)),
      setSearchModel: (data) => dispatch(setSearchModel(data)),
      setOtherFilters: (data, login) => dispatch(setOtherFilters(data, login)),
      setSortModelByLogin: (data, login) =>
        dispatch(setSortModelByLogin(data, login)),
      setQuantityRequests: (data) => dispatch(setQuantityRequests(data)),
      setPayers: (data) => dispatch(setPayers(data)),
    }),
  ),

  // Отвечает за отображение лоадинга
  withState('isLoading', 'setIsLoading', true),
  // Отвечает за состояние подгрузки данныъ
  withState('isDownload', 'setIsDownload', false),
  // Сколько получать данных на 1 странице
  withState('countRecords', 'setCountRecords', 100),
  // Текущая сатраница
  withState('page', 'setPage', 1),
  // Расшифровка полей
  withState('availableFields', 'setAvailableFields', {}),
  // Хранится хеадер в формате AG-GRID
  withState('tableHeader', 'setTableHeader', []),
  // Хранятся данные для таблицы
  withState('tableData', 'setTableData', []),
  // Хранятся параметры AG-GRID
  withState('tableParams', 'setTableParams', null),
  // Структура фильтрации таблицы
  withState('filters', 'setFilters', {}),
  // Состояние фильтра (применен или нет)
  withState('filtersActive', 'setFiltersActive', false),
  // Состояние фильтра для отображения точки над иконкой (применен или нет)
  withState('filtersActivePoint', 'setFiltersActivePoint', false),
  // Значение полнотекстового поиска
  withState('searchValue', 'setSearchValue', ''),
  // Таймер обновления таблицы, исходя из значения полнотекстового поиска
  withState('timerState', 'setTimerState', ''),
  // Состояние отображения календаря, в фильтре по диапазону дат
  withState('viewCalendar', 'setViewCalendar', false),
  // Типы полей
  withState('typeFields', 'setTypeFields', {
    number: {
      type: 'input',
      inputType: 'number',
    },
    state: {
      type: 'select',
      values: [
        'Предварительное',
        'В пути',
        'Прибыл',
        'Выдан клиенту',
        'Прогон',
        'Закрыта',
        'Вывоз',
      ],
    },
    dateLoad: {
      type: 'input',
      inputType: 'date',
    },
    plannedIssueDate: {
      type: 'input',
      inputType: 'date',
    },
    timeLoad: {
      type: 'input',
      inputType: 'text',
    },
    typeSend: {
      type: 'select',
      values: ['Контейнерная', 'Авто', 'Вагонная', 'Сборка'],
    },
    typeContainer: {
      type: 'select',
      values: ['20FT', '20FT(HC)', '40FT', '40FT(HC)', '40FT(HC)PW', '40SHC'],
    },
    typeElivery: {
      type: 'select',
      values: ['До двери', 'До станции', 'Вывоз'],
    },
    numberContainer: {
      type: 'input',
      inputType: 'text',
    },
    stopSheet: {
      type: 'checkbox',
    },
    companyAmixgroup: {
      type: 'checkbox',
    },
    numberSeal: {
      type: 'input',
      inputType: 'text',
    },
    payer: {
      type: 'autocomplete',
      service: PayerService,
    },
    shipper: {
      type: 'autocomplete',
      service: ShipperService,
    },
    consignee: {
      type: 'autocomplete',
      service: ConsigneeService,
    },
    railCarrier: {
      type: 'autocomplete',
      service: ConsigneeService,
    },
    railCarrierStatus: {
      type: 'checkbox',
    },
    prrOnArrived: {
      type: 'checkbox',
    },
    agentAutoLoad: {
      type: 'autocomplete',
      service: AutoagentService,
    },
    ownerContainer: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    agentAutoCall: {
      type: 'autocomplete',
      service: AutoagentService,
    },
    exportAgentEurosib: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    agentAutoCallStatus: {
      type: 'checkbox',
    },
    addressLoading: {
      type: 'autocomplete',
      service: AddressService,
    },
    addressUnloading: {
      type: 'autocomplete',
      service: AddressService,
    },
    stationDeparture: {
      type: 'autocomplete',
      service: StationService,
    },
    destinationCity: {
      type: 'autocomplete',
      service: CitiesService,
    },
    stationDestination: {
      type: 'autocomplete',
      service: StationService,
    },
    loadActual: {
      type: 'autocomplete',
      service: LoadService,
    },
    loadDocumented: {
      type: 'autocomplete',
      service: LoadService,
    },
    cargoCode: {
      type: 'input',
      inputType: 'text',
    },
    weight: {
      type: 'input',
      inputType: 'text',
    },
    numberSeats: {
      type: 'input',
      inputType: 'text',
    },
    driver: {
      type: 'autocomplete',
      service: DriverService,
    },
    unloadingDriverTaskStatus: {
      type: 'checkbox',
    },
    companyMoversUnloading: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    companyMoversLoading: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    containerAccessory: {
      type: 'select',
      values: ['Клиент', 'Евросиб'],
    },
    companyNameForwarder: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    car: {
      type: 'autocomplete',
      service: CarService,
    },
    trailer: {
      type: 'autocomplete',
      service: TrailerService,
    },
    terminalStaging: {
      type: 'autocomplete',
      service: TerminalService,
    },
    instructionsDelivery: {
      type: 'input',
      inputType: 'text',
    },
    releases: {
      type: 'input',
      inputType: 'text',
    },
    company: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    manager: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    managerByRegion: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    security: {
      type: 'checkbox',
    },
    warming: {
      type: 'input',
      inputType: 'text',
    },
    warmingCompany: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    forwarderLoading: {
      type: 'checkbox',
    },
    forwarderUnloading: {
      type: 'checkbox',
    },
    movers: {
      type: 'input',
      inputType: 'text',
    },
    companyMovers: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    veterinaryCertificate: {
      type: 'checkbox',
    },
    gdnUnloading: {
      type: 'checkbox',
    },
    loadFastening: {
      type: 'checkbox',
    },
    agentDocuments: {
      type: 'input',
      inputType: 'text',
    },
    returnOfDocumentsFromUnloading: {
      type: 'select',
      values: [
        'Вернуть в офис',
        'Вернуть в офис 2 адр.',
        'Вернуть в офис 3 адр.',
        'Вернуть в офис 4 адр.',
        'Сканы есть',
        'Неполный комплект',
        'Документы в офисе',
        'Оригиналы в офисе',
        'Документы передали',
        'Документы передали/оригиналы в офисе',
        'Документы передали/сканы есть',
        'Забрал сам клиент',
      ],
    },
    dateArrival: {
      type: 'input',
      inputType: 'date',
    },
    dateIssue: {
      type: 'input',
      inputType: 'date',
    },
    tracking: {
      type: 'input',
      inputType: 'text',
    },
    distance: {
      type: 'input',
      inputType: 'number',
    },
    railwayInvoices: {
      type: 'checkbox',
    },
    loading: {
      type: 'checkbox',
    },
    loadingTN: {
      type: 'checkbox',
    },
    unloadingTN: {
      type: 'checkbox',
    },
    act: {
      type: 'checkbox',
    },
    od: {
      type: 'input',
      inputType: 'text',
    },
    shipment: {
      type: 'input',
      inputType: 'text',
    },
    customerApplicationNumber: {
      type: 'input',
      inputType: 'text',
    },
    additionalServices: {
      type: 'input',
      inputType: 'text',
    },
    additionalServicesCounterparty: {
      type: 'autocomplete',
      service: CounterpartyService,
    },
    buh_amount: {
      type: 'input',
      inputType: 'number',
    },
    buh_carrier: {
      type: 'input',
      inputType: 'number',
    },
    buh_autoBeforeLoading: {
      type: 'input',
      inputType: 'number',
    },
    buh_forMKAD_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_forMKAD_km: {
      type: 'input',
      inputType: 'number',
    },
    buh_forMKAD: {
      type: 'input',
      inputType: 'number',
    },
    additionalLoadingAddress: {
      type: 'input',
      inputType: 'number',
    },
    buh_plain_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_plain_hour: {
      type: 'input',
      inputType: 'number',
    },
    buh_plain: {
      type: 'input',
      inputType: 'number',
    },
    buh_overload_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_overload_ton: {
      type: 'input',
      inputType: 'number',
    },
    buh_overload: {
      type: 'input',
      inputType: 'number',
    },
    buh_containerAgent: {
      type: 'input',
      inputType: 'number',
    },
    buh_aboveTheNorm: {
      type: 'input',
      inputType: 'number',
    },
    buh_aboveTheNorm_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_aboveTheNorm_day: {
      type: 'input',
      inputType: 'number',
    },
    buh_forwarderOnLoading: {
      type: 'input',
      inputType: 'number',
    },
    buh_moversOnLoading: {
      type: 'input',
      inputType: 'number',
    },
    buh_moversAtUnloading: {
      type: 'input',
      inputType: 'number',
    },
    buh_veterinaryCertificate: {
      type: 'input',
      inputType: 'number',
    },
    buh_loadFastening: {
      type: 'input',
      inputType: 'number',
    },
    buh_warming: {
      type: 'input',
      inputType: 'number',
    },
    buh_additionalServices: {
      type: 'input',
      inputType: 'number',
    },
    buh_exportAgent: {
      type: 'input',
      inputType: 'number',
    },
    buh_simpleToUnload_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_simpleToUnload_hour: {
      type: 'input',
      inputType: 'number',
    },
    buh_simpleToUnload: {
      type: 'input',
      inputType: 'number',
    },
    buh_simpleToLoad_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_simpleToLoad_hour: {
      type: 'input',
      inputType: 'number',
    },
    buh_simpleToLoad: {
      type: 'input',
      inputType: 'number',
    },
    buh_storageAtTheDestinationStation_rate: {
      type: 'input',
      inputType: 'number',
    },
    buh_storageAtTheDestinationStation_day: {
      type: 'input',
      inputType: 'number',
    },
    buh_storageAtTheDestinationStation: {
      type: 'input',
      inputType: 'number',
    },
    buh_seal: {
      type: 'input',
      inputType: 'number',
    },
    buh_bills: {
      type: 'input',
      inputType: 'number',
    },
    buh_agentToClient: {
      type: 'input',
      inputType: 'number',
    },
    buh_managerial: {
      type: 'input',
      inputType: 'number',
    },
    buh_agentSuppliers: {
      type: 'input',
      inputType: 'number',
    },
    buh_OBN: {
      type: 'input',
      inputType: 'number',
    },
    buh_total: {
      type: 'input',
      inputType: 'number',
    },
    buh_gasketMaterial: {
      type: 'input',
      inputType: 'number',
    },
    customerAccountNumber: {
      type: 'input',
      inputType: 'text',
    },
    customerAccountAmount: {
      type: 'input',
      inputType: 'text',
    },
    customerAccountDate: {
      type: 'input',
      inputType: 'date',
    },
    carrierAccount: {
      type: 'input',
      inputType: 'text',
    },
    accountsAutoServices: {
      type: 'input',
      inputType: 'text',
    },
    containerAgentAccount: {
      type: 'input',
      inputType: 'text',
    },
    exportAgentAccount: {
      type: 'input',
      inputType: 'text',
    },
    moversAccountOnLoading: {
      type: 'input',
      inputType: 'text',
    },
    moversAccountOnUnloading: {
      type: 'input',
      inputType: 'text',
    },
    accountSecuringCargo: {
      type: 'input',
      inputType: 'text',
    },
    billCargoWeatherization: {
      type: 'input',
      inputType: 'text',
    },
    moversOnLoading: {
      type: 'checkbox',
    },
    moversAtUnloading: {
      type: 'checkbox',
    },
    scheduledIssueTime: {
      type: 'input',
      inputType: 'text',
    },
    documentStatus: {
      type: 'select',
      values: [
        'Сканы есть',
        'Документы в офисе',
        'Оригиналы в офисе',
        'Документы передали',
        'Документы передали/оригиналы в офисе',
        'Документы передали/сканы есть',
        'Документы забрал сам клиент',
      ],
    },
    timeIssue: {
      type: 'input',
      inputType: 'text',
    },
    plannedDispatchDate: {
      type: 'input',
      inputType: 'date',
    },
    actualDateDispatch: {
      type: 'input',
      inputType: 'date',
    },
    departureVesselDate: {
      type: 'input',
      inputType: 'date',
    },
    arrivalVesselDate: {
      type: 'input',
      inputType: 'date',
    },
    notificationDate: {
      type: 'input',
      inputType: 'date',
    },
    buh_pay: {
      type: 'input',
      inputType: 'number',
    },
    buh_containerAgentPayment: {
      type: 'input',
      inputType: 'number',
    },
    balance: {
      type: 'input',
      inputType: 'number',
    },
    attorneyPowerNumber: {
      type: 'input',
      inputType: 'text',
    },
    note: {
      type: 'input',
      inputType: 'text',
    },
    exhibitedSimpleToUnload: {
      type: 'input',
      inputType: 'text',
    },
    exhibitedStorage: {
      type: 'input',
      inputType: 'text',
    },
    exhibitedOverflow: {
      type: 'input',
      inputType: 'text',
    },
    exhibitedAdditionalServices: {
      type: 'input',
      inputType: 'text',
    },
  }),
  // Хранятся выбранные заявки
  withState('selectedRequests', 'setSelectedRequests', []),
  // Статус загрузки excel файла
  withState('loadingExcel', 'setLoadingExcel', false),
  // Статус копирования заявки
  withState('copyH', 'setCopyH', false),
  // Хранится id копируемой заявки
  withState('idCopy', 'setIdCopy', ''),
  // Хранится номер копируемой заявки
  withState('numberCopy', 'setNumberCopy', ''),
  // Хранится позиция копируемой заявки
  withState('positionCopy', 'setPositionCopy', 0),
  // Статус удаления заявки
  withState('delPopup', 'setDelPop', false),
  withState('delSeveralRequests', 'setDelSeveralRequests', false),
  // Хранится id удаляемой заявки
  withState('idDelete', 'setIdDelete', ''),
  // Хранится номер удаляемой заявки
  withState('numberDelete', 'setNumberD', ''),
  withState('showAutocompleteValues', 'setShowAutocompleteValues', {}),
  withState('autocompleteValues', 'setAutocompleteValues', {}),
  // Хранится текст отзыва
  withState('ratingReview', 'setRatingReview', ''),
  withState('requestToRating', 'setRequestToRating', null),
  withState('isReviewSending', 'setIsReviewSending', false),
  // emails
  withState('showEmailsModal', 'setShowEmailsModal', false),
  withState('isEmailsSending', 'setIsEmailsSending', false),
  withState('stringEmails', 'setStringEmails', ''),
  withState('orderColumnsString', 'setOrderColumnsString', ''),
  withState('filterModel', 'setFilterModel', {}),
  withState('isShowFilterBlock', 'setShowFilterBlock', false),
  withState('pinnedColumnsString', 'setPinnedColumnsString', ''),
  withState('isShowModalDate', 'setIsShowModalDate', false),
  withState('modalDateValue', 'setModalDateValue', ''),
  withState('modalDateValueMoment', 'setModalDateValueMoment', ''),
  withState('idRequestEdit', 'setIdRequestEdit', ''),
  withState('fieldRequestEdit', 'setFieldRequestEdit', ''),
  withState('showWarning', 'setShowWarning', false),
  withState('initSort', 'setInitSort', true),
  withState('isShowModalTime', 'setIsShowModalTime', false),
  withState('modalTimeValue', 'setModalTimeValue', ''),
  withState('showWarningTime', 'setShowWarningTime', false),
  withState('showTooltip', 'setShowTooltip', true),
  withState('valueTooltip', 'setValueTooltip', null),
  withState('coordsTooltip', 'setCoordsTooltip', null),
  withState('showLoaderLoading', 'setShowLoaderLoading', false),
  withState('stateClaim', 'setStateClaim', 'Предварительное'),
  withState('editableRequest', 'setEditableRequest', null),
  withState('sendLoadingData', 'setSendLoadingData', false),
  withState('processLoading', 'setProcessLoading', false),
  withState('isSendTrackingData', 'setIsSendTrackingData', false),
  withState('processTracking', 'setProcessTracking', false),
  // Служебные функции
  withHandlers({
    formatDate: (props) => (dateString) =>
      `${
        new Date(dateString).getDate() < 10
          ? `0${new Date(dateString).getDate()}`
          : new Date(dateString).getDate()
      }-${
        new Date(dateString).getMonth() + 1 < 10
          ? `0${new Date(dateString).getMonth() + 1}`
          : new Date(dateString).getMonth() + 1
      }-${new Date(dateString).getFullYear()}`,
  }),
  withHandlers({
    functionCheckTime: (props) => (statusPopup, messagePopup) => {
      props.checkStatusPopup({
        statusCheck: statusPopup,
        messageBox: messagePopup,
        statusTime: true,
      });
      setTimeout(() => {
        props.checkStatusPopup({
          statusCheck: statusPopup,
          messageBox: messagePopup,
          statusTime: false,
        });
      }, 2000);
    },
    closeReviewModal: (props) => () => {
      props.setRatingReview('');
      props.setIsReviewSending(false);
      props.setRequestToRating(null);
      props.changeShowRatingModal(false);
    },
    closeEmailsModal: (props) => () => {
      props.setStringEmails('');
      props.setIsEmailsSending(false);
      props.setShowEmailsModal(false);
    },
    closeDateModal: (props) => () => {
      props.setIsShowModalDate(false);
      props.setModalDateValue('');
      props.setIdRequestEdit('');
      props.setFieldRequestEdit('');
      props.setShowWarning(false);
    },
    closeTimeModal: (props) => () => {
      props.setIsShowModalTime(false);
      props.setModalTimeValue('');
      props.setIdRequestEdit('');
      props.setFieldRequestEdit('');
      props.setShowWarningTime(false);
    },
    getAdditionalData: (props) => (rowsDataOrigin) => {
      const rowsData = rowsDataOrigin.map((item) => {
        item.buh_amountMore &&
          item.buh_amountMore.forEach((elem, index) => {
            // add data buh_amountMore
            item[`buh_amountMoreAccountNumber${index + 1}`] =
              elem.accountNumber;
            item[`buh_amountMoreAmount${index + 1}`] = elem.amount;
            item[`buh_amountMoreDate${index + 1}`] = elem.date
              ? props.formatDate(elem.date.slice(0, 10))
              : elem.date;
            item[`buh_amountMoreComment${index + 1}`] = elem.comment;
          });

        item.buh_carrierMore &&
          item.buh_carrierMore.forEach((elem, index) => {
            // add data buh_carrierMore
            item[`buh_carrierMoreAccountNumber${index + 1}`] =
              elem.accountNumber;
            item[`buh_carrierMoreAmount${index + 1}`] = elem.amount;
          });

        item.buh_exportAgentMore &&
          item.buh_exportAgentMore.forEach((elem, index) => {
            // add data buh_exportAgentMore
            item[`buh_exportAgentMoreAccountNumber${index + 1}`] =
              elem.accountNumber;
            item[`buh_exportAgentMoreAmount${index + 1}`] = elem.amount;
          });

        if (!item.id) {
          item.id = item._id;
        }

        return item;
      });
      return rowsData;
    },
    getMaxCountColumns: (props) => (user, template) =>
      RequestsService.getMaxCountColumns()
        .then((data) => ({
          countColumnsAmountMore: data.result.amountMore,
          countColumnsCarrierMore: data.result.carrierMore,
          countColumnsExportAgentMore: data.result.exportAgentMore,
          user,
          template,
        }))
        .catch((error) => {
          props.functionCheckTime('Error', error.message);
          throw new Error(error);
        }),
    getWidthColumn: (props) => (params) => {
      let widthColumn = 150;
      switch (params) {
        case 'stopSheet':
        case 'loading':
        case 'act':
        case 'security':
        case 'weight':
          widthColumn = 80;
          break;
        case 'releases':
        case 'warming':
        case 'cargoCode':
        case 'trailer':
        case 'timofeevo':
        case 'timurovo':
        case 'rating':
          widthColumn = 100;
          break;
        case 'loadingTN':
        case 'unloadingTN':
        case 'timeIssue':
        case 'driver':
        case 'typeSend':
        case 'timeLoad':
        case 'numberSeal':
        case 'dateIssue':
        case 'dateLoad':
        case 'numberSeats':
        case 'note':
          widthColumn = 110;
          break;
        case 'agentAutoCall':
        case 'companyAmixgroup':
        case 'railwayInvoices':
        case 'manager':
        case 'tracking':
          widthColumn = 120;
          break;
        case 'railCarrier':
        case 'numberContainer':
        case 'buh_amount':
        case 'dateArrival':
        case 'car':
          widthColumn = 140;
          break;
        case 'buh_exportAgent':
        case 'buh_agentSuppliers':
        case 'additionalServices':
        case 'unloadingDriverTaskStatus':
          widthColumn = 160;
          break;
        case 'railCarrierStatus':
        case 'prrOnArrived':
        case 'ownerContainer':
        case 'buh_simpleToUnload_hour':
        case 'buh_simpleToLoad_hour':
        case 'buh_gasketMaterial':
        case 'exportAgentEurosib':
        case 'accountSimpleToUnload':
        case 'scheduledIssueTime':
        case 'buh_loadFastening':
          widthColumn = 170;
          break;
        case 'plannedDispatchDate':
        case 'actualDateDispatch':
        case 'departureVesselDate':
        case 'arrivalVesselDate':
        case 'notificationDate':
        case 'buh_simpleToUnload_rate':
        case 'buh_simpleToLoad_rate':
        case 'buh_moversOnLoading':
        case 'companyNameForwarder':
        case 'documentStatus':
        case 'moversAccountOnLoading':
        case 'moversAccountOnUnloading':
        case 'customerAccountDate':
        case 'accountSimpleToLoad':
          widthColumn = 180;
          break;
        case 'buh_forwarderOnLoading':
        case 'buh_additionalServices':
        case 'plannedIssueDate':
        case 'veterinaryCertificate':
        case 'gdnUnloading':
        case 'containerAgentAccount':
        case 'buh_simpleToUnload':
        case 'buh_simpleToLoad':
        case 'containerAccessory':
        case 'buh_moversAtUnloading':
        case 'accountAdditionalServices':
        case 'exhibitedStorage':
        case 'exhibitedOverflow':
          widthColumn = 200;
          break;
        case 'instructionsDelivery':
        case 'additionalLoadingAddress':
        case 'returnOfDocumentsFromUnloading':
        case 'distance':
          widthColumn = 210;
          break;
        case 'companyMoversLoading':
        case 'companyMoversUnloading':
        case 'buh_containerAgentPayment':
        case 'buh_veterinaryCertificate':
        case 'customerAccountNumberStatus':
        case 'carrierAccountStatus':
        case 'accountsAutoServicesStatus':
        case 'containerAgentAccountStatus':
        case 'exportAgentAccountStatus':
        case 'moversAccountOnLoadingStatus':
        case 'moversAccountOnUnloadingStatus':
        case 'accountSecuringCargoStatus':
        case 'billCargoWeatherizationStatus':
        case 'exhibitedSimpleToUnload':
        case 'exhibitedAdditionalServices':
          widthColumn = 230;
          break;
        case 'buh_storageAtTheDestinationStation_rate':
        case 'buh_storageAtTheDestinationStation_day':
        case 'buh_storageAtTheDestinationStation':
        case 'accountStorageAtDestinationStation':
          widthColumn = 250;
          break;
        default:
          widthColumn = 150;
      }
      if (params.includes('buh_amountMore')) {
        widthColumn = 160;
      }
      if (params.includes('buh_carrierMore')) {
        widthColumn = 200;
      }
      if (params.includes('buh_exportAgentMore')) {
        widthColumn = 220;
      }

      if (params === 'statusRequest') {
        widthColumn = 60;
      }
      if (
        params === 'statusRequest' &&
        props.access &&
        props.access.directory &&
        props.access.directory.requests &&
        (props.access.directory.requests.sendLoadingData ||
          props.access.directory.requests.sendTrackingData)
      ) {
        widthColumn = 75;
      }
      if (
        params === 'statusRequest' &&
        props.access &&
        props.access.directory &&
        props.access.directory.requests &&
        props.access.directory.requests.sendLoadingData &&
        props.access.directory.requests.sendTrackingData
      ) {
        widthColumn = 95;
      }
      return widthColumn;
    },
    getEditableFields: (props) => (field) => {
      let editableField = false;
      if (
        (field === 'tracking' || field === 'customerApplicationNumber') &&
        props.access.directory.requests.update &&
        props.user.roleRightName !== ROLES_NAME.provider &&
        props.user.roleRightName !== ROLES_NAME.clients
      ) {
        editableField = true;
      }
      if (
        (field === 'carrierAccount' ||
          field === 'containerAgentAccount' ||
          field === 'exportAgentAccount' ||
          field === 'moversAccountOnLoading' ||
          field === 'moversAccountOnUnloading' ||
          field === 'billCargoWeatherization') &&
        props.access.directory.requests.update &&
        props.user.roleRightName === ROLES_NAME.transaction
      ) {
        editableField = true;
      }
      if (
        (field === 'exhibitedSimpleToUnload' ||
          field === 'exhibitedStorage' ||
          field === 'exhibitedOverflow' ||
          field === 'exhibitedAdditionalServices') &&
        props.access.directory.requests.update
      ) {
        editableField = true;
      }
      return editableField;
    },
    hasActiveFilters: (props) => (params) =>
      props.selectedStatus ||
      props.searchModel ||
      props.dateFiltersActive ||
      (props.allFilterModel &&
        Object.keys(props.allFilterModel)
          .map((item) => {
            if (
              props.allFilterModel[item].values &&
              props.allFilterModel[item].values.length
            ) {
              return 1;
            }
            return 0;
          })
          .reduce((accum, value) => accum + +value, 0) > 0),
    getStyleCell: (props) => (params) => {
      const styles = {};
      if (params.data.profitable !== undefined) {
        if (props.access.requests.profitable && !params.data.profitable) {
          styles.backgroundColor = colors.pink_light;
        }
      }
      if (params.data && params.data.stopSheet) {
        styles.backgroundColor = colors.purple_light;
      }
      if (
        params.colDef.field === 'buh_amount' &&
        props.access.requests.paymentState
      ) {
        if (params.data && params.data.paymentState) {
          styles.backgroundColor = colors.green_light;
        } else {
          styles.backgroundColor = colors.pink_light;
        }
      }
      if (
        params.colDef.field === 'agentAutoCall' &&
        params.data &&
        params.data.agentAutoCallStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'railCarrier' &&
        params.data &&
        params.data.railCarrierStatus
      ) {
        styles.backgroundColor = colors.green_light;
      } else if (
        props.access.requests.statusSendForm
          ? params.colDef.field === 'railCarrier' &&
            params.data &&
            params.data.statusSendForm
          : false
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (params.colDef.field === BUH_TOTAL_INPUT) {
        styles.fontWeight = 'bolder';
      }
      if (
        params.colDef.field === 'customerAccountNumber' &&
        params.data &&
        params.data.customerAccountNumberStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'carrierAccount' &&
        params.data &&
        params.data.carrierAccountStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'accountsAutoServices' &&
        params.data &&
        params.data.accountsAutoServicesStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'containerAgentAccount' &&
        params.data &&
        params.data.containerAgentAccountStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'exportAgentAccount' &&
        params.data &&
        params.data.exportAgentAccountStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'moversAccountOnLoading' &&
        params.data &&
        params.data.moversAccountOnLoadingStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'moversAccountOnUnloading' &&
        params.data &&
        params.data.moversAccountOnUnloadingStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'accountSecuringCargo' &&
        params.data &&
        params.data.accountSecuringCargoStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'billCargoWeatherization' &&
        params.data &&
        params.data.billCargoWeatherizationStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'accountStorageAtDestinationStation' &&
        params.data &&
        params.data.accountStorageAtDestinationStationStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'accountSimpleToUnload' &&
        params.data &&
        params.data.accountSimpleToUnloadStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'accountSimpleToLoad' &&
        params.data &&
        params.data.accountSimpleToLoadStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'accountOverload' &&
        params.data &&
        params.data.accountOverloadStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'accountAboveTheNorm' &&
        params.data &&
        params.data.accountAboveTheNormStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'accountAdditionalServices' &&
        params.data &&
        params.data.accountAdditionalServicesStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      if (
        params.colDef.field === 'driver' &&
        params.data &&
        params.data.unloadingDriverTaskStatus
      ) {
        styles.backgroundColor = colors.green_light;
      }
      for (let i = 1; i <= params.countColumnsAmountMore; i++) {
        if (
          params.colDef.field === `buh_amountMoreAccountNumber${i}` &&
          params.data.buh_amountMore.length > 0 &&
          params.data.buh_amountMore[i - 1] &&
          params.data.buh_amountMore[i - 1].status
        ) {
          styles.backgroundColor = colors.green_light;
        }
      }
      for (let i = 1; i <= params.countColumnsCarrierMore; i++) {
        if (
          params.colDef.field === `buh_carrierMoreAccountNumber${i}` &&
          params.data.buh_carrierMore.length > 0 &&
          params.data.buh_carrierMore[i - 1] &&
          params.data.buh_carrierMore[i - 1].status
        ) {
          styles.backgroundColor = colors.green_light;
        }
      }
      for (let i = 1; i <= params.countColumnsExportAgentMore; i++) {
        if (
          params.colDef.field === `buh_exportAgentMoreAccountNumber${i}` &&
          params.data.buh_exportAgentMore.length > 0 &&
          params.data.buh_exportAgentMore[i - 1] &&
          params.data.buh_exportAgentMore[i - 1].status
        ) {
          styles.backgroundColor = colors.green_light;
        }
      }
      return styles;
    },
  }),
  // Функции, связанные с пользователем
  withHandlers({
    getRight: (props) => () =>
      UserService.getRight()
        .then((item) => {
          props.saveUserAccesses(
            {
              directory: item.result.directories,
              requests: item.result.requests,
              additionalPermissions: item.result.additionalPermissions,
            },
            () => {},
          );
        })
        .catch((error) => {
          console.warn('[API]-[GET RIGHT]', error);
          throw new Error(error);
        }),
    getUser: (props) => () =>
      UserService.getUserById(props.user._id)
        .then((user) => user)
        .catch((error) => {
          console.warn('[API]-[GET USER]', error);
          throw new Error(error);
        }),
    getUserTemplate: (props) => (user) => {
      if (user.templateId) {
        return UserTemplateService.getTemplateById(user.templateId)
          .then((data) => ({ user, template: data.result }))
          .catch((error) => {
            console.warn('[API]-[GET USER TEMPLATE]', error);
            throw new Error(error);
          });
      }

      return { user, template: null };
    },
  }),
  // Функции для таблицы AG-GRID
  withHandlers({
    getData:
      (props) =>
      (page, needSetQuantityRequests = true) => {
        let query = {};
        const sorting =
          props.sortModel && props.sortModel.length
            ? props.sortModel
            : sortModelDefault;
        const filters = props.allFilterModel;
        // check sorting params
        const querySort = getQuerySort(sorting);
        // check filters values
        const queryFilter = getQueryFilters(filters);
        query = { ...queryFilter, ...querySort };
        // check search value
        if (props.searchModel) {
          query.search = props.searchModel;
        }
        // check status value
        if (props.selectedStatus) {
          query.state = JSON.stringify([props.selectedStatus]);
        }
        // check date filter
        if (props.dateFiltersActive) {
          query.daterange =
            props.dateFilters === undefined ? '' : props.dateFilters;
        }
        return RequestsService.getAllRequests(page, props.countRecords, query)
          .then((response) => {
            if (needSetQuantityRequests) {
              if (response.pages.current === 1) {
                props.setQuantityRequests({
                  rows: response.data.length,
                  totalRows: Math.min(
                    response.records.total,
                    response.records.all,
                  ),
                });
              } else {
                const current = props.tableParams.api.getDisplayedRowCount();
                props.setQuantityRequests({
                  rows: current + response.data.length,
                  totalRows: Math.min(
                    response.records.total,
                    response.records.all,
                  ),
                });
              }
            }

            return {
              success: true,
              rows: response.data,
              lastRow: response.records.all,
            };
          })
          .catch((error) => {
            console.warn('[API]-[GET REQUESTS FROM API]', error);
            return {
              success: false,
            };
          });
      },
  }),
  // Initial функции
  withHandlers({
    initHeader: (props) => () => {
      let availableFields;
      return RequestsService.getAvailableFields()
        .then((data) => {
          availableFields = data;
          props.setAvailableFields(data);
          return props.getUser();
        })
        .then((user) => props.getUserTemplate(user))
        .then(({ user, template }) => props.getMaxCountColumns(user, template))
        .then((data) => {
          const {
            user,
            template,
            countColumnsAmountMore,
            countColumnsCarrierMore,
            countColumnsExportAgentMore,
          } = data;
          const header = {};
          const requestFields = [];
          REQUEST_FIELDS.forEach((item) =>
            item.items.forEach((elem) => requestFields.push(elem.name)),
          );

          let fieldsRequests = user.fieldsRequests;
          let pinnedFields = user.pinnedFields;
          if (template && template.fieldsRequests && template.pinnedFields) {
            fieldsRequests = template.fieldsRequests;
            pinnedFields = template.pinnedFields;
          }

          fieldsRequests.forEach((item) => {
            if (
              item !== 'number' &&
              item !== 'buh_amountMore' &&
              item !== 'buh_carrierMore' &&
              item !== 'buh_exportAgentMore' &&
              availableFields[item] &&
              requestFields.includes(item)
            ) {
              header[item] = availableFields[item];

              // create columns buh_amountMore
              if (
                item === 'buh_amount' &&
                fieldsRequests.includes('buh_amountMore') &&
                availableFields.buh_amountMore
              ) {
                for (let i = 1; i <= countColumnsAmountMore; i++) {
                  header[
                    `buh_amountMoreAccountNumber${i}`
                  ] = `Номер счета для суммы ${i}`;
                  header[`buh_amountMoreAmount${i}`] = `Сумма ${i}`;
                  header[`buh_amountMoreDate${i}`] = `Дата счета суммы ${i}`;
                  header[
                    `buh_amountMoreComment${i}`
                  ] = `Комментарий к сумме ${i}`;
                }
              }

              // create columns buh_carrierMore
              if (
                item === 'buh_carrier' &&
                fieldsRequests.includes('buh_carrierMore') &&
                availableFields.buh_carrierMore
              ) {
                for (let i = 1; i <= countColumnsCarrierMore; i++) {
                  header[
                    `buh_carrierMoreAccountNumber${i}`
                  ] = `Номер счета для перевозчик ${i}`;
                  header[`buh_carrierMoreAmount${i}`] = `Сумма перевозчик ${i}`;
                }
              }

              // create columns buh_exportAgentMore
              if (
                item === 'buh_exportAgent' &&
                fieldsRequests.includes('buh_exportAgentMore') &&
                availableFields.buh_exportAgentMore
              ) {
                for (let i = 1; i <= countColumnsExportAgentMore; i++) {
                  header[
                    `buh_exportAgentMoreAccountNumber${i}`
                  ] = `Номер счета для агент по вывозу ${i}`;
                  header[
                    `buh_exportAgentMoreAmount${i}`
                  ] = `Сумма агент по вывозу ${i}`;
                }
              }
            }
          });
          props.setTableHeader(
            [
              {
                headerName: 'Номер',
                field: 'number',
                pinned: 'left',
                checkboxSelection: true,
                headerCheckboxSelection: false,
                filter: false,
                width: 105,
                // cellStyle(params) {
                //   if (params.data) {
                //     let color = '#A9D08E';
                //     let backgroundColor = '';
                //     switch (params.data.state) {
                //       case 'Предварительное':
                //         color = '#A9D08E';
                //         break;
                //       case 'В пути':
                //         color = '#FFFF00';
                //         break;
                //       case 'Прибыл':
                //         color = '#da9d0a';
                //         break;
                //       case 'Выдан клиенту':
                //         color = '#0ada35';
                //         break;
                //       case 'Прогон':
                //         color = '#F4B084';
                //         break;
                //       case 'Закрыта':
                //         color = '#808080';
                //         break;
                //       case 'Вывоз':
                //         color = '#F58223';
                //         break;
                //       default:
                //         color = '#A9D08E';
                //         break;
                //     }
                //     let colorLoadingDataStatus = colors.white;
                //     switch (params.data.sendLoadingDataStatus) {
                //       case 'pending':
                //         colorLoadingDataStatus = '#FFFF00';
                //         break;
                //       case 'success':
                //         colorLoadingDataStatus = '#6ac631';
                //         break;
                //       case 'error':
                //         colorLoadingDataStatus = colors.primary_red;
                //         break;
                //     }

                //     if (params.data.profitable !== undefined) {
                //       if (
                //         props.access.requests.profitable &&
                //         !params.data.profitable
                //       ) {
                //         backgroundColor = colors.pink_light;
                //       }
                //     }

                //     if (params.data && params.data.stopSheet) {
                //       backgroundColor = colors.purple_light;
                //     }

                //     const style = {};
                //     style.overflow = 'hiden';
                //     style.backgroundColor = `${backgroundColor}`;

                //     if (props.access.requests.state) {
                //       style.borderLeft = `5px solid ${color}`;
                //     }
                //     if (
                //       props.access.requests.sendLoadingDataStatus &&
                //       params.data.sendLoadingDataStatus
                //     ) {
                //       style.borderRight = `5px solid ${colorLoadingDataStatus}`;
                //     }

                //     return style;
                //   }
                // },
              },
              ...Object.keys(header).map((item) => ({
                headerName: header[item],
                field: item,
                pinned: pinnedFields.includes(item) ? 'left' : null,
                filter: !(
                  item === 'rating' ||
                  item === 'statusRequest' ||
                  item === 'dateLoad' ||
                  (item === 'manager' &&
                    (props.user.roleRightName === 'salesman' ||
                      props.user.roleRightName === 'transactionSalesman'))
                ),
                floatingFilterComponent: numbersList.includes(
                  item.replace(/[0-9]/g, ''),
                )
                  ? 'numberFloatingFilter'
                  : textsList.includes(item.replace(/[0-9]/g, ''))
                  ? 'textFloatingFilter'
                  : datesList.includes(item.replace(/[0-9]/g, ''))
                  ? 'dateFloatingFilter'
                  : checkboxesList.includes(item.replace(/[0-9]/g, ''))
                  ? 'directoryFilter'
                  : directoriesList.find(
                      (directoryItem) => directoryItem.field === item,
                    ) ||
                    selectors.find(
                      (selectorItem) => selectorItem.field === item,
                    )
                  ? 'directoryFilter'
                  : null,
                floatingFilterComponentParams: selectors.find(
                  (selectorItem) => selectorItem.field === item,
                )
                  ? {
                      suppressFilterButton: true,
                      predestined: selectors.find(
                        (selectorItem) => selectorItem.field === item,
                      ).items,
                    }
                  : { suppressFilterButton: true },
                width: props.getWidthColumn(item),
                cellRenderer:
                  item === 'rating'
                    ? 'rating'
                    : item === 'stopSheet' ||
                      item === 'companyAmixgroup' ||
                      item === 'veterinaryCertificate' ||
                      item === 'gdnUnloading' ||
                      item === 'loadFastening' ||
                      item === 'agentDocuments' ||
                      item === 'forwarderLoading' ||
                      item === 'forwarderUnloading' ||
                      item === 'moversOnLoading' ||
                      item === 'railCarrierStatus' ||
                      item === 'prrOnArrived' ||
                      item === 'moversAtUnloading' ||
                      item === 'security' ||
                      item === 'agentAutoCallStatus' ||
                      item === 'act' ||
                      item === 'railwayInvoices' ||
                      item === 'loading' ||
                      item === 'loadingTN' ||
                      item === 'unloadingTN' ||
                      item === 'paymentState' ||
                      item === 'unloadingDriverTaskStatus'
                    ? 'iconYesNo'
                    : item === 'statusRequest'
                    ? 'statusRequest'
                    : null,
                editable: props.getEditableFields(item),
                cellEditor:
                  item === 'dateArrival' || item === 'dateIssue'
                    ? 'inputDate'
                    : item === 'timeIssue'
                    ? 'inputTime'
                    : null,
                cellStyle(params) {
                  return props.getStyleCell({
                    ...params,
                    countColumnsAmountMore,
                    countColumnsCarrierMore,
                    countColumnsExportAgentMore,
                  });
                },
                valueFormatter(params) {
                  if (Array.isArray(params.value)) {
                    let value = '';
                    if (params.value.length > 0) {
                      params.value.forEach((item, index) => {
                        value += item.name;
                        value += index === params.value.length - 1 ? '' : ', ';
                      });
                    }
                    return value;
                  }
                  switch (typeof params.value) {
                    case 'number':
                      return numberWithCommas(params.value);
                    case 'object':
                      if (params.value) {
                        if (
                          params.colDef.field === 'addressLoading' ||
                          params.colDef.field === 'addressUnloading'
                        ) {
                          return params.value.title ? params.value.title : '';
                        }
                        if (params.colDef.field === 'car') {
                          return params.value.mark && params.value.number
                            ? `${params.value.mark} ${params.value.number}`
                            : '';
                        }
                        if (params.colDef.field === 'trailer') {
                          return params.value.number ? params.value.number : '';
                        }
                        return params.value.name ? params.value.name : '';
                      }
                      return '';

                    case 'string':
                      if (
                        params.colDef.field.replace(/[0-9]/g, '') ===
                        'buh_amountMoreDate'
                      ) {
                        return params.value ? params.value.slice(0, 10) : '';
                      }
                      return params.value;
                    default:
                      return params.value;
                  }
                },
              })),
            ],
            () => {},
          );
        })
        .catch((error) => {
          console.warn('[INIT HEADER]', error);
          throw new Error(error);
        });
    },
    initData:
      (props) =>
      (showLoading = true) => {
        if (showLoading) {
          props.setLoadingExcel(true);
        }
        return props
          .getData(1)
          .then((res) => {
            if (res.success) {
              const rowsData = props.getAdditionalData(res.rows);
              props.setTableData([], () => props.setTableData(rowsData));
              props.setPage(2, () => {
                props.setIsDownload(false);
                if (res.rows.length < props.countRecords) {
                  props.setIsDownload(true);
                }
              });

              return PayerService.getItems(1, {
                records: 2000,
                trackingStatus: true,
              });
            }
          })
          .then((res) => {
            props.setPayers(res.data);
          })
          .catch((error) => {})
          .finally(() => {
            if (showLoading) {
              props.setLoadingExcel(false);
            }
          });
      },
    initDataWithLastIndexRow:
      (props) =>
      (showLoading = true) => {
        if (showLoading) {
          props.setLoadingExcel(true);
        }
        const countRecords = Math.ceil(props.lastIndexRowRequest / 100) * 100;
        const countPages = countRecords / 100 + 1;
        props.setCountRecords(countRecords);
        return props
          .getData(1)
          .then((res) => {
            props.setCountRecords(100);
            if (res.success) {
              const rowsData = props.getAdditionalData(res.rows);
              props.setTableData(rowsData);
              props.setPage(countPages, () => {
                props.setIsDownload(false);
                if (res.rows.length < props.countRecords) {
                  props.setIsDownload(true);
                }
              });

              return PayerService.getItems(1, {
                records: 2000,
                trackingStatus: true,
              });
            }
          })
          .then((res) => {
            props.setPayers(res.data);
          })
          .catch((error) => {})
          .finally(() => {
            if (showLoading) {
              props.setLoadingExcel(false);
            }
          });
      },
  }),
  withHandlers({
    addRow: (props) => (id, position) => {
      if (!props.tableParams.api.getRowNode(id)) {
        return RequestsService.getRequestByIdForTable(id)
          .then((data) => {
            if (Object.keys(data).length) {
              const dataRows = props.getAdditionalData([data]);
              const isNeed = checkRequestInFilters(
                props.allFilterModel,
                props.searchModel,
                props.selectedStatus,
                props.dateFiltersActive,
                props.dateFilters,
                dataRows[0],
              );

              if (isNeed) {
                const sorting =
                  props.sortModel && props.sortModel.length
                    ? props.sortModel
                    : sortModelDefault;
                const pos = getPositionRequest(
                  props.tableParams.api,
                  dataRows[0],
                  sorting,
                );
                if (
                  pos.index !== pos.lastIndex ||
                  props.countRecords > pos.lastIndex + 1
                ) {
                  props.setQuantityRequests({
                    rows: props.tableParams.api.getDisplayedRowCount() + 1,
                    totalRows: props.quantityRequests.totalRows + 1,
                  });
                  props.tableParams.api.updateRowData({
                    add: dataRows,
                    addIndex: position || pos.index,
                  });
                } else {
                  props.setQuantityRequests({
                    rows: props.tableParams.api.getDisplayedRowCount(),
                    totalRows: props.quantityRequests.totalRows + 1,
                  });
                }
              }
            }
          })
          .catch((error) =>
            console.warn('[API]-[GET REQUEST FROM API]', error),
          );
      }
    },
    replacementRow: (props) => (id) =>
      RequestsService.getRequestByIdForTable(id)
        .then((data) => {
          const node = props.tableParams.api.getRowNode(id);
          if (node) {
            const index = node.rowIndex;
            props.tableParams.api.updateRowData({
              remove: [node],
            });
            if (Object.keys(data).length) {
              const dataRows = props.getAdditionalData([data]);
              return props.tableParams.api.updateRowData({
                add: dataRows,
                addIndex: index,
              });
            }
          } else if (props.hasActiveFilters()) {
          } else if (
            Object.keys(data).length &&
            (props.user.login === data.manager.login ||
              props.user.login === data.managerByRegion.login)
          ) {
            const dataRows = props.getAdditionalData([data]);
            return props.tableParams.api.updateRowData({
              add: dataRows,
              addIndex: 0,
            });
          }
        })
        .catch((error) => {
          console.warn('[API]-[GET REQUEST FROM API]', error);
        }),
    deleteRows: (props) => (ids) => {
      const arraysDelete = [];
      ids.forEach((item) => {
        const node = props.tableParams.api.getRowNode(item);
        if (node) {
          arraysDelete.push(node);
          props.setQuantityRequests({
            rows: props.tableParams.api.getDisplayedRowCount() - 1,
            totalRows: props.quantityRequests.totalRows - 1,
          });
        }
      });
      if (arraysDelete.length) {
        return props.tableParams.api.updateRowData({
          remove: arraysDelete,
        });
      }
    },
  }),
  // Функции фильтрации
  withHandlers({
    applyFilters: (props) => () => {
      props.setFiltersActive(!props.filtersActive, () => {
        props.setFiltersActivePoint(!props.filtersActivePoint);
        props.initData();
      });
    },
    deleteFilter: (props) => (field) => {
      const state = props.filters;
      delete state[field];
      props.setFilters(state, () => {
        if (props.filtersActive) {
          props.initData();
        }
        if (Object.keys(props.filters).length === 0) {
          props.setFiltersActive(false);
          props.setFiltersActivePoint(false);
          const elementTable = document.querySelector('.ag-theme-balham');
          const elementSideBar = document.querySelector('.side-bar-primary');
          elementTable.style.height = '';
          elementSideBar.style.paddingTop = '';
        }
      });
    },
    changeFilters: (props) => (field, value) => {
      const state = props.filters;
      const currentTypeFields = props.typeFields;
      const currentShowAutocompleteValues = props.showAutocompleteValues;
      const currentAutocompleteValues = props.autocompleteValues;
      if (props.typeFields[field].type === 'input') {
        if (props.typeFields[field].inputType === 'number') {
          state[field] = Object.assign(state[field], value);
        } else {
          state[field] = value;
        }
      } else {
        state[field] = value;
      }
      if (currentTypeFields[field].type === 'autocomplete') {
        state[field] = value;
        props.typeFields[field].service
          .getItems(1, { search: value })
          .then((data) => {
            currentAutocompleteValues[field] = data.data;
            currentShowAutocompleteValues[field] = true;
            props.setAutocompleteValues(currentAutocompleteValues);
            props.setShowAutocompleteValues(currentShowAutocompleteValues);
            props.setFilters(state, () => {
              if (props.filtersActive) {
                props.initData();
              }
            });
          })
          .catch((error) => {
            console.warn('[API]-[GET ITEMS FILTERS FROM API]', error);
            currentShowAutocompleteValues[field] = true;
            props.setAutocompleteValues(currentAutocompleteValues);
            props.setShowAutocompleteValues(currentShowAutocompleteValues);
            props.setFilters(state, () => {
              if (props.filtersActive) {
                props.initData();
              }
            });
          });
      }
    },
    selectedFiltersValue: (props) => (field, value) => {
      const state = props.filters;
      const currentShowAutocompleteValues = props.showAutocompleteValues;
      state[field] = value;
      currentShowAutocompleteValues[field] = false;
      props.setShowAutocompleteValues(currentShowAutocompleteValues);
      props.setFilters(state, () => {
        props.initData();
      });
    },
    search: (props) => (value) => {
      if (props.timerState) {
        clearTimeout(props.timerState);
      }
      props.setSearchModel(value);
      props.setOtherFilters({ searchModel: value }, props.user.login);
      const timer = setTimeout(() => {
        props.setSearchValue(value, () => {
          if (value.length > 0 && value.length < 2) {
            return;
          }
          props.initData();
        });
      }, 500);
      props.setTimerState(timer);
    },
    selectStatus: (props) => (value) => {
      props.setSelectedStatus(value);
      props.setOtherFilters({ selectedStatus: value }, props.user.login);
      setTimeout(() => {
        props.initData();
      }, 0);
    },
    clickCalendar: (props) => () => {
      if (props.viewCalendar) {
        props.setViewCalendar(false);
        return;
      }
      if (props.dateFilters === undefined ? '' : props.dateFilters === '') {
        props.setViewCalendar(true);
      } else {
        props.setDateFiltersActive(!props.dateFiltersActive);
        props.setOtherFilters(
          {
            dateFiltersActive: !props.dateFiltersActive,
          },
          props.user.login,
        );
        setTimeout(() => {
          props.initData();
        }, 0);
      }
    },
    changeDateRange: (props) => (dates) => {
      if (dates) {
        props.setViewCalendar(false);
        const dateRange = [
          `${dates[0]._d.getFullYear()}-${
            dates[0]._d.getMonth() + 1
          }-${dates[0]._d.getDate()}`,
          `${dates[1]._d.getFullYear()}-${
            dates[1]._d.getMonth() + 1
          }-${dates[1]._d.getDate()}`,
        ];
        try {
          const dateFilters = JSON.stringify(dateRange);
          props.setDateFilters(dateFilters);
          props.setOtherFilters(
            {
              dateFilters,
            },
            props.user.login,
          );
          setTimeout(() => {
            if (props.dateFiltersActive) {
              props.initData();
            }
          }, 0);
        } catch (err) {
          console.warn(err);
        }
      } else {
        props.setDateFilters('');
        props.setOtherFilters(
          {
            dateFilters: '',
          },
          props.user.login,
        );
      }
    },
    deleteDateFilter: (props) => () => {
      props.setDateFiltersActive(false);
      props.setDateFilters('');
      props.setOtherFilters(
        {
          dateFilters: '',
          dateFiltersActive: false,
        },
        props.user.login,
      );
      setTimeout(() => {
        props.initData();
      }, 0);
    },
    cancel: (props) => () => {
      props.setSelectedRequests([], () => {
        props.setFilters({}, () => {
          props.setFiltersActive(false, () => {
            props.setFiltersActivePoint(false);
            props.tableParams.api.deselectAll();
            return props.initData();
          });
        });
      });
      const elementSideBar = document.querySelector('.side-bar-primary');
      elementSideBar.style.paddingTop = '';
    },
    changeStatus: (props) => (ids) => {
      const tableModel = props.tableParams.api.getModel();
      const requestsId = tableModel.rowsToDisplay.map((item) => {
        if (item.data.id) {
          return item.data.id;
        }
        return item.data._id;
      });

      ids.forEach((item) => {
        if (requestsId.includes(item._id)) {
          item.dateLoad = item.dateLoad
            ? moment(item.dateLoad).format('DD-MM-YYYY')
            : item.dateLoad;
          item.plannedIssueDate = item.plannedIssueDate
            ? moment(item.plannedIssueDate).format('DD-MM-YYYY')
            : item.plannedIssueDate;
          item.dateArrival = item.dateArrival
            ? moment(item.dateArrival).format('DD-MM-YYYY')
            : item.dateArrival;
          item.dateIssue = item.dateIssue
            ? moment(item.dateIssue).format('DD-MM-YYYY')
            : item.dateIssue;
          item.customerAccountDate = item.customerAccountDate
            ? moment(item.customerAccountDate).format('DD-MM-YYYY')
            : item.customerAccountDate;
          item.plannedDispatchDate = item.plannedDispatchDate
            ? moment(item.plannedDispatchDate).format('DD-MM-YYYY')
            : item.plannedDispatchDate;
          item.actualDateDispatch = item.actualDateDispatch
            ? moment(item.actualDateDispatch).format('DD-MM-YYYY')
            : item.actualDateDispatch;

          const index = requestsId.indexOf(item._id);
          const node = props.tableParams.api.getDisplayedRowAtIndex(index);
          props.tableParams.api.updateRowData({
            remove: [node],
          });
          const dataRows = props.getAdditionalData([item]);
          props.tableParams.api.updateRowData({
            add: dataRows,
            addIndex: index,
          });
        }
      });
    },
  }),
  // Функции с данными
  withHandlers({
    getExcel:
      (props) =>
      (debet = false) => {
        props.setLoadingExcel(true);
        const sorting =
          props.sortModel && props.sortModel.length
            ? props.sortModel
            : sortModelDefault;
        const filters = props.allFilterModel;
        let query = {};
        // check sorting params
        const querySort = getQuerySort(sorting);
        // check filters values
        const queryFilter = getQueryFilters(filters);
        query = { ...queryFilter, ...querySort };
        // check type report
        if (debet) {
          query.typereport = 'debet';
        }
        // check selected requests
        if (props.selectedRequests.length) {
          query.id = JSON.stringify(props.selectedRequests);
        }
        // check filter of dataLoad
        if (props.dateFiltersActive) {
          query.daterange = props.dateFilters;
        }
        // check filter of status
        if (props.selectedStatus) {
          query.state = JSON.stringify([props.selectedStatus]);
        }
        // check search value
        if (props.searchModel) {
          query.search = props.searchModel;
        }
        // get excel from API
        return RequestsService.getRequestsExcel(query)
          .then((data) => {
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.xlsx');
            document.body.appendChild(link);
            link.click();
            props.setLoadingExcel(false);
          })
          .catch((error) => {
            console.warn('[API]-[GET EXCEL REQUESTS FROM API]', error);
            props.setLoadingExcel(false);
          });
      },
    addRequest: (props) => () => {
      props.history.push('/dashboard/requests/create');
    },
    openRequest: (props) => (id) =>
      props.history.push(`/dashboard/requests/view/${id}`),
    deleteRequests:
      (props) =>
      (id = null) => {
        const deleteRequests = [];
        const listIds = [];
        if (id) {
          deleteRequests.push(RequestsService.deleteRequest(id));
          listIds.push(id);
        } else {
          props.selectedRequests.forEach((element) => {
            deleteRequests.push(RequestsService.deleteRequest(element));
            listIds.push(element);
          });
        }
        return Promise.all(deleteRequests)
          .then(() => {
            props.setSelectedRequests([]);
            props.tableParams.api.deselectAll();
            return props.deleteRows(listIds);
          })
          .catch((error) => {
            console.warn('[API]-[DELETE REQUESTS FROM API]', error);
            props.setSelectedRequests([]);
            props.tableParams.api.deselectAll();
            return props.initData();
          });
      },
    copyRequest: (props) => (id, position) =>
      RequestsService.copyRequest(id)
        .then((data) => props.addRow(data.result._id, position))
        .catch((error) =>
          console.warn('[API]-[COPY REQUESTS FROM API]', error),
        ),
    editRequest: (props) => (id, field, value) =>
      RequestsService.updateRequest(id, { [field]: value })
        .then(() => props.replacementRow(id))
        .catch((error) => {
          console.warn('[API]-[UPDATA REQUESTS FROM API]', error);
        }),
    amountSuccessfull: (props) => (data) => {
      if (data.selectedRequests.length) {
        const tableModel = props.tableParams.api.getModel();
        const requestsData = tableModel.rowsToDisplay.map((item) => item.data);
        const requests = requestsData
          .filter((item) => data.selectedRequests.includes(item.id))
          .map((item) => ({ id: item.id, value: item.paymentState }));
        requests.forEach((item) => (item.value = !item.value));
        const requestsPromises = requests.map((item) =>
          RequestsService.updateRequest(item.id, {
            paymentState: item.value,
            updatePaymentState: true,
          }),
        );
        Promise.all(requestsPromises)
          .then(() => {
            const rowsPromises = requests.map((item) =>
              props.replacementRow(item.id),
            );
            Promise.all(rowsPromises).catch((error) =>
              props.functionCheckTime(
                'Error',
                error.message ? error.message : 'Произошла ошибка обновления',
              ),
            );
          })
          .catch((error) => props.functionCheckTime('Error', error.message))
          .finally(() => props.tableParams.api.deselectAll());
      } else {
        if (data.value) {
          data.value = false;
        } else {
          data.value = true;
        }
        RequestsService.updateRequest(data.id, {
          paymentState: data.value,
          updatePaymentState: true,
        })
          .then(() => props.replacementRow(data.id))
          .catch((error) => props.functionCheckTime('Error', error.message));
      }
    },
    postRequest: (props) => () => {
      props.setIsEmailsSending(true);
      return RequestsService.postRequest(
        props.selectedRequests,
        props.stringEmails.split(','),
      )
        .catch((error) => props.functionCheckTime('Error', error.message))
        .finally(() => props.closeEmailsModal());
    },
    changeStatusesRequests: (props) => (row, column) => {
      const tableModel = props.tableParams.api.getModel();
      let preparedData = [];
      const cellRanges =
        props.tableParams &&
        props.tableParams.api &&
        props.tableParams.api.getCellRanges() &&
        props.tableParams.api.getCellRanges().length
          ? props.tableParams.api.getCellRanges()
          : [
              {
                columns: [{ colId: column }],
                startRow: { rowIndex: row },
                endRow: { rowIndex: row },
              },
            ];

      cellRanges.forEach((elem) => {
        elem.columns.forEach((elemValue) => {
          const field = elemValue.colId;
          let fieldUpdate = '';
          let isValid = false;
          let numberField = null;

          switch (field) {
            case 'customerAccountNumber':
            case 'carrierAccount':
            case 'accountsAutoServices':
            case 'containerAgentAccount':
            case 'exportAgentAccount':
            case 'moversAccountOnLoading':
            case 'moversAccountOnUnloading':
            case 'accountSecuringCargo':
            case 'billCargoWeatherization':
            case 'accountStorageAtDestinationStation':
            case 'accountSimpleToUnload':
            case 'accountSimpleToLoad':
            case 'accountOverload':
            case 'accountAboveTheNorm':
            case 'accountAdditionalServices':
              isValid = true;
              fieldUpdate = `${field}Status`;
              break;
          }
          if (field.includes('buh_amountMoreAccountNumber')) {
            isValid = true;
            fieldUpdate = 'buh_amountMore';
            numberField = +field.replace('buh_amountMoreAccountNumber', '');
          }
          if (field.includes('buh_carrierMoreAccountNumber')) {
            isValid = true;
            fieldUpdate = 'buh_carrierMore';
            numberField = +field.replace('buh_carrierMoreAccountNumber', '');
          }
          if (field.includes('buh_exportAgentMoreAccountNumber')) {
            isValid = true;
            fieldUpdate = 'buh_exportAgentMore';
            numberField = +field.replace(
              'buh_exportAgentMoreAccountNumber',
              '',
            );
          }

          if (isValid) {
            const startRow = elem.startRow.rowIndex;
            const endRow = elem.endRow.rowIndex;
            let startIndex = startRow;
            let endIndex = endRow;
            if (startRow > endRow) {
              startIndex = endRow;
              endIndex = startRow;
            }
            const indexes = [];

            for (let index = startIndex; index <= endIndex; index++) {
              indexes.push(index);
            }
            const requests = tableModel.rowsToDisplay.filter((item) =>
              indexes.includes(item.rowIndex),
            );
            const changedRequests = requests.map((item) => ({
              id: item.id,
              field: fieldUpdate,
              value: item.data[fieldUpdate],
            }));
            if (numberField) {
              changedRequests.forEach((item) => {
                if (item.value.length && item.value[numberField - 1]) {
                  item.value[numberField - 1].status =
                    !item.value[numberField - 1].status;
                }
              });
            } else {
              changedRequests.forEach((item) => (item.value = !item.value));
            }
            preparedData = [...preparedData, ...changedRequests];
          }
        });
      });

      const payload = [];
      const idList = new Set();
      preparedData.forEach((item) => idList.add(item.id));
      idList.forEach((item) => {
        const preparedDataEqualId = preparedData.filter(
          (elem) => elem.id === item,
        );
        const updateFields = {};
        preparedDataEqualId.forEach(
          (elem) => (updateFields[elem.field] = elem.value),
        );
        payload.push({ id: item, updateFields });
      });

      const requestsPromises = payload.map((item) =>
        RequestsService.updateRequest(item.id, { ...item.updateFields }),
      );
      Promise.all(requestsPromises)
        .then(() => {
          const rowsPromises = payload.map((item) =>
            props.replacementRow(item.id),
          );
          Promise.all(rowsPromises).catch((error) =>
            props.functionCheckTime(
              'Error',
              error.message ? error.message : 'Произошла ошибка обновления',
            ),
          );
        })
        .catch((error) => props.functionCheckTime('Error', error.message));
    },
    getDriverTask: (props) => (data) => {
      props.setLoadingExcel(true);
      let listId = [data.id];
      if (data.selectedRequests.length) listId = [...data.selectedRequests];
      return RequestsService.getDriverTask(JSON.stringify(listId))
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'driverTask.txt');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) =>
          props.functionCheckTime(
            'Error',
            error.message ? error.message : 'Произошла ошибка выгрузки',
          ),
        )
        .finally(() => {
          props.setLoadingExcel(false);
          props.tableParams.api.deselectAll();
        });
    },
    postLoadingData: (props) => () => {
      let query = {};
      const filters = props.allFilterModel;
      const queryFilter = getQueryFilters(filters);
      query = { ...queryFilter };
      if (props.searchModel) {
        query.search = props.searchModel;
      }
      if (props.selectedStatus) {
        query.state = JSON.stringify([props.selectedStatus]);
      }
      if (props.dateFiltersActive) {
        query.daterange =
          props.dateFilters === undefined ? '' : props.dateFilters;
      }
      const body = { ids: props.selectedRequests };
      props.setProcessLoading(true);
      props.functionCheckTime(
        'Success',
        'Отправка данных на погрузку запущена',
      );
      return RequestsService.postLoadingData(body, query)
        .then((data) =>
          props.functionCheckTime('Success', 'Отправка данных произведена'),
        )
        .catch((error) => props.functionCheckTime('Error', error.message))
        .finally(() => {
          props.setProcessLoading(false);
          props.tableParams.api.deselectAll();
          props.tableParams.api.clearRangeSelection();
        });
    },
    updateStatusRequests: (props) => () => {
      props.history.push('/dashboard/requests/updateStatusRequests');
    },
    unloadAllRequests: (props) => () => {
      const records = 1000;
      let page = 1;
      let requests = [];

      props.setShowLoaderLoading(true);
      props.setCountRecords(records, async () => {
        try {
          const resultFirst = await props.getData(page, false);

          const numberOfRequests = Math.ceil(resultFirst.lastRow / records) - 1;
          const requestsPromises = [];
          while (numberOfRequests >= page) {
            requestsPromises.push(props.getData(++page, false));
          }

          const resultSecond = await Promise.all(requestsPromises);

          requests = [...resultFirst.rows];

          resultSecond.forEach((item) => {
            if (item.success) {
              requests = [...requests, ...item.rows];
            }
          });

          const rowsData = props.getAdditionalData(requests);

          props.setTableData([], () => {
            props.setIsDownload(true);
            props.setTableData(rowsData);
            props.setQuantityRequests({
              rows: resultFirst.lastRow,
              totalRows: resultFirst.lastRow,
            });
          });
        } catch (error) {
          props.functionCheckTime('Error', error.message);
        }

        props.setShowLoaderLoading(false);
        props.setCountRecords(100);
      });
    },
    sendTrackingData: (props) => async () => {
      try {
        props.setShowLoaderLoading(true);
        props.setProcessTracking(true);
        props.functionCheckTime('Success', 'Отправка данных успешно запущена');

        const sorting =
          props.sortModel && props.sortModel.length
            ? props.sortModel
            : sortModelDefault;
        const filters = props.allFilterModel;
        let query = {};
        // check sorting params
        const querySort = getQuerySort(sorting);
        // check filters values
        const queryFilter = getQueryFilters(filters);
        query = { ...queryFilter, ...querySort };

        // check selected requests
        if (props.selectedRequests.length) {
          query.listId = JSON.stringify(props.selectedRequests);
        }
        // check filter of dataLoad
        if (props.dateFiltersActive) {
          query.daterange = props.dateFilters;
        }
        // check filter of status
        if (props.selectedStatus) {
          query.state = JSON.stringify([props.selectedStatus]);
        }
        // check search value
        if (props.searchModel) {
          query.search = props.searchModel;
        }

        const result = await RequestsService.getTrackingDataExcel(query);

        if (
          result.data.sentToPayers.length &&
          !result.data.notSentToPayers.length
        ) {
          props.functionCheckTime(
            'Success',
            `Отправка успешно произведена. Письма отправлены: ${result.data.sentToPayers}`,
          );
        }
        if (
          result.data.sentToPayers.length &&
          result.data.notSentToPayers.length
        ) {
          props.functionCheckTime(
            'Success',
            `Отправка успешно произведена. Письма отправлены: ${result.data.sentToPayers}`,
          );
          setTimeout(
            () =>
              props.functionCheckTime(
                'Error',
                `Отправка не произведена. У плательщиков не установлен флаг отправки отслеживания: ${result.data.notSentToPayers}`,
              ),
            2000,
          );
        }
        if (
          !result.data.sentToPayers.length &&
          result.data.notSentToPayers.length
        ) {
          props.functionCheckTime(
            'Error',
            `Отправка не произведена. У плательщиков не установлен флаг отправки отслеживания: ${result.data.notSentToPayers}`,
          );
        }
        if (
          !result.data.sentToPayers.length &&
          !result.data.notSentToPayers.length
        ) {
          props.functionCheckTime(
            'Error',
            'Не найдено заявок для отправки писем',
          );
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      } finally {
        props.setShowLoaderLoading(false);
        props.setProcessTracking(false);
      }
    },
  }),
  // Функции попаб меню
  withHandlers({
    removeHandler: (props) => (data) => {
      props.setNumberD(data.number, () =>
        props.setIdDelete(data.id, () => props.setDelPop(true)),
      );
    },
    removeHandlerClear: (props) => () => {
      props.setNumberD('', () =>
        props.setIdDelete('', () => props.setDelPop(false)),
      );
    },
    copyHandler: (props) => (data) => {
      props.setNumberCopy(data.number, () =>
        props.setIdCopy(data.id, () =>
          props.setPositionCopy(data.position, () => props.setCopyH(true)),
        ),
      );
    },
    copyHandlerClear: (props) => () => {
      props.setNumberCopy('', () =>
        props.setIdCopy('', () =>
          props.setPositionCopy(0, () => props.setCopyH(false)),
        ),
      );
    },
  }),
  // События таблицы AG-GRID
  withHandlers({
    onGridReady: (props) => (params) => {
      props.setTableParams(params);
      if (props.lastIndexRowRequest) {
        params.api.ensureIndexVisible(props.lastIndexRowRequest, 'middle');
      }
      params.api.setSortModel(
        props.sortModel && props.sortModel.length
          ? props.sortModel
          : sortModelDefault,
      );

      setTimeout(() => {
        headerNode = document.getElementsByClassName('ag-header-container')[0];
        scrollableNode = document.getElementsByClassName(
          'ag-center-cols-viewport',
        )[0];

        if (headerNode) {
          headerNode.style.transform = null;
        }

        if (scrollableNode) {
          scrollableNode.addEventListener('scroll', () => {
            clearTimeout(timerScrollId);
            timerScrollId = setTimeout(() => {
              if (!headerNode) {
                headerNode = document.getElementsByClassName(
                  'ag-header-container',
                )[0];
              }

              if (headerNode.style.transform) {
                headerNode.style.left = headerNode.style.transform
                  .split('(')[1]
                  .split(')')[0];
                headerNode.style.transform = null;
              }
            }, 100);
          });
        }
      }, 1000);
    },
    onCellClicked: (props) => async (params) => {
      if (params.colDef.field === 'number') {
        if (props.access.directory.requests.update) {
          props.setLastIndexRowRequest(params.node.rowIndex);
          props.openRequest(params.data.id);
        }
      }
    },
    onCellDoubleClicked: (props) => (params) => {
      props.setEditableRequest(params.data);
      switch (params.colDef.field) {
        case 'stopSheet':
        case 'companyAmixgroup':
        case 'railCarrierStatus':
        case 'prrOnArrived':
        case 'agentAutoCallStatus':
        case 'security':
        case 'forwarderLoading':
        case 'forwarderUnloading':
        case 'veterinaryCertificate':
        case 'gdnUnloading':
        case 'loadFastening':
        case 'railwayInvoices':
        case 'loading':
        case 'loadingTN':
        case 'unloadingTN':
        case 'moversOnLoading':
        case 'moversAtUnloading':
        case 'act':
        case 'agentDocuments':
        case 'unloadingDriverTaskStatus':
          props.editRequest(
            params.node.data.id,
            params.colDef.field,
            !params.value,
          );
          break;
        case 'customerAccountNumber':
        case 'accountsAutoServices':
        case 'accountSecuringCargo':
        case 'accountStorageAtDestinationStation':
        case 'accountSimpleToUnload':
        case 'accountSimpleToLoad':
        case 'accountOverload':
        case 'accountAboveTheNorm':
        case 'accountAdditionalServices':
          props.editRequest(
            params.node.data.id,
            `${params.colDef.field}Status`,
            !params.node.data[`${params.colDef.field}Status`],
          );
          break;
        case 'carrierAccount':
        case 'containerAgentAccount':
        case 'exportAgentAccount':
        case 'moversAccountOnLoading':
        case 'moversAccountOnUnloading':
        case 'billCargoWeatherization':
          if (props.user.roleRightName !== ROLES_NAME.transaction) {
            props.editRequest(
              params.node.data.id,
              `${params.colDef.field}Status`,
              !params.node.data[`${params.colDef.field}Status`],
            );
          }
          break;
        case 'dateLoad':
          if (params.value) {
            const dayString = params.value.split('-').reverse().join('-');
            const dayMoment = moment(dayString);
            const dateFilters = JSON.stringify([dayString, dayString]);
            props.setRangeDate([dayMoment, dayMoment]);
            props.setDateFilters(dateFilters);
            props.setDateFiltersActive(!props.dateFiltersActive);
            props.setOtherFilters(
              {
                dateFilters,
                rangeDate: [dayMoment, dayMoment],
                dateFiltersActive: !props.dateFiltersActive,
              },
              props.user.login,
            );
            setTimeout(() => {
              props.initData();
            }, 0);
          }
          break;
        case 'dateIssue':
          if (
            props.user.roleRightName !== ROLES_NAME.clients &&
            (props.access.directory.requests.update ||
              props.user.roleRightName === ROLES_NAME.provider)
          ) {
            if (params.value) {
              const dayString = params.value.split('-').reverse().join('-');
              props.setModalDateValue(dayString);
              props.setModalDateValueMoment(dayString);
            } else {
              props.setModalDateValue('');
              props.setModalDateValueMoment('');
            }
            props.setIsShowModalDate(true);
            props.setIdRequestEdit(params.data.id);
            props.setFieldRequestEdit(params.colDef.field);
            if (params.data.state !== 'Выдан клиенту') {
              props.setStateClaim(params.data.state);
            }
          }
          break;
        case 'departureVesselDate':
          if (
            props.user.roleRightName !== ROLES_NAME.clients &&
            (props.access.directory.requests.update ||
              props.user.roleRightName === ROLES_NAME.provider)
          ) {
            if (params.value) {
              const dayString = params.value.split('-').reverse().join('-');
              props.setModalDateValue(dayString);
              props.setModalDateValueMoment(dayString);
            } else {
              props.setModalDateValue('');
              props.setModalDateValueMoment('');
            }
            props.setIsShowModalDate(true);
            props.setIdRequestEdit(params.data.id);
            props.setFieldRequestEdit(params.colDef.field);
          }
          break;
        case 'arrivalVesselDate':
          if (
            props.user.roleRightName !== ROLES_NAME.clients &&
            (props.access.directory.requests.update ||
              props.user.roleRightName === ROLES_NAME.provider)
          ) {
            if (params.value) {
              const dayString = params.value.split('-').reverse().join('-');
              props.setModalDateValue(dayString);
              props.setModalDateValueMoment(dayString);
            } else {
              props.setModalDateValue('');
              props.setModalDateValueMoment('');
            }
            props.setIsShowModalDate(true);
            props.setIdRequestEdit(params.data.id);
            props.setFieldRequestEdit(params.colDef.field);
          }
          break;
        case 'notificationDate':
          if (
            props.user.roleRightName !== ROLES_NAME.clients &&
            (props.access.directory.requests.update ||
              props.user.roleRightName === ROLES_NAME.provider)
          ) {
            if (params.value) {
              const dayString = params.value.split('-').reverse().join('-');
              props.setModalDateValue(dayString);
              props.setModalDateValueMoment(dayString);
            } else {
              props.setModalDateValue('');
              props.setModalDateValueMoment('');
            }
            props.setIsShowModalDate(true);
            props.setIdRequestEdit(params.data.id);
            props.setFieldRequestEdit(params.colDef.field);
          }
          break;
        case 'dateArrival':
          if (
            props.user.roleRightName !== ROLES_NAME.clients &&
            (props.access.directory.requests.update ||
              props.user.roleRightName === ROLES_NAME.provider)
          ) {
            if (params.value) {
              const dayString = params.value.split('-').reverse().join('-');
              props.setModalDateValue(dayString);
              props.setModalDateValueMoment(dayString);
            } else {
              props.setModalDateValue('');
              props.setModalDateValueMoment('');
            }
            props.setIsShowModalDate(true);
            props.setIdRequestEdit(params.data.id);
            props.setFieldRequestEdit(params.colDef.field);
            if (params.data.state !== 'Прибыл') {
              props.setStateClaim(params.data.state);
            }
          }
          break;
        case 'actualDateDispatch':
          if (
            props.user.roleRightName !== ROLES_NAME.clients &&
            (props.access.directory.requests.update ||
              props.user.roleRightName === ROLES_NAME.provider)
          ) {
            if (params.value) {
              const dayString = params.value.split('-').reverse().join('-');
              props.setModalDateValue(dayString);
              props.setModalDateValueMoment(dayString);
            } else {
              props.setModalDateValue('');
              props.setModalDateValueMoment('');
            }
            props.setIsShowModalDate(true);
            props.setIdRequestEdit(params.data.id);
            props.setFieldRequestEdit(params.colDef.field);
          }
          break;
        case 'timeIssue':
          if (
            props.user.roleRightName !== ROLES_NAME.clients &&
            (props.access.directory.requests.update ||
              props.user.roleRightName === ROLES_NAME.provider)
          ) {
            props.setIsShowModalTime(true);
            props.setModalTimeValue(params.value);
            props.setIdRequestEdit(params.data.id);
            props.setFieldRequestEdit(params.colDef.field);
          }
          break;
      }
      if (params.colDef.field.includes('buh_amountMoreAccountNumber')) {
        const numberField = +params.colDef.field.replace(
          'buh_amountMoreAccountNumber',
          '',
        );
        const buh_amountMore = params.node.data.buh_amountMore.map((item) => ({
          ...item,
        }));
        if (buh_amountMore[numberField - 1]) {
          buh_amountMore[numberField - 1].status =
            !buh_amountMore[numberField - 1].status;
          props.editRequest(
            params.node.data.id,
            'buh_amountMore',
            buh_amountMore,
          );
        }
      }
      if (params.colDef.field.includes('buh_carrierMoreAccountNumber')) {
        const numberField = +params.colDef.field.replace(
          'buh_carrierMoreAccountNumber',
          '',
        );
        const buh_carrierMore = params.node.data.buh_carrierMore.map(
          (item) => ({ ...item }),
        );
        if (buh_carrierMore[numberField - 1]) {
          buh_carrierMore[numberField - 1].status =
            !buh_carrierMore[numberField - 1].status;
          props.editRequest(
            params.node.data.id,
            'buh_carrierMore',
            buh_carrierMore,
          );
        }
      }
      if (params.colDef.field.includes('buh_exportAgentMoreAccountNumber')) {
        const numberField = +params.colDef.field.replace(
          'buh_exportAgentMoreAccountNumber',
          '',
        );
        const buh_exportAgentMore = params.node.data.buh_exportAgentMore.map(
          (item) => ({ ...item }),
        );
        if (buh_exportAgentMore[numberField - 1]) {
          buh_exportAgentMore[numberField - 1].status =
            !buh_exportAgentMore[numberField - 1].status;
          props.editRequest(
            params.node.data.id,
            'buh_exportAgentMore',
            buh_exportAgentMore,
          );
        }
      }
    },
    getContextMenuItems: (props) => (params) => {
      const result = [
        props.access.directory.requests.copy && {
          name: 'Копировать заявку',
          action: () => {
            if (params.node) {
              return props.copyHandler({
                id: params.node.data.id,
                number: params.node.data.number,
                position: params.node.rowIndex + 1,
              });
            }
          },
        },
        props.access.directory.requests.delete && {
          name: 'Удалить заявку',
          action: () => {
            if (params.node) {
              return props.removeHandler({
                id: params.node.data.id,
                number: params.node.data.number,
              });
            }
          },
        },
        props.access.directory.requests.driverTask && {
          name: 'Задание для водителя',
          action: () => {
            if (params.node) {
              return props.getDriverTask({
                id: params.node.data.id,
                selectedRequests: props.selectedRequests,
              });
            }
          },
        },
        props.access.directory.requests.update &&
          props.access.requests.buh_amount &&
          props.access.requests.paymentState && {
            name: 'Сменить статусы сумм',
            action: () => {
              if (params.node) {
                return props.amountSuccessfull({
                  id: params.node.data.id,
                  value: params.node.data.paymentState,
                  selectedRequests: props.selectedRequests,
                });
              }
            },
          },
      ];

      if (props.access.directory.requests.update) {
        result.push({
          name: 'Сменить статусы счетов',
          action: () => {
            props.changeStatusesRequests(
              params.node.rowIndex,
              params.column.colId,
            );
          },
        });
      }

      return result;
    },
    onCellValueChanged: (props) => (params) => {
      if (!(params.oldValue === null && !params.newValue)) {
        if (params.oldValue !== params.newValue) {
          return props.editRequest(
            params.node.data.id,
            params.colDef.field,
            params.newValue,
          );
        }
      }
    },
    onBodyScroll: (props) => (params) => {
      if (
        params.api.rowRenderer.lastRenderedRow >=
          (props.page - 1) * props.countRecords - 20 &&
        !props.isDownload
      ) {
        props.setShowLoaderLoading(true);
        props.setIsDownload(true);
        props
          .getData(props.page)
          .then((res) => {
            if (res.success) {
              const rowsData = props.getAdditionalData(res.rows);
              props.tableParams.api.updateRowData({ add: rowsData });
              props.setPage(props.page + 1, () => {
                props.setIsDownload(false);
                if (
                  props.tableParams.api.getModel().rowsToDisplay.length ===
                    res.lastRow ||
                  res.rows.length < props.countRecords
                ) {
                  props.setIsDownload(true);
                }
              });
            } else {
              props.setIsDownload(false);
            }
          })
          .finally(() => props.setShowLoaderLoading(false));
      }
    },
    onDragStopped: (props) => async (params) => {
      const movedColumn = params.columnApi.columnController.gridColumns
        .filter((item) => item.left !== item.oldLeft)
        .map((item) => item.colId);
      const isAdditionalColumns = movedColumn
        .map((item) => {
          if (
            item.includes('buh_amountMore') ||
            item.includes('buh_carrierMore') ||
            item.includes('buh_exportAgentMore')
          ) {
            return 1;
          }
          return 0;
        })
        .reduce((accum, value) => accum + +value, 0);
      if (isAdditionalColumns) return;

      const gridColumns = params.columnApi.columnController.gridColumns.map(
        (item) => item.colId,
      );
      const orderColumns = gridColumns.filter(
        (item) =>
          item !== 'number' &&
          !item.includes('buh_amountMore') &&
          !item.includes('buh_carrierMore') &&
          !item.includes('buh_exportAgentMore'),
      );
      const isBuh_amountMore = gridColumns.includes('buh_amountMoreAmount1');
      if (isBuh_amountMore) orderColumns.push('buh_amountMore');
      const isBuh_carrierMore = gridColumns.includes('buh_carrierMoreAmount1');
      if (isBuh_carrierMore) orderColumns.push('buh_carrierMore');
      const isBuh_exportAgentMore = gridColumns.includes(
        'buh_exportAgentMoreAmount1',
      );
      if (isBuh_exportAgentMore) orderColumns.push('buh_exportAgentMore');
      const orderColumnsString = orderColumns.join(',');

      const pinnedColumns = params.columnApi.columnController.gridColumns
        .filter(
          (item) =>
            item.pinned &&
            item.colId !== 'number' &&
            !item.colId.includes('buh_amountMore') &&
            !item.colId.includes('buh_carrierMore') &&
            !item.colId.includes('buh_exportAgentMore'),
        )
        .map((item) => item.colId);
      const pinnedColumnsString = pinnedColumns.join(',');

      if (
        orderColumnsString === props.orderColumnsString &&
        pinnedColumnsString === props.pinnedColumnsString
      ) {
        return;
      }
      props.setOrderColumnsString(orderColumnsString);
      props.setPinnedColumnsString(pinnedColumnsString);

      const body = {
        fieldsRequests: orderColumns,
        pinnedFields: pinnedColumns,
      };
      try {
        const result = await UserService.updateUser(props.user._id, body);
        const resultUser = await UserService.getUserById(props.user._id);

        let resultTemplate = { status: 200 };
        if (resultUser.templateId) {
          resultTemplate = await UserTemplateService.updateTemplate(
            resultUser.templateId,
            body,
          );
        }
        if (
          result.status === 200 &&
          resultTemplate &&
          resultTemplate.status === 200
        ) {
          props.functionCheckTime('Success', 'Сохранено');
        }
      } catch (error) {
        props.functionCheckTime('Error', error.message);
      }
    },
    onSelectionChanged: (props) => (params) => {
      if (props.tableParams) {
        const selectedRequests = props.tableParams.api.getSelectedRows();
        props.setSelectedRequests(selectedRequests.map((item) => item.id));
      }
    },
    onSortChanged: (props) => (params) => {
      if (props.initSort) {
        props.setInitSort(false);
        return;
      }

      const sortModel = props.tableParams
        ? props.tableParams.api.getSortModel()
        : sortModelDefault;
      props.setSortModel(sortModel);
      props.setSortModelByLogin(sortModel, props.user.login);
      setTimeout(() => props.initData(), 0);
    },
    onCellMouseOver: (props) => (params) => {
      clearTimeout(timerOverId);
      timerOverId = setTimeout(() => {
        let { value } = params;
        if (params.column.colId === 'rating') {
          value = params.value.toFixed(2);
        }

        // status request
        if (params.column.colId === 'statusRequest') {
          const columnStatusRequestClassList = [];
          params.event.path &&
            params.event.path.forEach((item) => {
              item.classList &&
                item.classList.forEach((elem) => {
                  columnStatusRequestClassList.push(elem);
                });
            });

          if (columnStatusRequestClassList.includes('status-state')) {
            value = 'Состояние';
          }
          if (columnStatusRequestClassList.includes('status-loading-data')) {
            value = 'Отправка данных на погрузку';
          }
          if (columnStatusRequestClassList.includes('status-tracking-data')) {
            value = 'Слежение за контейнерами';
          }
        }

        props.setShowTooltip(true);
        props.setValueTooltip(value);
        props.setCoordsTooltip({
          x: params.event.clientX,
          y: params.event.clientY,
        });
      }, 250);
    },
    onCellMouseOut: (props) => (params) => {
      clearTimeout(timerOutId);
      timerOutId = setTimeout(() => {
        if (props.showTooltip) {
          props.setShowTooltip(false);
          clearTimeout(timerOverId);
        }
      }, 8000);
    },
    onCellContextMenu: (props) => (params) => {
      const cellRanges = props.tableParams.api.getCellRanges();
      if (
        cellRanges.length === 1 &&
        cellRanges[0].columns.length === 1 &&
        cellRanges[0].startRow.rowIndex === cellRanges[0].endRow.rowIndex
      ) {
        props.tableParams.api.clearRangeSelection();
      }
    },
  }),
  // Функции рейтинга
  withHandlers({
    setRating: (props) => (request, rating) => {
      if (rating < 5) {
        props.setRequestToRating({
          id: request,
          rating,
        });
        props.changeShowRatingModal(true);
      } else {
        RequestsService.updateRequest(request, { rating }).catch((error) => {
          props.checkStatusPopup({
            statusCheck: 'Error',
            messageBox: error.message,
            statusTime: true,
          });
          setTimeout(() => {
            props.checkStatusPopup({
              statusCheck: 'Error',
              messageBox: error.message,
              statusTime: false,
            });
          }, 2000);
        });
      }
    },
    sendReview: (props) => () => {
      if (props.requestToRating) {
        if (!props.ratingReview) {
          props.checkStatusPopup({
            statusCheck: 'Error',
            messageBox: 'Вы не указали причину своей оценки',
            statusTime: true,
          });
          setTimeout(() => {
            props.checkStatusPopup({
              statusCheck: 'Error',
              messageBox: 'Вы не указали причину своей оценки',
              statusTime: false,
            });
          }, 2000);
          return;
        }
        props.setIsReviewSending(true);
        RequestsService.updateRequest(props.requestToRating.id, {
          rating: props.requestToRating.rating,
          review: props.ratingReview,
        })
          .then(() => {
            props.closeReviewModal();
            props.checkStatusPopup({
              statusCheck: 'Success',
              messageBox: 'Сообщение отправлено руководству Амикс Групп',
              statusTime: true,
            });
            setTimeout(() => {
              props.checkStatusPopup({
                statusCheck: 'Success',
                messageBox: 'Сообщение отправлено руководству Амикс Групп',
                statusTime: false,
              });
            }, 2000);
          })
          .catch((error) => {
            props.closeReviewModal();
            props.checkStatusPopup({
              statusCheck: 'Error',
              messageBox: error.message,
              statusTime: true,
            });
            setTimeout(() => {
              props.checkStatusPopup({
                statusCheck: 'Error',
                messageBox: error.message,
                statusTime: false,
              });
            }, 2000);
          });
      }
    },
    readOnlyRating: (props) => () =>
      props.user.roleRightName !== ROLES_NAME.clients,
  }),
  withHandlers({
    addFullScreen: (props) => () => {
      const element = document.querySelector('.main-dashboard');
      const scrollArea = document.querySelector('.scroll-area');
      const elementForm = document.querySelector('.main-dashboard-form');
      if (element && elementForm) {
        element.classList.add('hidden');
        elementForm.classList.add('hidden');
      }
      if (scrollArea) {
        scrollArea.style.height = `${window.innerHeight - 110}px`;
      }
    },
    removeFullScreen: (props) => () => {
      const element = document.querySelector('.main-dashboard');
      const scrollArea = document.querySelector('.scroll-area');
      const elementForm = document.querySelector('.main-dashboard-form');
      if (element && elementForm && element.classList.contains('hidden')) {
        if (element && elementForm) {
          element.classList.remove('hidden');
          elementForm.classList.remove('hidden');
        }
        if (scrollArea) {
          scrollArea.style.height = `${window.innerHeight - 225}px`;
        }
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      socketConnection();
      this.props.setActiveSidebarItem('requests');
      this.props
        .getRight()
        .then(() => this.props.initHeader())
        .then(() =>
          this.props.lastIndexRowRequest
            ? this.props.initDataWithLastIndexRow(false)
            : this.props.initData(false),
        )
        .then(() => this.props.setIsLoading(false))
        .catch(() => console.warn('[INIT ERROR]'));
      // сокет, прослушиваем изменения в таблице
      socket.on('table', ({ event, _id }) => {
        if (event === 'create') {
          setTimeout(() => this.props.addRow(_id), 5000);
        } else if (event === 'update') {
          this.props.replacementRow(_id);
        } else if (event === 'delete') {
          this.props.deleteRows([_id]);
        } else if (event === 'changeStatus') {
          this.props.changeStatus(_id);
        }
      });
      if (this.props.expand) {
        this.props.addFullScreen();
      }
      setTimeout(() => {
        this.props.setSecondaryBarItems([]);
      }, 200);
      const filterModel = this.props.allFilterModelByLogin
        ? this.props.allFilterModelByLogin[this.props.user.login]
        : null;
      if (filterModel) {
        this.props.setAllFilterModel(filterModel, this.props.user.login);
      } else {
        this.props.setAllFilterModel(
          { values: [], ids: {} },
          this.props.user.login,
        );
      }
      const otherFilters = this.props.otherFilters
        ? this.props.otherFilters[this.props.user.login]
        : null;
      if (otherFilters) {
        this.props.setSelectedStatus(otherFilters.selectedStatus);
        this.props.setSearchModel(
          otherFilters.searchModel ? otherFilters.searchModel : '',
        );
        this.props.setDateFilters(
          otherFilters.dateFilters ? otherFilters.dateFilters : '',
        );
        this.props.setRangeDate(
          otherFilters.rangeDate ? otherFilters.rangeDate : [],
        );
        this.props.setDateFiltersActive(
          otherFilters.dateFiltersActive
            ? otherFilters.dateFiltersActive
            : false,
        );
      } else {
        this.props.setSelectedStatus(null);
        this.props.setSearchModel('');
        this.props.setDateFilters('');
        this.props.setRangeDate([]);
        this.props.setDateFiltersActive(false);
      }
      const sortModel = this.props.sortModelByLogin
        ? this.props.sortModelByLogin[this.props.user.login]
        : null;
      if (sortModel) {
        this.props.setSortModel(sortModel);
      } else {
        this.props.setSortModel(null);
      }
    },
    componentDidUpdate(prevProps) {
      if (
        (prevProps.user.templateId || this.props.user.templateId) &&
        prevProps.user.templateId !== this.props.user.templateId
      ) {
        // this.props.setIsLoading(true);
        this.props.setLoadingExcel(true);
        this.props.setTableHeader([], () => {
          this.props.initHeader(false).finally(() => {
            this.props.setLoadingExcel(false);
            this.props.functionCheckTime('Success', 'Шаблон успешно применен');
          });
        });
      }
    },
    componentWillUnmount() {
      socket.close();
      this.props.removeFullScreen();
    },
  }),
)(RequestTableView);
