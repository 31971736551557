import React from 'react';

import { numberWithCommas } from '../../../helpers/RoundingNumbers';

import { Input } from '../..';

import {
  FilterValue,
  FilterValueContainer,
  ButtonsContainer,
  OpenInputContainer,
  OpenInput,
  OpenFilterButton,
  Button,
  FilterValueDelete,
  SearchIcon,
} from './NumberStyled';

export const NumberView = (props) => {
  const index = 0;
  const previewValue = () => {
    let val1 = '';
    let val2 = '';
    val1 =
      props.filterModel[props.column.colId] &&
      props.filterModel[props.column.colId].values &&
      props.filterModel[props.column.colId].values[index] &&
      props.filterModel[props.column.colId].values[index][0] &&
      numberWithCommas(props.filterModel[props.column.colId].values[index][0]);
    val2 =
      props.filterModel[props.column.colId] &&
      props.filterModel[props.column.colId].values &&
      props.filterModel[props.column.colId].values[index] &&
      props.filterModel[props.column.colId].values[index][1] &&
      numberWithCommas(props.filterModel[props.column.colId].values[index][1]);
    if (val1 === undefined && val2 === undefined) {
      return '';
    }
    if (val1 === '' && val2 === '') {
      return '0';
    }
    if (val1 === '') {
      val1 = '0';
    }
    if (val2 === '') {
      val2 = '0';
    }
    return `от ${val1} до ${val2}`;
  };

  return (
    <OpenInputContainer>
      <div id={`custom-ag-filter-${props.column.colId}`}>
        <OpenInputContainer>
          <OpenInput
            className="custom-ag-filter-input"
            placeholder=""
            value={`  ${previewValue()}`}
            readOnly
            onClick={props.toggleOpened}
          />
          <OpenFilterButton
            className="ag-icon ag-icon-filter"
            unselectable="on"
            onClick={props.toggleOpened}
          />
        </OpenInputContainer>
        {props.column.colId === props.openFilterField ? (
          <FilterValueContainer>
            <FilterValue style={{ marginBottom: '0.5em' }}>
              <SearchIcon />
              <Input
                type="text"
                value={
                  (props.localItems[props.column.colId] &&
                    props.localItems[props.column.colId].values &&
                    props.localItems[props.column.colId].values[index] &&
                    props.localItems[props.column.colId].values[index][0] &&
                    numberWithCommas(
                      props.localItems[props.column.colId].values[index][0],
                    )) ||
                  ''
                }
                placeholder="Введите значение от..."
                changed={(value) => {
                  if (value.split('-').length === 2 && value.split('-')[0])
                    return;
                  if (value !== '-' && isNaN(value.split(' ').join(''))) return;
                  value = value.replace(',', '.');
                  let values = [];
                  if (props.localItems[props.column.colId]) {
                    values = [...props.localItems[props.column.colId].values];
                    if (values[index] === '') {
                      values[index] = [];
                      values[index][0] = value.split(' ').join('');
                      values[index][1] = '';
                    } else if (values[index] === undefined) {
                      values[index] = [];
                      values[index][0] = value.split(' ').join('');
                      values[index][1] = '';
                    } else if (values[index][0] !== undefined) {
                      values[index][0] = value.split(' ').join('');
                    }
                  } else {
                    values[index] = [];
                    values[index][0] = value.split(' ').join('');
                    values[index][1] = '';
                  }
                  props.changeFilter(props.column.colId, { values });
                }}
              />
              {props.localItems[props.column.colId] &&
                props.localItems[props.column.colId].values &&
                props.localItems[props.column.colId].values[index] &&
                props.localItems[props.column.colId].values[index][0] && (
                  <FilterValueDelete
                    onClick={() => {
                      const values = [];
                      values[index] = [];
                      values[index][0] = '';
                      values[index][1] =
                        props.localItems[props.column.colId].values[index][1];
                      props.changeFilter(props.column.colId, { values });
                    }}
                    src={require('../../../assets/icons/delete-black.svg')}
                    alt="delete"
                  />
                )}
            </FilterValue>
            <FilterValue>
              <SearchIcon />
              <Input
                type="text"
                value={
                  (props.localItems[props.column.colId] &&
                    props.localItems[props.column.colId].values &&
                    props.localItems[props.column.colId].values[index] &&
                    props.localItems[props.column.colId].values[index][1] &&
                    numberWithCommas(
                      props.localItems[props.column.colId].values[index][1],
                    )) ||
                  ''
                }
                placeholder="Введите значение до..."
                changed={(value) => {
                  if (value.split('-').length === 2 && value.split('-')[0])
                    return;
                  if (value !== '-' && isNaN(value.split(' ').join(''))) return;
                  value = value.replace(',', '.');
                  let values = [];
                  if (props.localItems[props.column.colId]) {
                    values = [...props.localItems[props.column.colId].values];
                    if (values[index] === '') {
                      values[index] = [];
                      values[index][0] = '';
                      values[index][1] = value.split(' ').join('');
                    } else if (values[index] === undefined) {
                      values[index] = [];
                      values[index][1] = value.split(' ').join('');
                      values[index][0] = '';
                    } else if (values[index][1] !== undefined) {
                      values[index][1] = value.split(' ').join('');
                    }
                  } else {
                    values[index] = [];
                    values[index][1] = value.split(' ').join('');
                    values[index][0] = '';
                  }
                  props.changeFilter(props.column.colId, { values });
                }}
              />
              {props.localItems[props.column.colId] &&
                props.localItems[props.column.colId].values &&
                props.localItems[props.column.colId].values[index] &&
                props.localItems[props.column.colId].values[index][1] && (
                  <FilterValueDelete
                    onClick={() => {
                      const values = [];
                      values[index] = [];
                      values[index][0] =
                        props.localItems[props.column.colId].values[index][0];
                      values[index][1] = '';
                      props.changeFilter(props.column.colId, { values });
                    }}
                    src={require('../../../assets/icons/delete-black.svg')}
                    alt="delete"
                  />
                )}
            </FilterValue>
            <ButtonsContainer>
              <Button
                onClick={() => props.clearFilter(props.column.colId, index)}>
                Не заполнено
              </Button>
              <Button
                onClick={() => props.resetFilter(props.column.colId, index)}>
                Сбросить
              </Button>
              <Button onClick={props.applyFilter}>Применить</Button>
            </ButtonsContainer>
          </FilterValueContainer>
        ) : null}
      </div>
    </OpenInputContainer>
  );
};
