/**
 * @flow
 */
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import {
  Container,
  Title,
  TitleButton,
  Header,
  HeaderRow,
  Body,
  Footer,
  Text,
  ContainerGrid,
  ContainerLoader,
  ContainerButtons,
  ContainerTabs,
  Input,
  Lable,
} from './UpdateStatusRequestsStyles';

import { PopupMessage, ModaleShadow } from '../Directories/DirectoriesStyled';

import { Button, Dropzone, Loader, Popup } from '../../../components';

type UpdateStatusRequestsPropsType = {
  back: () => void,
  dataFile: any,
  setDataFile: () => void,
  functionCheckTime: () => void,
  updateStatusRequests: () => void,
  resetExcel: () => void,
  changeSheet: () => void,
  tableHeader: Array,
  tableData: Array,
  showDropzone: Boolean,
  showGrid: Boolean,
  showLoader: Boolean,
  currentSheet: Number,
  setShowModalWindow: () => void,
  showModalWindow: Number,
};

export default function UpdateStatusRequestsView(
  props: UpdateStatusRequestsPropsType,
) {
  return (
    <Container>
      <Header>
        <HeaderRow>
          <Title>Обновление статуса заявок</Title>
          <TitleButton white>
            <Button
              mode="white"
              clicked={() => {
                props.back();
              }}>
              Вернуться
            </Button>
          </TitleButton>
        </HeaderRow>
        {props.dataFile ? (
          <Text>
            Загрузите файл с номерами заявок и их статусами, чтобы их обновить.
          </Text>
        ) : (
          <Text>
            Добавьте файл с номерами контейнеров и их статусами, чтобы обновить
            заявки.
          </Text>
        )}
      </Header>
      <Body>
        {props.showDropzone && (
          <Dropzone
            dataFile={props.dataFile}
            setDataFile={props.setDataFile}
            functionCheckTime={props.functionCheckTime}
            iconName="excel"
          />
        )}
        {props.showGrid && (
          <ContainerTabs>
            <Input
              type="radio"
              name="tab-btn"
              id="tab-btn-1"
              value=""
              onClick={() => props.changeSheet(0)}
            />
            <Lable for="tab-btn-1" sheet={0} currentSheet={props.currentSheet}>
              В пути
            </Lable>
            <Input
              type="radio"
              name="tab-btn"
              id="tab-btn-2"
              value=""
              onClick={() => props.changeSheet(1)}
            />
            <Lable for="tab-btn-2" sheet={1} currentSheet={props.currentSheet}>
              Прибывшие
            </Lable>
            <ContainerGrid className="ag-theme-balham">
              {props.showLoader ? (
                <ContainerLoader>
                  <Loader />
                </ContainerLoader>
              ) : (
                <AgGridReact
                  columnDefs={props.tableHeader}
                  defaultColDef={{
                    // width: 200,
                    suppressMenu: true,
                  }}
                  rowData={props.tableData}
                  rowSelection="multiple"
                  suppressRowClickSelection
                  suppressContextMenu
                  onGridReady={props.onGridReady}
                />
              )}
            </ContainerGrid>
          </ContainerTabs>
        )}
      </Body>
      <Footer>
        <ContainerButtons>
          <Button
            mode="red"
            clicked={props.updateStatusRequests}
            disabled={!props.dataFile}>
            Обновить
          </Button>
          <Button
            mode="red"
            clicked={() => props.setShowModalWindow(true)}
            disabled={!props.tableData.length}>
            Очистить
          </Button>
        </ContainerButtons>
      </Footer>
      <PopupMessage show={props.showModalWindow}>
        <Popup status="Warning">
          <p>Вы точно хотите очистить данные?</p>
          <div>
            <button type="button" onClick={() => props.resetExcel()}>
              Да
            </button>
            <button
              type="button"
              onClick={() => props.setShowModalWindow(false)}>
              Нет
            </button>
          </div>
        </Popup>
      </PopupMessage>
    </Container>
  );
}
