import * as actionTypes from './actionTypes';

export function changeShowRatingModal(data) {
  return {
    type: actionTypes.SHOW_RATING_MODAL,
    payload: data,
  };
}

export function setLastIndexRowRequest(data) {
  return {
    type: actionTypes.SET_LAST_INDEX_ROW_REQUEST,
    payload: data,
  };
}

export function setAllFilterModel(data, login) {
  return {
    type: actionTypes.SET_ALL_FILTER_MODEL,
    payload: { data, login },
  };
}

export function setSelectedFilters(data) {
  return {
    type: actionTypes.SET_SELECTED_FILTERS,
    payload: data,
  };
}
export function setDateFilters(data) {
  return {
    type: actionTypes.SET_DATE_FILTERS,
    payload: data,
  };
}

export function setRangeDate(data) {
  return {
    type: actionTypes.SET_RANGE_DATE,
    payload: data,
  };
}

export function setDateFiltersActive(data) {
  return {
    type: actionTypes.SET_DATE_FILTERS_ACTIVE,
    payload: data,
  };
}

export function setSelectedStatus(data) {
  return {
    type: actionTypes.SET_SELECTED_STATUS,
    payload: data,
  };
}

export function setSortModel(data) {
  return {
    type: actionTypes.SET_SORT_MODEL,
    payload: data,
  };
}

export function setSearchModel(data) {
  return {
    type: actionTypes.SET_SEARCH_MODEL,
    payload: data,
  };
}

export function setOtherFilters(data, login) {
  return {
    type: actionTypes.SET_OTHER_FILTERS,
    payload: { data, login },
  };
}

export function setSortModelByLogin(data, login) {
  return {
    type: actionTypes.SET_SORT_MODEL_BY_LOGIN,
    payload: { data, login },
  };
}

export function setPayers(data) {
  return {
    type: actionTypes.SET_PAYERS,
    payload: data,
  };
}
