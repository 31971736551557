import React from 'react';

import {
  StatusBarContainer,
  StatusBarValue,
  StatusBarText,
} from './StatusBarStyled';

export const StatusBarView = (props) => {
  const value = props.quantityRequests[props.type];
  const text = props.type === 'rows' ? 'Строк:' : 'Всего строк:';

  return (
    <StatusBarContainer>
      <StatusBarText>{text}</StatusBarText>
      <StatusBarValue>{value}</StatusBarValue>
    </StatusBarContainer>
  );
};
