import styled from 'styled-components';

import { base, screens } from '../../../styles';

export const ContentPage = styled.div`
  width: 100%;
`;

export const FormCardInfo = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  padding-top: ${base * 11.25}px;
  width: ${base * 38}px;
  @media (max-width: ${screens.screen_xs_max}) {
    width: 100%;
  }
`;

export const ContentGlobal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin: 12px;
  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
  button {
    margin: auto auto 10px 24px;
  }
`;

export const MobileAddButton = styled.div`
  display: none;
  @media (max-width: ${screens.screen_xs_max}) {
    display: block;
    position: fixed;
    top: 110px;
    right: 8px;
  }
`;

export const Loader = styled.div`
  border: 7px solid #dadada;
  border-top: 7px solid #656565;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
  margin-left: 20px;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
