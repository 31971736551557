import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

export const StampFilterWraper = styled.div`
  font-size: ${base + 4}px;
  cursor: pointer;
`;

export const StampFilterHeader = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  background-color: ${colors.primary_gray};
  width: ${base * 10}px;
  height: 28px;
  border-left: 5px solid ${colors.primary_red};
  padding: 0 12px;

  ${(props) =>
    props.currentStateItem &&
    css`
      border-left: none;
      color: black;
    `}
`;

export const StampFilterName = styled.div`
  color: ${colors.primary_white_gray};
  margin: auto 0;
`;

export const StampFilterOptions = styled.div`
  border: 1px solid ${colors.primary_white_gray};
  margin-top: 12px;
  border-radius: ${base - 5}px;
  width: ${base * 24}px;
`;

export const StampClose = styled.div`
  background-image: url(${require('../../assets/icons/close-default.svg')});
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  margin: auto;
  margin-right: 0;
  &:hover {
    background-image: url(${require('../../assets/icons/close-hover.svg')});
  }
`;
