import io from 'socket.io-client';
import { BASE_URL } from '../constants';

// eslint-disable-next-line import/no-mutable-exports
let socket;

function connection() {
  socket = io.connect(BASE_URL, { transports: ['websocket'] });
}

export { connection, socket };
