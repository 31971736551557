import { apiGet, apiPut, apiPost, apiDelete } from '../ApiService';

export default {
  getApiName() {
    return 'roles';
  },

  getRoles() {
    return apiGet('/roles?records=1000')
      .then((response) =>
        response.data.docs.map((role) => ({
          id: role._id,
          name: role.name,
          url: role.rightName,
          protection: role.protection,
        })),
      )
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  searchItem(body) {
    return apiGet(`/roles?search=${body.search}`)
      .then((response) =>
        response.data.docs.map((item) => ({
          id: item._id,
          ...item,
        })),
      )
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getRolesAll() {
    return apiGet('/roles/rights/translation')
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getRoleAccessRights(id) {
    return apiGet(`/roles/rights/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  editRoles(id, result) {
    return apiPut(`/roles/${id}`, result)
      .then((response) => console.log('result', result))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  createRole(result) {
    return apiPost('/roles', result)
      .then((response) => console.log('result', result))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  deleteRole(id) {
    return apiDelete(`/roles/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
};
