import React from 'react';
import Downshift from 'downshift';

import {
  List,
  Item,
  Button,
  Container,
  Input,
  DeleteValue,
} from './SelectStyles';

type SelectViewType = {
  onChange: () => void,
  list: Array<any>,
  placeholder: string,
  itemValue: object,
  autocomplete: boolean,
  onInputValueChange: () => void,
  disabled: Boolean,
  itemName?: string,
  fieldsWithErrors?: Array<string>,
  type?: string,
  btnDelete?: boolean,
  useSelect?: boolean,
};

export default function SelectView(props: SelectViewType) {
  return (
    <Downshift
      onChange={(selection) => props.onChange(selection)}
      itemToString={(item) => (item ? item.name : '')}
      inputValue={(props.itemValue && props.itemValue.name) || ''}
      selectedItem={props.itemValue}>
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getRootProps,
        isOpen,
        highlightedIndex,
        selectedItem,
        getToggleButtonProps,
        inputValue,
        openMenu,
      }) => (
        <Container {...getRootProps()}>
          <Input
            {...getInputProps({
              type: 'text',
              placeholder: props.placeholder,
              disabled: props.disabled,
              onFocus() {
                openMenu();
                props.onInputValueChange(inputValue);
              },
              onChange(e) {
                props.onInputValueChange(e.target.value);
              },
            })}
            autocomplete={props.autocomplete}
            itemName={props.itemName}
            fieldsWithErrors={props.fieldsWithErrors}
            id={props.itemName}
            btnDelete={props.btnDelete}
            isOpen={isOpen}
            useSelect={props.useSelect}
          />
          {props.btnDelete &&
            props.itemValue &&
            props.itemValue.name &&
            isOpen && (
              <DeleteValue
                src={require('../../../src/assets/icons/delete-black.svg')}
                onClick={() => props.onInputValueChange('')}
                alt="delete"
              />
            )}
          {isOpen && (
            <div>
              <List {...getMenuProps()} type={props.type}>
                {props.list.map((item, index) => (
                  <Item
                    {...getItemProps({
                      key: index,
                      item,
                      style: {
                        color:
                          highlightedIndex === index ? '#da350a' : '#9b9b9b',
                        fontWeight:
                          selectedItem.name === item.name ||
                          item.name.includes('+ создать') ||
                          item.name.includes('Редактировать шаблон')
                            ? 'bold'
                            : 'normal',
                        fontSize: item.name.includes('+ создать')
                          ? '14px'
                          : '12px',
                      },
                    })}>
                    {item.name}
                  </Item>
                ))}
              </List>
            </div>
          )}
          {props.useSelect && props.autocomplete && (
            <Button
              {...getToggleButtonProps({
                type: 'text',
                placeholder: props.placeholder,
                disabled: props.disabled,
                onFocus() {
                  props.onInputValueChange(inputValue);
                },
                onChange(e) {
                  props.onInputValueChange(e.target.value);
                },
              })}
              isOpen={isOpen}
              tabIndex="-1"
              type={props.type}
            />
          )}
          {!props.useSelect && !props.autocomplete && (
            <Button
              {...getToggleButtonProps()}
              isOpen={isOpen}
              tabIndex="-1"
              type={props.type}
            />
          )}
        </Container>
      )}
    </Downshift>
  );
}
