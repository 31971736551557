import styled, { css } from 'styled-components';
import { colors } from '../../styles';

export const ModaleShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const ModaleWindow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${colors.primary_red};
  position: fixed;
  left: 50%;
  margin-left: -200px;
  top: ${(props) => props.top || 5}%;
  border-radius: 6px;
  min-width: ${(props) => props.width}px;
  min-height: ${(props) => props.height}px;
  padding: 24px;
  z-index: 3000;
  background-color: white;
  ${(props) =>
    props.status &&
    css`
      display: none;
    `}
  ${(props) =>
    props.exact &&
    css`
      width: ${(props) => props.width}px;
      height: ${(props) => props.height}px;
    `}
`;

export const ModaleWindowClose = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  background-color: ${colors.primary_red};
  margin: 0 0 0 auto;
  cursor: pointer;
  img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const ModaleWindowBackDrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2000;
  ${(props) =>
    props.status &&
    css`
      display: none;
    `}
`;

export const ModaleWindowForm = styled.form``;
