/**
 * @flow
 */
import React from 'react';

import { Button } from './ButtonStyled';

import { Tooltip } from '..';

type ButtonViewPropsType = {
  clicked: (any) => void,
  children: any,
  circle?: boolean,
  rectangle?: boolean,
  cross?: boolean,
  secondary?: boolean,
  crossHover?: boolean,
  plus?: boolean,
  mode?: string,
  maxWidth?: boolean,
  title?: string,
  disabled?: boolean,
  showTooltip: boolean,
  setShowTooltip: (any) => void,
  coordsTooltip: Object,
  setCoordsTooltip: (any) => void,
  type: string,
};

export default function ButtonView(props: ButtonViewPropsType) {
  return (
    <>
      <Button
        type={props.type || 'button'}
        onClick={(event) => {
          event.preventDefault();
          props.clicked();
        }}
        circle={props.circle}
        rectangle={props.rectangle}
        cross={props.cross}
        secondary={props.secondary}
        crossHover={props.crossHover}
        plus={props.plus}
        mode={props.mode}
        maxWidth={props.maxWidth}
        disabled={props.disabled}
        onMouseEnter={(e) => {
          props.setCoordsTooltip({ x: e.clientX, y: e.clientY });
          props.setShowTooltip(true);
        }}
        onMouseLeave={() => {
          props.setCoordsTooltip(null);
          props.setShowTooltip(false);
        }}>
        {props.plus && (
          <img
            style={{ width: '10px', transform: 'rotate(45deg)' }}
            src={require('../../assets/icons/close-white.svg')}
            alt="plus"
            transform="rotate(45deg)"
          />
        )}
        {props.children}
      </Button>
      <Tooltip
        show={props.showTooltip && props.title}
        value={props.title}
        coords={props.coordsTooltip}
      />
    </>
  );
}

ButtonView.defaultProps = {
  circle: false,
  rectangle: false,
  cross: false,
  secondary: false,
  crossHover: false,
  plus: false,
  mode: '',
  maxWidth: false,
  title: '',
  disabled: false,
};
