import styled, { css } from 'styled-components';

export const StyledTabPane = styled.div`
  display: none;

  ${(props) =>
    props.active &&
    css`
      display: block;
    `}
`;
