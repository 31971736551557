import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../../styles';

export const ContentSelect = styled.div`
  width: 50%;
  margin-bottom: 20px;
  @media (max-width: ${screens.screen_xs_max}) {
    width: 100%;
  }
  line-height: 24px;
  font-size: ${base + 4}px;
  input,
  div {
    line-height: 24px;
    font-size: ${base + 4}px;
  }
`;
