/**
 * @flow
 */
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  Container,
  ImgIcon,
  Progress,
  DeleteButton,
  Preview,
  TextButton,
} from './DropzoneStyled';

const icons = {
  excel: require('../../assets/icons/excel.svg'),
};

type DropzoneViewPropsType = {
  dataFile: any,
  setDataFile: (any) => void,
  showIcon: Boolean,
  setShowIcon: (any) => void,
  functionCheckTime: (any) => void,
  progress: Number,
  setProgress: (any) => void,
  iconName: String,
};

export default function DropzoneView(props: DropzoneViewPropsType) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onerror = () => {
        props.functionCheckTime('Error', 'Произошла ошибка чтения файла');
      };
      reader.onprogress = (e) => {
        props.setProgress(Math.round((e.loaded / e.total) * 100));
      };
      reader.onloadstart = () => {
        props.setShowIcon(true);
      };
      reader.onload = () => {
        props.setDataFile(reader.result);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  return (
    <Container
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      showIcon={props.showIcon}>
      <input {...getInputProps()} />
      {props.showIcon ? (
        <Preview>
          <DeleteButton
            onClick={(e) => {
              props.setDataFile(null);
              props.setShowIcon(false);
              e.stopPropagation();
            }}
          />
          <ImgIcon src={icons[props.iconName]} alt="icon" />
          <Progress value={props.progress} max="100" />
          <TextButton
            mode="cancel"
            onClick={(e) => {
              props.setDataFile(null);
              props.setShowIcon(false);
              e.stopPropagation();
            }}>
            Отмена
          </TextButton>
        </Preview>
      ) : (
        <p>
          Перетащите файл, чтобы прикрепить, либо нажмите{' '}
          <TextButton>выбрать</TextButton>.
        </p>
      )}
    </Container>
  );
}
