/* eslint-disable react/no-unescaped-entities */
/**
 * @flow
 */
import React from 'react';

import {
  Checkbox,
  Button,
  Popup,
  Loader,
  Input,
  SelectDropList,
  ModaleWindow,
} from '../../../../components';

import {
  PopupMessage,
  ModaleShadow,
} from '../../Directories/DirectoriesStyled';

import { ModalContentWrapper } from '../../RequestsTable/RequestTableStyles';

import {
  MainPage,
  TitleCust,
  CustColumnsMain,
  NameColumns,
  CheckboxContainer,
  HeaderTitle,
  PopupCustTitle,
  ColumnsSection,
  CheckBoxWrapper,
  SectionTitleWrapper,
  InputSearchBlockContainer,
  InputSearchBlock,
  FiltersValueDelete,
  ContainerSelectDropList,
  ButtonGroups,
  ContainerInputModal,
  TitleCustConatiner,
} from './CustColumnsStyled';

type CustColumnsPropsType = {
  isLoading: boolean,
  filteredCustList: Array<Object>,
  setCheckValue: (any) => void,
  saveCustColumnsData: (any) => void,
  custSaved: any,
  selectAllInSection: (string, boolean) => void,
  changeTemplateName: (string) => void,
  templateName: string,
};

const renderCustList = (custList, setCheckValue, selectAllInSection) =>
  custList.map((item) => {
    if (!item.items.length) {
      return null;
    }

    return (
      <ColumnsSection key={item.name}>
        <SectionTitleWrapper>
          <NameColumns>{item.title}</NameColumns>
          <Checkbox
            message="Выбрать все"
            name={item.name}
            onClickItem={(value) => {
              selectAllInSection(item.name, value);
            }}
          />
        </SectionTitleWrapper>
        <CheckboxContainer>
          {item.items.map((checkbox) => (
            <CheckBoxWrapper key={checkbox.name}>
              <Checkbox
                message={checkbox.title}
                value={checkbox.value}
                name={checkbox.name}
                onClickItem={() => {
                  setCheckValue(checkbox.name);
                }}
              />
            </CheckBoxWrapper>
          ))}
        </CheckboxContainer>
      </ColumnsSection>
    );
  });

export default function CustColumns(props: CustColumnsPropsType) {
  return (
    <MainPage>
      <HeaderTitle>
        <TitleCust>
          <span>Настройка</span>
          <span>колонок</span>
        </TitleCust>
        <ButtonGroups>
          {props.template && (
            <Button clicked={props.cancelTemplate}>Отменить шаблон</Button>
          )}
          {props.template && (
            <Button
              clicked={() => {
                props.setShowPopupRemove(true);
              }}>
              Удалить шаблон
            </Button>
          )}
          <Button
            clicked={() => {
              if (props.template) {
                props.setShowPopupUpdate(true);
              } else {
                props.saveCustColumnsData();
              }
            }}>
            Сохранить
          </Button>
        </ButtonGroups>
      </HeaderTitle>
      <HeaderTitle>
        <TitleCustConatiner>
          <span>{props.templateName}</span>
        </TitleCustConatiner>
      </HeaderTitle>
      <CustColumnsMain className="cust-columns-main">
        <InputSearchBlockContainer>
          <ContainerSelectDropList>
            <SelectDropList
              list={[
                ...props.templates.map((item) => ({
                  name: item.name,
                  value: item._id,
                })),
                { name: '+ создать шаблон', value: '' },
              ]}
              itemValue={props.template || {}}
              onInputValueChange={props.getTemplates}
              onChange={props.chooseTemplate}
              placeholder="Выберите шаблон"
              autocomplete
              useSelect
            />
          </ContainerSelectDropList>
          <InputSearchBlock>
            <Input
              placeholder="Поиск"
              search="true"
              value={props.searchValue}
              changed={(value) => {
                props.setSearchValue(value);
                props.searchItems(value);
              }}
            />
            {props.searchValue && (
              <FiltersValueDelete
                onClick={() => {
                  props.setSearchValue('');
                  props.searchItems('');
                }}
                src={require('../../../../assets/icons/close-default.svg')}
                alt="delete"
                style={{ right: '12px', top: '18px' }}
              />
            )}
          </InputSearchBlock>
        </InputSearchBlockContainer>
        <p>Настройте видимость колонок в разделе "Заявки":</p>
        {props.isLoading ? (
          <Loader />
        ) : (
          renderCustList(
            props.filteredCustList,
            props.setCheckValue,
            props.selectAllInSection,
          )
        )}
      </CustColumnsMain>
      <PopupCustTitle>
        {props.custSaved && <Popup status="Success">Данные сохранены</Popup>}
      </PopupCustTitle>
      <ModaleShadow show={props.showPopupUpdate}>
        <PopupMessage show={props.showPopupUpdate}>
          <Popup status="Warning">
            <p>Вы действительно хотите изменить конфигурацию шаблона?</p>
            <div>
              <button
                type="button"
                onClick={() => {
                  props.saveCustColumnsData();
                  props.setShowPopupUpdate(false);
                }}>
                Да
              </button>
              <button
                type="button"
                onClick={() => {
                  props.setShowPopupUpdate(false);
                }}>
                Нет
              </button>
            </div>
          </Popup>
        </PopupMessage>
      </ModaleShadow>
      <ModaleShadow show={props.showPopupRemove}>
        <PopupMessage show={props.showPopupRemove}>
          <Popup status="Warning">
            <p>Вы действительно хотите удалить шаблон?</p>
            <div>
              <button
                type="button"
                onClick={() => {
                  props.removeTemplate();
                  props.setShowPopupRemove(false);
                }}>
                Да
              </button>
              <button
                type="button"
                onClick={() => {
                  props.setShowPopupRemove(false);
                }}>
                Нет
              </button>
            </div>
          </Popup>
        </PopupMessage>
      </ModaleShadow>
      {props.showTemplateModal && (
        <ModaleWindow
          closeAction={() => {
            props.setTemplateName('');
            props.closeTemplateModal();
          }}
          width={100}
          height={90}
          top={38}>
          <ModalContentWrapper>
            <ContainerInputModal>
              <Input
                placeholder="Введите имя шаблона"
                value={props.templateName}
                changed={(value) => props.changeTemplateName(value)}
                autoFocus
              />
            </ContainerInputModal>
            <Button
              clicked={() => {
                if (props.templateName.trim()) {
                  props.closeTemplateModal();
                  props.saveCustColumnsData();
                } else {
                  props.functionCheckTime(
                    'Error',
                    'Имя шаблона не может быть пустым',
                  );
                }
              }}
              type="submit">
              Сохранить шаблон
            </Button>
          </ModalContentWrapper>
        </ModaleWindow>
      )}
    </MainPage>
  );
}
