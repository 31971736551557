import styled, { css } from 'styled-components';

export const TooltipContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  background-color: #f9f9f9;
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 8px;
  max-width: 240px;
  min-height: 16px;
  font-size: 12px;
  z-index: 1;
  ${(props) =>
    props.coords &&
    css`
      top: ${props.coords.y + 8}px;
      left: ${props.coords.x + 8}px;
    `}
`;

export const TooltipValue = styled.div``;
