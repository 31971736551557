import { compose, withState } from 'recompose';
import { connect } from 'react-redux';

// actions
import {
  saveUserInfoFirstNameData,
  saveUserInfoLastNameData,
} from '../../store/actions/users';

import { saveUserAccesses } from '../../store/actions/auth';

import PayerInfoView from './PayerInfoView';

export default compose(
  // withState('edit', 'setEdit', false),
  withState('itemToAdd', 'onItemSelect', ''),
  withState('activeTab', 'setActiveTab', 0),
  connect(
    (state) => ({
      usersInfoFirstName: state.users.usersInfoFirstName,
      usersInfoLastName: state.users.usersInfoLastName,
      needPadding: state.dashboard.needPadding,
      access: state.auth.userAccesses,
      activeSecondaryItem: state.dashboard.activeSecondaryItem,
    }),
    (dispatch) => ({
      saveUserAccesses: (data) => dispatch(saveUserAccesses(data)),
      saveUserInfoFirstNameData: (items) =>
        dispatch(saveUserInfoFirstNameData(items)),
      saveUserInfoLastNameData: (items) =>
        dispatch(saveUserInfoLastNameData(items)),
    }),
  ),
)(PayerInfoView);
