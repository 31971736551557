/**
 * @flow
 */
import React from 'react';

import { Input, Button } from '../index';
import {
  Form,
  InputContainer,
  ButtonContainer,
  FormHeading,
  InputMargin,
} from './LoginFormStyled';

type LoginFormViewPropsType = {
  setLogin: (any) => void,
  setPassword: (any) => void,
  submitForm: (any) => void,
  submitEvent: (any) => void,
  emptyFields: Array<String>,
  showErrorsFields: boolean,
};

export default function LoginFormView(props: LoginFormViewPropsType) {
  return (
    <Form onKeyDown={props.submitEvent}>
      <InputContainer>
        <FormHeading>
          <strong>Войдите в систему</strong>
        </FormHeading>
        <InputMargin>
          <Input
            type="text"
            placeholder="Логин"
            changed={props.setLogin}
            valid={
              props.showErrorsFields
                ? false
                : !props.emptyFields.includes('login')
            }
          />
        </InputMargin>
        <Input
          type="password"
          placeholder="Пароль"
          changed={props.setPassword}
          valid={
            props.showErrorsFields
              ? false
              : !props.emptyFields.includes('password')
          }
        />
      </InputContainer>
      <ButtonContainer>
        <Button clicked={props.submitForm}>
          Войти
          <img
            style={{ width: '70px', padding: '0 10px' }}
            src={require('../../assets/icons/white-arrow-svg.svg')}
            alt="arrow"
          />
        </Button>
      </ButtonContainer>
    </Form>
  );
}
