/**
 * @flow
 */

import React from 'react';

import { Popup, Loader } from './PopupStyled';

type PopupViewPropsType = {
  message: any,
  status: any,
  children: any,
  height: any,
};

export default function PopupView(props: PopupViewPropsType) {
  return (
    <Popup status={props.status} height={props.height}>
      {props.message}
      {props.children}
      <Loader />
    </Popup>
  );
}
