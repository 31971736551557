import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const ContainerSvg = styled.div`
  width: 24px;
  height: 24px;
`;
