import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

export const Paragraph = styled.p`
  margin: 0;
  color: ${colors.black};
  font-size: 32px;
  ${(props) =>
    props.smallParagraph &&
    css`
      /* font-family: 'MuseoSansCyrl light',sans-serif; */
      font-size: 18px;
      color: ${colors.light_gray};
      // padding: 0 40px 3px 40px;
    `}
`;

export const Container = styled.div`
  margin-bottom: 12px;
`;

export const AddItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 3px 40px;
  button {
    width: 20px;
    height: 20px;
    text-align: center;
    color: ${colors.primary_red};
    background-color: white;
    border: 1px solid ${colors.primary_red};
    cursor: pointer;
  }
`;

export const ListBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
