import { apiGet, apiPost, apiPut, apiDelete } from '../ApiService';

export default {
  getApiName() {
    return 'railcarriers';
  },

  getItems(p = 1, params) {
    return apiGet(`/railcarriers?page=${p}`, { params })
      .then((response) => ({
        data: response.data.docs.map((item) => ({
          id: item._id,
          ...item,
        })),
        records: response.data.pages.allRecords,
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getById(id) {
    return apiGet(`/railcarriers/${id}`)
      .then((response) => {
        const result = {
          id: response.data._id,
          name: response.data.name,
          relations: [],
          inputs: [],
          checkboxes: [],
          selection: [],
        };

        result.inputs.push({
          title: 'Страна регистрации',
          name: 'countryRegistration',
          value: response.data.countryRegistration,
        });
        result.inputs.push({
          title: 'ИНН',
          name: 'INN',
          value: response.data.INN,
        });
        result.inputs.push({
          title: 'КПП',
          name: 'KPP',
          value: response.data.KPP,
        });
        result.inputs.push({
          title: 'ОГРН',
          name: 'OGRN',
          value: response.data.OGRN,
        });
        result.inputs.push({
          title: 'Банк',
          name: 'bank',
          value: response.data.bank,
        });
        result.inputs.push({
          title: 'Номер счета',
          name: 'accountNumber',
          value: response.data.accountNumber,
        });
        result.inputs.push({
          title: 'e-mail адреса',
          name: 'emails',
          value: response.data.emails,
        });
        result.selection.push({
          title: 'Тип отчета',
          name: 'reportType',
          value: response.data.reportType,
          values: ['Экодор', 'Купавна', 'Евросиб'],
        });
        result.checkboxes.push({
          title: 'Отметка компания Амикс',
          name: 'mark',
          value: response.data.mark,
        });
        return result;
      })
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  removeLinkedItem(id, item, itemId) {
    let result = null;
    if (item === 'addresses') {
      result = apiPut(`/railcarrier/${id}?deladdress=${itemId}`)
        .then((response) => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    }

    return result;
  },

  addLinkedItem(id, item, itemId) {
    let result = null;
    if (item === 'addresses') {
      result = apiPut(`/railcarrier/${id}?addaddresses=["${itemId}"]`)
        .then((response) => response)
        .catch((error) => {
          throw new Error(error.response.data.error.message);
        });
    }

    return result;
  },

  deleteItem(id) {
    return apiDelete(`/railcarriers/${id}`)
      .then((response) => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  getFields() {
    return {
      relations: [],
      inputs: [
        {
          title: 'Название',
          name: 'name',
          value: '',
        },
        {
          title: 'Страна регистрации',
          name: 'countryRegistration',
          value: '',
        },
        {
          title: 'ИНН',
          name: 'INN',
          value: '',
        },
        {
          title: 'КПП',
          name: 'KPP',
          value: '',
        },
        {
          title: 'ОГРН',
          name: 'OGRN',
          value: '',
        },
        {
          title: 'Банк',
          name: 'bank',
          value: '',
        },
        {
          title: 'Номер счета',
          name: 'accountNumber',
          value: '',
        },
        {
          title: 'e-mail адреса',
          name: 'emails',
          value: '',
        },
      ],
      selection: [
        {
          title: 'Тип отчета',
          name: 'reportType',
          value: '',
          values: ['Экодор', 'Купавна', 'Евросиб'],
        },
      ],
      checkboxes: [
        {
          title: 'Отметка компания Амикс',
          name: 'mark',
          value: false,
        },
      ],
    };
  },

  updateItem(id, body) {
    return apiPut(`/railcarriers/${id}`, body)
      .then((response) => response)
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  createItem(body) {
    return apiPost('/railcarriers', body)
      .then((response) => ({
        status: response.status,
        data: {
          ...response.data,
          id: response.data._id,
        },
      }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },

  searchItem(body) {
    return apiGet(`/railcarriers?search=${body.search}`)
      .then((response) =>
        response.data.docs.map((item) => ({
          id: item._id,
          ...item,
        })),
      )
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
};
