/**
 * @flow
 */

import React from 'react';

import { PageTitle } from './PageTitleStyled';

type PageTitleViewPropsType = {
  children: any,
  dashboard?: boolean,
};

export default function PageTitleView(props: PageTitleViewPropsType) {
  return <PageTitle dashboard={props.dashboard}>{props.children}</PageTitle>;
}

PageTitleView.defaultProps = {
  dashboard: false,
};
