import styled, { css } from 'styled-components';
import { base, colors, screens } from '../../styles';

export const Container = styled.div`
  position: relative;
  margin: 0 12px 12px 0;
  width: 100%;
`;

export const Input = styled.input`
  font-size: 12px;
  height: ${base * 3.6}px;
  width: 250px;
  border: 0px;
  padding: 0 12px;
  border-radius: ${base - 4}px;
  box-sizing: border-box;
  background-color: #f2f2f2;

  &::placeholder {
    color: #8e8e8e;
  }
  &:focus {
    outline: none;
    ${({ autocomplete }) =>
      !autocomplete &&
      css`
         {
          caret-color: transparent;
        }
      `}
  }
  ${({ autocomplete }) =>
    !autocomplete &&
    css`
       {
        background-color: transparent;
        border: 1px solid ${colors.primary_white_gray};
      }
    `}
  ${({ useSelect }) =>
    useSelect &&
    css`
       {
        background-color: transparent;
        border: 1px solid ${colors.primary_white_gray};
      }
    `}
  ${({ itemName, fieldsWithErrors }) =>
    fieldsWithErrors &&
    fieldsWithErrors.includes(itemName) &&
    css`
       {
        border: 1px solid ${colors.primary_red};
      }
    `} 
  ${(props) =>
    props.btnDelete &&
    props.isOpen &&
    css`
      padding-right: 24px;
    `}
  @media (max-width: ${screens.screen_2xs_max}) {
    width: 350px;
  }
  @media (max-width: ${screens.screen_4xs_max}) {
    width: 221px;
  }
`;

export const Button = styled.button`
  height: ${base * 3.6}px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background-image: ${({ isOpen }) =>
    isOpen
      ? `url(${require('../../assets/icons/drop-down-up.svg')})`
      : `url(${require('../../assets/icons/drop-down.svg')})`};
  background-position: center right 19px;
  background-repeat: no-repeat;
  background-size: 9px;
  ${(props) =>
    props.type === 'filter' &&
    css`
      background-position: center right 4px;
    `}
  @media (max-width: ${screens.screen_2xs_max}) {
    right: 90px;
  }
  @media (max-width: ${screens.screen_4xs_max}) {
    right: 0;
  }
`;

export const List = styled.div`
  position: absolute;
  box-sizing: border-box;
  box-shadow: 0 10px 20px #efefef;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  background-color: #fff;
  border-radius: 6px;
  padding: 6px 10px;
  padding-right: 0px;
  z-index: 8;
  ${(props) => {
    setTimeout(() => {
      const elem = document.getElementById(props.id);
      if (elem) {
        const input = elem.offsetParent;
        const { clientHeight } = document.body;
        const coords = input.getBoundingClientRect();
        if (coords.bottom + 300 > clientHeight) {
          elem.style.bottom = `${base * 3.6}px`;
          elem.style.top = '';
        }
      }
    }, 500);
  }}
  ${(props) =>
    props.type === 'filter' &&
    css`
      top: 36px;
    `}
  @media (max-width: ${screens.screen_2xs_max}) {
    width: 350px;
  }
  @media (max-width: ${screens.screen_4xs_max}) {
    width: 221px;
  }
`;

export const Item = styled.div`
  padding: 3px 0;
  color: #9b9b9b;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const DeleteValue = styled.img`
  position: absolute;
  top: 35%;
  right: 8px;
  outline: none;
  cursor: pointer;
`;
