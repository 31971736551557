import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../styles';

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: ${colors.white_smoke};
  border: none;
  border-radius: ${base / 2}px;
  @media (max-width: ${screens.screen_2xs_max}) {
    width: 350px;
  }
  @media (max-width: ${screens.screen_4xs_max}) {
    width: 100%;
  }
`;

export const InputComponent = styled.input`
  box-sizing: border-box;
  height: ${base * 3.6}px;
  padding: 0 12px;
  font-size: 12px;
  color: ${colors.black};
  outline: none;
  width: 100%;
  background: transparent;
  border: none;
  &::placeholder {
    color: ${colors.light_gray};
  }
  &::-webkit-inner-spin-button {
    position: relative;
    top: ${(base * 3.6) / 4}px;
  }
  &::-ms-clear {
    display: none;
  }
  ${(props) =>
    !props.valid &&
    css`
      border: 1px solid ${colors.primary_red};
      border-radius: ${base / 2}px;
    `};
  ${(props) =>
    props.readOnly &&
    css`
      cursor: pointer;
    `};
  ${(props) =>
    props.location === 'grid' &&
    css`
      position: relative;
      height: 100%;
      top: 4px;
      &::-webkit-inner-spin-button {
        position: relative;
        top: 0px;
      }
    `};
  ${(props) =>
    props.location === 'filter' &&
    css`
      &::-webkit-inner-spin-button {
        position: relative;
        top: 4px;
      }
    `};
  ${(props) =>
    props.btnDelete &&
    props.value &&
    css`
      padding-right: 24px;
    `}
`;

export const Form = styled.div`
  ${(props) =>
    props.search &&
    css`
      display: flex;
    `};
`;

export const SearchIcon = styled.div`
  height: 32px;
  width: 32px;
  margin: 7px 0 0 10px;
  background-image: url(${require('../../assets/icons/searchsvg.svg')});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
`;

export const DeleteValue = styled.img`
  position: absolute;
  top: 35%;
  right: 8px;
  outline: none;
  cursor: pointer;
`;
