import styled, { css } from 'styled-components';

import { base, colors, screens } from '../../../styles';

export const ContentPage = styled.div`
  width: 100%;
`;

export const FormCardInfo = styled.div`
  position: fixed;
  right: 0;
`;

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: ${screens.screen_xs_max}) {
    display: none;
  }
  ${(props) =>
    props.bigRightMargin &&
    css`
      margin-right: 390px;
    `}
`;

export const MobileTitleBlock = styled.div`
  display: none;
  position: relative;
  input {
    padding-right: 24px;
    padding-left: 10px;
  }
  @media (max-width: ${screens.screen_xs_max}) {
    display: block;
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const ButtonBlock = styled.div`
  margin-right: 72px;
  display: flex;
  align-items: center;
  margin: auto auto 10px 24px;
`;

export const InputSearchBlock = styled.div`
  position: relative;
  width: ${base * 20}px;
  display: flex;
  align-items: center;
  input {
    padding-right: 24px;
    padding-left: 10px;
  }
`;

export const ContentGlobal = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${screens.screen_xs_max}) {
    width: ${base * 19}px;
  }
`;

export const PopupMessage = styled.div`
  display: none;
  position: fixed;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
  border-radius: 12px;
  transition: bottom 0.3s ease-in-out;
  div:first-child {
    border-radius: 12px;
    padding: 16px 42px;
  }
  div:nth-child(2n) {
    margin-top: ${base - 6}px;
  }
  button {
    background: transparent;
    border: none;
    color: ${colors.black};
    font-size: 12px;
    /* background-color: ${colors.whi}; */
    cursor: pointer;
    margin: 0 12px;
    outline: none;
    font-size: 18px;
    &:first-child:hover {
      color: green;
    }
    &:last-child:hover {
      color: ${colors.primary_red};
    }
    &:active {
      outline: none;
    }
  }
  ${(props) =>
    props.show &&
    css`
      bottom: 20px;
      display: block;
    `}
  ${(props) =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `}
`;

export const ModaleShadow = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);

  ${(props) =>
    props.show &&
    css`
      display: block;
    `}
`;

export const Middle = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;

export const InfoBlock = styled.div`
  margin-top: 18px;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 12px;
  }
`;

export const Tabs = styled.p`
  flex-direction: row;
  padding: 0 40px;
`;

export const Tab = styled.button`
  margin-right: 72px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: ${colors.light_gray};
  outline: none;
  &:hover {
    color: ${colors.primary_red};
  }
  &:active {
    color: ${colors.primary_red};
    outline: none;
    border: none;
  }
  ${(props) =>
    props.active &&
    css`
      color: ${colors.primary_red};
    `}
`;

export const FromList = styled.div`
  padding: 0 40px;
  margin: 18px 0 18px;

  p {
    padding: 0;
    padding-bottom: ${base / 2}px;
  }

  input {
    font-size: ${base + 4}px;
  }
  div {
    /* width: 100%; */
  }
  ${(props) =>
    props.type === 'delete' &&
    css`
      input {
        padding-right: 24px;
        padding-left: 10px;
      }
    `}
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const FormListInputWrapper = styled.div`
  margin-bottom: 12px;
`;

export const Paragraph = styled.p`
  margin: 0;
  color: ${colors.black};
  font-size: 32px;
  ${(props) =>
    props.smallParagraph &&
    css`
      font-size: 18px;
      color: ${colors.light_gray};
      padding: 0 40px 3px 40px;
    `}
`;

export const ListBlock = styled.div`
  height: ${base * 23}px;
  overflow: hidden;
  overflow-y: scroll;
`;

export const ListItem = styled.div`
  padding: 3px 40px;
  position: relative;
  flex-direction: row;
  cursor: pointer;
  display: flex;
  p {
    font-size: 18px;
    color: ${colors.black};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ${(props) =>
    props.withoutHidden &&
    css`
      display: block;
      p {
        overflow: visible;
        white-space: normal;
      }
    `}
  &:hover {
    background-color: ${colors.white_smoke};
  }
`;

export const TextArea = styled.textarea`
  background-color: ${colors.white_smoke};
  border: none;
  border-radius: ${base / 2}px;
  font-size: 12px;
  color: ${colors.light_gray};
  padding: 12px 16px;
  resize: none;
  outline: none;
  width: calc(100% - 32px);
  height: 84px;
`;

export const InputWrapper = styled.div`
  width: 80%;
  margin: ${base / 2}px auto 0 auto;
  input {
    font-size: 16px;
  }
`;

export const CheckboxWrapper = styled.div`
  margin: 12px auto;
`;

export const Loaders = styled.div`
  border: 7px solid #dadada;
  border-top: 7px solid #656565;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
  margin-left: 20px;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const AdditionalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const AdditionalButton = styled.span`
  color: ${colors.light_gray};
  font-size: 18px;
  cursor: pointer;
  &:hover {
    color: ${colors.primary_red};
  }
`;

export const FiltersValueDelete = styled.img`
  width: 10px;
  position: absolute;
  top: 6px;
  right: 4px;
  outline: none;
  cursor: pointer;
`;
