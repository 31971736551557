import styled from 'styled-components';

import { colors } from '../../styles';

// export const Content = styled.div`
//   padding: 40px 0 0 48px;
// `;

// export const UserName = styled.span`
//   color: ${colors.primary_red};
// `;

// export const TimeZonesWrapper = styled.div`
//   max-width: ${base * 58.5}px;
//   background-color: ${colors.white};
//   height: ${base * 26}px;
//   border-radius: 12px;
//   padding: ${base * 3.85}px;
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: column;

//   @media (max-width: ${screens.screen_md_mid}) {
//     height: auto;
//     padding: 24px;
//     div {
//       div {
//         width: 100%;
//         padding: 0 24px;
//       }
//     }
//   }
// `;

// export const Greeting = styled.div`
//   margin-bottom: ${base * 3.8}px;
// `;

// export const TimeZonesTitle = styled.h4`
//   color: ${colors.primary_red};
//   font-size: 24px;
//   margin-bottom: 24px;
//   @media (max-width: ${screens.screen_md_mid}) {
//     text-align: center;
//   }
// `;

export const PopupStatusResult = styled.div`
  min-width: 324px;
  max-width: 594px;
  div {
    position: fixed;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
    transition: bottom 0.3s ease-in-out;
    bottom: 20px;
  }
`;

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  div {
    position: fixed;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
    transition: bottom 0.3s ease-in-out;
    bottom: 20px;
  }
  button {
    background: transparent;
    border: none;
    color: ${colors.black};
    font-size: 12px;
    cursor: pointer;
    margin: 0 12px;
    outline: none;
    font-size: 18px;
    &:first-child:hover {
      color: green;
    }
    &:last-child:hover {
      color: ${colors.primary_red};
    }
  }
`;
