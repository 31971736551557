/**
 * @flow
 */
import React from 'react';

import { LoginForm, Logo, TimeZones, Popup } from '../../components';

import {
  Container,
  MainPart,
  SecondaryPart,
  LogoWrapper,
  TimeZonesWrapper,
  AdditionalInformation,
  MainPartContainer,
  PopupMessage,
} from './LoginStyled';

type LoginPagePropsType = {
  setLogin: (any) => void,
  setPassword: (any) => void,
  submitForm: (any) => void,
  error?: string,
  setError: (any) => void,
  isLoading: boolean,
  submitEvent: (any) => void,
  emptyFields: Array<String>,
  setEmptyFields: (any) => void,
  showEmptyFields: boolean,
  setShowEmptyFields: (any) => void,
  showErrorsFields: boolean,
};

const decodingFields = {
  login: 'Логин',
  password: 'Пароль',
};

export default function LoginPage(props: LoginPagePropsType) {
  return (
    <Container background={require('../../assets/images/logo_bg.png')}>
      <MainPart>
        <MainPartContainer>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <LoginForm
            setLogin={props.setLogin}
            setPassword={props.setPassword}
            submitForm={props.submitForm}
            submitEvent={props.submitEvent}
            emptyFields={props.emptyFields}
            showErrorsFields={props.showErrorsFields}
          />
          <AdditionalInformation>
            <p>
              <a href="https://suffix.by/">
                <img alt="Tapston" src="https://suffix.by/img/logos/logo.svg" />
              </a>
            </p>
          </AdditionalInformation>
        </MainPartContainer>
      </MainPart>

      <SecondaryPart>
        <TimeZonesWrapper>
          <TimeZones />
        </TimeZonesWrapper>
      </SecondaryPart>

      <PopupMessage show={props.error} onClick={() => props.setError(null)}>
        <Popup status="Error" message="Неверный логин или пароль" />
      </PopupMessage>

      <PopupMessage show={props.isLoading}>
        <Popup status="WarningLoader" message="Идет запрос" />
      </PopupMessage>

      <PopupMessage
        show={props.showEmptyFields && props.emptyFields.length}
        onClick={() => props.setShowEmptyFields(false)}>
        <Popup
          status="Error"
          message={`Не заполнены поля: ${props.emptyFields
            .map((item) => decodingFields[item])
            .join(', ')}`}
        />
      </PopupMessage>
    </Container>
  );
}

LoginPage.defaultProps = {
  error: '',
};
