import React from 'react';

import { TooltipContainer, TooltipValue } from './TooltipStyled';

export const TooltipView = (props) => {
  const { value } = props;
  if (typeof value !== 'boolean' && !value) {
    return null;
  }
  if (typeof value === 'object') {
    if (Array.isArray(value) && !value.length) {
      return null;
    }
    if (!Array.isArray(value) && !value.name && !value.title) {
      return null;
    }
  }
  if (!props.show) {
    return null;
  }

  let data = '';
  if (typeof value === 'boolean') {
    if (value) {
      data = 'Да';
    } else {
      data = 'Нет';
    }
  }
  if (typeof value === 'string' || typeof value === 'number') {
    data = value;
  }
  if (typeof value === 'object') {
    if (value.title) {
      data = value.title;
    }
    if (value.name) {
      data = value.name;
    }
    if (value.length) {
      data = value.map((item) => item.name).join(', ');
    }
  }

  const coords = { ...props.coords };
  const elemTooltip = document.getElementById('tooltip');
  if (elemTooltip && Object.keys(coords).length) {
    const coordsTooltip = elemTooltip.getBoundingClientRect();
    const elemTable = document.querySelector('.ag-theme-balham');

    if (elemTable) {
      const coordsTable = elemTable.getBoundingClientRect();

      if (coordsTable.right - props.coords.x < coordsTooltip.width + 16) {
        coords.x = coordsTable.right - coordsTooltip.width - 16 - 100;
      }
      if (
        coordsTable.bottom - props.coords.y - 56 <
        coordsTooltip.height + 16
      ) {
        coords.y = coordsTable.bottom - coordsTooltip.height - 16 - 100;
      }
    }
  }

  return (
    <TooltipContainer id="tooltip" coords={coords}>
      <TooltipValue>{data}</TooltipValue>
    </TooltipContainer>
  );
};
