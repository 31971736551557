/**
 * @flow
 */
import React from 'react';

import { StyledTabNavItem } from './TabNavItemStyled';

type TabNavItemPropsType = {
  children: any,
  tabId: number,
  activeTabId: number,
  onSetActiveTab: (number) => void,
};

const TabNavItemView = (props: TabNavItemPropsType) => {
  const setActiveTab = () => {
    props.onSetActiveTab(props.tabId);
  };

  return (
    <StyledTabNavItem
      {...props}
      onClick={setActiveTab}
      active={props.activeTabId === props.tabId}>
      {props.children}
    </StyledTabNavItem>
  );
};

export default TabNavItemView;
