import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

const getColor = (props) => {
  if (props.isDragAccept) {
    return colors.green_light;
  }
  if (props.isDragReject) {
    return colors.red_dark;
  }
  if (props.isDragActive) {
    return colors.purple_light;
  }
  return colors.primary_white_gray;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${base * 2}px;
  min-height: ${base * 4}px;
  border-width: ${base / 6}px;
  border-radius: ${base / 6}px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: ${colors.white_d};
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  ${(props) =>
    props.showIcon &&
    css`
      align-items: flex-start;
    `};
`;

export const ImgIcon = styled.img`
  width: ${base * 13}px;
`;

export const Preview = styled.div`
  position: relative;
`;

export const Progress = styled.progress`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: ${base * 13}px;
  height: ${base / 2}px;
  background-color: ${colors.primary_red};

  &::-webkit-progress-value {
    background-color: ${colors.primary_red};
  }
`;

export const DeleteButton = styled.button`
  position: absolute;
  right: ${base * 4}px;
  top: -8px;
  width: 17px;
  height: 17px;
  border: none;
  outline: none;
  border-radius: 50%;
  background-color: ${colors.primary_red};
  margin-left: 10px;
  background-image: url(${require('../../assets/icons/close-white.svg')});
  background-size: 7px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

export const TextButton = styled.span`
  color: ${colors.blue};

  ${(props) =>
    props.mode === 'cancel' &&
    css`
      position: relative;
      top: ${base / 2}px;
      right: ${-base / 2}px;
      color: ${colors.primary_red};
    `};
`;
