export const numberWithCommas = (num, crop = 2, edit = true) => {
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  if (parts[1]) {
    parts[1] = parts[1].slice(0, crop);
    if (!edit) {
      if (parts[1].length < 2) {
        parts[1] += '0';
      }
    }
  }
  if (!edit) {
    if (!parts[1]) {
      parts[1] = '00';
    }
  }
  return parts.join('.');
};

export const roundingNumber = (num, degree = 2) => {
  if (num && !isNaN(num)) {
    return (+(+num).toFixed(degree + 1)).toFixed(degree);
  }
  return num;
};
