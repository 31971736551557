import styled, { css } from 'styled-components';
import { colors, base, screens } from '../../styles';

export const Form = styled.div`
  background: ${colors.primary_gray};
`;

export const TitleContainerFixed = styled.div`
  z-index: 101;
  position: fixed;
  top: 86px;
  width: 100%;
  background: ${colors.primary_gray};

  @media (max-width: ${screens.screen_xs_max}) {
    margin: 0 0 24px;
  }
`;

export const TitleContainerFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 35px 12px;
  background: ${colors.primary_gray};
  width: 100%;

  @media (max-width: ${screens.screen_md_mid}) {
    width: 40%;
  }
  @media (max-width: ${screens.screen_2xs_max}) {
    width: 70%;
    padding: 5px 12px;
  }
  @media (max-width: ${screens.screen_4xs_max}) {
    width: 70%;
    padding: 35px 12px;
  }
`;

export const CalendarMask = styled.div`
  position: fixed;
  width: 95%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
`;

export const TitleText = styled.h1`
  margin: 0;
  margin-right: 60px;
  font-size: 42px;
  font-weight: 600;

  @media (max-width: ${screens.screen_3xs_max}) {
    margin-right: 0;
  }

  @media (max-width: ${screens.screen_xs_max}) {
    font-size: 24px;
    width: 100%;
    margin-bottom: 18px;
  }
`;

export const TitleButton = styled.div`
  margin-right: 10px;

  @media (max-width: ${screens.screen_xs_max}) {
    margin-top: 4px;
  }
`;

export const CalcValue = styled.div`
  font-size: 20px;
  font-weight: 800;
  ${(props) => {
    if (props.value < 0) {
      return css`
        color: ${colors.primary_red};
      `;
    }
    return false;
  }}
`;

export const StateContainer = styled.div`
  background: ${colors.white};
  padding: 14px 0 16px 16px;
  margin-top: 100px;

  @media (max-width: 1092px) {
    margin-top: 185px;
  }
`;

export const StateButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 3px;
`;

export const OuterFormCheckboxWrapper = styled.div`
  margin-top: ${base * 1.6}px;
`;

export const StateButton = styled.button`
  font-size: 13px;
  color: ${colors.history_gray};
  white-space: nowrap;
  background-color: ${colors.white};
  outline: none;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 10px 0 0;
  border: 1px solid #b8b8b8;
  border-radius: 3px;
  text-align: center;
  margin-top: 8px;
  color: ${colors.black};
  &:focus {
    border: 1px solid ${colors.gray_dark};
  }
  ${(props) => {
    if (props.active) {
      switch (props.status) {
        case 'Предварительное':
          return css`
            background-color: #a9d08e;
            border: 1px solid #a9d08e;
          `;
        case 'В пути':
          return css`
            background-color: #ffff00;
            border: 1px solid #ffff00;
          `;
        case 'Прибыл':
          return css`
            background-color: #da9d0a;
            border: 1px solid #da9d0a;
          `;
        case 'Выдан клиенту':
          return css`
            background-color: #0ada35;
            border: 1px solid #0ada35;
          `;
        case 'Прогон':
          return css`
            background-color: #f4b084;
            border: 1px solid #f4b084;
          `;
        case 'Закрыта':
          return css`
            background-color: #808080;
            border: 1px solid #808080;
          `;
        case 'Вывоз':
          return css`
            background-color: #f58223;
            border: 1px solid #f58223;
          `;
        default:
          return css`
            background-color: #aeda9b;
            border: 1px solid #b8b8b8;
          `;
      }
    }
    return true;
  }}
`;

export const BasicInformationContainer = styled.div`
  padding: 20px 20px 0 0;
  border-top: 190px solid transparent;
  margin-top: -190px;
  @media (max-width: ${screens.screen_xs_max}) {
    padding: 20px 0 0 0;
  }
`;

export const CargoInformationContainer = styled.div`
  padding: 20px 20px 0 0;
  border-top: 190px solid transparent;
  margin-top: -190px;
  @media (max-width: ${screens.screen_xs_max}) {
    padding: 20px 0 0 0;
  }
`;

export const AdditionalServicesContainer = styled.div`
  padding: 20px 20px 0 0;
  border-top: 190px solid transparent;
  margin-top: -190px;
  @media (max-width: ${screens.screen_xs_max}) {
    padding: 20px 0 0 0;
  }
`;

export const CountingContainer = styled.div`
  padding: 20px 20px 0 0;
  border-top: 190px solid transparent;
  margin-top: -190px;
  @media (max-width: ${screens.screen_xs_max}) {
    padding: 20px 0 0 0;
  }
`;

export const ContainerTitle = styled.div`
  padding-bottom: 20px;
  font-weight: 600;
`;

export const ContainerValues = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div > div {
    margin-bottom: 8px !important;
  }
`;

export const ContainerValue = styled.div`
  position: relative
  width: 250px;
  margin: 0 60px 20px 0;

  & {
    div:nth-child(3) {
      top: 70px;
    }
  }
  @media (max-width: ${screens.screen_xs_max}) {
    width: 100%;
    margin: 0 0 20px 0;
  }
`;

export const ContainerValueCalendar = styled.div`
  position: relative
  width: 250px;
  margin: 0 60px 20px 0;

  @media (max-width: ${screens.screen_xs_max}) {
    width: 100%;
    margin: 0 0 20px 0;
  }
`;

export const ValueTitle = styled.div`
  margin-bottom: 8px;
  font-size: 13px;
`;

export const Select = styled.select`
  width: 100%;
  height: ${base * 3.6}px;
  outline: none;
  color: ${colors.black};
  padding: 0 12px;
  border: none;
  border-radius: ${base / 2}px;
  background-color: ${colors.white_smoke};
`;

export const AutocompleteItem = styled.div`
  padding: 6px;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: ${colors.primary_red};
  }
`;

export const ValueOptions = styled.div`
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  width: 242px;
  max-height: 165px;
  margin-top: 5px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 10px 20px #efefef;
  padding: 6px 4px;
`;

export const OptionValues = styled.div`
  padding: 3px 5px 3px 5px;

  &:active {
    color: ${colors.primary_red};
  }
`;

export const CalendarContainer = styled.div`
  position: absolute;
  z-index: 100;

  ${(props) =>
    props.lastCalendar &&
    css`
      .rc-calendar {
        top: -400px;
      }
    `}
  ${(props) => {
    setTimeout(() => {
      const elem = document.querySelector('.calendar-container');
      if (elem) {
        const coords = elem.getBoundingClientRect();
        if (coords.bottom > document.body.clientHeight) {
          elem.style.bottom = '50px';
        }
      }
    }, 0);
  }}
`;

export const AddFieldButton = styled.button`
  width: 17px;
  height: 17px;
  border: none;
  outline: none;
  border-radius: 50%;
  background-color: #da350a;
  margin-left: 10px;
  background-image: url(${require('../../assets/icons/close-white.svg')});
  transform: rotate(45deg);
  background-size: 7px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

export const DeleteFieldButtonContainer = styled.div`
  position: relative;
  left: -72px;

  @media (max-width: ${screens.screen_sm_mid}) {
    left: 240px;
    top: -70px;
    margin-right: 58px;
  }
`;

export const DeleteFieldButton = styled.button`
  width: 17px;
  height: 17px;
  border: none;
  outline: none;
  border-radius: 50%;
  background-color: #da350a;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: 7px;
    height: 1px;
    background-color: #fff;
    top: 8px;
    left: 5px;
  }
`;

export const ContainerValueButton = styled.div`
  position: relative
  width: 250px;
  margin: auto 0;

  @media (max-width: ${screens.screen_md_mid}) {
    left: -70px;
  }
  @media (max-width: ${screens.screen_sm_mid}) {
    bottom: 92px;
    left: 268px;
    margin-top: 12px;
  }
`;

export const ContainerFlex = styled.div`
  display: flex;

  @media (max-width: ${screens.screen_md_mid}) {
    flex-wrap: wrap;
  }
`;
