import { apiPost, apiGet, apiPut, apiDelete } from '../ApiService';

export default {
  getTemplates(params) {
    return apiGet('/userstemplates', { params })
      .then((response) => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  getTemplateById(id) {
    return apiGet(`/userstemplates/${id}`)
      .then((response) => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  addTemplate(data) {
    return apiPost('/userstemplates', data)
      .then((response) => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  updateTemplate(id, data) {
    return apiPut(`/userstemplates/${id}`, data)
      .then((response) => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
  deleteTemplate(id) {
    return apiDelete(`/userstemplates/${id}`)
      .then((response) => ({ result: response.data, status: response.status }))
      .catch((error) => {
        throw new Error(error.response.data.error.message);
      });
  },
};
