import styled, { css } from 'styled-components';

import { base, colors } from '../../../styles';

export const SideBarItemWrapper = styled.div`
  color: #747474;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: ${base * 20}px;
  height: 48px;
  border-left: 6px solid transparent;
  ${(props) =>
    props.active &&
    css`
      border-left: 6px solid ${colors.primary_red};
      background-color: #f4f4f4;
      color: ${colors.primary_red};
    `}
  ${(props) =>
    props.toggleLoad &&
    css`
      pointer-events: none;
    `}
`;

export const IconSection = styled.div`
  margin: 0 0 0 32px;
`;

export const TextSection = styled.div`
  width: ${base * (20 - 7)}px;
  text-align: left;
  margin: 0 32px;
`;

export const Image = styled.img`
  width: 24px;
  height: 24px;
  display: block;
`;

export const Name = styled.p`
  height: auto;
`;
