import styled, { css } from 'styled-components';
import { base, colors, screens } from '../../../styles';

const clientHeight =
  document.documentElement.clientHeight || document.body.clientHeight;

export const Container = styled.div`
  .ag-watermark {
    display: none;
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
`;

export const Text = styled.p`
  margin-top: ${base}px;
  margin-bottom: ${base * 2}px;
`;

export const Header = styled.div``;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Body = styled.div`
  margin-bottom: ${base * 2}px;
  width: 80%;
`;

export const Footer = styled.div``;

export const ContainerGrid = styled.div`
  height: ${clientHeight - 320}px;
`;

export const ContainerLoader = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleButton = styled.div`
  margin-right: ${base * 2}px;
  margin-left: 52px;

  ${(props) =>
    props.white &&
    css`
      margin-right: 0;
      margin-left: 0;
      @media (max-width: ${screens.screen_xs_max}) {
        display: none;
      }
    `}
`;

export const ContainerButtons = styled.div`
  display: flex;
  margin-bottom: ${base * 2}px;

  button {
    width: ${base * 11}px;
    justify-content: center;
  }

  button:nth-child(2) {
    margin-left: ${base}px;
  }
`;

export const ContainerTabs = styled.div``;

export const Input = styled.input`
  display: none;
`;

export const Lable = styled.label`
  display: inline-block;
  width: ${base * 7}px;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: ${colors.gray_3};
  border: 1px solid ${colors.gray_2};
  padding: ${base / 6}px ${(base / 3) * 2}px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  cursor: pointer;
  position: relative;
  top: 1px;
  z-index: 1;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 700;
  font-size: 12px;
  font-family: 'Segoe UI';

  &:not(:first-of-type) {
    border-left: none;
  }

  ${(props) =>
    props.sheet === props.currentSheet &&
    css`
      background-color: ${colors.gray_1};
      border-bottom: 1px solid ${colors.gray_1};
    `}
`;
