import styled, { css } from 'styled-components';

import { base, colors } from '../../styles';

export const Cross = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  margin-left: 6px;
  position: absolute;
  height: 40px;
  overflow: hidden;
  ${(props) =>
    !props.isActive &&
    css`
      & > button {
        height: 18px;
        margin: 0;
        display: none;
        background-size: 22%;
      }
      & > button:hover {
        background: url(${require('../../assets/icons/close-hover.svg')});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 22%;
        height: 18px;
      }
    `}
  ${(props) =>
    props.isActive &&
    css`
    &>button{
      display: none;
    }
    &>button:hover{
      display
    }
  `}
`;

export const PayerForm = styled.div`
  height: 56px;
  width: 100%;
  padding-left: 24px;
  background: ${colors.white};
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
`;

export const Payer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  &::before {
    content: '';
    height: 40px;
    width: 6px;
    background-color: ${colors.white};
  }
  &:nth-child(2n) {
    ${PayerForm} {
      background: ${colors.primary_gray};
    }
    &::before {
      background-color: ${colors.primary_gray};
    }
    ${(props) =>
      props.isActive &&
      css`
        &::before {
          background-color: ${colors.primary_red};
        }
      `};
  }
  &:hover {
    ${Cross} {
      & > button {
        display: flex;
      }
      ${(props) =>
        props.isActive &&
        css`
          & > button {
            display: none;
          }
        `}
    }
  }
  ${(props) =>
    props.isActive &&
    css`
      &::before {
        background-color: ${colors.primary_red};
      }
    `};
`;

export const NameCompany = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const CountersCompany = styled.div`
  display: flex;
  font-weight: normal;
  margin-top: 2px;
  font-size: 11px;
`;

export const Counter = styled.p`
  margin: 0;
  margin-right: 24px;
  color: #878787;
`;

export const Name = styled.p`
  margin: 0;
`;
