/**
 * @flow
 */

import React from 'react';

import { InputCheckBox, CheckBox, LabelCheckBox } from './CheckboxStyled';

type CheckboxViewPropsType = {
  message: any,
  status?: boolean,
  setCheck: (any) => void,
  onClickItem: (any) => void,
  disabled: boolean,
};

export default function CheckboxView(props: CheckboxViewPropsType) {
  return (
    <CheckBox
      onClick={() => {
        props.setCheck(!props.status);
        props.onClickItem(!props.status);
      }}
      disabled={props.disabled}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          props.setCheck(!props.status);
          props.onClickItem(!props.status);
        }
      }}
      status={props.status}>
      <InputCheckBox status={props.status} tabIndex="0" />
      <LabelCheckBox>{props.message}</LabelCheckBox>
    </CheckBox>
  );
}

CheckboxView.defaultProps = {
  status: false,
};
