/**
 * @flow
 */
import React from 'react';

import { Button } from '../index';

import {
  LinkedContainer,
  LinkedItemHover,
  LinkedItemDefolt,
} from './LinkedItemStyled';

type LinkedItemPropsType = {
  address?: string,
  action: () => void,
  setNeedPadding: (boolean) => void,
};

export default function LinkedItemView(props: LinkedItemPropsType) {
  return (
    <LinkedContainer>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <LinkedItemHover className="linked-item-hover">
          <span>{props.address}</span>
        </LinkedItemHover>
      </div>
      <LinkedItemDefolt>
        <Button cross clicked={() => props.action()} />
        <span
          onMouseLeave={() => props.setNeedPadding(false)}
          onMouseEnter={() => props.setNeedPadding(true)}>
          {props.address}
        </span>
      </LinkedItemDefolt>
    </LinkedContainer>
  );
}

LinkedItemView.defaultProps = {
  address: '',
};
