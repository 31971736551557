import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';

// view
import MainView from './MainView';

// actions
import {
  setActiveSidebarItem,
  setSecondaryBarItems,
} from '../../../store/actions/dashboard';

// services
import { UserService } from '../../../services';

import { saveUserAccesses } from '../../../store/actions/auth';

export default compose(
  connect(
    (state) => ({
      user: state.auth.currentUser,
    }),
    (dispatch) => ({
      setActiveSidebarItem: (item) => dispatch(setActiveSidebarItem(item)),
      setSecondaryBarItems: (items) => dispatch(setSecondaryBarItems(items)),
      saveUserAccesses: (data) => dispatch(saveUserAccesses(data)),
    }),
  ),

  lifecycle({
    componentDidMount() {
      this.props.setActiveSidebarItem('');
      this.props.setSecondaryBarItems([]);
      UserService.getRight()
        .then((item) => {
          this.props.saveUserAccesses({
            directory: item.result.directories,
            requests: item.result.requests,
            additionalPermissions: item.result.additionalPermissions,
          });
        })
        .catch((err) => console.log('err', err));
    },
  }),
)(MainView);
