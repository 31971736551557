// Auth
export const UPDATE_REFRESH_TOKEN = 'UPDATE_REFRESH_TOKEN';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const SAVE_USER_ACCESSES = 'SAVE_USER_ACCESSES';
export const CLEAN_USER_DATA = 'CLEAN_USER_DATA';

// Users
export const SAVE_USERINFO_FIRSTNAME_DATA = 'SAVE_USERINFO_FIRSTNAME_DATA';
export const SAVE_USERINFO_LASTNAME_DATA = 'SAVE_USERINFO_LASTNAME_DATA';
export const SAVE_USERINFO_LOGIN_DATA = 'SAVE_USERINFO_LOGIN_DATA';
export const SAVE_USERINFO_PHONE_NUMBER_DATA =
  'SAVE_USERINFO_PHONE_NUMBER_DATA';
export const SAVE_USERINFO_EMAIL_DATA = 'SAVE_USERINFO_EMAIL_DATA';
export const SAVE_USERINFO_POSITION_DATA = 'SAVE_USERINFO_POSITION_DATA';
export const SAVE_USERINFO_AVATAR_DATA = 'SAVE_USERINFO_AVATAR_DATA';
export const SAVE_USERINFO_PASSWORD_DATA = 'SAVE_USERINFO_PASSWORD_DATA';
export const SAVE_USERINFO_PASSWORD_REPEAT_DATA =
  'SAVE_USERINFO_PASSWORD_REPEAT_DATA';

// Dashboard
export const SET_ACTIVE_SIDEBAR_ITEM = 'SET_ACTIVE_SIDEBAR_ITEM';
export const SET_ACTIVE_SECONDARY_SIDEBAR_ITEM =
  'SET_ACTIVE_SECONDARY_SIDEBAR_ITEM';
export const SET_SECONDARY_BAR_ITEMS = 'SET_SECONDARY_BAR_ITEMS';
export const CHECK_STATUS_POPUP = 'CHECK_STATUS_POPUP';
export const NEED_PADDING = 'NEED_PADDING';
export const SET_EXPAND = 'SET_EXPAND';
export const SHOW_NEW_REQUEST = 'SHOW_NEW_REQUEST';
export const TOGGLE_DROP = 'TOGGLE_DROP';
export const TOGGLE_LOAD = 'TOGGLE_LOAD';
export const SET_OPEN_FILTER_FIELD = 'SET_OPEN_FILTER_FIELD';
export const SET_QUANTITY_REQUESTS = 'SET_QUANTITY_REQUESTS';
export const SET_SHOW_WARNING_SAVE_REQUEST = 'SET_SHOW_WARNING_SAVE_REQUEST';
export const SET_CALLBACK_EXIT_REQUEST = 'SET_CALLBACK_EXIT_REQUEST';
export const SET_DATA_REQUEST = 'SET_DATA_REQUEST';
export const SET_CHANGED_DATA_REQUEST = 'SET_CHANGED_DATA_REQUEST';
export const SET_DATA_PAYMENT_REQUEST = 'SET_DATA_PAYMENT_REQUEST';
export const SET_CHANGED_DATA_PAYMENT_REQUEST =
  'SET_CHANGED_DATA_PAYMENT_REQUEST';
export const SET_EXCEL_DATA = 'SET_EXCEL_DATA';
export const SET_EXCEL_ROW_INDEX = 'SET_EXCEL_ROW_INDEX';

// Request
export const SHOW_RATING_MODAL = 'SHOW_RATING_MODAL';
export const SET_LAST_INDEX_ROW_REQUEST = 'SET_LAST_INDEX_ROW_REQUEST';
export const SET_ALL_FILTER_MODEL = 'SET_ALL_FILTER_MODEL';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const SET_DATE_FILTERS = 'SET_DATE_FILTERS';
export const SET_RANGE_DATE = 'SET_RANGE_DATE';
export const SET_DATE_FILTERS_ACTIVE = 'SET_DATE_FILTERS_ACTIVE';
export const SET_SELECTED_STATUS = 'SET_SELECTED_STATUS';
export const SET_SORT_MODEL = 'SET_SORT_MODEL';
export const SET_SEARCH_MODEL = 'SET_SEARCH_MODEL';
export const SET_OTHER_FILTERS = 'SET_OTHER_FILTERS';
export const SET_SORT_MODEL_BY_LOGIN = 'SET_SORT_MODEL_BY_LOGIN';
export const SET_PAYERS = 'SET_PAYERS';
