/**
 * @flow
 */
import React from 'react';
import validator from 'validator';

import { Input, Button, Loader } from '../../../../components';
import {
  ContentPersonal,
  InfoTitle,
  InfoMain,
  Header,
  UserAvatar,
  OptionProfile,
  DownloadAvatar,
  DeleteAvatar,
  Main,
  LeftBlockInfo,
  RightBlockInfo,
  TitleInfoProgile,
  InfoHeader,
  InputBorder,
} from './PersonalInfoStyled';

type PersonalInfoPropsType = {
  myAvatar: (any) => void,
  saveIMG?: any,
  statusCheckInfo: (any) => void,
  setSaveIMG: (any) => void,
  firstName: string,
  lastName: string,
  position: string,
  login: string,
  phoneNumber: string,
  email: string,
  setFirstName: (any) => void,
  setLastName: (any) => void,
  setPhoneNumber: (any) => void,
  setEmail: (any) => void,
  saveProfileData: (any) => void,
  deleteAvatar: (any) => void,
  avatarId: String,
  setAvatar: (any) => void,
  showLoader: Boolean,
};

export default function PersonalInfo(props: PersonalInfoPropsType) {
  return (
    <ContentPersonal>
      <InfoHeader>
        <InfoTitle>
          <span>Личная</span>
          <span>информация</span>
        </InfoTitle>
        <Button
          clicked={() => {
            console.log('clicked');
            props.saveProfileData();
          }}
          mode="red">
          Сохранить
        </Button>
      </InfoHeader>
      <InfoMain>
        <Header>
          <UserAvatar avatar={props.saveIMG}>
            {props.showLoader && (
              <div
                style={{
                  position: 'absolute',
                  left: '40%',
                  top: '40%',
                  zIndex: 1,
                }}>
                <Loader width={3} height={3} marginLeft={0} />
              </div>
            )}
          </UserAvatar>
          <OptionProfile>
            <DownloadAvatar>
              <label
                htmlFor="load-avatar"
                title="Загрузить"
                style={{
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                }}
              />
              <input
                id="load-avatar"
                type="file"
                name="photo"
                multiple=""
                accept="image/*,image/jpeg"
                onChange={props.myAvatar}
                title="Загрузить"
                style={{ display: 'none' }}
              />
            </DownloadAvatar>
            <DeleteAvatar
              onClick={() => {
                props.deleteAvatar(props.avatarId);
                props.setAvatar('');
              }}
              title="Удалить"
            />
          </OptionProfile>
        </Header>
        <Main>
          <LeftBlockInfo>
            <TitleInfoProgile>Имя</TitleInfoProgile>
            <InputBorder valid={!!props.firstName}>
              <Input
                type="text"
                value={props.firstName}
                placeholder="Имя работника"
                changed={(value) => {
                  props.setFirstName(value);
                  setTimeout(() => {
                    props.statusCheckInfo(value.lenght);
                  }, 0);
                }}
              />
            </InputBorder>
            <TitleInfoProgile>Должность</TitleInfoProgile>
            <Input
              type="text"
              placeholder="Должность"
              value={props.position}
              changed={(value) => console.log(value)}
              readOnlyState
            />
            <TitleInfoProgile>Телефон</TitleInfoProgile>
            <InputBorder
              valid={
                !!props.phoneNumber &&
                validator.isMobilePhone(props.phoneNumber)
              }>
              <Input
                type="text"
                placeholder="Телефон работника"
                value={props.phoneNumber}
                changed={(value) => {
                  props.setPhoneNumber(value);
                  setTimeout(() => {
                    props.statusCheckInfo(value.lenght);
                  }, 0);
                }}
              />
            </InputBorder>
          </LeftBlockInfo>
          <RightBlockInfo>
            <TitleInfoProgile>Фамилия</TitleInfoProgile>
            <InputBorder valid={!!props.lastName}>
              <Input
                type="text"
                placeholder="Фамилия работника"
                value={props.lastName}
                changed={(value) => {
                  props.setLastName(value);
                  setTimeout(() => {
                    props.statusCheckInfo(value.lenght);
                  }, 0);
                }}
              />
            </InputBorder>
            <TitleInfoProgile>Логин</TitleInfoProgile>
            <Input
              readOnly
              type="text"
              placeholder="Укажите логин"
              value={props.login}
              changed={(value) => console.log(value)}
              readOnlyState
            />
            <TitleInfoProgile>Электронная почта</TitleInfoProgile>
            <InputBorder
              valid={
                !!props.email &&
                validator.isEmail(props.email) &&
                /^[a-zA-Z0-9.-_@]+$/.test(props.email)
              }>
              <Input
                type="text"
                placeholder="Почта работника"
                value={props.email}
                changed={(value) => {
                  props.setEmail(value);
                  setTimeout(() => {
                    props.statusCheckInfo(value.lenght);
                  }, 0);
                }}
              />
            </InputBorder>
          </RightBlockInfo>
        </Main>
      </InfoMain>
      {/* <PopupSaveResult>
        {props.saveResualt && (<Popup status="Success">Сохранено!</Popup>)}
      </PopupSaveResult> */}
    </ContentPersonal>
  );
}

PersonalInfo.defaultProps = {
  saveIMG: require('../../../../assets/images/default_avatar.png'),
};
